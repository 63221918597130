import React from 'react'
import { Trans } from '@lingui/macro'
import { atob, isBase64Encoded } from '../../../utils/index'
import Tag from '../../utils/Tag'
import DrawerDetailsItem, {
    DrawerDetailsItemText,
} from '../../utils/DrawerDetailsItem'
import RequestParamTableRow from '../../utils/RequestParamTableRow'
import Avatar from '../../utils/Avatar'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import Tooltip from '../../utils/Tooltip'

export default function ScanRequestDetailsDrawerTabDetails({ scanRequest }) {
    const {
        method,
        audit,
        scan,
        session,
        resourceType,
        rootDomain,
        scanPage,
        initiator,
    } = scanRequest

    const url = new URL(scanRequest.url)
    const { pathname, searchParams } = url
    const decodedSearchParams = Array.from(url.searchParams.entries()).map(
        ([key, value]) => [key, isBase64Encoded(value) ? atob(value) : value]
    )
    // const requestSearchParams = Array.from(searchParams.entries()).map(
    //     ([key, value]) => ({
    //         request: key,
    //         value,
    //     })
    // )
    return (
        <>
            {audit && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Audit</Trans>}
                >
                    <div className="flex items-center gap-2">
                        <Avatar size="xs" src={audit.imageUrl} />
                        {audit.name}
                    </div>
                </DrawerDetailsItemText>
            )}

            {scan && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Scan</Trans>}>
                    <Tag type="primary">{`${scan.startedAt.split('T')[0]} - ${
                        scan.consentProfile.name
                    }`}</Tag>
                </DrawerDetailsItemText>
            )}
            {session && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Session</Trans>}
                >
                    <Tag type="primary">{`${
                        session.startedAt.split('T')[0]
                    } - ${session.consentProfile.name}`}</Tag>
                </DrawerDetailsItemText>
            )}
            {scanPage && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Page</Trans>}>
                    {scanPage ? new URL(scanPage?.url).pathname : 'N/A'}
                </DrawerDetailsItemText>
            )}
            {method && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Method</Trans>}
                >
                    {method}
                </DrawerDetailsItemText>
            )}
            {resourceType && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Type</Trans>}>
                    {resourceType}
                </DrawerDetailsItemText>
            )}
            {initiator && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Initiator Type</Trans>}
                >
                    <Tag type="primary">{initiator.type}</Tag>
                </DrawerDetailsItemText>
            )}
            {initiator && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Initiator Path</Trans>}
                >
                    <Tooltip event="hover" content={initiator.url}>
                        {initiator.url}
                    </Tooltip>
                </DrawerDetailsItemText>
            )}
            {rootDomain && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Domain</Trans>}
                >
                    {rootDomain}
                </DrawerDetailsItemText>
            )}
            {pathname && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Path</Trans>}>
                    {pathname}
                </DrawerDetailsItemText>
            )}
            {url && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Full URL</Trans>}
                >
                    <Tooltip
                        event="hover"
                        position="bottom-end"
                        content={
                            <div className="max-w-[400px] overflow-hidden break-words">
                                {scanRequest.url}
                            </div>
                        }
                        contentClassName="max-w-[400px]"
                    >
                        {scanRequest.url}
                    </Tooltip>
                </DrawerDetailsItemText>
            )}

            {/* <DrawerDetailsItem>
                {requestSearchParams.length !== 0 && (
                    <>
                        <div className="flex flex-row gap-2 mb-4">
                            <h3>
                                <Trans>Request Parameters</Trans>
                            </h3>
                            <Icon name="star-01" color="primary" size="sm" />
                        </div>

                        <Table hasMinWidth={false} isRounded hasHeader={false}>
                            <TableBody>
                                {requestSearchParams.map((requestInfo) => {
                                    const { request, value } = requestInfo
                                    return (
                                        <TableRow key={request}>
                                            <TableCell
                                                py="sm"
                                                className="flex flex-row gap-10"
                                            >
                                                <div className="w-32 gap-1 text-end">
                                                    <Trans>{request}</Trans>
                                                </div>
                                                <div className="grow gap-1 text-start">
                                                    <Trans>{value}</Trans>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </>
                )}
            </DrawerDetailsItem> */}

            {decodedSearchParams.length > 0 && (
                <DrawerDetailsItem>
                    <div className="mb-4 flex flex-row gap-2">
                        <h3>Request Parameters</h3>
                    </div>
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {decodedSearchParams.map(([key, value]) => (
                                <RequestParamTableRow
                                    paramName={key}
                                    paramValue={value}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </DrawerDetailsItem>
            )}
        </>
    )
}
