import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import DateTime from '../../utils/DateTime'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import AlertCountTag from '../../utils/AlertCountTag'
import Tabs, { Tab } from '../../utils/Tabs'
import ScanPageDetailsDrawerTabAlerts from '../scans/ScanPageDetailsDrawerTabAlerts '
import ScanPageDetailsDrawerTabRequests from '../scans/ScanPageDetailsDrawerTabRequests'
import ScanPageDetailsDrawerTabCookies from '../scans/ScanPageDetailsDrawerTabCookies'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'

const QUERY = gql`
    query pageRequestsPage($sessionId: ID!, $after: String) {
        session(id: $sessionId) {
            id
            startedAt
            consentProfile {
                name
            }
            audit {
                id
                name
            }
            deviceType
            requests {
                id
                method
                resourceType
                url
                search
                alerts {
                    id
                    type
                    severityType
                    message
                }
            }
            cookies {
                id
                type
                rootDomain
                name
                value
                retention
                tracker {
                    id
                    domain
                    purposes {
                        id
                        name
                    }
                    vendor {
                        id
                        name
                        imageUrl
                    }
                }
                cookie {
                    id
                    purposes {
                        id
                        name
                    }
                    vendor {
                        id
                        name
                        imageUrl
                    }
                }
                alerts {
                    id
                    type
                    severityType
                    message
                }
            }
        }
        alerts(sessionId: $sessionId, after: $after) {
            edges {
                node {
                    id
                    scan {
                        id
                    }
                    groupingId
                    type
                    severityType
                    subjectName
                    subjectType
                    message
                    occurrences
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function AuditSessionsDrawer({
    sessionId,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'alerts'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { sessionId }
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(QUERY, {
        variables,
    })

    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />

    const { id, deviceType, audit, createdAt, requests, cookies } = data.session
    const alerts = data.alerts.edges.map(({ node }) => node)
    return (
        <>
            <DrawerHeader
                avatarSrc={audit.imageUrl}
                avatarIconName="scan"
                title={deviceType}
                description={
                    createdAt ? (
                        <DateTime>{createdAt}</DateTime>
                    ) : (
                        'No creation date found'
                    )
                }
                // buttons={
                //     <Button
                //         type="white"
                //         size="sm"
                //         iconBeforeProps={{
                //             name: 'menu-dots',
                //             size: 'sm',
                //         }}
                //     />
                // }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                <Tab
                    isActive={activeTab === 'cookies'}
                    onClick={() => setActiveTab('cookies')}
                >
                    <Trans>Cookies</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'requests'}
                    onClick={() => setActiveTab('requests')}
                >
                    <Trans>Requests</Trans>
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === 'alerts' && (
                    <ScanPageDetailsDrawerTabAlerts
                        level="SESSION"
                        alerts={alerts}
                    />
                )}
                {activeTab === 'cookies' && (
                    <ScanPageDetailsDrawerTabCookies cookies={cookies} />
                )}
                {activeTab === 'requests' && (
                    <ScanPageDetailsDrawerTabRequests requests={requests} />
                )}
            </DrawerContent>
        </>
    )
}
