import React from 'react'
import clsx from 'clsx'
import { useLocation, Link, useRouteMatch } from 'react-router-dom'
import Icon from '../utils/Icon'

export default function SidebarMenuItem({
    children,
    to,
    disabled,
    iconName,
    as: Component = Link,
    isActive: isActiveProp = false,
    matchPath,
    className,
    ...props
}) {
    const { search } = useLocation()
    const isMatch = useRouteMatch({
        path: matchPath || to,
        exact: true,
    })
    const isActive = isActiveProp || isMatch
    return (
        <Component
            exact
            matchPath={to}
            to={`${to}${search}`}
            className={clsx(
                'flex items-center gap-2 px-2.5 whitespace-nowrap rounded-lg hover:bg-gray-100 relative group font-medium text-sm h-9 ml-3',
                isActive ? 'text-slate-950' : 'text-slate-500',
                disabled && 'pointer-events-none',
                className
            )}
            {...props}
        >
            <div
                className={clsx(
                    'h-0 w-[2px] bg-primary absolute -left-3 top-1/2 -translate-y-1/2 transition-height duration-200',
                    isActive ? 'h-full' : 'h-0'
                )}
            />
            <Icon
                name={iconName}
                size="sm"
                className={clsx(
                    'flex-shrink-0',
                    isActive ? 'text-primary-600' : 'text-slate-600'
                )}
            />
            <span className="hidden sm:inline">{children}</span>
        </Component>
    )
}
