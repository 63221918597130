import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteLoginProfileModal from './DeleteLoginProfileModal'
import useDeleteLoginProfile from './useDeleteLoginProfile'

const DeleteLoginProfileModalWithState = ({
    loginProfile,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteLoginProfile, { loading }] = useDeleteLoginProfile({
        onCompleted,
        onError,
    })
    return (
        <DeleteLoginProfileModal
            onDismiss={onDismiss}
            loginProfile={loginProfile}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { id: loginProfile.id }
                    await deleteLoginProfile({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {loginProfile.name} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteLoginProfileModalWithState
