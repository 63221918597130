/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DropdownMenuTitle from '../../utils/DropdownMenuTitle'
import ActionButton from '../../utils/ActionButton'

const AuditAlertsPageTableRowActions = ({
    vendor,
    tracker,
    cookie,
    canIgnoreAlert,
    canReviewAlert,
    canUpdateTracker,
    canDeleteTracker,
    canUpdateCookie,
    canDeleteCookie,
    isIgnored,
    isLoading,
    isReviewed,
    onIgnoreAlert,
    onReviewAlert,
    onUpdateTracker,
    onDeleteTracker,
    onUpdateVendor,
    onUpdateOrganizationVendor,
    onUpdateOrganizationTracker,
    onDeleteOrganizationTracker,
    onUpdateCookie,
    onDeleteCookie,
    onReclassifyCookieForOrganization,
    onReclassifyTrackerForOrganization,
    onUpdateOrganizationCookie,
    onDeleteOrganizationCookie,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <>
                {(vendor || tracker || cookie) && (
                    <DropdownMenuTitle>
                        <Trans>Staff actions</Trans>
                    </DropdownMenuTitle>
                )}
                {vendor && (
                    <DropdownMenuItem
                        iconName="building-07"
                        disabled={
                            vendor === null || vendor.belongsToOrganization
                        }
                        onClick={(e) => {
                            onUpdateVendor(tracker)
                            close()
                            e.stopPropagation()
                        }}
                    >
                        <Trans>Update vendor</Trans>
                    </DropdownMenuItem>
                )}
                {tracker && (
                    <>
                        <DropdownMenuItem
                            iconName="edit"
                            disabled={!canUpdateTracker}
                            onClick={(e) => {
                                onUpdateTracker(tracker)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Update tracker classification</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="x"
                            disabled={!canDeleteTracker}
                            onClick={(e) => {
                                onDeleteTracker(tracker)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Remove tracker classification</Trans>
                        </DropdownMenuItem>
                    </>
                )}
                {cookie && (
                    <>
                        <DropdownMenuItem
                            iconName="edit"
                            disabled={!canUpdateCookie}
                            onClick={(e) => {
                                onUpdateCookie(cookie)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Update cookie classification</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="x"
                            disabled={!canDeleteCookie}
                            onClick={(e) => {
                                onDeleteCookie(cookie)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Remove cookie classification</Trans>
                        </DropdownMenuItem>
                    </>
                )}
                <DropdownMenuTitle>
                    <Trans>Organization actions</Trans>
                </DropdownMenuTitle>
                <DropdownMenuItem
                    iconName={
                        isIgnored ? 'switch-horizontal-01' : 'mute-notification'
                    }
                    disabled={!canIgnoreAlert}
                    onClick={async (e) => {
                        e.stopPropagation()
                        await onIgnoreAlert()
                        close()
                    }}
                >
                    {isIgnored ? (
                        <Trans>Unignore alert</Trans>
                    ) : (
                        <Trans>Ignore alert</Trans>
                    )}
                </DropdownMenuItem>
                {vendor && (
                    <DropdownMenuItem
                        iconName="building-07"
                        disabled={
                            vendor === null || !vendor.belongsToOrganization
                        }
                        onClick={(e) => {
                            onUpdateOrganizationVendor(tracker)
                            close()
                            e.stopPropagation()
                        }}
                    >
                        <Trans>Update vendor for this organization</Trans>
                    </DropdownMenuItem>
                )}
                {tracker && (
                    <>
                        <DropdownMenuItem
                            iconName="edit"
                            onClick={(e) => {
                                onReclassifyTrackerForOrganization()
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>
                                Reclassify tracker for this organization
                            </Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="edit"
                            disabled={tracker && !tracker.belongsToOrganization}
                            onClick={(e) => {
                                onUpdateOrganizationTracker(tracker)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>
                                Update organization tracker classification
                            </Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="x"
                            disabled={tracker && !tracker.belongsToOrganization}
                            onClick={(e) => {
                                onDeleteOrganizationTracker()
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>
                                Remove organization tracker classification
                            </Trans>
                        </DropdownMenuItem>
                    </>
                )}
                {cookie && (
                    <>
                        <DropdownMenuItem
                            iconName="edit"
                            onClick={(e) => {
                                onReclassifyCookieForOrganization()
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>
                                Reclassify cookie for this organization
                            </Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="edit"
                            disabled={
                                cookie === null ||
                                (cookie && !cookie.belongsToOrganization)
                            }
                            onClick={(e) => {
                                onUpdateOrganizationCookie(cookie)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>
                                Update organization cookie classification
                            </Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="x"
                            disabled={
                                cookie === null ||
                                (cookie && !cookie.belongsToOrganization)
                            }
                            onClick={(e) => {
                                onDeleteOrganizationCookie()
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>
                                Remove organization cookie classification
                            </Trans>
                        </DropdownMenuItem>
                    </>
                )}
            </>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default AuditAlertsPageTableRowActions
