import React from 'react'
import { Trans } from '@lingui/macro'
import { getDelegationText } from '../../../utils/delegations'
import AlertListItem from './AlertListItem'

export default function AlertActivityListItem({ activity, onClick }) {
    switch (activity.__typename) {
        case 'AlertDelegation':
            return (
                <AlertListItem item={activity} onClick={onClick}>
                    {getDelegationText(
                        activity.author,
                        activity.user,
                        activity.externalEmail,
                        activity.type
                    )}
                </AlertListItem>
            )
        case 'AlertComment':
            return (
                <AlertListItem item={activity} onClick={onClick}>
                    <Trans>
                        <strong className="font-medium">
                            {activity.author.fullName}
                        </strong>{' '}
                        commented on this alert
                    </Trans>
                </AlertListItem>
            )
        default:
            return null
    }
}
