import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function AuditVendorsPageTableHeaderRow() {
    return (
        <TableHeaderRow isSticky stickyPosition="below-actions">
            <TableHeaderCell columnId="name" initialWidth={300}>
                <Trans>Name & Domain</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="cookiesCount" initialWidth={200}>
                <Trans>Cookies & Trackers</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="types" initialWidth={100}>
                <Trans>Types</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="alerts" initialWidth={200}>
                <Trans>Alerts</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="occurrence" canSort initialWidth={200}>
                <Trans>Occurrence</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="lastSeenAt" initialWidth={200}>
                <Trans>Last Seen at</Trans>
            </TableHeaderCell>
            <TableHeaderCell initialWidth={50} />
        </TableHeaderRow>
    )
}
