import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import UpdateCustomCmpPageHeader from './UpdateCustomCmpPageHeader'
import UpdateCustomCmpForm from './UpdateCustomCmpForm'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'

const UpdateCustomCmpPage = ({
    consentProfiles,
    formState,
    onDisableResolver,
    onEnableProfile,
    onSubmit,
}) => (
    <PageContainer data-testid="UpdateCustomCmpPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent>
                <UpdateCustomCmpPageHeader />
                <UpdateCustomCmpForm
                    consentProfiles={consentProfiles}
                    formState={formState}
                    onDisableResolver={onDisableResolver}
                    onEnableProfile={onEnableProfile}
                    onSubmit={onSubmit}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UpdateCustomCmpPage
