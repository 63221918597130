import React, { useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCountTag from '../../utils/AlertCountTag'
import PurposesTags from '../../utils/PurposesTags'
import ScanCookieDetailsDrawer from './ScanCookieDetailsDrawer'
import useSearch from '../../hooks/useSearch'
import SearchInput from '../../utils/SearchInput'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerTabError from '../../utils/DrawerTabError'
import InfiniteScroll from '../../utils/InfiniteScroll'
import { useScanPageDetailCookiesDrawerQuery } from './useScanPageDetailCookiesDrawer'
import useFilters from '../../hooks/useFilters'
import {
    ScanPageCookiesActiveFilters,
    ScanPageCookiesAvailableFilters,
} from './ScanPageDetailsDrawerTabCookiesFilters'

export default function ScanPageDetailsDrawerTabCookies({ scanPageId }) {
    const { add } = useDrawerStack()
    const [search, handleSearch] = useSearch('')
    const [activeTab, setActiveTab] = useState('all')
    const [typeFilter, onChangeTypeFilter] = useState(null)
    const [purposeFilter, onChangePurposeFilter] = useState(null)
    const [vendorsFilter, onChangeVendorsFilter] = useState([])
    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        type: typeFilter,
        purpose: purposeFilter,
        vendors: vendorsFilter,
    })
    const {
        cookies,
        isFetching: loading,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
    } = useScanPageDetailCookiesDrawerQuery({
        scanPageId,
        search,
        filters: { ...filterValues },
    })

    const finalCookies = cookies.filter((cookie) => {
        if (activeTab === 'all') {
            return true
        }
        return cookie.alerts.length > 0
    })

    const cookiesWithAlerts = useMemo(
        () => cookies.filter((cookie) => cookie.alerts.length > 0),
        [cookies.length]
    )

    if (error) return <DrawerTabError error={error} />
    return (
        <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
                <AnimatedTabs activeTab={activeTab}>
                    <AnimatedTab
                        className="px-3"
                        isActive={activeTab === 'all'}
                        onClick={() => setActiveTab('all')}
                    >
                        <div className="flex flex-row items-center gap-1">
                            <Trans>All</Trans>
                        </div>
                    </AnimatedTab>
                    <AnimatedTab
                        className="px-3"
                        isActive={activeTab === 'new'}
                        onClick={() => setActiveTab('new')}
                    >
                        <div className="flex flex-row items-center gap-1">
                            <Trans>Alerts</Trans>
                            <Tag>{cookiesWithAlerts.length}</Tag>
                        </div>
                    </AnimatedTab>
                </AnimatedTabs>
                <SearchInput
                    id="scanPageCookieSearch"
                    value={search}
                    onChange={handleSearch}
                    className="w-full"
                />
                <ScanPageCookiesAvailableFilters
                    onAddFilter={onAddFilter}
                    activeFilters={activeFilters}
                    onRemoveAllFilters={onRemoveAllFilters}
                />
            </div>
            <div className="flex gap-2">
                <ScanPageCookiesActiveFilters
                    scanPageId={scanPageId}
                    activeFilters={activeFilters}
                    onRemoveFilter={onRemoveFilter}
                    typeFilter={typeFilter}
                    onChangeTypeFilter={onChangeTypeFilter}
                    vendorsFilter={vendorsFilter}
                    onChangeVendorsFilter={onChangeVendorsFilter}
                    purposeFilter={purposeFilter}
                    onChangePurposeFilter={onChangePurposeFilter}
                />
            </div>
            {loading && <DrawerTabLoading />}
            {!loading && (
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {finalCookies.length === 0 ? (
                                <DrawerEmptyCard
                                    iconName="cookie"
                                    title={<Trans>No cookies</Trans>}
                                    description={
                                        <Trans>No cookies were found</Trans>
                                    }
                                />
                            ) : (
                                finalCookies.map((scanCookie) => {
                                    const {
                                        id,
                                        name,
                                        value,
                                        rootDomain,
                                        alerts,
                                        cookie,
                                        tracker,
                                    } = scanCookie
                                    let vendor = null
                                    let tagPurposes = []
                                    if (cookie) {
                                        tagPurposes = cookie.purposes
                                        vendor = cookie.vendor
                                    } else if (tracker) {
                                        tagPurposes = tracker.purposes
                                        vendor = tracker.vendor
                                    }
                                    return (
                                        <TableRow
                                            isSelectable
                                            key={id}
                                            onClick={() => {
                                                add(ScanCookieDetailsDrawer, {
                                                    scanCookieId: id,
                                                })
                                            }}
                                        >
                                            <TableCellAvatar
                                                py="sm"
                                                px="sm"
                                                truncate
                                                avatarIconName="cookie"
                                                avatarSrc={vendor?.imageUrl}
                                                className="w-2/3"
                                                title={name}
                                                text={rootDomain}
                                            />
                                            <TableCell py="sm">
                                                <div className="flex justify-end gap-1">
                                                    <AlertCountTag
                                                        alerts={alerts}
                                                    />
                                                    <PurposesTags
                                                        showAll
                                                        purposes={tagPurposes}
                                                    />
                                                    <Tooltip
                                                        content={value}
                                                        contentClassName="max-w-lg text-wrap"
                                                    >
                                                        <Tag type="gray">
                                                            Show value
                                                        </Tag>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            )}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            )}
        </div>
    )
}
