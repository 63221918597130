import React from 'react'
import clsx from 'clsx'
import Checkbox from './Checkbox'

const FormCheckbox = ({
    label,
    description,
    htmlFor,
    className,
    labelClassName,
    descriptionClassName,
    ...props
}) => (
    <div className={clsx('relative flex items-start', className)}>
        <div className="flex items-center h-5">
            <Checkbox {...props} />
        </div>
        <div className="ml-3 text-sm leading-5">
            <label
                htmlFor={htmlFor}
                className={clsx(
                    'font-medium text-gray-700 cursor-pointer',
                    labelClassName
                )}
            >
                {label}
            </label>
            {description && (
                <p className={clsx('text-gray-500', descriptionClassName)}>
                    {description}
                </p>
            )}
        </div>
    </div>
)

export default FormCheckbox
