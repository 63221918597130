import React from 'react'
import BulkUploadCard from './BulkUploadCard'
import FormInputErrors from '../../utils/FormInputErrors'
import InfoIcon from '../../utils/InfoIcon'

const FormUploadCard = ({ errors, className, inputClassName, ...props }) => (
    <div className={className}>
        <BulkUploadCard className={inputClassName} {...props}>
            <InfoIcon iconName="upload-cloud-02" size="md" className="mb-2" />
            <h1 className="text-sm">
                <b className="text-primary-600">Click to upload </b>
                or drag and drop
            </h1>
            <p className="text-xs">SVG, PNG, JPG or GIF (max. 800 x 400px)</p>
        </BulkUploadCard>
        <FormInputErrors errors={errors} />
    </div>
)

export default FormUploadCard
