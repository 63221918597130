import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import CookiesPageTableRow from './CookiesPageTableRow'

const QUERY = gql`
    query cookiesPage(
        $search: String
        $offset: Int
        $filters: CookiesFilter
        $orderBy: CookieOrderByInput
    ) {
        cookies(
            search: $search
            offset: $offset
            filters: $filters
            orderBy: $orderBy
        ) {
            edges {
                cursor
                node {
                    ...CookiesPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${CookiesPageTableRow.FRAGMENT}
`

const useCookiesPageQuery = ({ search, filters, orderBy }) => {
    const variables = { search, filters, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'cookies'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            cookies: [],
            isFetching,
        }
    }
    return {
        cookies: connectionToCollection(data.cookies),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && data.cookies.pageInfo.hasNextPage,
    }
}

export default useCookiesPageQuery
