import React from 'react'
import clsx from 'clsx'
import Popover from './Popover'

const Tooltip = ({
    type = 'black',
    hasArrow = true,
    position = 'top',
    content,
    title,
    enabled = true,
    contentClassName,
    ...props
}) => {
    if (!enabled) {
        return props.children
    }
    return (
        <Popover
            event="hover"
            position={position}
            hasArrow={hasArrow}
            querySelector="#tooltips"
            arrowClassName={clsx(
                'tooltip-arrow',
                type === 'black' && 'bg-black',
                type === 'white' && 'bg-white'
            )}
            content={
                <div
                    className={clsx(
                        'text-sm px-4 py-2 shadow-md  rounded-lg',
                        type === 'black' && 'bg-black text-white',
                        type === 'white' && 'bg-white text-gray-800',
                        contentClassName
                    )}
                >
                    {title && <h3 className="font-bold mb-1">{title}</h3>}
                    {content}
                </div>
            }
            {...props}
        />
    )
}

export default Tooltip
