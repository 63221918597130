import React from 'react'
import PageContainer from '../layout/PageContainer'
import LoadingIndicator from './LoadingIndicator'

const PageLoading = () => (
    <PageContainer data-testid="PageLoading">
        <div className="flex h-full w-full items-center justify-center">
            <LoadingIndicator />
        </div>
    </PageContainer>
)

export default PageLoading
