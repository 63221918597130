import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation reviewAlert(
        $groupingIds: [ID!]
        $auditId: ID
        $level: AlertStatusLevel!
    ) {
        reviewAlert(groupingIds: $groupingIds, auditId: $auditId, level: $level)
    }
`

const useReviewAlertMutation = (options) => useMutation(MUTATION, options)

export default useReviewAlertMutation
