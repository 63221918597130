import React from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import SearchInput from '../../utils/SearchInput'
import VendorsPageTableActionsFilters from './VendorsPageTableActionsFilters'
import TableViewSettingsMenu from '../../utils/TableViewSettingsMenu'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

const VendorsPageTableActions = ({
    search,
    vendorTypeFilter,
    activeFilters,
    onSearch,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    onChangeVendorTypeFilter,
}) => {
    const { isSelectingRows, startSelectingRows, stopSelectingRows } =
        useTableState()
    return (
        <TableActionsContainer isSticky>
            {isSelectingRows && (
                <Button
                    type="white"
                    size="sm"
                    onClick={() => stopSelectingRows()}
                >
                    <Trans>Cancel</Trans>
                </Button>
            )}
            {!isSelectingRows && (
                <div className="flex gap-2 items-center">
                    <Button
                        type="white"
                        size="sm"
                        iconBeforeProps={{
                            name: 'check-done-01',
                            size: 'sm',
                        }}
                        onClick={() => startSelectingRows()}
                    />
                    <SearchInput value={search} onChange={onSearch} />
                    <VendorsPageTableActionsFilters
                        vendorTypeFilter={vendorTypeFilter}
                        activeFilters={activeFilters}
                        onAddFilter={onAddFilter}
                        onRemoveFilter={onRemoveFilter}
                        onRemoveAllFilters={onRemoveAllFilters}
                        onChangeVendorTypeFilter={onChangeVendorTypeFilter}
                    />
                </div>
            )}
            <TableViewSettingsMenu />
        </TableActionsContainer>
    )
}

export default VendorsPageTableActions
