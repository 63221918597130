import React, { useState } from 'react'
import gql from 'graphql-tag'
import { format, addDays } from 'date-fns'
import { useQuery } from '@apollo/client'
import DashboardPageAlertsByAuditChart from './DashboardPageAlertsByAuditChart'
import * as constants from '../../../constants'

const { DATE_FORMAT_GRAPHQL_DATE: FORMAT } = constants

const QUERY = gql`
    query alertsByAuditChart($filters: AlertFilters) {
        alertsByAuditChart(filters: $filters) {
            id
            audit {
                id
                name
                imageUrl
            }
            alertCount
        }
    }
`

const DashboardPageAlertsByAuditChartWithState = ({ startDate, endDate }) => {
    const filters = { startDate, endDate }
    const variables = { filters }
    const { data = {}, loading: isFetching } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return (
        <DashboardPageAlertsByAuditChart
            isFetching={isFetching}
            chartData={data.alertsByAuditChart || []}
        />
    )
}

export default DashboardPageAlertsByAuditChartWithState
