import React, { useState, useEffect } from 'react'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import clsx from 'clsx'
import Icon from './Icon'

const DraggableList = ({ children, items, onChange }) => {
    const [keys, setKeys] = useState([])
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragEnd = (event) => {
        const { active, over } = event
        if (active.id !== over.id) {
            setKeys((prevKeys) => {
                const oldIndex = prevKeys.indexOf(active.id)
                const newIndex = prevKeys.indexOf(over.id)
                const newOrder = arrayMove(prevKeys, oldIndex, newIndex)
                const test = arrayMove(items, oldIndex, newIndex)
                onChange(test)
                return newOrder
            })
        }
    }

    // Update state when items are added
    useEffect(() => {
        if (items) {
            setKeys(items.map((_, index) => index + 1))
        }
    }, [items])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={keys}
                strategy={verticalListSortingStrategy}
            >
                {children}
            </SortableContext>
        </DndContext>
    )
}

DraggableList.Item = ({ id, children, className }) => {
    const { attributes, listeners, setNodeRef, transform } = useSortable({ id })

    return (
        <div
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
            }}
            className={clsx('flex items-center gap-2', className)}
        >
            <button
                {...attributes}
                {...listeners}
                type="button"
                className="p-1.5 hover:bg-gray-50 rounded-md cursor-grab focus:cursor-grabbing"
            >
                <Icon name="drag" size="sm" stroke="none" fill="currentColor" />
            </button>
            {children}
        </div>
    )
}

export default DraggableList
