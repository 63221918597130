import React from 'react'
import { Trans } from '@lingui/macro'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import UpdateOrganizationCookieModal from './UpdateOrganizationCookieModal'
import useUpdateOrganizationCookieMutation from './useUpdateOrganizationCookieMutation'
import useUpdateOrganizationCookieFormState from './useUpdateOrganizationCookieFormState'
import useUpdateOrganizationCookieModalQuery from './useUpdateOrganizationCookieModalQuery'

const UpdateOrganizationCookieModalWithState = ({
    title,
    description,
    cookie,
    scanCookie,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const query = useUpdateOrganizationCookieModalQuery(cookie.id)
    const [updateCookie, { loading: isUpdating }] =
        useUpdateOrganizationCookieMutation({
            onCompleted,
            onError,
        })
    const formState = useUpdateOrganizationCookieFormState(
        query.cookie,
        scanCookie
    )
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <UpdateOrganizationCookieModal
            title={title}
            description={description}
            isFetching={query.isFetching}
            isUpdating={isUpdating}
            initialVendor={query.cookie.vendor}
            canCreateVendor={currentUser.isStaff}
            originalCookieName={scanCookie && scanCookie.originalName}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        const variables = { id: cookie.id, input }
                        await updateCookie({ variables })
                        await query.refetch()
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Cookie updated successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default UpdateOrganizationCookieModalWithState
