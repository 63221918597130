import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query acceptInvitationPage($id: ID) {
        invitation(id: $id) {
            id
            status
            email
            user {
                id
                firstName
            }
            organization {
                id
                name
            }
            expiresAt
        }
    }
`

const useInvitationQuery = (options) => {
    const {
        data = {},
        loading: isFetching,
        error,
        ...result
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            invitation: {},
            isFetching,
            ...result,
        }
    }

    const { invitation } = data

    return {
        isFetching,
        invitation,
        ...result,
    }
}

export default useInvitationQuery
