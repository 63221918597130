/* eslint-disable no-nested-ternary */
import React from 'react'
import { Trans } from '@lingui/macro'
import { twMerge } from 'tailwind-merge'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import VendorTypeTags from '../../utils/VendorTypeTags'
import VendorVersionCompareModalTableRowActions from './VendorVersionCompareModalTableRowActions'

const VendorVersionCompareModalTableRow = ({
    vendorItem,
    rowType,
    onUpdateCompleted,
}) => {
    const { name, domain, imageUrl, types } = vendorItem.vendor

    return (
        <TableRow
            className={twMerge(
                'bg-white',
                rowType === 'new' && 'bg-green-50',
                rowType === 'danger' && 'bg-red-50',
                rowType === 'warning' && 'bg-orange-50'
            )}
        >
            <TableCellAvatar
                truncate
                className="max-w-32 overflow-hidden"
                columnId="name"
                size="sm"
                avatarSrc={imageUrl}
                avatarIconName="building-07"
                title={name}
                text={
                    domain || (
                        <span className="text-slate-300">
                            <Trans>No domain found</Trans>
                        </span>
                    )
                }
            />
            <TableCell columnId="vendorTypes" size="sm" className="max-w-10">
                <VendorTypeTags types={types} />
            </TableCell>
            <TableCell columnId="state" className="w-fit text-right">
                {rowType !== 'same' ? (
                    <Tag
                        type={
                            rowType === 'new'
                                ? 'green'
                                : rowType === 'danger'
                                  ? 'red'
                                  : rowType === 'warning'
                                    ? 'orange'
                                    : 'gray'
                        }
                    >
                        {rowType === 'new'
                            ? 'Added'
                            : rowType === 'danger'
                              ? 'Not found'
                              : rowType === 'warning'
                                ? 'Removed'
                                : null}
                    </Tag>
                ) : (
                    <Tag type="gray">Unchanged</Tag>
                )}
            </TableCell>
            <TableCell size="sm" columnId="actions">
                <VendorVersionCompareModalTableRowActions
                    vendorTableItemId={vendorItem.id}
                    rowType={rowType}
                    onUpdateCompleted={onUpdateCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

export default VendorVersionCompareModalTableRow
