import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createTrackers($input: UploadTrackerInput!) {
        createTrackers(input: $input)
    }
`

const useBulkUploadCreateTrackersMutation = (options) =>
    useMutation(MUTATION, options)

export default useBulkUploadCreateTrackersMutation
