import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createCookieTableVersion($input: CookieTableVersionInput!) {
        createCookieTableVersion(input: $input) {
            cookieTableVersion {
                id
                name
                audits {
                    id
                    name
                    imageUrl
                }
            }
        }
    }
`

const useCreateCookieTableVersion = (options) => useMutation(MUTATION, options)

export default useCreateCookieTableVersion
