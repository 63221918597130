import React from 'react'
import { Trans } from '@lingui/macro'
import TableFilterClearButton from '../../utils/TableFilterClearButton'
import TableFiltersMenu from '../../utils/TableFiltersMenu'
import TableFiltersMenuItem from '../../utils/TableFiltersMenuItem'

export default function VendorsPageTableActionsFilters({
    activeFilters = [],
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
}) {
    return (
        <div className="flex gap-2 items-center">
            <TableFilterClearButton
                name="hasNoDescription"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No description</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoVendor"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No vendor</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoPurposes"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No purposes</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoDomain"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No Domain</Trans>
            </TableFilterClearButton>

            <TableFiltersMenu
                onRemoveAll={onRemoveAllFilters}
                activeFilters={activeFilters}
            >
                <TableFiltersMenuItem
                    iconName="help-octagon"
                    name="hasNoDescription"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No description</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="cookie"
                    name="hasNoVendor"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No vendor</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="mark"
                    name="hasNoPurposes"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No purposes</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="globe"
                    name="hasNoDomain"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No domain</Trans>
                </TableFiltersMenuItem>
            </TableFiltersMenu>
        </div>
    )
}
