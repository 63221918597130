import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useRefetch = (refetch) => {
    const { state = {} } = useLocation()
    useEffect(() => {
        if (state.refetch && typeof refetch === 'function') {
            refetch()
        }
    }, [refetch, state.refetch])
}

export default useRefetch
