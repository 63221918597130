import React, { useState } from 'react'
import CreateAlertReportModalWithState from './CreateAlertReportModalWithState'
import CreateCookieTableReportModalWithState from './CreateCookieTableReportModalWithState'
import CreateVendorTableReportModalWithState from './CreateVendorTableReportModalWithState'
import CreateScanReportModalWithState from './CreateScanReportModalWithState'
import CreateIdentifiedCookiesReportModalWithState from './CreateIdentifiedCookiesReportModalWithState'
import ReportsPageCreateReportButton from './ReportsPageCreateReportButton'
import CreateIdentifiedVendorsReportModalWithState from './CreateIdentifiedVendorReportModalWithState'

const ReportsPageCreateReportButtonWithState = ({ level, onReportCreated }) => {
    const [isAlertReportModalOpen, setIsAlertReportModalOpen] = useState(false)
    const [isCookieTableModalOpen, setIsCookieTableModalOpen] = useState(false)
    const [isVendorTableModalOpen, setIsVendorTableModalOpen] = useState(false)
    const [isScanReportModalOpen, setIsScanReportModalOpen] = useState(false)
    const [isIdentifiedCookiesModalOpen, setIsIdentifiedCookiesModalOpen] =
        useState(false)
    const [isIdentifiedVendorsModalOpen, setIsIdentifiedVendorsModalOpen] =
        useState(false)
    return (
        <>
            {isCookieTableModalOpen && (
                <CreateCookieTableReportModalWithState
                    level={level}
                    onCompleted={onReportCreated}
                    onDismiss={() => setIsCookieTableModalOpen(false)}
                />
            )}
            {isVendorTableModalOpen && (
                <CreateVendorTableReportModalWithState
                    level={level}
                    onCompleted={onReportCreated}
                    onDismiss={() => setIsVendorTableModalOpen(false)}
                />
            )}
            {isScanReportModalOpen && (
                <CreateScanReportModalWithState
                    level={level}
                    onCompleted={onReportCreated}
                    onDismiss={() => setIsScanReportModalOpen(false)}
                />
            )}
            {isAlertReportModalOpen && (
                <CreateAlertReportModalWithState
                    level={level}
                    onCompleted={onReportCreated}
                    onDismiss={() => setIsAlertReportModalOpen(false)}
                />
            )}
            {isIdentifiedCookiesModalOpen && (
                <CreateIdentifiedCookiesReportModalWithState
                    level={level}
                    onCompleted={onReportCreated}
                    onDismiss={() => setIsIdentifiedCookiesModalOpen(false)}
                />
            )}
            {isIdentifiedVendorsModalOpen && (
                <CreateIdentifiedVendorsReportModalWithState
                    level={level}
                    onCompleted={onReportCreated}
                    onDismiss={() => setIsIdentifiedVendorsModalOpen(false)}
                />
            )}
            <ReportsPageCreateReportButton
                isLoading={false}
                onCreateAlertReport={() => setIsAlertReportModalOpen(true)}
                onCreateCookieTableReport={() =>
                    setIsCookieTableModalOpen(true)
                }
                onCreateVendorTableReport={() =>
                    setIsVendorTableModalOpen(true)
                }
                onCreateScanReport={() => setIsScanReportModalOpen(true)}
                onCreateCookieObservationsReport={() =>
                    setIsIdentifiedCookiesModalOpen(true)
                }
                onCreateVendorObservationsReport={() =>
                    setIsIdentifiedVendorsModalOpen(true)
                }
            />
        </>
    )
}

export default ReportsPageCreateReportButtonWithState
