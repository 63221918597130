import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function TableActionsContainer({
    className,
    children,
    isSticky = false,
}) {
    return (
        <div
            className={twMerge(
                'flex justify-between py-4',
                isSticky && 'sticky top-[0] z-50 -mx-6 bg-white px-6',
                className
            )}
        >
            {children}
        </div>
    )
}
