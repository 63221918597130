import React from 'react'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import Duration from '../../utils/Duration'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tooltip from '../../utils/Tooltip'
import AlertCountTag from '../../utils/AlertCountTag'
import { getScanStatusColor } from '../../../utils/entities'
import * as constants from '../../../constants'
import LoadingIndicator from '../../utils/LoadingIndicator'
import useDrawerStack from '../../hooks/useDrawerStack'
import ScanDetailsDrawer from './ScanDetailsDrawer'
import ScanPageActionsDropdownMenu from '../staff-scans/ScanPageActionsDropdownMenu'
import ActionButton from '../../utils/ActionButton'

const {
    SCAN_PAGE_STATUS_IN_PROGRESS: IN_PROGRESS,
    SCAN_PAGE_STATUS_FAILED: FAILED,
} = constants

const FRAGMENT = gql`
    fragment ScanPageTableRow on Scan {
        id
        url
        duration
        status
        consentProfile {
            name
        }
        loginProfile {
            name
        }
        audit {
            id
            name
            imageUrl
        }
        createdAt
        errorMessages
        alertsCount
        criticalAlertsCount
        warningAlertsCount
        issueAlertsCount
    }
`

const ScanPageTableRow = ({ scan, onDeleteScanCompleted }) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        url,
        audit,
        status,
        createdAt,
        duration,
        consentProfile,
        errorMessages,
        criticalAlertsCount,
        warningAlertsCount,
        issueAlertsCount,
    } = scan
    return (
        <TableRow
            isSelectable
            isActive={currentDrawerProps?.vendorId === id}
            selectionValue={scan}
            selectionCriteria={(row) => row.id === id}
            onClick={() => {
                open(ScanDetailsDrawer, {
                    scanId: scan.id,
                    onDeleteScanCompleted,
                })
            }}
        >
            <TableCellAvatar
                columnId="url"
                size="xs"
                avatarSrc={audit.imageUrl}
                avatarIconName="building-07"
                title={url}
            />
            <TableCell size="xs">
                <AlertCountTag
                    criticalAlertsCount={criticalAlertsCount}
                    issueAlertsCount={issueAlertsCount}
                    warningAlertsCount={warningAlertsCount}
                />
            </TableCell>
            <TableCell size="xs">
                {consentProfile && <Tag type="gray">{consentProfile.name}</Tag>}
            </TableCell>
            <TableCell size="xs">
                <Tooltip
                    event="hover"
                    enabled={status === FAILED}
                    content={errorMessages.join(' ')}
                >
                    <Tag
                        type={getScanStatusColor(status)}
                        className="flex items-center mr-2 capitalize"
                    >
                        {status === IN_PROGRESS && (
                            <LoadingIndicator size={12} className="mr-2" />
                        )}
                        {status.toLowerCase().replace('_', ' ')}
                    </Tag>
                </Tooltip>
            </TableCell>
            <TableCell size="xs">
                <DateTime>{createdAt}</DateTime>
            </TableCell>
            <TableCell size="xs">
                <Duration>{duration}</Duration>
            </TableCell>
            <TableCell className="text-right" size="sm">
                <ScanPageActionsDropdownMenu
                    scan={scan}
                    onDeleteScanCompleted={onDeleteScanCompleted}
                >
                    <ActionButton />
                </ScanPageActionsDropdownMenu>
            </TableCell>
        </TableRow>
    )
}

ScanPageTableRow.FRAGMENT = FRAGMENT

export default ScanPageTableRow
