import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation validateCookies($input: UploadCookieInput!) {
        validateCookies(input: $input) {
            errors {
                field
                row
                type
                message
            }
            warnings {
                field
                row
                type
                message
            }
            totalCount
        }
    }
`

const useBulkUploadValidateCookiesMutation = (options) =>
    useMutation(MUTATION, options)

export default useBulkUploadValidateCookiesMutation
