import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { connectionToCollection } from '../../../utils/graphql'
import AuditVendorsPageTableRow from './AuditVendorsPageTableRow'

const QUERY = gql`
    query auditVendorsPage(
        $auditId: ID!
        $search: String
        $after: String
        $offset: Int
        $orderBy: VendorOrderByInput
        $filters: IdentifiedVendorsFilters
    ) {
        audit(id: $auditId) {
            id
            name
            imageUrl
            isWeb
            isMobile
            vendorTable {
                id
                name
                vendors {
                    id
                    name
                }
            }
        }
        identifiedVendors(
            search: $search
            after: $after
            offset: $offset
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                node {
                    ...AuditVendorsPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${AuditVendorsPageTableRow.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useAuditVendorsPageQuery = (
    search,
    startDate,
    endDate,
    period,
    orderBy
) => {
    const { id: auditId } = useParams()
    const filters = { auditId, startDate, endDate, period }
    const variables = { auditId, search, filters, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'auditVendors'
    )
    return {
        error,
        audit: data.audit || {},
        vendors: connectionToCollection(data.identifiedVendors),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useAuditVendorsPageQuery
