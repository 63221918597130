/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DeleteTrackerModalWithState from '../audits-scans-trackers/DeleteTrackerModalWithState'
import UpdateTrackerModalWithState from '../audits-scans-trackers/UpdateTrackerModalWithState'

const TrackersPageActionsDropdownMenu = ({
    tracker,
    onDeleteTrackerCompleted,
    onUpdateTrackerCompleted,
    children,
}) => {
    const [isUpdateTrackerModalOpen, setUpdateTrackerModalOpen] =
        useState(false)
    const [isRemoveTrackerModalOpen, setRemoveTrackerModalOpen] =
        useState(false)
    return (
        <>
            {isUpdateTrackerModalOpen && (
                <UpdateTrackerModalWithState
                    tracker={tracker}
                    onDismiss={() => setUpdateTrackerModalOpen(false)}
                    onCompleted={onUpdateTrackerCompleted}
                />
            )}
            {isRemoveTrackerModalOpen && (
                <DeleteTrackerModalWithState
                    tracker={tracker}
                    onDismiss={() => setRemoveTrackerModalOpen(false)}
                    onCompleted={onDeleteTrackerCompleted}
                />
            )}
            <DropdownMenu
                as="span"
                position="bottom-start"
                content={({ close }) => (
                    <>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={(e) => {
                                setRemoveTrackerModalOpen(true)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Delete Tracker</Trans>
                        </DropdownMenuItem>

                        <DropdownMenuItem
                            iconName="edit"
                            onClick={(e) => {
                                setUpdateTrackerModalOpen(true)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Update Tracker</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                {children}
            </DropdownMenu>
        </>
    )
}

export default TrackersPageActionsDropdownMenu
