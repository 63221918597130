import React from 'react'
import { Trans } from '@lingui/macro'
import PageLoading from '../../utils/PageLoading'
import VendorDetailsPage, {
    CONSENT,
    FLEXIBLE,
    LEGITIMATE_INTEREST,
} from './VendorDetailsPage'
import useVendorDetailsPageQuery from './useVendorDetailsPageQuery'

const allPurposes = [
    {
        value: 1,
        label: <Trans>Store and/or access information on a device</Trans>,
    },
    {
        value: 2,
        label: <Trans>Select basic ads</Trans>,
    },
    {
        value: 3,
        label: <Trans>Create a personalised ads profile</Trans>,
    },
    {
        value: 4,
        label: <Trans>Select personalised ads</Trans>,
    },
    {
        value: 5,
        label: <Trans>Create a personalised content profile</Trans>,
    },
    {
        value: 6,
        label: <Trans>Select personalised content</Trans>,
    },
    {
        value: 7,
        label: <Trans>Measure ad performance</Trans>,
    },
    {
        value: 8,
        label: <Trans>Measure content performance</Trans>,
    },
    {
        value: 9,
        label: (
            <Trans>Apply market research to generate audience insights</Trans>
        ),
    },
    {
        value: 10,
        label: <Trans>Develop and improve products</Trans>,
    },
]

const AuditSettingsPageWithState = () => {
    const { vendor, isFetching } = useVendorDetailsPageQuery()
    const { purposes, legIntPurposes, flexiblePurposes } = vendor

    const vendorPurposes = allPurposes.reduce((acc, { label, value }) => {
        const purpose = purposes?.find((v) => v === value)
        if (typeof purpose !== 'undefined') {
            return [
                ...acc,
                {
                    label,
                    type: CONSENT,
                },
            ]
        }
        const flexPurpose = flexiblePurposes?.find((v) => v === value)
        if (typeof flexPurpose !== 'undefined') {
            return [
                ...acc,
                {
                    label,
                    type: FLEXIBLE,
                },
            ]
        }
        const legIntPurpose = legIntPurposes?.find((v) => v === value)
        if (typeof legIntPurpose !== 'undefined') {
            return [
                ...acc,
                {
                    label,
                    type: LEGITIMATE_INTEREST,
                },
            ]
        }
        return acc
    }, [])
    return (
        <VendorDetailsPage
            vendor={vendor}
            vendorPurposes={vendorPurposes}
            isFetching={isFetching}
        />
    )
}

export default AuditSettingsPageWithState
