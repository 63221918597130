import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useNotifications from '../../hooks/useNotifications'
import useCollectionState from '../../hooks/useCollectionState'
import AuditSessionsPage from './AuditSessionsPage'
import useAuditSessionsPageQuery from './useAuditSessionsPageQuery'
import useDeleteSessionsMutation from './useDeleteSessionsMutation'

const AuditSettingsPageWithState = () => {
    useCloseDrawerOnLeave()
    const {
        collection,
        addToCollection,
        addAllToCollection,
        clearCollection,
        removeFromCollection,
    } = useCollectionState()
    const [deleteSessions] = useDeleteSessionsMutation()
    const [isSelecting, onSetSelecting] = useState(false)
    const { dispatchSuccess, dispatchError } = useNotifications()
    const {
        audit,
        sessions,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditSessionsPageQuery()

    const handleDeleteSessions = async (
        selectedRows,
        clearSelectedRows,
        stopSelectingRows
    ) => {
        const ids = selectedRows.map(({ id }) => id)
        try {
            await deleteSessions({
                variables: {
                    input: {
                        ids,
                    },
                },
            })
            dispatchSuccess({
                message: <Trans>Deleted Sessions!</Trans>,
            })
            clearSelectedRows()
            stopSelectingRows()
            refetch()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    return (
        <AuditSessionsPage
            audit={audit}
            sessions={sessions}
            isSelecting={isSelecting}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            selectedSessions={collection}
            onUploadCompleted={() => refetch()}
            onStartSelectingSessions={() => onSetSelecting(true)}
            onStopSelectingSessions={() => {
                onSetSelecting(false)
                clearCollection()
            }}
            onSelectAllSessions={(e) => {
                if (e.target.checked) {
                    addAllToCollection(sessions)
                } else {
                    clearCollection()
                }
            }}
            onSelectSession={(isSelected, session) => {
                if (isSelected) {
                    addToCollection(session)
                } else {
                    removeFromCollection(session)
                }
            }}
            onDeleteSessions={handleDeleteSessions}
        />
    )
}

export default AuditSettingsPageWithState
