import React, { forwardRef } from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const FormTextarea = forwardRef(
    (
        {
            id,
            hasError,
            className,
            textareaClassName,
            disabled,
            isRounded = true,
            ...props
        },
        ref
    ) => (
        <div
            ref={ref}
            className={clsx('relative rounded-md shadow-sm', className)}
        >
            <textarea
                id={id}
                disabled={disabled}
                className={clsx(
                    'form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5',
                    !hasError &&
                        'border-gray-300 enabled:hover:border-gray-400',
                    hasError &&
                        'border-red-300 text-red-600 placeholder-red-300 enabled:hover:border-red-400',
                    disabled && 'opacity-50 cursor-not-allowed',
                    isRounded && 'rounded-md ',
                    textareaClassName
                )}
                {...props}
            />
        </div>
    )
)

export default FormTextarea
