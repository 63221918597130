/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DropdownMenuItemDivider from '../../utils/DropdownMenuItemDivider'
import DropdownMenuTitle from '../../utils/DropdownMenuTitle'
import ActionButton from '../../utils/ActionButton'

const ScanCookiesPageTableRowActionButtonStaff = ({
    cookie,
    isLoading,
    canClassify,
    canDelete,
    canUpdate,
    onClassify,
    onDelete,
    onUpdate,
    onClassifyOrganization,
    onDeleteOrganization,
    onUpdateOrganization,
    children,
}) => (
    <DropdownMenu
        as="span"
        position="bottom-end"
        content={({ close }) => (
            <>
                <DropdownMenuTitle>
                    <Trans>Staff actions</Trans>
                </DropdownMenuTitle>
                <DropdownMenuItem
                    iconName="cookie"
                    disabled={!canClassify}
                    onClick={(e) => {
                        onClassify()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Classify cookie</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={!canUpdate}
                    onClick={(e) => {
                        onUpdate(cookie)
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Update classification</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="x"
                    disabled={!canDelete}
                    onClick={(e) => {
                        onDelete()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Remove classification</Trans>
                </DropdownMenuItem>
                <DropdownMenuItemDivider />
                <DropdownMenuTitle>
                    <Trans>Organization actions</Trans>
                </DropdownMenuTitle>
                <DropdownMenuItem
                    iconName="cookie"
                    disabled={cookie !== null && cookie.belongsToOrganization}
                    onClick={(e) => {
                        onClassifyOrganization()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Classify cookie for organization</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={
                        cookie === null ||
                        (cookie && !cookie.belongsToOrganization)
                    }
                    onClick={(e) => {
                        onUpdateOrganization(cookie)
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Update organization classification</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="x"
                    disabled={
                        cookie === null ||
                        (cookie && !cookie.belongsToOrganization)
                    }
                    onClick={(e) => {
                        onDeleteOrganization()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Remove organization classification</Trans>
                </DropdownMenuItem>
            </>
        )}
    >
        {children}
    </DropdownMenu>
)

export default ScanCookiesPageTableRowActionButtonStaff
