import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isValidEmail, isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'firstName',
        validate: isRequired,
        message: <Trans>The first name is required</Trans>,
    },
    {
        path: 'lastName',
        validate: isRequired,
        message: <Trans>The last name is required</Trans>,
    },
    {
        path: 'email',
        validate: isValidEmail,
        message: <Trans>The email field is required</Trans>,
    },
    {
        path: 'password',
        validate: isRequired,
        message: <Trans>Please choose a password</Trans>,
    },
    {
        path: 'repeatPassword',
        validate: isRequired,
        message: <Trans>Please repeat your password</Trans>,
    },
])

const valuesToInput = ({ repeatPassword, ...values }) => values

const useCreateAuditFormState = (initialValues, options) =>
    useFormState(
        {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            repeatPassword: '',
            ...initialValues,
        },
        {
            ...options,
            validation,
            valuesToInput,
        }
    )

export default useCreateAuditFormState
