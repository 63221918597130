import React from 'react'
import { useParams } from 'react-router-dom'
import AlertsDashboard from '../alerts/AlertsDashboard'
import useAuditAlertsDashboardQuery from './useAuditAlertsDashboardQuery'

const AuditAlertsPageDashboardWithState = ({
    selectedType,
    selectedTag,
    startDate,
    endDate,
}) => {
    const { id } = useParams()
    const { isFetching, alertsByPeriod } = useAuditAlertsDashboardQuery(
        id,
        selectedType === 'ALL' ? null : selectedType,
        selectedTag === 'ALL' ? null : selectedTag,
        startDate,
        endDate
    )
    return (
        <AlertsDashboard
            isFetching={isFetching}
            alertsByPeriod={alertsByPeriod}
        />
    )
}

export default AuditAlertsPageDashboardWithState
