import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createVendorTableVersion($input: VendorTableVersionInput!) {
        createVendorTableVersion(input: $input) {
            vendorTableVersion {
                id
                name
                audits {
                    id
                    name
                    imageUrl
                }
            }
        }
    }
`

const useCreateVendorTableVersion = (options) => useMutation(MUTATION, options)

export default useCreateVendorTableVersion
