import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../utils/validation'

const validation = createFormValidation([
    {
        path: 'text',
        validate: isRequired,
        message: <Trans>Comment is is required</Trans>,
    },
])

const valuesToInput = (values) => values

const useAlertCommentFormState = (initialValues, options) =>
    useFormState(
        {
            text: '',
            ...initialValues,
        },
        {
            ...options,
            validation,
            valuesToInput,
        }
    )

export default useAlertCommentFormState
