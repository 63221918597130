import React from 'react'
import clsx from 'clsx'
import Avatar from '../utils/Avatar'
import LoadingIndicator from '../utils/LoadingIndicator'
import SearchInput from '../utils/SearchInput'
import Tag from '../utils/Tag'

export default function OrganizationDropdown({
    currentOrganization,
    organizations,
    onSelectOrganization,
    search,
    isFetching,
    onSearch,
}) {
    return (
        <>
            <div className="px-2 pt-1 pb-2">
                <SearchInput onChange={onSearch} value={search} />
            </div>
            <div className="border-t  border-slate-200 h-[240px] overflow-scroll">
                {isFetching && (
                    <div className="h-full w-full flex items-center justify-center">
                        <LoadingIndicator />
                    </div>
                )}
                {!isFetching && (
                    <ul className="py-2">
                        {organizations.map((organization) => {
                            const isCurrent =
                                currentOrganization.id === organization.id
                            return (
                                <li key={organization.id}>
                                    <button
                                        type="button"
                                        className={clsx(
                                            'flex items-center justify-between py-2 px-4 hover:bg-slate-100 w-full',
                                            !isCurrent && 'hover:bg-slate-100',
                                            isCurrent && 'bg-slate-100'
                                        )}
                                        onClick={() =>
                                            onSelectOrganization(
                                                organization.id
                                            )
                                        }
                                    >
                                        <div className="flex items-center gap-2">
                                            <Avatar
                                                size="sm"
                                                iconName="building-07"
                                                src={organization.imageUrl}
                                            />
                                            <div className="truncate max-w-60 pl-0">
                                                {organization.name}
                                            </div>
                                        </div>
                                        {isCurrent && <Tag>Current</Tag>}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
            {/* <button
                type="button"
                className="flex items-center gap-2 py-3 px-4 hover:bg-slate-100 text-slate-600 w-full"
            >
                <Icon name="plus" />
                <Trans>Create organization</Trans>
            </button> */}
        </>
    )
}
