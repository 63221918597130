import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import AuditDomainsPageConsentProfileTableRow from './AuditDomainsPageConsentProfileTableRow'
import EmptyTable from '../../utils/EmptyTable'
import Button from '../../utils/Button'

const AuditDomainsPageTable = ({
    audit,
    consentProfiles = [],
    onRemoveConsentProfileCompleted,
    onAddConsentProfile,
}) => (
    <Table data-testid="AuditDomainsPageTable">
        <thead>
            <tr>
                <TableHeaderCell>
                    <Trans>Consent Profile</Trans>
                </TableHeaderCell>
                <TableHeaderCell>
                    <Trans>CMP</Trans>
                </TableHeaderCell>
                <TableHeaderCell>
                    <Trans>Purposes</Trans>
                </TableHeaderCell>
                <TableHeaderCell />
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {consentProfiles.length > 0 ? (
                consentProfiles.map((consentProfile) => (
                    <AuditDomainsPageConsentProfileTableRow
                        key={consentProfile.id}
                        audit={audit}
                        consentProfile={consentProfile}
                        onRemoveConsentProfileCompleted={
                            onRemoveConsentProfileCompleted
                        }
                    />
                ))
            ) : (
                <EmptyTable
                    colSpan={4}
                    title={<Trans>No consent profiles</Trans>}
                    description={
                        <Trans>
                            No consent profiles were found for this audit.
                        </Trans>
                    }
                >
                    <Button
                        onClick={onAddConsentProfile}
                        className="mt-6 mx-auto"
                        iconBeforeProps={{
                            name: 'plus',
                            color: 'white',
                        }}
                    >
                        Add consent profile
                    </Button>
                </EmptyTable>
            )}
        </tbody>
    </Table>
)

export default AuditDomainsPageTable
