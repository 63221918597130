import React from 'react'
import { Trans } from '@lingui/macro'
import Sidebar from '../../layout/Sidebar'
import SidebarMenu from '../../layout/SidebarMenu'
import SidebarTitle from '../../layout/SidebarTitle'
import SidebarMenuItem from '../../layout/SidebarMenuItem'

export default function VendorsPageSidebar() {
    return (
        <Sidebar>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>Global directory</Trans>
                </SidebarTitle>
                <SidebarMenuItem to="/staff/vendors" iconName="building-07">
                    <Trans>Vendors</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem to="/staff/vendors/cookies" iconName="cookie">
                    <Trans>Cookies</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem to="/staff/vendors/trackers" iconName="mark">
                    <Trans>Trackers</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>Unclassified</Trans>
                </SidebarTitle>
                <SidebarMenuItem
                    to="/staff/vendors/unclassified-cookies"
                    iconName="cookie"
                >
                    <Trans>Unclassified Cookies</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    to="/staff/vendors/unclassified-trackers"
                    iconName="mark"
                >
                    <Trans>Unclassified Trackers</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>Organization</Trans>
                </SidebarTitle>
                <SidebarMenuItem
                    to="/staff/vendors/organization-vendors"
                    iconName="building-07"
                >
                    <Trans>Organization vendors</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    to="/staff/vendors/organization-cookies"
                    iconName="cookie"
                >
                    <Trans>Organization cookies</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    to="/staff/vendors/organization-trackers"
                    iconName="mark"
                >
                    <Trans>Organization trackers</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
        </Sidebar>
    )
}
