/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const SearchInput = ({
    value: valueFromProps = '',
    className,
    onChange,
    isCollapsed: canBeCollapsed = false,
    ...props
}) => {
    const [isCollapsed, setIsCollapsed] = useState(canBeCollapsed)
    const [value, setValue] = useState(valueFromProps)
    const handleChange = (e) => {
        setValue(e.target.value)
        onChange(e.target.value)
    }
    const finalIsCollapsed = value === '' && isCollapsed
    return (
        <div className={clsx('relative', className)}>
            <div
                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"
                aria-hidden="true"
            >
                <Icon name="search" className="text-gray-700" />
            </div>
            <input
                type="text"
                name="search"
                className={clsx(
                    'form-input block w-full rounded-lg border-gray-300 shadow-xs transition-all ease-in-out hover:border-gray-400 sm:text-sm',
                    !finalIsCollapsed && 'w-full pl-9',
                    finalIsCollapsed &&
                        'w-[38px] pl-[38px] pr-0 focus:w-full focus:pl-9'
                )}
                placeholder="Search"
                value={value}
                onFocus={() => {
                    if (canBeCollapsed) {
                        setIsCollapsed(false)
                    }
                }}
                onBlur={() => {
                    if (canBeCollapsed) {
                        setIsCollapsed(true)
                    }
                }}
                onChange={handleChange}
                {...props}
            />
            {value && (
                <div className="absolute inset-y-0 right-0 flex items-center pl-3">
                    <button
                        type="button"
                        onClick={() => {
                            setValue('')
                            onChange('')
                        }}
                        className="ml-3 mr-2 rounded p-0.5 hover:bg-gray-100"
                    >
                        <Icon name="x" size="sm" />
                    </button>
                </div>
            )}
        </div>
    )
}

export default SearchInput
