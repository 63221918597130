import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteOrganizationVendorModal from './DeleteOrganizationVendorModal'
import useDeleteOrganizationVendorMutation from './useDeleteOrganizationVendorMutation'

const DeleteOrganizationVendorModalWithState = ({
    vendor,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteVendor, { loading }] = useDeleteOrganizationVendorMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteOrganizationVendorModal
            onDismiss={onDismiss}
            vendor={vendor}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { id: vendor.id }
                    await deleteVendor({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{vendor.name} successfully deleted.</Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteOrganizationVendorModalWithState
