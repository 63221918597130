import React, { useState } from 'react'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import AuditSettingsPageLoginProfilesTableRowActionButton from './AuditSettingsPageLoginProfilesTableRowActionButton'
import RemoveLoginProfileModalWithState from './RemoveLoginProfileModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const AuditSettingsPageLoginProfilesTableRowActionButtonWithState = ({
    audit,
    loginProfile,
    onRemoveLoginProfileCompleted,
}) => {
    const { currentUser } = useAppState()
    const [isRemoveProfileModalOpen, setRemoveProfileModalOpen] =
        useState(false)
    return (
        <>
            {isRemoveProfileModalOpen && (
                <RemoveLoginProfileModalWithState
                    audit={audit}
                    loginProfile={loginProfile}
                    onDismiss={() => setRemoveProfileModalOpen(false)}
                    onCompleted={onRemoveLoginProfileCompleted}
                />
            )}
            <AuditSettingsPageLoginProfilesTableRowActionButton
                loginProfile={loginProfile}
                canRemoveLoginProfile={currentUser.role === OWNER}
                onRemoveLoginProfile={() => setRemoveProfileModalOpen(true)}
            />
        </>
    )
}

export default AuditSettingsPageLoginProfilesTableRowActionButtonWithState
