import React from 'react'
import { ApolloProvider } from '@apollo/client'
import NotificationsProvider from './providers/NotificationsProvider'
import LinguiProvider from './providers/LinguiProvider'

const AppProviders = ({
    apolloClient,
    currentUser,
    notificationDispatcher,
    locales,
    children,
}) => (
    <ApolloProvider client={apolloClient}>
        <NotificationsProvider dispatcher={notificationDispatcher}>
            <LinguiProvider currentUser={currentUser} locales={locales}>
                {children}
            </LinguiProvider>
        </NotificationsProvider>
    </ApolloProvider>
)

export default AppProviders
