import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import Sidebar from '../../layout/Sidebar'
import SidebarMenu from '../../layout/SidebarMenu'
import SidebarTitle from '../../layout/SidebarTitle'
import SidebarMenuItem from '../../layout/SidebarMenuItem'

const StaffJobsPageSidebar = ({ activeQueue, onChangeQueue }) => {
    const history = useHistory()
    return (
        <Sidebar>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>Queues</Trans>
                </SidebarTitle>
                <SidebarMenuItem
                    as="button"
                    onClick={() => history.push('/staff/jobs/audit')}
                    isActive={activeQueue === 'audit'}
                    iconName="clock-fast-forward"
                >
                    <Trans>Audits</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    as="button"
                    onClick={() => history.push('/staff/jobs/scan')}
                    isActive={activeQueue === 'scan'}
                    iconName="scan"
                >
                    <Trans>Scans</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    as="button"
                    onClick={() =>
                        history.push('/staff/jobs/identified-period')
                    }
                    isActive={activeQueue === 'identified_period'}
                    iconName="calendar"
                >
                    <Trans>Identified Period</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    as="button"
                    onClick={() => history.push('/staff/jobs/report')}
                    isActive={activeQueue === 'report'}
                    iconName="presentation-chart-01"
                >
                    <Trans>Reports</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
        </Sidebar>
    )
}

export default StaffJobsPageSidebar
