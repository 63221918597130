import React from 'react'
import { cva } from 'cva'
import Icon from './Icon'

const variant = cva('', {
    variants: {
        size: {
            sm: 'p-1',
            md: 'p-2',
            lg: 'p-2',
            xl: 'p-6',
        },
        color: {
            none: '',
            brand: 'bg-primary-100',
        },
        theme: {
            modern: 'rounded-lg border border-gray-200 shadow-xs',
        },
    },
    defaultVariants: {
        size: 'md',
        color: 'none',
        theme: 'modern',
    },
})

export default function InfoIcon({ iconName, className, size, color, theme }) {
    return (
        <div
            className={`${variant({
                color,
                size,
                theme,
            })} item-center flex justify-center ${className}`}
        >
            <Icon name={iconName} size={size} color={color} />
        </div>
    )
}
