import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const START_JOB_MUTATION = gql`
    mutation startJob($queue: String!, $jobName: String!) {
        staffStartJob(queue: $queue, jobName: $jobName)
    }
`

const useStartJobMutation = (options) => {
    return useMutation(START_JOB_MUTATION, {
        ...options,
    })
}

export default useStartJobMutation
