import React from 'react'
import Select from '../../utils/Select'
import { getAlertStatusText } from '../../utils/AlertTag'
import * as constants from '../../../constants'

const { AVAILABLE_ALERT_TYPES } = constants

const AlertTypeSelect = (props) => (
    <Select {...props}>
        <option value="ALL">All types</option>
        {AVAILABLE_ALERT_TYPES.map((type) => (
            <option key={type} value={type}>
                {getAlertStatusText({ type })}
            </option>
        ))}
    </Select>
)

export default AlertTypeSelect
