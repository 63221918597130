import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const VendorTableItemTableRowActionButton = ({ onDeleteVendorTableItem }) => (
    <DropdownMenu
        as="span"
        position="bottom-start"
        content={({ close }) => (
            <DropdownMenuItem iconName="bin" onClick={onDeleteVendorTableItem}>
                <Trans>Remove from list</Trans>
            </DropdownMenuItem>
        )}
    >
        <ActionButton />
    </DropdownMenu>
)

export default VendorTableItemTableRowActionButton
