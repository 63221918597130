import useAppState from '../hooks/useAppState'

const HasPermission = ({ type, role, placeholder = null, children }) => {
    const { currentUser } = useAppState()
    if (currentUser === null) {
        return placeholder
    }
    if (!type && currentUser.role === role) {
        return children
    }
    if (currentUser.type === type) {
        return children
    }
    return placeholder
}

export default HasPermission
