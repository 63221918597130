import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import TableStateProvider from '../../providers/TableStateProvider'
import ScanPageTable from './ScanPageTable'
import ScanPageTableActions from './ScanPageTableActions'

export default function ScansPage({
    search,
    scans,
    audits,
    consentProfiles,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    isFetching,
    activeFilters,
    dateRangeState,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    initialOrderBy,
    onChangeOrderBy,
    onDeleteScanCompleted,
    onDeleteScans,
    statusFilter,
    consentProfilesFilter,
    auditsFilter,
    onChangeStatusFilter,
    onChangeConsentProfilesFilter,
    onChangeAuditsFilter,
}) {
    return (
        <PageContainer data-testid="ScansPage">
            <OrganizationHeader />
            <SidebarContainer>
                <PageContent size="full">
                    <PageHeader title={<Trans>Scans</Trans>} />
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <ScanPageTableActions
                            dateRangeState={dateRangeState}
                            search={search}
                            audits={audits}
                            consentProfiles={consentProfiles}
                            onSearch={onSearch}
                            activeFilters={activeFilters}
                            onAddFilter={onAddFilter}
                            onRemoveFilter={onRemoveFilter}
                            onRemoveAllFilters={onRemoveAllFilters}
                            statusFilter={statusFilter}
                            consentProfilesFilter={consentProfilesFilter}
                            auditsFilter={auditsFilter}
                            onChangeStatusFilter={onChangeStatusFilter}
                            onChangeConsentProfilesFilter={
                                onChangeConsentProfilesFilter
                            }
                            onChangeAuditsFilter={onChangeAuditsFilter}
                            onDeleteScans={onDeleteScans}
                        />
                        <ScanPageTable
                            scans={scans}
                            onDeleteScanCompleted={onDeleteScanCompleted}
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            isFetching={isFetching}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
