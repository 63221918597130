import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this vendor table.</Trans>,
    },
])

const toFormValues = ({ name, audits = [] }) => ({
    name,
    auditIds: audits.map(({ id }) => id),
})

const useUpdateVendorTableFormState = (vendorTable) => {
    const formState = useFormState(toFormValues(vendorTable), { validation })
    return formState
}

export default useUpdateVendorTableFormState
