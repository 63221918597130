import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createVendors($input: UploadVendorInput!) {
        createVendors(input: $input)
    }
`

const useBulkUploadCreateVendorsMutation = (options) =>
    useMutation(MUTATION, options)

export default useBulkUploadCreateVendorsMutation
