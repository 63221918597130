import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import AuditSessionsPageTableRow from './AuditSessionsPageTableRow'
import AuditSessionsPageTableHeaderRow from './AuditSessionsPageTableHeaderRow'

export default function AuditSessionsPageTable({
    audit,
    sessions = [],
    isFetchingMore,
    isFetching,
    onFetchMore,
    hasMoreRecords,
}) {
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                <thead>
                    <AuditSessionsPageTableHeaderRow />
                </thead>
                <TableBody>
                    {sessions.map((session) => (
                        <AuditSessionsPageTableRow session={session} />
                    ))}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
