import React, { useState } from 'react'
import AlertsPageDelegateDropdown from './AlertsPageDelegateDropdown'
import useAlertDelegateQuery from './useAlertDelegateQuery'
import useSearch from '../../hooks/useSearch'
import AlertExternalDelegationModal from './AlertExternalDelegationModal'
import useAlertExternalDelegationFormState from './useAlertExternalDelegationFormState'

const AlertsPageActionsDelegateButtonWithState = ({
    onClearDelegation,
    onDelegateExternal,
    onDelegateInternal,
    selectedAlerts,
    children,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [isSendingMail, setIsSendingMail] = useState(false)
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
    const [search, setSearch] = useSearch('')
    const { users, isFetching } = useAlertDelegateQuery(
        { search },
        {
            skip: !dropdownIsOpen,
        }
    )
    const formState = useAlertExternalDelegationFormState()

    return (
        <>
            {modalIsOpen && (
                <AlertExternalDelegationModal
                    groupingIds={selectedAlerts.map(
                        (alert) => alert.groupingId
                    )}
                    formState={formState}
                    onDismiss={() => setModalIsOpen(false)}
                    onSubmit={async () => {
                        if (formState.validate()) {
                            const input = formState.valuesToInput()
                            setIsSendingMail(true)
                            await onDelegateExternal(input)
                            setIsSendingMail(false)
                            formState.resetForm()
                            setModalIsOpen(false)
                        }
                    }}
                    isSendingMail={isSendingMail}
                />
            )}
            <AlertsPageDelegateDropdown
                users={users}
                isFetching={isFetching}
                onOpenDropdownMenu={() => setDropdownIsOpen(true)}
                onDelegateExternal={() => setModalIsOpen(true)}
                onSelectUser={onDelegateInternal}
                onClearDelegation={onClearDelegation}
                canClearDelegation
                onSearch={(value) => setSearch(value)}
            >
                {children}
            </AlertsPageDelegateDropdown>
        </>
    )
}

export default AlertsPageActionsDelegateButtonWithState
