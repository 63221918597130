import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
import Button from './Button'

const ConfirmModal = ({
    onDismiss,
    title,
    description,
    isLoading,
    isDisabled,
    type = 'danger',
    confirmButtonText = 'Ok',
    cancelButtonText = 'Cancel',
    onConfirm,
    children,
}) => (
    <Modal onDismiss={onDismiss} className="max-w-lg">
        <ModalTitle
            title={title}
            description={description}
            isDisabled={isDisabled}
            onClose={onDismiss}
        />
        {children}
        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
            <span className="flex w-full rounded-md sm:ml-3 sm:w-auto">
                <Button
                    type={type}
                    onClick={onConfirm}
                    loading={isLoading}
                    disabled={isDisabled}
                >
                    <Trans>{confirmButtonText}</Trans>
                </Button>
            </span>
            <span className="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
                <Button type="white" onClick={onDismiss} disabled={isDisabled}>
                    <Trans>{cancelButtonText}</Trans>
                </Button>
            </span>
        </div>
    </Modal>
)

export default ConfirmModal
