import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation deleteConsentProfile($id: ID!) {
        deleteConsentProfile(id: $id)
    }
`

const useDeleteConsentProfile = (options) => useMutation(MUTATION, options)

export default useDeleteConsentProfile
