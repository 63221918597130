import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import useNotifications from '../../hooks/useNotifications'
import useAppState from '../../hooks/useAppState'
import CreateOrganizationCookieModal from './CreateOrganizationCookieModal'
import useCreateOrganizationCookieFormState from './useCreateOrganizationCookieFormState'
import useCreateOrganizationCookieMutation from './useCreateOrganizationCookieMutation'

const { USER_ROLE_OWNER: OWNER } = constants

const CreateOrganizationCookieModalWithState = ({
    title,
    description,
    cookie,
    scanCookie,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const [createCookie, { loading: isUpdating }] =
        useCreateOrganizationCookieMutation({
            onCompleted,
            onError,
        })
    const formState = useCreateOrganizationCookieFormState(scanCookie, cookie)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateOrganizationCookieModal
            title={title}
            isUpdating={isUpdating}
            description={description}
            currentUser={currentUser}
            initialVendor={cookie && cookie.vendor}
            originalCookieName={
                (scanCookie && scanCookie?.originalName) ||
                scanCookie?.name ||
                'No name found'
            }
            canCreateVendor={currentUser.role === OWNER}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = {
                            ...formState.values,
                            scanCookieName: scanCookie ? scanCookie.name : null,
                        }
                        await createCookie({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Cookie created successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateOrganizationCookieModalWithState
