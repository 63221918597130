import React from 'react'
import AlertIcon from '../../utils/AlertIcon'

const AlertsDashboardCard = ({
    severityType,
    title,
    isFetching,
    alertsDashboardValue,
}) => (
    <div className="flex-1 flex justify-between bg-white p-4 border border-gray-200 rounded-xl">
        <div>
            <AlertIcon alert={{ severityType }} className="mb-2" />
            <h3>{title}</h3>
        </div>
        {!isFetching && (
            <div className="text-3xl font-semibold mr-2">
                {alertsDashboardValue}
            </div>
        )}
    </div>
)

export default AlertsDashboardCard
