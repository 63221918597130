import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'

const FRAGMENT = gql`
    fragment VendorTrackersPageTableRow on Tracker {
        id
        domain
        purposes {
            id
            name
        }
    }
`

const VendorTrackersPageTableRow = ({ tracker }) => {
    const { domain, purpose } = tracker
    return (
        <tr data-testid="VendorTrackersPageTableRow">
            <TableCell>{domain}</TableCell>
            <TableCell>
                {purpose && (
                    <Tag key={purpose.id} type="blue" className="mr-2">
                        {purpose.name}
                    </Tag>
                )}
            </TableCell>
        </tr>
    )
}

VendorTrackersPageTableRow.FRAGMENT = FRAGMENT

export default VendorTrackersPageTableRow
