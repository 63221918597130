import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function AuditAlertPageTableHeaderRow() {
    return (
        <TableHeaderRow isSticky>
            <TableHeaderCell columnId="subject" initialWidth={200}>
                <Trans>Subject</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="vendor" initialWidth={100}>
                <Trans>Vendor</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="alert" initialWidth={200}>
                <Trans>Alert</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="occurrences" initialWidth={200}>
                <Trans>Occurrences</Trans>
            </TableHeaderCell>
            <TableHeaderCell />
        </TableHeaderRow>
    )
}
