import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import TableLoading from '../../utils/TableLoading'
import PageContainer from '../../layout/PageContainer'
import PageContent from '../../utils/PageContent'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import PageHeader from '../../layout/PageHeader'
import UserInvitationsPageTable from './UserInvitationsPageTable'
import SettingsPagePageSidebar from '../settings/SettingsPageSidebar'

const UserInvitationsPage = ({
    invitations,
    onResendInvitationComplete,
    isFetchingMore,
    isFetching,
    onFetchMore,
    hasMoreRecords,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => (
    <PageContainer data-testid="UserInvitationsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPagePageSidebar />
            <PageContent size="sm" isFetching={isFetching}>
                <PageHeader
                    className="pb-4"
                    title={<Trans>User invitations</Trans>}
                    description={
                        <Trans>
                            All the users invitations for your organization
                        </Trans>
                    }
                />
                <UserInvitationsPageTable
                    invitations={invitations}
                    onResendInvitationComplete={onResendInvitationComplete}
                    isFetchingMore={isFetchingMore}
                    onFetchMore={onFetchMore}
                    hasMoreRecords={hasMoreRecords}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                    onResendInvitationSuccess={onResendInvitationSuccess}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UserInvitationsPage
