import React from 'react'
import { Trans } from '@lingui/macro'
import AlertsDashboardCard from './AlertsDashboardCard'
import * as constants from '../../../constants'

const {
    ALERT_SEVERITY_TYPE_WARNING: WARNING,
    ALERT_SEVERITY_TYPE_ISSUE: ISSUE,
    ALERT_SEVERITY_TYPE_CRITICAL: CRITICAL,
} = constants

const AlertsDashboard = ({ isFetching, alertsByPeriod }) => (
    <div className="flex gap-4 max-w-[1250px]">
        <AlertsDashboardCard
            severityType={WARNING}
            title={<Trans>Warnings</Trans>}
            isFetching={isFetching}
            alertsDashboardValue={alertsByPeriod?.warningAlerts}
        />
        <AlertsDashboardCard
            severityType={ISSUE}
            title={<Trans>Issues</Trans>}
            isFetching={isFetching}
            alertsDashboardValue={alertsByPeriod?.issueAlerts}
        />
        <AlertsDashboardCard
            severityType={CRITICAL}
            title={<Trans>Critical alerts</Trans>}
            isFetching={isFetching}
            alertsDashboardValue={alertsByPeriod?.criticalAlerts}
        />
    </div>
)

export default AlertsDashboard
