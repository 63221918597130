import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import AuditDetailsPageCard from './AuditDetailsPageCard'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import AuditScansPageTableRow from '../audits-scans/AuditScansPageTableRow'
import AuditSessionsPageTableRow from '../audits-sessions/AuditSessionsPageTableRow'

const QUERY = gql`
    query alertsPage(
        $auditId: ID!
        $scanFilters: ScanFilters
        $scansAfter: String
        $sessionsAfter: String
    ) {
        audit(id: $auditId) {
            ...AuditDetailsPageCard
        }
        scans(first: 10, after: $scansAfter, filters: $scanFilters) {
            edges {
                node {
                    ...AuditScansPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
        sessions(first: 10, after: $sessionsAfter, auditId: $auditId) {
            edges {
                node {
                    ...AuditSessionsPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${AuditDetailsPageCard.FRAGMENT}
    ${AuditScansPageTableRow.FRAGMENT}
    ${AuditSessionsPageTableRow.FRAGMENT}
`

const useAuditAlertsPageQuery = () => {
    const { id: auditId } = useParams()
    const scanFilters = { auditId }
    const variables = { auditId, scanFilters }
    const { data = {}, loading: isFetching } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'no-cache',
    })
    return {
        isFetching,
        audit: data.audit || {},
        scans: connectionToCollection(data.scans),
        sessions: connectionToCollection(data.sessions),
    }
}

export default useAuditAlertsPageQuery
