import React, { useState } from 'react'
import gql from 'graphql-tag'
import { format, addDays } from 'date-fns'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import AuditDetailsPageAlertsChart from './AuditDetailsPageAlertsChart'
import * as constants from '../../../constants'

const { DATE_FORMAT_GRAPHQL_DATE: FORMAT } = constants

const QUERY = gql`
    query alertsPage($auditId: ID!, $filters: AlertFilters) {
        alertsByDayChart(auditId: $auditId, filters: $filters) {
            startDate
            warningAlerts
            issueAlerts
            criticalAlerts
        }
    }
`

const AuditAlertsPageWithState = () => {
    const [selectedDate, setSelectedDate] = useState('last-7')
    const { id: auditId } = useParams()
    let startDate = format(addDays(new Date(), -7), FORMAT)
    let endDate = format(new Date(), FORMAT)
    if (selectedDate === 'last-14') {
        startDate = format(addDays(new Date(), -14), FORMAT)
        endDate = format(new Date(), FORMAT)
    }
    if (selectedDate === 'last-30') {
        startDate = format(addDays(new Date(), -30), FORMAT)
        endDate = format(new Date(), FORMAT)
    }
    const filters = { startDate, endDate }
    const variables = { auditId, filters }
    const { data = {}, loading: isFetching } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return (
        <AuditDetailsPageAlertsChart
            isFetching={isFetching}
            selectedDate={selectedDate}
            onChangeDate={(e) => setSelectedDate(e.target.value)}
            alertsByDayChart={data.alertsByDayChart || []}
        />
    )
}

export default AuditAlertsPageWithState
