import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const UPDATE_DOMAIN_MUTATION = gql`
    mutation updateDomain($id: ID!, $input: DomainInput!) {
        updateDomain(id: $id, input: $input) {
            domain {
                id
            }
        }
    }
`

const useUpdateDomainMutation = (options) =>
    useMutation(UPDATE_DOMAIN_MUTATION, options)

export default useUpdateDomainMutation
