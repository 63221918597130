import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import PageHeader from '../../layout/PageHeader'
import AuditVendorsPageTable from './AuditVendorsPageTable'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditVendorsPageActions from './AuditVendorsPageActions'
import AuditVendorPageSidebar from './AuditVendorPageSidebar'

export default function AuditVendorsPage({
    audit,
    newVendors,
    isFetching,
    isFetchingMore,
    onFetchMore,
    startDate,
    endDate,
    hasMoreRecords,
    dateRangeState,
    search,
    vendors,
    initialOrderBy,
    onChangeOrderBy,
    onUpdateCompleted,
    onSearch,
}) {
    return (
        <PageContainer data-testid="AuditVendorsPage">
            <AuditHeader />
            <SidebarContainer>
                <AuditVendorPageSidebar id={audit.id} />
                <PageContent size="full">
                    <PageHeader
                        title={<Trans>Vendors</Trans>}
                        description={
                            <Trans>
                                Here you can see the vendors that are present in
                                your domain.
                            </Trans>
                        }
                    />
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <AuditVendorsPageActions
                            dateRangeState={dateRangeState}
                            search={search}
                            onSearch={onSearch}
                        />
                        <AuditVendorsPageTable
                            audit={audit}
                            vendors={vendors}
                            onUpdateCompleted={onUpdateCompleted}
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            isFetching={isFetching}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
