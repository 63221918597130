import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../utils/PageHeader'

const UpdateCustomCmpPageHeader = () => (
    <PageHeader
        title={<Trans>Update CMP</Trans>}
        breadcrumbsItems={[
            {
                text: <Trans>Settings</Trans>,
                to: '/settings/cmps',
            },
            {
                text: <Trans>Update CMP</Trans>,
            },
        ]}
    />
)

export default UpdateCustomCmpPageHeader
