import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import AuditsPageTableRowActionButton from './AuditsPageTableRowActionButton'
import DeleteAuditModalWithState from './DeleteAuditModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const MARK_AS_FAVORITE_MUTATION = gql`
    mutation markAuditAsFavorite($id: ID!, $removeAsFavorite: Boolean) {
        markAuditAsFavorite(id: $id, removeAsFavorite: $removeAsFavorite)
    }
`

const useMarkAuditAsFavorite = (options) =>
    useMutation(MARK_AS_FAVORITE_MUTATION, options)

const AuditsPageTableRowActionButtonWithState = ({
    audit,
    onDeleteAuditCompleted,
    onMarkAsFavoriteCompleted,
}) => {
    const { currentUser } = useAppState()
    const [isRemoveAuditModalOpen, setRemoveAuditModalOpen] = useState(false)
    const variables = {
        id: audit.id,
        removeAsFavorite: audit.isFavorite || false,
    }
    const [markAuditAsFavorite, { loading }] = useMarkAuditAsFavorite({
        variables,
        onCompleted: onMarkAsFavoriteCompleted,
    })
    return (
        <>
            {isRemoveAuditModalOpen && (
                <DeleteAuditModalWithState
                    audit={audit}
                    onDismiss={() => setRemoveAuditModalOpen(false)}
                    onCompleted={onDeleteAuditCompleted}
                />
            )}
            <AuditsPageTableRowActionButton
                audit={audit}
                canDeleteAudit={currentUser.role === OWNER}
                isMarkingAsFavorite={loading}
                onDeleteAudit={() => setRemoveAuditModalOpen(true)}
                onMarkAsFavorite={() => markAuditAsFavorite()}
            />
        </>
    )
}

export default AuditsPageTableRowActionButtonWithState
