import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import Alert from '../../utils/Alert'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormSelect from '../../utils/FormSelect'

const AddLoginProfileModal = ({
    loginProfiles,
    formState,
    onDismiss,
    onSubmit,
}) => {
    const noLoginProfileToAdd = loginProfiles.length === 0
    return (
        <Modal onDismiss={onDismiss} className="max-w-lg">
            <ModalTitle
                title={<Trans>Add login profile</Trans>}
                description={
                    <Trans>Add a new login profile to this audit.</Trans>
                }
                onClose={onDismiss}
            />
            {(() => {
                if (noLoginProfileToAdd) {
                    return (
                        <Alert
                            type="warning"
                            className="mb-6"
                            title={<Trans>No login profile to add</Trans>}
                        >
                            <Trans>
                                This audit already contains all login profiles.
                                You cannot add more login profiles than this.
                            </Trans>
                        </Alert>
                    )
                }
                return (
                    <FormGroup htmlFor="roleId">
                        <FormSelect
                            id="role"
                            errors={formState.getErrorMessages(
                                'loginProfileId'
                            )}
                            {...formState.getNativeInputProps('loginProfileId')}
                        >
                            {loginProfiles.map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </FormSelect>
                    </FormGroup>
                )
            })()}
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit} disabled={noLoginProfileToAdd}>
                        <Trans>Add Profile</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default AddLoginProfileModal
