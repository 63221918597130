/* eslint-disable react/no-array-index-key */
import React from 'react'
import Avatar from './Avatar'
import { getDelegationText } from '../../utils/delegations'
import { timeAgo } from '../../utils/dates'

const AlertDelegation = ({ delegation }) => {
    const { author, user, externalEmail, type, createdAt } = delegation
    return (
        <div className="flex gap-2">
            <Avatar name={author.fullName} />
            <div className="border border-gray-200 w-full rounded-lg">
                <div className="flex items-center justify-between py-2 px-4">
                    <div className="text-sm">
                        {getDelegationText(author, user, externalEmail, type)}
                    </div>
                    <div className="text-gray-500 text-sm">
                        {timeAgo(createdAt)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertDelegation
