import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'

const AuditDomainsPageTableHeaderRow = () => (
    <tr>
        <TableHeaderCell>
            <Trans>Domain</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Purpose</Trans>
        </TableHeaderCell>
    </tr>
)

export default AuditDomainsPageTableHeaderRow
