/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DropdownMenuTitle from '../../utils/DropdownMenuTitle'
import ActionButton from '../../utils/ActionButton'

const AuditAlertsPageTableRowActions = ({
    cookie,
    tracker,
    canIgnoreAlert,
    canReviewAlert,
    canUpdateTracker,
    canDeleteTracker,
    canUpdateCookie,
    canDeleteCookie,
    isIgnored,
    isLoading,
    isReviewed,
    onIgnoreAlert,
    onReviewAlert,
    onUpdateTracker,
    onDeleteTracker,
    onUpdateCookie,
    onDeleteCookie,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <>
                <DropdownMenuItem
                    iconName={
                        isIgnored ? 'switch-horizontal-01' : 'mute-notification'
                    }
                    disabled={!canIgnoreAlert}
                    onClick={async (e) => {
                        e.stopPropagation()
                        await onIgnoreAlert()
                        close()
                    }}
                >
                    {isIgnored ? (
                        <Trans>Unignore alert</Trans>
                    ) : (
                        <Trans>Ignore alert</Trans>
                    )}
                </DropdownMenuItem>
                {tracker && (
                    <>
                        <DropdownMenuItem
                            iconName="edit"
                            disabled={!canUpdateTracker}
                            onClick={(e) => {
                                onUpdateTracker(tracker)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            {tracker.belongsToOrganization ? (
                                <Trans>
                                    Update your tracker classification
                                </Trans>
                            ) : (
                                <Trans>Change tracker classification</Trans>
                            )}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="x"
                            disabled={!canDeleteTracker}
                            onClick={(e) => {
                                onDeleteTracker(tracker)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            {tracker.belongsToOrganization ? (
                                <Trans>
                                    Remove your tracker classification
                                </Trans>
                            ) : (
                                <Trans>Remove tracker classification</Trans>
                            )}
                        </DropdownMenuItem>
                    </>
                )}
                {cookie && (
                    <>
                        <DropdownMenuItem
                            iconName="edit"
                            disabled={!canUpdateCookie}
                            onClick={(e) => {
                                onUpdateCookie(cookie)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            {cookie.belongsToOrganization ? (
                                <Trans>Update your cookie classification</Trans>
                            ) : (
                                <Trans>Change cookie classification</Trans>
                            )}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="x"
                            disabled={!canDeleteCookie}
                            onClick={(e) => {
                                onDeleteCookie(cookie)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            {cookie.belongsToOrganization ? (
                                <Trans>Remove your cookie classification</Trans>
                            ) : (
                                <Trans>Remove cookie classification</Trans>
                            )}
                        </DropdownMenuItem>
                    </>
                )}
            </>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default AuditAlertsPageTableRowActions
