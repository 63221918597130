import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const CREATE_REPORT_MUTATION = gql`
    mutation createReport($input: CreateReportInput!) {
        createReport(input: $input) {
            report {
                id
            }
        }
    }
`

const useCreateReportMutation = (options) =>
    useMutation(CREATE_REPORT_MUTATION, options)

export default useCreateReportMutation
