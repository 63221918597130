import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import PageHeader from '../../layout/PageHeader'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'
import TableStateProvider from '../../providers/TableStateProvider'
import AvatarGroup from '../../utils/AvatarGroup'
import useDrawerStack from '../../hooks/useDrawerStack'
import VendorTableItemsPageTable from './VendorTableItemsPageTable'
import VendorTableItemsPageTableActions from './VendorTableItemsPageTableActions'
import CreateVendorTableVersionModalWithState from './CreateVendorTableVersionModalWithState'
import CustomizeVendorTableModalWithState from './CustomizeVendorTableModalWithState'
import UpdateVendorTableDrawer from './UpdateVendorTableDrawer'
import VendorTableItemsPageMenuDotsButton from './VendorTableItemsPageMenuDotsButton'
import EmbedVendorTableModal from './EmbedVendorTableModal'

const VendorTableItemsPage = ({
    vendorTable,
    vendorTableItems = [],
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    search,
    onSearch,
    activeTab,
    initialOrderBy,
    onChangeOrderBy,
    onUpdateVendorTableCompleted,
    onUpdateVendorTableItemsCompleted,
    onCreateVendorTableVersionCompleted,
    onDeleteVendorTableCompleted,
}) => {
    const { open, close } = useDrawerStack()
    const { NEXT_PUBLIC_API_URL } = process.env
    const [
        isCreateVendorTableVersionModalOpen,
        setCreateVendorTableVersionModalOpen,
    ] = useState(false)
    const [isCustomizeVendorTableModalOpen, setCustomizeVendorTableModalOpen] =
        useState(false)
    const [isEmbedVendorTableModalOpen, setEmbedVendorTableModalOpen] =
        useState(false)

    const auditImageUrls =
        vendorTable?.latestVersion?.audits?.map((audit) => audit.imageUrl) || []

    const auditIds =
        vendorTable?.latestVersion?.audits?.map((audit) => audit.id) || []

    return (
        <>
            {isCustomizeVendorTableModalOpen && (
                <CustomizeVendorTableModalWithState
                    onDismiss={() => setCustomizeVendorTableModalOpen(false)}
                    onCompleted={() => {
                        setCustomizeVendorTableModalOpen(false)
                    }}
                />
            )}
            {isEmbedVendorTableModalOpen && (
                <EmbedVendorTableModal
                    link={`${NEXT_PUBLIC_API_URL}/vendor-table/${vendorTable.id}`}
                    onDismiss={() => setEmbedVendorTableModalOpen(false)}
                    onCompleted={() => {
                        setEmbedVendorTableModalOpen(false)
                    }}
                />
            )}
            {isCreateVendorTableVersionModalOpen && (
                <CreateVendorTableVersionModalWithState
                    onDismiss={() =>
                        setCreateVendorTableVersionModalOpen(false)
                    }
                    vendorTable={vendorTable}
                    onCompleted={() => {
                        setCreateVendorTableVersionModalOpen(false)
                        onCreateVendorTableVersionCompleted()
                    }}
                />
            )}
            <PageContainer data-testid="VendorTableItemsPage">
                <OrganizationHeader />
                <SidebarContainer>
                    <IdentifiedVendorsPageSidebar />
                    <PageContent size="full">
                        <PageHeader
                            avatar={<AvatarGroup imageUrls={auditImageUrls} />}
                            title={vendorTable?.name}
                            description={vendorTable?.latestVersion?.name}
                            rightChildren={
                                <div className="flex gap-2">
                                    <VendorTableItemsPageMenuDotsButton
                                        vendorTable={vendorTable}
                                        onDeleteCompleted={
                                            onDeleteVendorTableCompleted
                                        }
                                    />
                                    <Button
                                        className="h-[40px]"
                                        type="white"
                                        onClick={() =>
                                            open(UpdateVendorTableDrawer, {
                                                vendorTableId: vendorTable?.id,
                                                onDismiss: () => close(),
                                                onCreateNewVersion: () =>
                                                    setCreateVendorTableVersionModalOpen(
                                                        true
                                                    ),
                                                onCustomizeVendorTable: () =>
                                                    setCustomizeVendorTableModalOpen(
                                                        true
                                                    ),
                                                onEmbedVendorTable: () => {
                                                    setEmbedVendorTableModalOpen(
                                                        true
                                                    )
                                                },
                                                onCompleted:
                                                    onUpdateVendorTableCompleted,
                                            })
                                        }
                                        iconBeforeProps={{
                                            name: 'pencil-02',
                                            size: 'sm',
                                        }}
                                    >
                                        <Trans>Edit</Trans>
                                    </Button>
                                    <Button
                                        className="h-fit"
                                        type="primary"
                                        onClick={() =>
                                            setCreateVendorTableVersionModalOpen(
                                                true
                                            )
                                        }
                                        iconBeforeProps={{
                                            name: 'plus',
                                            size: 'sm',
                                        }}
                                    >
                                        <Trans>Create new version</Trans>
                                    </Button>
                                </div>
                            }
                        />
                        {(() => {
                            return (
                                <TableStateProvider
                                    initialOrderBy={initialOrderBy}
                                    onChangeOrderBy={onChangeOrderBy}
                                >
                                    <VendorTableItemsPageTableActions
                                        search={search}
                                        onSearch={onSearch}
                                        onCompleted={
                                            onUpdateVendorTableItemsCompleted
                                        }
                                    />
                                    <VendorTableItemsPageTable
                                        onUpdateVendorTableItemsCompleted={
                                            onUpdateVendorTableItemsCompleted
                                        }
                                        auditIds={auditIds}
                                        vendorTableItems={vendorTableItems}
                                        activeTab={activeTab}
                                        isFetchingMore={isFetchingMore}
                                        hasMoreRecords={hasMoreRecords}
                                        onFetchMore={onFetchMore}
                                    />
                                </TableStateProvider>
                            )
                        })()}
                    </PageContent>
                </SidebarContainer>
            </PageContainer>
        </>
    )
}

export default VendorTableItemsPage
