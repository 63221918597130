import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import StaffJobsPageTableRow from './StaffJobsPageTableRow'

const QUERY = gql`
    query staffJobsPageQuery($status: String, $queue: String) {
        staffJobs(status: $status, queue: $queue) {
            ...StaffJobsPageTableRow
        }
    }
    ${StaffJobsPageTableRow.FRAGMENT}
`

const useStaffJobsPageQuery = (status, queue) => {
    const variables = { status, queue }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        jobs: isFetching ? [] : data.staffJobs,
        isFetching,
        refetch,
    }
}

export default useStaffJobsPageQuery
