import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query changeUserRoleModal {
        roles
    }
`

const useChangeUserRoleModalQuery = (options) => {
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        roles: data.roles || [],
        isFetching,
    }
}

export default useChangeUserRoleModalQuery
