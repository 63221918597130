import React from 'react'
import clsx from 'clsx'

const DetailsCard = ({ className, ...props }) => (
    <div
        className={clsx(
            'bg-white shadow overflow-hidden sm:rounded-lg mb-8',
            className
        )}
        {...props}
    />
)

export default DetailsCard
