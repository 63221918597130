import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../utils/validation'

const validation = createFormValidation([
    {
        path: 'domain',
        validate: isRequired,
        message: <Trans>Choose a domain for this tracker.</Trans>,
    },
    {
        path: 'vendorId',
        validate: isRequired,
        message: <Trans>Choose a vendor for this tracker.</Trans>,
    },
    {
        path: 'purposeIds',
        validate: isRequired,
        message: <Trans>Choose purpose(s) for this tracker.</Trans>,
    },
])

const useCreateTrackerFormState = () => {
    const formState = useFormState(
        {
            domain: '',
            description: '',
            vendorId: '',
            purposeIds: [],
        },
        {
            validation,
        }
    )
    return formState
}

export default useCreateTrackerFormState
