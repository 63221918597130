import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import OrganizationListItem from './OrganizationListItem'

const QUERY = gql`
    query myOrganizationsPage {
        myOrganizations {
            ...OrganizationListItem
        }
    }
    ${OrganizationListItem.FRAGMENT}
`

const useMyOrganizationsPageQuery = (options) => {
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        organizations: data.myOrganizations || [],
        isFetching,
    }
}

export default useMyOrganizationsPageQuery
