import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteVendorTableModal from './DeleteVendorTableModal'
import useDeleteVendorTable from './useDeleteVendorTable'

const DeleteVendorTableModalWithState = ({
    vendorTable,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteVendorTable, { loading }] = useDeleteVendorTable({
        onCompleted,
        onError,
    })
    return (
        <DeleteVendorTableModal
            onDismiss={onDismiss}
            vendorTable={vendorTable}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { id: vendorTable.id }
                    await deleteVendorTable({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {vendorTable.name} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteVendorTableModalWithState
