import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import useNotifications from '../../hooks/useNotifications'
import ConfirmModal from '../../utils/ConfirmModal'

const MUTATION = gql`
    mutation staffClearIdentifiedPeriods($organizationId: ID!) {
        staffClearIdentifiedPeriods(organizationId: $organizationId)
    }
`

const useClearIdentifiedPeriods = (options) => useMutation(MUTATION, options)

const ClearIdentifiedPeriodsModal = ({
    organization,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [clear, { loading }] = useClearIdentifiedPeriods({
        onCompleted,
        onError,
    })
    return (
        <ConfirmModal
            title={<Trans>Clear Identified Periods</Trans>}
            description={
                <Trans>
                    Are you sure you want to clear the identified periods for{' '}
                    <strong>{organization.name}</strong>?
                </Trans>
            }
            confirmButtonText={<Trans>Clear periods</Trans>}
            isLoading={loading}
            isDisabled={loading}
            onDismiss={onDismiss}
            onConfirm={async () => {
                try {
                    await clear({
                        variables: { organizationId: organization.id },
                    })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                Identified periods for{organization.name}{' '}
                                successfully clear.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default ClearIdentifiedPeriodsModal
