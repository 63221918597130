import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import OrganizationTrackersPageTableRowActionButton from './OrganizationTrackersPageTableRowActionButton'
import DeleteOrganizationTrackerModalWithState from './DeleteOrganizationTrackerModalWithState'
import UpdateOrganizationTrackerModalWithState from './UpdateOrganizationTrackerModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const OrganizationTrackersPageTableRowActionButtonWithState = ({
    tracker,
    onDeleteCompleted,
    onUpdateCompleted,
}) => {
    const history = useHistory()
    const { currentUser } = useAppState()
    const deleteModalIsOpen = useRouteMatch({
        path: `/classifications/organization/trackers/${tracker.id}/delete`,
    })
    const updateModalIsOpen = useRouteMatch({
        path: `/classifications/organization/trackers/${tracker.id}/update`,
    })
    return (
        <>
            {deleteModalIsOpen && (
                <DeleteOrganizationTrackerModalWithState
                    tracker={tracker}
                    onDismiss={() =>
                        history.push('/classifications/organization/trackers')
                    }
                    onCompleted={onDeleteCompleted}
                />
            )}
            {updateModalIsOpen && (
                <UpdateOrganizationTrackerModalWithState
                    tracker={tracker}
                    onDismiss={() =>
                        history.push('/classifications/organization/trackers')
                    }
                    onCompleted={onUpdateCompleted}
                />
            )}
            <OrganizationTrackersPageTableRowActionButton
                tracker={tracker}
                canDelete={currentUser.role === OWNER}
                canUpdate={currentUser.role === OWNER}
                onDelete={() =>
                    history.push(
                        `/classifications/organization/trackers/${tracker.id}/delete`
                    )
                }
                onUpdate={() =>
                    history.push(
                        `/classifications/organization/trackers/${tracker.id}/update`
                    )
                }
            />
        </>
    )
}

export default OrganizationTrackersPageTableRowActionButtonWithState
