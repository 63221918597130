import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import RemoveConsentProfileModal from './RemoveConsentProfileModal'
import useRemoveConsentProfileMutation from './useRemoveConsentProfileMutation'

const RemoveConsentProfileModalWithState = ({
    audit,
    consentProfile,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [removeConsentProfile, { loading }] = useRemoveConsentProfileMutation(
        {
            onCompleted,
            onError,
        }
    )
    return (
        <RemoveConsentProfileModal
            onDismiss={onDismiss}
            consentProfile={consentProfile}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const auditId = audit.id
                    const input = { consentProfileId: consentProfile.id }
                    const variables = { auditId, input }
                    await removeConsentProfile({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {consentProfile.name} successfully removed.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default RemoveConsentProfileModalWithState
