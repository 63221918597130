import React from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../utils/validation'
import useNotifications from '../hooks/useNotifications'

const QUERY = gql`
    query updateVendor($id: ID!) {
        vendor(id: $id) {
            id
            name
            types
            externalId
            description
            domain
            websiteUrl
            belongsToOrganization
        }
    }
`

const MUTATION = gql`
    mutation ($id: ID!, $input: VendorInput!) {
        updateVendor(id: $id, input: $input) {
            vendor {
                id
                name
            }
        }
    }
`

export const useUpdateVendorMutation = (options) =>
    useMutation(MUTATION, options)

export const useUpdateVendorQuery = (id, options) => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        variables: { id },
        ...options,
    })
    return {
        error,
        vendor: data.vendor,
        isFetching,
        refetch,
    }
}

export const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this vendor.</Trans>,
    },
])

export const useUpdateVendorFormState = ({
    externalId = '',
    name = '',
    description = '',
    types = [],
    domain = '',
    websiteUrl = '',
} = {}) => {
    const formState = useFormState(
        {
            externalId,
            name,
            description,
            types: types || [],
            domain,
            websiteUrl,
        },
        {
            validation,
        }
    )
    return formState
}

const useUpdateVendor = (vendorId, { onCompleted, onError }) => {
    const { vendor, isFetching } = useUpdateVendorQuery(vendorId)
    const [updateVendor] = useUpdateVendorMutation({ onCompleted, onError })
    const formState = useUpdateVendorFormState(vendor)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return {
        vendor,
        isFetching,
        formState,
        handleSubmit: async () => {
            if (formState.validate()) {
                try {
                    const input = formState.values
                    await updateVendor({ variables: { id: vendorId, input } })
                    formState.resetForm()
                    dispatchSuccess({
                        message: <Trans>Vendor updated successfully!</Trans>,
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }
        },
    }
}

export default useUpdateVendor
