import React from 'react'

const PageContainer = (props) => (
    <div
        data-testid="PageContainer"
        className="h-screen flex overflow-hidden bg-white print:overflow-visible print:bg-white"
        {...props}
    />
)

export default PageContainer
