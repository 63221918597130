import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const UsersPageTableRowActionButton = ({
    isLoading,
    canDeleteUser,
    canChangeRole,
    onChangeRole,
    onDeleteUser,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={!canChangeRole}
                    onClick={async () => {
                        await onChangeRole()
                        close()
                    }}
                >
                    <Trans>Change Role</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="bin"
                    disabled={!canDeleteUser}
                    onClick={async () => {
                        await onDeleteUser()
                        close()
                    }}
                >
                    <Trans>Remove User</Trans>
                </DropdownMenuItem>
            </>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default UsersPageTableRowActionButton
