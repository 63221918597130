import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation useDeleteCookieTableVersionMutation($id: ID!) {
        deleteCookieTableVersion(id: $id)
    }
`

const useDeleteCookieTableVersionMutation = (options) =>
    useMutation(MUTATION, options)

export default useDeleteCookieTableVersionMutation
