import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'
import * as constants from '../../../constants'

const { SESSION_DEVICE_TYPE_IOS: IOS, SESSION_DEVICE_TYPE_WEB: WEB } = constants

const validation = createFormValidation([
    {
        path: 'consentProfileId',
        validate: isRequired,
        message: <Trans>Select a consent profile.</Trans>,
    },
    {
        path: 'harFile',
        validate: isRequired,
        message: <Trans>Choose a HAR file.</Trans>,
    },
])

const useUploadSessionFormState = (audit) =>
    useFormState(
        {
            consentProfileId: null,
            deviceType: audit.isWeb ? WEB : IOS,
            harFile: null,
        },
        {
            validation,
        }
    )

export default useUploadSessionFormState
