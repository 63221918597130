import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation classifyCookies($ids: [ID!], $input: CookiesInput!) {
        classifyCookies(ids: $ids, input: $input) {
            cookies {
                id
            }
        }
    }
`

const useClassifyCookiesMutation = (options) => useMutation(MUTATION, options)

export default useClassifyCookiesMutation
