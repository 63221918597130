import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import RemoveLoginProfileModal from './RemoveLoginProfileModal'
import useRemoveLoginProfileMutation from './useRemoveLoginProfileMutation'

const RemoveLoginProfileModalWithState = ({
    audit,
    loginProfile,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [removeLoginProfile, { loading }] = useRemoveLoginProfileMutation({
        onCompleted,
        onError,
    })
    return (
        <RemoveLoginProfileModal
            onDismiss={onDismiss}
            loginProfile={loginProfile}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const auditId = audit.id
                    const input = { loginProfileId: loginProfile.id }
                    const variables = { auditId, input }
                    await removeLoginProfile({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {loginProfile.name} successfully removed.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default RemoveLoginProfileModalWithState
