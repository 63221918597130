import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const CREATE_ALERT_REPORT_MUTATION = gql`
    mutation createAlertReport($input: CreateAlertReportInput!) {
        createAlertReport(input: $input) {
            report {
                id
            }
        }
    }
`

const useCreateAlertReportMutation = (options) =>
    useMutation(CREATE_ALERT_REPORT_MUTATION, options)

export default useCreateAlertReportMutation
