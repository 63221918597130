/* eslint-disable import/prefer-default-export */
export const setupCrisp = () => {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = '5943096d-86fc-487c-ad95-e0a2b98f3ec9'
    const script = document.createElement('script')
    script.src = 'https://client.crisp.chat/l.js'
    script.async = 1
    document.getElementsByTagName('body')[0].appendChild(script)
}

export const scopeCrispUser = ({ email, firstName, lastName }) => {
    window.$crisp.push(['set', 'user:email', [email]])
    window.$crisp.push(['set', 'user:nickname', [`${firstName} ${lastName}`]])
}
