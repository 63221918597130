import React from 'react'
import PageHeader from '../../layout/PageHeader'
import AuditHeaderActions from './AuditHeaderActions'

const AuditHeader = ({
    title,
    description,
    audit,
    isLoading,
    onStartScan,
    onUploadSession,
}) => {
    const { isMobile, isWeb } = audit
    return (
        <PageHeader
            title={title}
            description={description}
            rightChildren={
                <AuditHeaderActions
                    isMobile={isMobile}
                    isWeb={isWeb}
                    onStartScan={onStartScan}
                    isLoading={isLoading}
                    onUploadSession={onUploadSession}
                />
            }
        />
    )
}

export default AuditHeader
