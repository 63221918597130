import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useAppState from '../../hooks/useAppState'
import ClassifyTrackerModal from './ClassifyTrackerModal'
import useClassifyTrackerMutation from './useClassifyTrackerMutation'
import useClassifyTrackerFormState from './useClassifyTrackerFormState'
import useClassifyTrackerModalQuery from './useClassifyTrackerModalQuery'

const ClassifyTrackerModalWithState = ({
    scanTracker,
    purposes = null,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const canSkipDataFetching = purposes !== null
    const { currentUser } = useAppState()
    const query = useClassifyTrackerModalQuery({ skip: canSkipDataFetching })
    const finalPurposes = purposes || query.purposes
    const [classifyTracker] = useClassifyTrackerMutation({
        onCompleted,
        onError,
    })
    const formState = useClassifyTrackerFormState(scanTracker)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <ClassifyTrackerModal
            purposes={finalPurposes}
            isFetching={query.isFetching}
            canCreateVendor={currentUser.isStaff}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await classifyTracker({ variables: { input } })
                        await query.refetch()
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Tracker classified successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default ClassifyTrackerModalWithState
