import React from 'react'
import StaffJobsPageStartJobButton from './StaffJobsPageStartJobButton'
import useStartJobMutation from './useStartJobMutation'

const StaffJobsPageStartJobButtonWithState = () => {
    const [startJob] = useStartJobMutation()
    return (
        <StaffJobsPageStartJobButton
            isLoading={false}
            onCreateIdentifiedPeriod={() => {
                const variables = {
                    queue: 'identified-period',
                    jobName: 'plan',
                }
                startJob({ variables })
            }}
        />
    )
}

export default StaffJobsPageStartJobButtonWithState
