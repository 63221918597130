import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import ConsentProfilesPageTableRow from './ConsentProfilesPageTableRow'
import ConsentProfilesTablesPageTableHeaderRow from './ConsentProfilesTablesPageHeaderRow'

const ConsentProfilesPageTable = ({
    consentProfiles,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="ConsentProfilesPageTable">
            <thead>
                <ConsentProfilesTablesPageTableHeaderRow />
            </thead>
            <TableBody>
                {consentProfiles.map((consentProfile) => (
                    <ConsentProfilesPageTableRow
                        key={consentProfile.id}
                        consentProfile={consentProfile}
                        onDeleteCompleted={onDeleteCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default ConsentProfilesPageTable
