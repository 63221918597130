import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import useRefetch from '../../hooks/useRefetch'
import UpdateCustomCmpPage from './UpdateCustomCmpPage'
import useUpdateCustomCmpMutation from './useUpdateCustomCmpMutation'
import useUpdateCustomCmpPageQuery from './useUpdateCustomCmpPageQuery'
import useUpdateCustomCmpFormState from './useUpdateCustomCmpFormState'

const CreateDomainPageWithState = () => {
    const { id: cmpId } = useParams()
    const history = useHistory()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const { isFetching, cmp, consentProfiles, refetch } =
        useUpdateCustomCmpPageQuery()
    useRefetch(refetch)
    const [createCustomCmp, mutationData] = useUpdateCustomCmpMutation()
    const formState = useUpdateCustomCmpFormState(cmp)
    if (isFetching) {
        return <PageLoading />
    }
    return (
        <UpdateCustomCmpPage
            cmp={cmp}
            consentProfiles={consentProfiles}
            formState={formState}
            isLoading={mutationData.loading}
            onDisableResolver={(resolver) => {
                const { resolvers } = formState.values
                const newResolvers = resolvers.filter(
                    ({ id }) => id !== resolver.id
                )
                formState.handleChange('resolvers', newResolvers)
            }}
            onEnableProfile={(profile) => {
                const { resolvers } = formState.values
                const newResolver = {
                    script: '',
                    type: 'CSS',
                    profile,
                }
                const newResolvers = [...resolvers, newResolver]
                formState.handleChange('resolvers', newResolvers)
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { name, resolvers } = formState.values
                        const input = {
                            name,
                            resolvers: resolvers.map(
                                ({ id = null, script, type, profile }) => ({
                                    id,
                                    script,
                                    type,
                                    profileId: profile.id,
                                })
                            ),
                        }
                        const variables = { id: cmpId, input }
                        await createCustomCmp({ variables })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Cmp <strong>{name}</strong> created!
                                </Trans>
                            ),
                        })
                        history.push('/settings/cmps', { refetch: true })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default CreateDomainPageWithState
