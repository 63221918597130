/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useAppState from '../../hooks/useAppState'
import ScanTrackersPageTableRowActionButtonStaff from './ScanTrackersPageTableRowActionButtonStaff'
import ScanTrackersPageTableRowActionButton from './ScanTrackersPageTableRowActionButton'
import ClassifyTrackerModalWithState from './ClassifyTrackerModalWithState'
import DeleteTrackerModalWithState from './DeleteTrackerModalWithState'
import UpdateTrackerModalWithState from './UpdateTrackerModalWithState'
import CreateOrganizationTrackerModalWithState from '../classifications-organization-trackers/CreateOrganizationTrackerModalWithState'
import UpdateOrganizationTrackerModalWithState from '../classifications-organization-trackers/UpdateOrganizationTrackerModalWithState'
import DeleteOrganizationTrackerModalWithState from '../classifications-organization-trackers/DeleteOrganizationTrackerModalWithState'

const ScanTrackersPageActionsDropdownMenu = ({
    auditId,
    tracker,
    scanTracker,
    onClassifyTrackerCompleted,
    onDeleteTrackerCompleted,
    onUpdateTrackerCompleted,
    children,
}) => {
    const { currentUser } = useAppState()
    const [classifyModalIsOpen, setClassifyModalIsOpen] = useState(false)
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
    const [
        classifyOrganizationModalIsOpen,
        setClassifyOrganizationModalIsOpen,
    ] = useState(false)
    const [deleteOrganizationModalIsOpen, setDeleteOrganizationModalIsOpen] =
        useState(false)
    const [updateOrganizationModalIsOpen, setUpdateOrganizationModalIsOpen] =
        useState(false)
    if (currentUser.isStaff) {
        return (
            <>
                {classifyModalIsOpen && (
                    <ClassifyTrackerModalWithState
                        scanTracker={scanTracker}
                        onDismiss={() => setClassifyModalIsOpen(false)}
                        onCompleted={onClassifyTrackerCompleted}
                    />
                )}
                {deleteModalIsOpen && (
                    <DeleteTrackerModalWithState
                        tracker={tracker}
                        onDismiss={() => setDeleteModalIsOpen(false)}
                        onCompleted={onDeleteTrackerCompleted}
                    />
                )}
                {updateModalIsOpen && (
                    <UpdateTrackerModalWithState
                        trackerId={tracker.id}
                        tracker={tracker}
                        onDismiss={() => setUpdateModalIsOpen(false)}
                        onCompleted={onUpdateTrackerCompleted}
                    />
                )}
                {classifyOrganizationModalIsOpen && (
                    <CreateOrganizationTrackerModalWithState
                        title={<Trans>Classify tracker</Trans>}
                        tracker={tracker}
                        scanTracker={scanTracker}
                        onDismiss={() =>
                            setClassifyOrganizationModalIsOpen(false)
                        }
                        onCompleted={onClassifyTrackerCompleted}
                    />
                )}
                {deleteOrganizationModalIsOpen && (
                    <DeleteOrganizationTrackerModalWithState
                        title={<Trans>Remove your classification</Trans>}
                        tracker={tracker}
                        onDismiss={() =>
                            setDeleteOrganizationModalIsOpen(false)
                        }
                        onCompleted={onDeleteTrackerCompleted}
                    />
                )}
                {updateOrganizationModalIsOpen && (
                    <UpdateOrganizationTrackerModalWithState
                        title={<Trans>Update your classification</Trans>}
                        tracker={tracker}
                        onDismiss={() =>
                            setUpdateOrganizationModalIsOpen(false)
                        }
                        onCompleted={onUpdateTrackerCompleted}
                    />
                )}
                <ScanTrackersPageTableRowActionButtonStaff
                    tracker={tracker}
                    canClassify={tracker === null}
                    canDelete={tracker !== null}
                    canUpdate={tracker !== null}
                    onClassify={() => setClassifyModalIsOpen(true)}
                    onDelete={() => setDeleteModalIsOpen(true)}
                    onUpdate={() => setUpdateModalIsOpen(true)}
                    onClassifyOrganization={() =>
                        setClassifyOrganizationModalIsOpen(true)
                    }
                    onDeleteOrganization={() =>
                        setDeleteOrganizationModalIsOpen(true)
                    }
                    onUpdateOrganization={() =>
                        setUpdateOrganizationModalIsOpen(true)
                    }
                >
                    {children}
                </ScanTrackersPageTableRowActionButtonStaff>
            </>
        )
    }
    return (
        <>
            {classifyOrganizationModalIsOpen && (
                <CreateOrganizationTrackerModalWithState
                    title={<Trans>Classify tracker</Trans>}
                    scanTracker={scanTracker}
                    onDismiss={() => setClassifyOrganizationModalIsOpen(false)}
                    onCompleted={onClassifyTrackerCompleted}
                />
            )}
            {deleteOrganizationModalIsOpen && (
                <DeleteOrganizationTrackerModalWithState
                    title={<Trans>Remove your classification</Trans>}
                    tracker={tracker}
                    onDismiss={() => setDeleteOrganizationModalIsOpen(false)}
                    onCompleted={onDeleteTrackerCompleted}
                />
            )}
            {updateOrganizationModalIsOpen && (
                <>
                    {tracker.belongsToOrganization ? (
                        <UpdateOrganizationTrackerModalWithState
                            title={<Trans>Update your classification</Trans>}
                            tracker={tracker}
                            onDismiss={() =>
                                setUpdateOrganizationModalIsOpen(false)
                            }
                            onCompleted={onUpdateTrackerCompleted}
                        />
                    ) : (
                        <CreateOrganizationTrackerModalWithState
                            title={<Trans>Change classification</Trans>}
                            tracker={tracker}
                            scanTracker={scanTracker}
                            onDismiss={() =>
                                setUpdateOrganizationModalIsOpen(false)
                            }
                            onCompleted={onClassifyTrackerCompleted}
                        />
                    )}
                </>
            )}
            <ScanTrackersPageTableRowActionButton
                tracker={tracker}
                canClassify={tracker === null}
                canDelete={tracker !== null && tracker.belongsToOrganization}
                canUpdate={tracker !== null}
                onClassify={() => setClassifyOrganizationModalIsOpen(true)}
                onDelete={() => setDeleteOrganizationModalIsOpen(true)}
                onUpdate={() => setUpdateOrganizationModalIsOpen(true)}
            >
                {children}
            </ScanTrackersPageTableRowActionButton>
        </>
    )
}

export default ScanTrackersPageActionsDropdownMenu
