import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function CookieTableVersionCompareModalTableHeaderRow() {
    return (
        <TableHeaderRow stickyPosition="below-actions">
            <TableHeaderCell columnId="name">
                <Trans>Name</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="purposes" />
            <TableHeaderCell columnId="state" className="flex justify-end">
                <Trans>State</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="actions" />
        </TableHeaderRow>
    )
}
