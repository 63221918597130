import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation clearAlertDelegation($groupingIds: [ID!], $auditId: ID) {
        clearAlertDelegation(groupingIds: $groupingIds, auditId: $auditId)
    }
`

const useClearAlertDelegationMutation = (options) =>
    useMutation(MUTATION, options)

export default useClearAlertDelegationMutation
