import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import AcceptInvitationCard from './AcceptInvitationCard'

const AcceptInvitationAuthenticated = ({
    organization,
    onAcceptInvitation,
}) => (
    <AcceptInvitationCard
        iconName="invitation"
        title={<Trans>Accept invitation</Trans>}
        description={
            <Trans>
                You have been invited to the{' '}
                <strong>{organization.name}</strong> organization.
            </Trans>
        }
    >
        <div className="mt-6 flex flex-col justify-center">
            <Button onClick={onAcceptInvitation}>
                <Trans>Accept</Trans>
            </Button>
        </div>
    </AcceptInvitationCard>
)

export default AcceptInvitationAuthenticated
