/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import Button from '../../utils/Button'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import FormButtonGroup from '../../utils/FormButtonGroup'
import MultiActionSelectorInput from '../../utils/MultiActionSelectorInput'
import { resolverActionOptions } from '../settings-login-profiles/useCreateLoginProfileFormState'

const UpdateLoginProfileForm = ({ formState, isLoading, onSubmit }) => {
    const { values, getNativeInputProps, getErrorMessages, handleChange } =
        formState
    return (
        <Card>
            <form>
                <CardHeader
                    title={<Trans>Consent Profile</Trans>}
                    description={
                        <Trans>
                            A login profile describes a login flow to simulate
                            an authenticated session.
                        </Trans>
                    }
                />
                <div className="divide-y">
                    <FormGroup label={<Trans>Name</Trans>} isHorizontal>
                        <FormInput
                            errors={getErrorMessages('name')}
                            {...getNativeInputProps('name')}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Login actions</Trans>}
                        className="mb-4"
                        isHorizontal
                    >
                        <MultiActionSelectorInput
                            actions={values.resolverActions}
                            options={resolverActionOptions}
                            onChange={handleChange}
                            onAdd={formState.handleAddResolverAction}
                            onRemove={formState.handleRemoveResolverAction}
                            inputKey="resolverActions"
                        />
                    </FormGroup>
                </div>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                    <Link to="/settings/login-profiles">
                        <Button type="white">
                            <Trans>Cancel</Trans>
                        </Button>
                    </Link>
                </FormButtonGroup>
            </form>
        </Card>
    )
}

export default UpdateLoginProfileForm
