import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import UpdateDomainPageHeader from './UpdateDomainPageHeader'
import UpdateDomainForm from './UpdateDomainForm'

const UpdateDomainPage = ({
    domain,
    audit,
    isFetching,
    isLoading,
    formState,
    onSubmit,
}) => (
    <PageContainer data-testid="UpdateDomainPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent isFetching={isFetching}>
                <UpdateDomainPageHeader domain={domain} audit={audit} />
                <UpdateDomainForm
                    audit={audit}
                    isLoading={isLoading}
                    formState={formState}
                    onSubmit={onSubmit}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UpdateDomainPage
