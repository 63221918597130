import React from 'react'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'
import FormButtonGroup from '../../utils/FormButtonGroup'
import Button from '../../utils/Button'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import Toggle from '../../utils/Toggle'

const SettingsPageAuditAlertsFormCard = ({
    formState,
    cookieTables,
    vendorTables,
    isLoading,
    onSubmit,
}) => (
    <Card>
        <form>
            <CardHeader
                title={<Trans>Default audit alert rules</Trans>}
                description={
                    <Trans>
                        Use these settings to modify your alerts and
                        notifications rules.
                    </Trans>
                }
            />
            <div className="divide-y">
                <FormGroup
                    isHorizontal
                    label={<Trans>Maximum cookie retention period</Trans>}
                    htmlFor="maxCookieRetention"
                >
                    <FormInput
                        id="maxCookieRetention"
                        className="max-w-xs"
                        errors={formState.getErrorMessages(
                            'maxCookieRetention'
                        )}
                        {...formState.getNativeInputProps('maxCookieRetention')}
                    />
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Vendor Table</Trans>}
                    htmlFor="url"
                >
                    <FormSelect
                        id="vendorTableId"
                        className="max-w-md"
                        errors={formState.getErrorMessages('vendorTableId')}
                        {...formState.getNativeInputProps('vendorTableId')}
                    >
                        <option key={null} value="NONE">
                            None
                        </option>
                        {vendorTables.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Vendor table alerts</Trans>}
                    htmlFor="showVendorTableAlerts"
                >
                    <Toggle
                        name="showVendorTableAlerts"
                        value={formState.values.showVendorTableAlerts}
                        onClick={(value) =>
                            formState.handleChange(
                                'showVendorTableAlerts',
                                value
                            )
                        }
                    />
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Cookie Table</Trans>}
                    htmlFor="url"
                >
                    <FormSelect
                        id="cookieTableId"
                        className="max-w-md"
                        errors={formState.getErrorMessages('cookieTableId')}
                        {...formState.getNativeInputProps('cookieTableId')}
                    >
                        <option key={null} value="NONE">
                            None
                        </option>
                        {cookieTables.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Cookie table alerts</Trans>}
                    htmlFor="showCookieTableAlerts"
                >
                    <Toggle
                        name="showCookieTableAlerts"
                        value={formState.values.showCookieTableAlerts}
                        onClick={(value) =>
                            formState.handleChange(
                                'showCookieTableAlerts',
                                value
                            )
                        }
                    />
                </FormGroup>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                        className="w-md"
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                </FormButtonGroup>
            </div>
        </form>
    </Card>
)

export default SettingsPageAuditAlertsFormCard
