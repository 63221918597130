import React from 'react'
import clsx from 'clsx'

const Toggle = ({ name, value, onClick, disabled }) => (
    <button
        value={value}
        name={name}
        type="button"
        disabled={disabled}
        className={clsx(
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full  transition-colors ease-in-out duration-200',
            value ? 'bg-primary' : 'bg-gray-200',
            disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
        )}
        aria-pressed="false"
        onClick={() => {
            if (!disabled) {
                onClick(!value)
            }
        }}
    >
        <span className="sr-only">Toggle</span>
        <span
            aria-hidden="true"
            className={clsx(
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                value ? 'translate-x-5' : 'translate-x-0'
            )}
        />
    </button>
)

export default Toggle
