import React from 'react'
import Tag from './Tag'
import Tooltip from './Tooltip'
import { getVendorTypeLabel } from './VendorTypeSelect'

const VendorTypeTags = ({ types, showAll = false }) => {
    if (!types) {
        return null
    }
    if (types.length === 0) {
        return null
    }
    if (showAll) {
        return (
            <div className="flex gap-2">
                {types.map((p) => (
                    <Tag key={p} type="primary">
                        {getVendorTypeLabel(p)}
                    </Tag>
                ))}
            </div>
        )
    }
    return (
        <div className="flex gap-2 ">
            <div>
                <Tag type="primary">{getVendorTypeLabel(types[0])}</Tag>
            </div>
            {types.length > 1 && (
                <Tooltip
                    content={
                        <ul className="flex flex-col gap-2 list-disc ml-3">
                            {types.slice(1).map((p) => (
                                <li key={p}>{getVendorTypeLabel(p)}</li>
                            ))}
                        </ul>
                    }
                >
                    <Tag type="primary" className="cursor-pointer">
                        +{types.length - 1}
                    </Tag>
                </Tooltip>
            )}
        </div>
    )
}

export default VendorTypeTags
