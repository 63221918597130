import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import AuditSessionsPageTableRow from './AuditSessionsPageTableRow'

const QUERY = gql`
    query auditSessionsPage($auditId: ID!, $after: String) {
        audit(id: $auditId) {
            id
            name
            isMobile
            isWeb
            imageUrl
            consentProfiles {
                id
                name
            }
        }
        sessions(after: $after, auditId: $auditId) {
            edges {
                node {
                    ...AuditSessionsPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${AuditSessionsPageTableRow.FRAGMENT}
`

const useAuditSessionsPageQuery = () => {
    const { id: auditId } = useParams()
    const variables = { auditId }
    const {
        data = {},
        error,
        loading,
        fetchMore,
        ...result
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'sessions'
    )
    return {
        error,
        audit: data.audit || {},
        sessions: connectionToCollection(data.sessions),
        isFetching: loading,
        isFetchingMore,
        handleFetchMore,
        hasMore: !loading && !error && hasNextPage,
        ...result,
    }
}

export default useAuditSessionsPageQuery
