import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useStartAuditMutation from './useStartAuditMutation'
import useAuditHeaderQuery from './useAuditHeaderQuery'
import AuditHeader from './AuditHeader'
import UploadSessionModalWithState from '../audits-sessions/UploadSessionModalWithState'

const AuditHeaderWithState = ({
    title,
    description,
    onScanCompleted,
    onUploadCompleted = () => {},
}) => {
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false)
    const history = useHistory()
    const { audit } = useAuditHeaderQuery()
    const [startAudit] = useStartAuditMutation({
        onCompleted: onScanCompleted,
    })
    return (
        <>
            <AuditHeader
                title={title}
                description={description}
                audit={audit}
                onStartScan={async () => {
                    await startAudit()
                    history.push(`/audits/${audit.id}/scans`)
                }}
                onUploadSession={() => setUploadModalIsOpen(true)}
            />
            {uploadModalIsOpen && (
                <UploadSessionModalWithState
                    audit={audit}
                    onDismiss={() => {
                        setUploadModalIsOpen(false)
                    }}
                    onCompleted={() => {
                        onUploadCompleted()
                        history.push(`/audits/${audit.id}/sessions`)
                    }}
                />
            )}
        </>
    )
}

export default AuditHeaderWithState
