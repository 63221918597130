import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createVendorTable($input: VendorTableInput!) {
        createVendorTable(input: $input) {
            vendorTable {
                id
            }
        }
    }
`

const useCreateVendorTableMutation = (options) => useMutation(MUTATION, options)

export default useCreateVendorTableMutation
