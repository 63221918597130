import React from 'react'
import Avatar from '../../utils/Avatar'
import Tag from '../../utils/Tag'

const AlertsBySubjectListItem = ({
    iconName,
    name,
    imageUrl,
    alertCount = 0,
    maxAlertCount = 1,
}) => (
    <li className="grid grid-cols-3 w-full mb-4">
        <div className="flex items-center gap-3">
            <Avatar
                iconName={iconName}
                className="flex-shrink-0"
                src={imageUrl}
            />
            <div className="font-medium text-sm text-gray-400 truncate">
                {name}
            </div>
        </div>
        <div className="flex-1 flex items-center col-span-2">
            <Tag>{alertCount}</Tag>
            <div className="relative h-2 w-full bg-gray-200  overflow-hidden ml-4">
                <div
                    className="absolute h-full bg-primary"
                    style={{ width: `${(alertCount / maxAlertCount) * 100}%` }}
                />
            </div>
        </div>
    </li>
)

export default AlertsBySubjectListItem
