import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteUserModal from './DeleteUserModal'
import useRemoveUserMutation from './useRemoveUserMutation'

const DeleteUserModalWithState = ({
    user,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [removeUser, { loading }] = useRemoveUserMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteUserModal
            onDismiss={onDismiss}
            user={user}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await removeUser({ variables: { userId: user.id } })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{user.fullName} successfully deleted.</Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteUserModalWithState
