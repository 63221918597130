import React, { useEffect } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateConsentProfileModal from './CreateConsentProfileModal'
import useCreateConsentProfileMutation from './useCreateConsentProfileMutation'
import useCreateConsentProfileFormState from './useCreateConsentProfileFormState'
import useCreateConsentProfileModalQuery from './useCreateConsentProfileModalQuery'

const useFirstSelected = (formState, records, key) => {
    const selected = formState.values[key] || null
    const [first] = records
    useEffect(() => {
        if (selected === null && first) {
            formState.handleChange(key, first.id)
        }
    }, [first, selected])
    return selected
}

const CreateConsentProfileModalWithState = ({
    purposes = null,
    cmps = null,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const canSkipDataFetching = cmps !== null && purposes !== null
    const query = useCreateConsentProfileModalQuery({
        skip: canSkipDataFetching,
    })
    const finalCmps = cmps || query.cmps
    const finalPurposes = purposes || query.purposes
    const [createConsentProfile] = useCreateConsentProfileMutation({
        onCompleted,
        onError,
    })
    const customCmp = finalCmps.find(
        ({ externalId }) => externalId === 'custom'
    )
    const customCmpId = customCmp && customCmp.id
    const formState = useCreateConsentProfileFormState(
        finalPurposes,
        customCmpId
    )
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    useFirstSelected(formState, finalCmps, 'cmpId')
    return (
        <CreateConsentProfileModal
            cmps={finalCmps}
            purposes={finalPurposes}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await createConsentProfile({ variables: { input } })
                        await query.refetch()
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>New consent profile created!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
            customCmpId={customCmpId}
        />
    )
}

export default CreateConsentProfileModalWithState
