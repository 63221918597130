import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteVendorModal from './DeleteVendorModal'
import useDeleteVendorMutation from './useDeleteVendorMutation'

const DeleteVendorModalWithState = ({
    vendor,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteVendor, { loading }] = useDeleteVendorMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteVendorModal
            onDismiss={onDismiss}
            vendor={vendor}
            isLoading={loading}
            onConfirm={ async () => {
                try {
                    await deleteVendor({ variables: { id: vendor.id } })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{vendor.name} successfully deleted.</Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteVendorModalWithState
