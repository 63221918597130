import { useState } from 'react'
import { delay } from '../../utils/index'
import { mergeQueryResults } from '../../utils/graphql'

const useFetchMore = (fetchMore, data, key) => {
    const hasNextPage = data && data[key] && data[key].pageInfo.hasNextPage
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    const handleFetchMore = async () => {
        setIsFetchingMore(true)
        const { endCursor, nextOffset } = data[key].pageInfo
        const variables = {}
        if (nextOffset) {
            variables.offset = nextOffset
        } else {
            variables.after = endCursor
        }
        await fetchMore({
            variables,
            updateQuery: (previousResult, { fetchMoreResult }) =>
                mergeQueryResults(previousResult, fetchMoreResult, key),
        })
        setIsFetchingMore(false)
    }
    return [handleFetchMore, isFetchingMore, hasNextPage]
}

export default useFetchMore
