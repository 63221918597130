/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from './Button'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
import FormGroup from './FormGroup'
import FormInput from './FormInput'
import VendorSearchSelect from './VendorSearchSelect'
import PurposesSelect from './PurposesSelect'

const CreateTrackerModal = ({ formState, onDismiss, onSubmit }) => {
    const { values, getErrorMessages, getNativeInputProps, handleChange } =
        formState
    const vendorId = null
    return (
        <Modal onDismiss={onDismiss} className="max-w-lg">
            <ModalTitle
                title={<Trans>Create new Tracker</Trans>}
                description={
                    <Trans>Fill in all details about the tracker below.</Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Domain</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('domain')}
                    {...getNativeInputProps('domain')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Description</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('description')}
                    {...getNativeInputProps('description')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Vendor</Trans>} className="mb-4">
                <VendorSearchSelect
                    value={vendorId}
                    errors={getErrorMessages('vendorId')}
                    onChange={(id) => handleChange('vendorId', id)}
                />
            </FormGroup>
            <FormGroup label={<Trans>Purposes</Trans>} htmlFor="type">
                <PurposesSelect
                    value={values.purposeIds}
                    errors={getErrorMessages('purposeIds')}
                    onChange={(ids) => handleChange('purposeIds', ids)}
                />
            </FormGroup>
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit}>
                        <Trans>Done</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default CreateTrackerModal
