import React from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import ScanRequestDetailsDrawer from '../scans/ScanRequestDetailsDrawer'
import SearchInput from '../../utils/SearchInput'
import useSearch from '../../hooks/useSearch'
import { useAlertDetailsDrawerScanRequestsQuery } from './useAlertDetailsDrawerQuery'
import InfiniteScroll from '../../utils/InfiniteScroll'
import DrawerTabError from '../../utils/DrawerTabError'

const GroupedAlertDetailsDrawerTabRequest = ({
    alertGroupingId,
    startDate,
    endDate,
}) => {
    const { add } = useDrawerStack()
    const [search, handleSearch] = useSearch('')

    const {
        scanRequests,
        error,
        isFetching,
        isFetchingMore,
        hasMore,
        handleFetchMore,
    } = useAlertDetailsDrawerScanRequestsQuery({
        filters: { alertGroupingId, startDate, endDate },
        search,
    })

    if (error) <DrawerTabError error={error} />
    if (!isFetching && (!scanRequests || scanRequests.length === 0)) {
        return (
            <div className="flex flex-col gap-2">
                <SearchInput
                    value={search}
                    onChange={handleSearch}
                    placeholder="Search requests..."
                />
                <DrawerEmptyCard
                    title={<Trans>No requests</Trans>}
                    description={<Trans>No requests were found</Trans>}
                />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-2">
            <SearchInput
                value={search}
                onChange={handleSearch}
                placeholder="Search requests..."
            />
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore && !isFetching}
            >
                <Table
                    isFetching={isFetching}
                    hasMinWidth={false}
                    isRounded
                    hasHeader={false}
                >
                    <TableBody>
                        {scanRequests.map((request) => {
                            return (
                                <TableRow
                                    isSelectable
                                    key={request.id}
                                    onClick={() => {
                                        add(ScanRequestDetailsDrawer, {
                                            requestId: request.id,
                                        })
                                    }}
                                >
                                    <TableCell
                                        py="sm"
                                        truncate
                                        className="w-2/3"
                                    >
                                        <Tooltip
                                            event="hover"
                                            position="bottom-end"
                                            content={
                                                <div className="max-w-[400px] overflow-hidden break-words">
                                                    {request.url}
                                                </div>
                                            }
                                            contentClassName="max-w-[400px]"
                                        >
                                            {new URL(request.url).pathname}
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell py="sm">
                                        <div className="flex justify-end gap-1">
                                            <Tag type="gray">
                                                {request.resourceType}
                                            </Tag>
                                            <Tag type="blue">
                                                {request.method}
                                            </Tag>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </InfiniteScroll>
        </div>
    )
}

export default GroupedAlertDetailsDrawerTabRequest
