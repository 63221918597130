import React from 'react'
import { Trans } from '@lingui/macro'
import Sidebar from '../../layout/Sidebar'
import SidebarMenu from '../../layout/SidebarMenu'
import SidebarTitle from '../../layout/SidebarTitle'
import SidebarMenuItem from '../../layout/SidebarMenuItem'

export default function AuditVendorPageSidebar({ id }) {
    return (
        <Sidebar>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>General</Trans>
                </SidebarTitle>
                <SidebarMenuItem
                    exact
                    disabled={!id}
                    matchPath={`/audits/${id}/vendors*`}
                    to={`/audits/${id}/vendors`}
                    iconName="building-07"
                >
                    <Trans>Vendors</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    exact
                    disabled={!id}
                    matchPath={`/audits/${id}/cookies*`}
                    to={`/audits/${id}/cookies`}
                    iconName="cookie"
                >
                    <Trans>Cookies</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    exact
                    disabled={!id}
                    matchPath={`/audits/${id}/trackers*`}
                    to={`/audits/${id}/trackers`}
                    iconName="tracker"
                >
                    <Trans>Trackers</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
        </Sidebar>
    )
}
