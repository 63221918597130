import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import AuditScanPageTableHeaderRow from './AuditScansPageTableHeaderRow'
import AuditScanPageTableRow from './AuditScansPageTableRow'

export default function AuditScanPageTable({
    audit,
    scans = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onDeleteScanCompleted,
}) {
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                <thead>
                    <AuditScanPageTableHeaderRow isSticky />
                </thead>
                <TableBody>
                    {scans.map((scan) => (
                        <AuditScanPageTableRow
                            key={scan.id}
                            audit={audit}
                            scan={scan}
                            onDeleteScanCompleted={onDeleteScanCompleted}
                        />
                    ))}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
