import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'
import * as constants from '../../../constants'

const { BULK_UPLOAD_CREATE: Create, BULK_UPLOAD_UPDATE: Update } = constants

const validation = createFormValidation([
    {
        path: 'action',
        validate: isRequired,
        message: <Trans>Select an action.</Trans>,
    },
    {
        path: 'csvFile',
        validate: isRequired,
        message: <Trans>Choose a csv file.</Trans>,
    },
])

const useBulkUploadTrackersFormState = () =>
    useFormState(
        {
            action: 'CREATE',
            csvFile: null,
        },
        {
            validation,
        }
    )

export default useBulkUploadTrackersFormState
