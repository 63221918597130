import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useAppState from '../../hooks/useAppState'
import ClassifyTrackersModal from './ClassifyTrackersModal'
import useClassifyTrackersMutation from './useClassifyTrackersMutation'
import useClassifyTrackersFormState from './useClassifyTrackersFormState'

const ClassifyTrackersModalWithState = ({
    rootDomains,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const [classifyTrackers] = useClassifyTrackersMutation({
        onCompleted,
        onError,
    })
    const formState = useClassifyTrackersFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <ClassifyTrackersModal
            canCreateVendor={currentUser.isStaff}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await classifyTrackers({
                            variables: {
                                ids: rootDomains,
                                input,
                            },
                        })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Tracker classified successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default ClassifyTrackersModalWithState
