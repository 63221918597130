import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

const UsersPageTableHeaderRow = () => (
    <TableHeaderRow>
        <TableHeaderCell columnId="user" initialWidth={1000}>
            <Trans>User</Trans>
        </TableHeaderCell>
        <TableHeaderCell columnId="role" initialWidth={1000}>
            <Trans>Role</Trans>
        </TableHeaderCell>
        <TableHeaderCell initialWidth={200} />
        <TableHeaderCell initialWidth={200} />
    </TableHeaderRow>
)

export default UsersPageTableHeaderRow
