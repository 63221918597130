import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'

const CookiesPageTableHeaderRow = () => (
    <tr>
        <TableHeaderCell>
            <Trans>Cookie</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Purpose</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Description</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
            <Trans>Vendor</Trans>
        </TableHeaderCell>
        <TableHeaderCell className="text-right" />
    </tr>
)

export default CookiesPageTableHeaderRow
