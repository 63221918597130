import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteVendorModal = ({ vendor, isLoading, onDismiss, onConfirm }) => (
    <ConfirmModal
        title={<Trans>Delete Vendor</Trans>}
        description={
            <Trans>
                Are you sure you want to delete <strong>{vendor.name}</strong>{' '}
                from you organization?
            </Trans>
        }
        confirmButtonText={<Trans>Delete vendor</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteVendorModal
