import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const CREATE_SCAN_REPORT_MUTATION = gql`
    mutation createScanReport($input: CreateScanReportInput!) {
        createScanReport(input: $input) {
            report {
                id
            }
        }
    }
`

const useCreateScanReportMutation = (options) =>
    useMutation(CREATE_SCAN_REPORT_MUTATION, options)

export default useCreateScanReportMutation
