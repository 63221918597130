import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Alert from '../../utils/Alert'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import LoginPageContainer from '../login/LoginPageContainer'
import LoginPageTitle from '../login/LoginPageTitle'
import LoginPageCard from '../login/LoginPageCard'

const ResetPasswordPage = ({
    formState,
    errorMessage,
    isLoading,
    isPasswordReset,
    onSubmit,
}) => {
    const { values, getNativeInputProps, getErrorMessages } = formState
    return (
        <LoginPageContainer data-testid="ResetPasswordPage">
            <LoginPageCard>
                {errorMessage && (
                    <Alert
                        className="mb-6"
                        title={<Trans>Failed to login</Trans>}
                    >
                        {errorMessage}
                    </Alert>
                )}
                {isPasswordReset ? (
                    <Alert type="success" title={<Trans>Success</Trans>}>
                        <Trans>
                            A reset password link has been sent to{' '}
                            <strong>{values.email}</strong>. Please check your
                            inbox.
                        </Trans>
                    </Alert>
                ) : (
                    <form>
                        <FormGroup
                            label={<Trans>Email address</Trans>}
                            htmlFor="email"
                        >
                            <FormInput
                                id="email"
                                type="email"
                                className="max-w-lg"
                                errors={getErrorMessages('email')}
                                {...getNativeInputProps('email')}
                            />
                        </FormGroup>
                        <div className="mt-6 flex items-center justify-between">
                            <div className="text-sm leading-5">
                                <Link to="/login">
                                    <button
                                        type="button"
                                        className="font-medium text-primary-600 hover:text-blue-500 transition ease-in-out duration-150"
                                    >
                                        <Trans>Back to login</Trans>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="mt-6">
                            <Button
                                data-testid="ResetPasswordButton"
                                disabled={isLoading}
                                loading={isLoading}
                                onClick={onSubmit}
                                className="w-full"
                            >
                                <Trans>Reset password</Trans>
                            </Button>
                        </div>
                    </form>
                )}
            </LoginPageCard>
        </LoginPageContainer>
    )
}

export default ResetPasswordPage
