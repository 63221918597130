import React, { useState } from 'react'
import ClassifyCookieModalWithState from '../audits-scans-cookies/ClassifyCookieModalWithState'
import StaffUnclassifiedCookiesPageTableRowActionButton from './StaffUnclassifiedCookiesPageTableRowActionButton'

const StaffUnclassifiedCookiesPageTableRowActionButtonWithState = ({
    scanCookie,
    onClassifyCookieCompleted,
}) => {
    const [classifyModalIsOpen, setClassifyModalIsOpen] = useState(false)
    return (
        <>
            {classifyModalIsOpen && (
                <ClassifyCookieModalWithState
                    scanCookie={scanCookie}
                    onDismiss={() => setClassifyModalIsOpen(false)}
                    onCompleted={onClassifyCookieCompleted}
                />
            )}
            <StaffUnclassifiedCookiesPageTableRowActionButton
                isLoading={classifyModalIsOpen}
                onClassify={() => setClassifyModalIsOpen(true)}
            />
        </>
    )
}

export default StaffUnclassifiedCookiesPageTableRowActionButtonWithState
