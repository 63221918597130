/* eslint-disable no-nested-ternary */
import React from 'react'
import { Trans } from '@lingui/macro'
import { twMerge } from 'tailwind-merge'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import PurposesTags from '../../utils/PurposesTags'
import Tag from '../../utils/Tag'
import CookieVersionCompareModalTableRowActions from './CookieVersionCompareModalTableRowActions'

const CookieVersionCompareModalTableRow = ({
    cookieItem,
    type,
    onUpdateCompleted,
}) => {
    const { id, name, rootDomain, cookie } = cookieItem
    return (
        <TableRow
            className={twMerge(
                'bg-white',
                type === 'new' && 'bg-green-50',
                type === 'danger' && 'bg-red-50',
                type === 'warning' && 'bg-orange-50'
            )}
        >
            <TableCellAvatar
                className="max-w-32 overflow-hidden"
                columnId="name"
                size="sm"
                avatarSrc={cookie?.vendor?.imageUrl}
                avatarIconName="building-07"
                title={name}
            />
            <TableCell columnId="purposes" size="sm" className="max-w-10">
                <PurposesTags purposes={cookie?.purposes || []} />
            </TableCell>
            <TableCell columnId="state" className="w-fit text-right">
                {type !== 'same' ? (
                    <Tag
                        type={
                            type === 'new'
                                ? 'green'
                                : type === 'danger'
                                  ? 'red'
                                  : type === 'warning'
                                    ? 'orange'
                                    : 'gray'
                        }
                    >
                        {type === 'new'
                            ? 'Added'
                            : type === 'danger'
                              ? 'Not found'
                              : type === 'warning'
                                ? 'Removed'
                                : null}
                    </Tag>
                ) : (
                    <Tag type="gray">Unchanged</Tag>
                )}
            </TableCell>
            <TableCell size="sm" columnId="actions">
                <CookieVersionCompareModalTableRowActions
                    cookieTableItemId={cookieItem.id}
                    rowType={type}
                    onUpdateCompleted={onUpdateCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

export default CookieVersionCompareModalTableRow
