import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const DELETE_SESSIONS_MUTATION = gql`
    mutation deleteSessions($input: DeleteSessionsInput!) {
        deleteSessions(input: $input)
    }
`

const useDeleteSessionsMutation = (options) =>
    useMutation(DELETE_SESSIONS_MUTATION, options)

export default useDeleteSessionsMutation
