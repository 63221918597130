/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DeleteScanModalWithState from './DeleteScanModalWithState'

export default function ScanPageActionsDropdownMenu({
    scan,
    onDeleteScanCompleted,
    children,
}) {
    const [isDeleteScanModalOpen, setDeleteScanModalOpen] = useState(false)
    return (
        <>
            {isDeleteScanModalOpen && (
                <DeleteScanModalWithState
                    scan={scan}
                    onDismiss={() => setDeleteScanModalOpen(false)}
                    onCompleted={onDeleteScanCompleted}
                />
            )}
            <DropdownMenu
                as="span"
                position="bottom-start"
                content={({ close }) => (
                    <DropdownMenuItem
                        iconName="bin"
                        onClick={() => {
                            setDeleteScanModalOpen(true)
                            close()
                        }}
                    >
                        <Trans>Delete Scan</Trans>
                    </DropdownMenuItem>
                )}
            >
                {children}
            </DropdownMenu>
        </>
    )
}
