/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const AuditSettingsPageLoginProfilesTableRowActionButton = ({
    isLoading,
    canRemoveLoginProfile,
    onRemoveLoginProfile,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <DropdownMenuItem
                iconName="cross"
                disabled={!canRemoveLoginProfile}
                onClick={(e) => {
                    onRemoveLoginProfile()
                    close()
                    e.stopPropagation()
                }}
            >
                <Trans>Remove login profile</Trans>
            </DropdownMenuItem>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default AuditSettingsPageLoginProfilesTableRowActionButton
