import React from 'react'
import clsx from 'clsx'

const Radio = ({ className, disabled, ...props }) => (
    <input
        type="radio"
        className={clsx(
            'form-radio rounded-full border-gray-300 h-4 w-4 text-primary-600 transition duration-150 ease-in-out',
            disabled && 'opacity-50 cursor-not-allowed',
            className
        )}
        disabled={disabled}
        {...props}
    />
)

export default Radio
