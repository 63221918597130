import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import CookieTablesPageTableRow from './CookieTablesPageTableRow'
import CookieTablesPageTableHeaderRow from './CookieTablesPageTableHeaderRow'

const CookieTablesPageTable = ({
    cookieTables,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="CookieTablesPageTable">
            <thead>
                <CookieTablesPageTableHeaderRow />
            </thead>
            <TableBody>
                {cookieTables.map((cookieTable) => (
                    <CookieTablesPageTableRow
                        key={cookieTable.id}
                        cookieTable={cookieTable}
                        onDeleteCompleted={onDeleteCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default CookieTablesPageTable
