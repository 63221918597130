import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteCookieTableItemModal from './DeleteCookieTableItemModal'
import useUpdateCookieTableItemMutation from './useUpdateCookieTableItemMutation'
import { COOKIE_TABLE_ITEM_STATUS_DEPRECATED } from '../../../constants'

const DeleteCookieTableItemModalWithState = ({
    cookieTableItem,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [updateCookieTableItem, { loading }] =
        useUpdateCookieTableItemMutation({ onCompleted, onError })
    return (
        <DeleteCookieTableItemModal
            onDismiss={onDismiss}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await updateCookieTableItem({
                        variables: {
                            id: cookieTableItem.id,
                            input: {
                                status: COOKIE_TABLE_ITEM_STATUS_DEPRECATED,
                            },
                        },
                    })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>Cookie successfully removed from list</Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteCookieTableItemModalWithState
