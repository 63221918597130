import gql from 'graphql-tag'

const CURRENT_USER_FRAGMENT = gql`
    fragment CurrentUser on Me {
        id
        type
        role
        firstName
        lastName
        fullName
        email
        isStaff
        currentOrganization {
            id
            name
            imageUrl
        }
    }
`

export default CURRENT_USER_FRAGMENT
