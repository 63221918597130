import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const IDENTIFIED_COOKIES_QUERY = gql`
    query identifiedVendorDrawerCookies(
        $search: String
        $offset: Int
        $filters: IdentifiedCookiesFilters
    ) {
        identifiedCookies(search: $search, offset: $offset, filters: $filters) {
            edges {
                cursor
                node {
                    id
                    lastSeenAt
                    name
                    rootDomain
                    lastScanCookie {
                        id
                        retention
                        type
                        value
                    }
                    audits {
                        id
                        name
                        imageUrl
                    }
                    cookie {
                        id
                        description
                        purposes {
                            id
                            name
                            parent {
                                id
                                name
                                parent {
                                    id
                                    name
                                }
                            }
                        }
                    }
                    tracker {
                        id
                        domain
                        purposes {
                            id
                            name
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export function useIdentifiedVendorDrawerCookiesQuery({ search, filters }) {
    const variables = { search, filters }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(IDENTIFIED_COOKIES_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'identifiedCookies'
    )

    return {
        error,
        identifiedCookies: connectionToCollection(data.identifiedCookies),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}
