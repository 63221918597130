import React from 'react'
import Avatar from './Avatar'
import TableCell from './TableCell'

export default function TableCellAvatar({
    title,
    afterTitle,
    text,
    afterText,
    avatarColor,
    avatarSrc,
    avatarStatusSrc,
    avatarStatusName,
    avatarIconColor,
    avatarIconName,
    children,
    className,
    ...props
}) {
    return (
        <TableCell className={className} {...props}>
            <div className="flex items-center gap-3">
                {(avatarIconName || avatarSrc) && (
                    <Avatar
                        size="md"
                        className="flex-shrink-0 "
                        src={avatarSrc}
                        iconName={avatarIconName}
                        iconColor={avatarIconColor}
                        color={avatarColor}
                        avatarStatusSrc={avatarStatusSrc}
                        avatarStatus={avatarStatusName}
                    />
                )}
                <div className="overflow-hidden">
                    <div className="flex items-center gap-3 ">
                        {title && (
                            <div className="text-sm font-medium text-gray-900 overflow-hidden text-ellipsis">
                                {title}
                            </div>
                        )}
                        {afterTitle}
                    </div>
                    {(text || afterText) && (
                        <div className="flex items-center gap-3 ">
                            {text && (
                                <div className="text-sm text-gray-500 overflow-hidden text-ellipsis">
                                    {text}
                                </div>
                            )}
                            {afterText}
                        </div>
                    )}
                </div>
                {children}
            </div>
        </TableCell>
    )
}
