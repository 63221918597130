import React from 'react'
import Icon from '../../utils/Icon'

export default function AuditDetailsPageCardScreenshotWarning({
    title,
    description,
    icon = 'photo',
}) {
    return (
        <div className="w-[426px] h-[266px] bg-gray-100 flex flex-col items-center justify-center text-center">
            <Icon name={icon} size="xl" />
            <div className="font-medium text-xs mt-1 text-gray-800">
                {title}
            </div>
            <div className="text-xs mt-1 text-gray-500 max-w-[250px]">
                {description}
            </div>
        </div>
    )
}
