import React from 'react'
import clsx from 'clsx'
import useTableState from '../hooks/useTableState'
import TableHeaderCell from './TableHeaderCell'
import Checkbox from './Checkbox'

const TableHeaderRow = ({
    className,
    selectionCellClassName,
    canSelectAll = false,
    children,
    isSticky,
    stickyPosition = 'below-actions',
    allRowsAreSelected,
    selectionCellSize,
    onSelectAllRows,
    onDeSelectAllRows,
    ...props
}) => {
    const { isSelectingRows = false } = useTableState() || {}
    return (
        <tr
            {...props}
            className={clsx(
                'header-cell py-1.5 bg-gray-50 text-left text-gray-500 tracking-wider break-all',
                isSticky && 'sticky z-10 ',
                isSticky && stickyPosition === 'below-actions' && 'top-[70px]',
                isSticky &&
                    stickyPosition === 'below-actions-deprecated-lg' &&
                    'top-[90px]',
                isSticky &&
                    stickyPosition === 'below-actions-deprecated' &&
                    'top-[86px]',
                isSticky && stickyPosition === 'top' && 'top-0',
                className
            )}
        >
            {isSelectingRows && (
                <TableHeaderCell
                    isSticky={isSticky}
                    noPaddingRight
                    className={clsx(
                        'w-[10px] text-left',
                        selectionCellClassName
                    )}
                >
                    {canSelectAll && (
                        <Checkbox
                            value={allRowsAreSelected}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    onSelectAllRows()
                                } else {
                                    onDeSelectAllRows()
                                }
                            }}
                        />
                    )}
                </TableHeaderCell>
            )}
            {children}
        </tr>
    )
}

export default TableHeaderRow
