import React from 'react'
import useRefetch from '../../hooks/useRefetch'
import VendorCookiesPage from './VendorCookiesPage'
import useVendorCookiesPageQuery from './useVendorCookiesPageQuery'

const VendorCookiesPageWithState = () => {
    const {
        vendor,
        cookies,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch,
    } = useVendorCookiesPageQuery()
    useRefetch(refetch)
    return (
        <VendorCookiesPage
            vendor={vendor}
            cookies={cookies}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            isFetching={isFetching}
        />
    )
}

export default VendorCookiesPageWithState
