import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'

const CreateCustomCmpModal = ({ formState, onDismiss, onSubmit }) => (
    <Modal onDismiss={onDismiss} className="max-w-lg">
        <ModalTitle
            title={<Trans>Create CMP</Trans>}
            description={
                <Trans>
                    Create a new custom consent management platform. Choose the
                    name of your CMP below and continue.
                </Trans>
            }
            onClose={onDismiss}
        />
        <FormGroup label={<Trans>Name</Trans>} htmlFor="name">
            <FormInput
                id="name"
                errors={formState.getErrorMessages('name')}
                {...formState.getNativeInputProps('name')}
            />
        </FormGroup>
        <div className="mt-10 flex justify-end">
            <Button type="white" className="mr-4" onClick={onDismiss}>
                <Trans>Cancel</Trans>
            </Button>
            <Button
                onClick={onSubmit}
                iconAfterProps={{
                    color: 'white',
                    name: 'arrow-right',
                }}
            >
                <Trans>Next</Trans>
            </Button>
        </div>
    </Modal>
)

export default CreateCustomCmpModal
