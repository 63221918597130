import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query addConsentProfileModal($auditId: ID!) {
        audit(id: $auditId) {
            id
            consentProfiles {
                id
                name
            }
        }
        consentProfiles {
            edges {
                node {
                    id
                    name
                    cmp {
                        id
                        name
                    }
                }
            }
        }
    }
`

const useAddConsentProfileModalQuery = (auditId, options) => {
    const {
        data = { audit: {} },
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables: { auditId },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        consentProfiles: connectionToCollection(data.consentProfiles),
        currentConsentProfiles: data.audit.consentProfiles,
        isFetching,
        refetch,
    }
}

export default useAddConsentProfileModalQuery
