/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from './DropdownMenu'
import DropdownMenuItem from './DropdownMenuItem'
import DropdownMenuItemDivider from './DropdownMenuItemDivider'
import Button from './Button'

export default function TableFiltersMenu({
    children,
    onRemoveAll,
    iconBefore = 'plus',
    iconAfter = 'chevron-down',
    label = 'Filter',
    ...props
}) {
    return (
        <DropdownMenu
            position="bottom-end"
            content={({ close }) => (
                <>
                    {children}
                    <DropdownMenuItemDivider />
                    <DropdownMenuItem
                        iconName="x"
                        onClick={() => {
                            onRemoveAll()
                            close()
                        }}
                    >
                        <Trans>Clear all filters</Trans>
                    </DropdownMenuItem>
                </>
            )}
            {...props}
        >
            <Button
                className="text-nowrap"
                type="white"
                size="sm"
                iconBeforeProps={{
                    name: iconBefore,
                    size: 'sm',
                }}
                iconAfterProps={{
                    name: iconAfter,
                    size: 'sm',
                }}
            >
                <Trans>{label}</Trans>
            </Button>
        </DropdownMenu>
    )
}
