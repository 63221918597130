import React from 'react'
import FormInputErrors from './FormInputErrors'
import ColorPicker from './ColorPicker'

const FormColorPicker = ({ errors, className, inputClassName, ...props }) => (
    <div className={className}>
        <ColorPicker
            className={inputClassName}
            hasError={errors?.length > 0}
            {...props}
        />
        <FormInputErrors errors={errors} />
    </div>
)

export default FormColorPicker
