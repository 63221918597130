import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

export const validation = createFormValidation([
    {
        path: 'purposeIds',
        validate: (purposeIds) => purposeIds.length > 0,
        message: <Trans>Select at least one purpose.</Trans>,
    },
    {
        path: 'vendorId',
        validate: isRequired,
        message: <Trans>Select a vendor.</Trans>,
    },
])

const useClassifyCookiesFormState = () => {
    const formState = useFormState(
        {
            purposeIds: [],
            vendorId: null,
        },
        {
            validation,
        }
    )
    return formState
}

export default useClassifyCookiesFormState
