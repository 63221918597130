import React from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import LoadingIndicator from '../../utils/LoadingIndicator'
import Icon from '../../utils/Icon'
import InfiniteScroll from '../../utils/InfiniteScroll'
import MyAlertListItemWithState from './MyAlertListItemWithState'
import AlertDetailsDrawer from '../staff-scans/AlertDetailsDrawer'

const DashboardPageMyAlerts = ({
    isFetching,
    alertDelegations = [],
    onAlertStatusChanged,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    startDate,
    endDate,
}) => {
    return (
        <div className="bg-white gap-4 py-6 rounded-2xl border border-gray-200 mb-8 flex flex-col max-h-[610px]">
            {(() => {
                if (isFetching) {
                    return (
                        <div className="h-[300px] flex items-center justify-center">
                            <LoadingIndicator />
                        </div>
                    )
                }
                return (
                    <>
                        <div className="flex justify-between mb-8">
                            <div>
                                <h3 className="font-semibold px-6">
                                    <Trans>My alerts</Trans>
                                </h3>
                            </div>
                            <div />
                        </div>
                        <div className="flex flex-col overflow-auto flex-1">
                            {alertDelegations.length === 0 ? (
                                <div className="flex items-center justify-center flex-col gap-4">
                                    <Icon name="bell" size="2xl" />
                                    <h2>No alerts delegated to you</h2>
                                </div>
                            ) : (
                                <ul className="w-full flex flex-col">
                                    <InfiniteScroll
                                        isFetchingMore={isFetchingMore}
                                        onFetchMore={onFetchMore}
                                        hasMoreRecords={hasMoreRecords}
                                    >
                                        {alertDelegations.map(
                                            (alertDelegation) => (
                                                <MyAlertListItemWithState
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    key={alertDelegation.cursor}
                                                    alertDelegation={
                                                        alertDelegation.node
                                                    }
                                                    onAlertStatusChanged={
                                                        onAlertStatusChanged
                                                    }
                                                />
                                            )
                                        )}
                                    </InfiniteScroll>
                                </ul>
                            )}
                        </div>
                    </>
                )
            })()}
        </div>
    )
}

export default DashboardPageMyAlerts
