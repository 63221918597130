import useFormState from 'use-form-state'
import { validation } from '../audits-scans-trackers/useClassifyTrackerFormState'

const useCreateOrganizationTrackerFormState = (scanTracker, tracker) => {
    const initialState = {
        domain: '',
        description: '',
        purposeIds: [],
        vendorId: null,
    }
    if (scanTracker) {
        initialState.domain = scanTracker.rootDomain
    }
    if (tracker) {
        initialState.domain = tracker.domain
        initialState.description = tracker.description
        if (tracker.purposes) {
            initialState.purposeIds = tracker.purposes.map(({ id }) => id)
        }
        if (tracker.vendor) {
            initialState.vendorId = tracker.vendor.id
        }
    }
    const formState = useFormState(initialState, {
        validation,
    })
    return formState
}

export default useCreateOrganizationTrackerFormState
