import React from 'react'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'

const CreateOrganizationForm = ({ formState, cmps }) => {
    const { getErrorMessages, getNativeInputProps } = formState
    return (
        <form>
            <FormGroup
                label={<Trans>Name</Trans>}
                htmlFor="name"
                className="mb-4"
            >
                <FormInput
                    id="name"
                    className="max-w-lg"
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>CMP</Trans>} htmlFor="cmpId">
                <FormSelect
                    id="cmpId"
                    className="max-w-md"
                    errors={getErrorMessages('cmpId')}
                    {...getNativeInputProps('cmpId')}
                >
                    <option value="" disabled>
                        Select your option
                    </option>
                    {cmps.map((cmp) => (
                        <option key={cmp.id} value={cmp.id}>
                            {cmp.name}
                        </option>
                    ))}
                </FormSelect>
            </FormGroup>
            <FormGroup label={<Trans>Website</Trans>} htmlFor="websiteUrl">
                <FormInput
                    id="websiteUrl"
                    className="max-w-lg"
                    errors={getErrorMessages('websiteUrl')}
                    {...getNativeInputProps('websiteUrl')}
                />
            </FormGroup>
        </form>
    )
}

export default CreateOrganizationForm
