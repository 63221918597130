/* eslint-disable no-else-return */
import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import Tabs, { Tab } from '../../utils/Tabs'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import PurposesTags from '../../utils/PurposesTags'
import Tag from '../../utils/Tag'
import AlertCountTag from '../../utils/AlertCountTag'
import IdentifiedScanTrackerDetailsDrawerTabDetails from './IdentifiedScanTrackerDetailsDrawerTabDetails'
import IdentifiedScanTrackerDetailsDrawerTabAlerts from './IdentifiedScanTrackerDetailsDrawerTabAlerts'
import IdentifiedScanTrackerDetailsDrawerTabObservations from './IdentifiedScanTrackerDetailsDrawerTabObservations'
import IdentifiedScanTrackerDetailsDrawerTabInstances from './IdentifiedScanTrackerDetailsDrawerTabInstances'

export default function IdentifiedScanTrackerDetailsDrawer({
    identifiedScanRequest,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'details'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })

    if (!identifiedScanRequest) {
        return (
            <DrawerEmptyCard
                iconName="cookie"
                title={<Trans>No cookies</Trans>}
                description={
                    <Trans>No cookies where found for this scan.</Trans>
                }
            />
        )
    }

    const { id, tracker, rootDomain, alerts, pages } = identifiedScanRequest

    return (
        <>
            <DrawerHeader
                avatarIconName="tracker"
                avatarSrc={tracker?.vendor.imageUrl}
                title={rootDomain}
                description="Tracker"
                afterTitle={
                    tracker?.purposes ? (
                        <PurposesTags showAll purposes={tracker.purposes} />
                    ) : (
                        <Tag>Unclassified</Tag>
                    )
                }
                // buttons={
                //     <Button
                //         type="white"
                //         size="sm"
                //         iconBeforeProps={{
                //             name: 'menu-dots',
                //             size: 'sm',
                //         }}
                //     />
                // }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                <Tab
                    isActive={activeTab === 'observations'}
                    onClick={() => setActiveTab('observations')}
                >
                    <Trans>Observations</Trans>
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === 'details' && (
                    <IdentifiedScanTrackerDetailsDrawerTabDetails
                        identifiedScanRequest={identifiedScanRequest}
                    />
                )}
                {activeTab === 'alerts' && (
                    <IdentifiedScanTrackerDetailsDrawerTabAlerts
                        identifiedScanTrackerAlerts={alerts}
                    />
                )}
                {activeTab === 'observations' && (
                    <IdentifiedScanTrackerDetailsDrawerTabObservations
                        pages={pages}
                    />
                )}
                {activeTab === 'instances' && (
                    <IdentifiedScanTrackerDetailsDrawerTabInstances
                        identifiedScanTrackerId={id}
                    />
                )}
            </DrawerContent>
        </>
    )
}
