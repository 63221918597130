import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteOrganizationTrackerModal = ({
    tracker,
    title = <Trans>Delete tracker</Trans>,
    description = (
        <Trans>
            Are you sure you want to delete <strong>{tracker.domain}</strong>{' '}
            from you organization?
        </Trans>
    ),
    confirmButtonText = <Trans>Delete tracker</Trans>,
    isLoading,
    onDismiss,
    onConfirm,
}) => (
    <ConfirmModal
        title={title}
        description={description}
        confirmButtonText={confirmButtonText}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteOrganizationTrackerModal
