import React from 'react'
import useRefetch from '../../hooks/useRefetch'
import VendorTrackersPage from './VendorTrackersPage'
import useVendorTrackersPageQuery from './useVendorTrackersPageQuery'

const VendorTrackersPageWithState = () => {
    const {
        vendor,
        trackers,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch,
    } = useVendorTrackersPageQuery()
    useRefetch(refetch)
    return (
        <VendorTrackersPage
            vendor={vendor}
            trackers={trackers}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            isFetching={isFetching}
        />
    )
}

export default VendorTrackersPageWithState
