import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from './DropdownMenu'
import DropdownMenuItem from './DropdownMenuItem'
import Button from './Button'
import Select from './Select'

export default function Filters({
    filters,
    selectedFilters,
    onClearFilters,
    onChangeFilter,
}) {
    const [openFilters, setOpenFilters] = useState([])
    const addFilter = (filter) => setOpenFilters([...openFilters, filter])
    return (
        <>
            <DropdownMenu
                as="div"
                position="bottom-start"
                content={
                    <>
                        {Object.keys(filters).map((key) => {
                            const { label, iconName } = filters[key]
                            if (openFilters.includes(key)) {
                                return null
                            }
                            return (
                                <DropdownMenuItem
                                    key={key}
                                    iconName={iconName}
                                    onClick={() => addFilter(key)}
                                >
                                    {label}
                                </DropdownMenuItem>
                            )
                        })}
                        {openFilters.length < 4 && (
                            <hr className="my-2 border-gray-200" />
                        )}
                        <DropdownMenuItem
                            className="w-[175px]"
                            iconName="x"
                            onClick={() => {
                                onClearFilters()
                                setOpenFilters([])
                            }}
                        >
                            <Trans>Clear all filters</Trans>
                        </DropdownMenuItem>
                    </>
                }
            >
                <Button
                    type="white"
                    iconBeforeProps={{
                        name: 'plus',
                        size: 'sm',
                    }}
                    iconAfterProps={{
                        name: 'chevron-down',
                        size: 'sm',
                    }}
                >
                    <Trans>Filter</Trans>
                </Button>
            </DropdownMenu>
            {openFilters.map((key) => (
                <Select
                    key={key}
                    className={filters[key].className}
                    onChange={(e) => onChangeFilter(key, e.target.value)}
                    value={selectedFilters[key]}
                >
                    {filters[key].options.map(({ value, label }) => (
                        <option value={value}>{label}</option>
                    ))}
                </Select>
            ))}
        </>
    )
}
