import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation restoreAlert(
        $groupingIds: [ID!]
        $auditId: ID
        $level: AlertStatusLevel!
    ) {
        restoreAlert(
            groupingIds: $groupingIds
            auditId: $auditId
            level: $level
        )
    }
`

const useRestoreAlertMutation = (options) => useMutation(MUTATION, options)

export default useRestoreAlertMutation
