import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import LoginPageContainer from '../login/LoginPageContainer'
import LoginPageTitle from '../login/LoginPageTitle'
import LoginPageCard from '../login/LoginPageCard'

const ChoosePasswordPage = ({ email, formState, onSave }) => {
    const { getNativeInputProps, getErrorMessages } = formState
    return (
        <LoginPageContainer>
            <LoginPageCard>
                <LoginPageTitle>
                    <Trans>Choose password</Trans>
                </LoginPageTitle>
                <div>
                    <FormGroup
                        label={<Trans>Email address</Trans>}
                        htmlFor="email"
                    >
                        <FormInput
                            id="email"
                            name="email"
                            type="email"
                            className="max-w-lg"
                            disabled
                            value={email}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Password</Trans>}
                        htmlFor="password"
                    >
                        <FormInput
                            id="password"
                            type="password"
                            className="max-w-lg"
                            errors={getErrorMessages('password')}
                            {...getNativeInputProps('password')}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Repeat password</Trans>}
                        htmlFor="password"
                    >
                        <FormInput
                            id="repeatPassword"
                            type="password"
                            className="max-w-lg"
                            errors={getErrorMessages('repeatPassword')}
                            {...getNativeInputProps('repeatPassword')}
                        />
                    </FormGroup>
                    <div className="mt-6">
                        <Button onClick={onSave} className="w-full">
                            <Trans>Done</Trans>
                        </Button>
                    </div>
                </div>
            </LoginPageCard>
        </LoginPageContainer>
    )
}

export default ChoosePasswordPage
