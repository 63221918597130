import React from 'react'
import useAppState from '../../hooks/useAppState'
import GlossaryPage from './GlossaryPage'

const GlossaryPageWithState = () => {
    const { logout } = useAppState()
    return <GlossaryPage onLogout={logout} />
}

export default GlossaryPageWithState
