import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import AcceptInvitationCard from './AcceptInvitationCard'

const AcceptInvitationUnauthorized = ({ onNavigateHome }) => (
    <AcceptInvitationCard
        iconName="invitation"
        title={<Trans>Unauthorized</Trans>}
        description={
            <Trans>You are not authorized to accept this invitation.</Trans>
        }
    >
        <div className="mt-6 flex flex-col justify-center">
            <Button onClick={onNavigateHome}>
                <Trans>Go to homepage</Trans>
            </Button>
        </div>
    </AcceptInvitationCard>
)

export default AcceptInvitationUnauthorized
