import roundTo from 'round-to'
import { formatDistance } from 'date-fns'

const MS_IN_SEC = 1000
const MS_IN_DAY = 86400 * 1000

const WORDS = 'words'
const DAYS = 'days'
const SECS = 'secs'
const SECS_SHORT = 'secs-short'

export const getDuration = (value, format = SECS) => {
    if (value === null) {
        return null
    }
    const time = parseInt(value, 10)
    if (format === WORDS) {
        const now = new Date()
        const date = new Date(now.getTime() + time)
        return formatDistance(now, date)
    }
    if (format === DAYS) {
        const days = roundTo(time / MS_IN_DAY, 0)
        return `${days} days`
    }
    if (format === SECS_SHORT) {
        const secs = roundTo(time / MS_IN_SEC, 0)
        return `${secs}s`
    }
    const secs = roundTo(time / MS_IN_SEC, 0)
    return `${secs} sec`
}

const Duration = ({ format = SECS, children }) => getDuration(children, format)

Duration.SECS = SECS
Duration.DAYS = DAYS
Duration.WORDS = WORDS
Duration.MS_IN_DAY = MS_IN_DAY

export default Duration
