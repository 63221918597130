import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import StaffUsersPageTable from './StaffUsersPageTable'
import PageHeader from '../../layout/PageHeader'
import PageContainer from '../../layout/PageContainer'
import StaffHeader from '../../layout/StaffHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import StaffOrganizationsSidebar from '../staff-organizations/StaffOrganizationsSidebar'
import Button from '../../utils/Button'

const StaffUsersPage = ({
    users,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteUserSuccess,
    onCreateStaffUser,
    isFetching,
    refetch,
}) => (
    <PageContainer data-testid="StaffUsersPage">
        <StaffHeader />
        <SidebarContainer>
            <StaffOrganizationsSidebar />
            <PageContent>
                <PageHeader
                    title={<Trans>Staff Users</Trans>}
                    className="mb-8"
                    rightChildren={
                        <Button
                            onClick={onCreateStaffUser}
                            iconBeforeProps={{ name: 'plus' }}
                            size="sm"
                        >
                            <Trans>Create Staff User</Trans>
                        </Button>
                    }
                />
                <StaffUsersPageTable
                    users={users}
                    isFetchingMore={isFetchingMore}
                    onFetchMore={onFetchMore}
                    hasMoreRecords={hasMoreRecords}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                    isFetching={isFetching}
                    refetch={refetch}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default StaffUsersPage
