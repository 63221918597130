import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import CURRENT_USER_FRAGMENT from '../../../apollo/fragments/currentUser'

export const REGISTER_USER_MUTATION = gql`
    mutation registerUser($id: ID!, $input: RegisterUserInput!) {
        registerUser(invitationId: $id, input: $input) {
            me {
                ...CurrentUser
            }
            accessToken
            refreshToken
        }
    }
    ${CURRENT_USER_FRAGMENT}
`

const useRegisterUserMutation = (options) =>
    useMutation(REGISTER_USER_MUTATION, options)

export default useRegisterUserMutation
