import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateTrackers($input: UploadTrackerInput!) {
        updateTrackers(input: $input)
    }
`

const useBulkUploadUpdateTrackersMutation = (options) =>
    useMutation(MUTATION, options)

export default useBulkUploadUpdateTrackersMutation
