import React, { useState, useRef } from 'react'
import Icon from '../../utils/Icon'
import Button from '../../utils/Button'
import { formatFileSize } from '../../../utils/files'

const FileUploadCard = ({ errors, children, onChange, onReset, ...props }) => {
    const [uploadedFile, setUploadedFile] = useState(null)
    const fileInputRef = useRef(null)

    const handleFileSelect = (file) => {
        if (file) {
            setUploadedFile(file)
            if (onChange) {
                onChange(file)
            }
        }
    }

    const clearFile = () => {
        setUploadedFile(null)
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
        if (onReset) {
            onReset()
        }
    }

    const handleDragOver = (event) => {
        event.preventDefault()
    }

    const handleDrop = (event) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0]
        handleFileSelect(file)
    }

    return (
        <label
            htmlFor={props.id}
            className="flex flex-col items-center border-1 border-gray-200 rounded-lg p-4 cursor-pointer"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <input
                ref={fileInputRef}
                type="file"
                onChange={(event) => handleFileSelect(event.target.files[0])}
                {...props}
            />
            {uploadedFile ? (
                <div className="flex items-center gap-4 w-full">
                    <Icon name="csv-page" size="xl" viewBox="0 0 40 40" />
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row justify-between h-fit w-full">
                            <span className="text-sm text-gray-700">
                                {uploadedFile.name}
                            </span>
                            <Button
                                className="h-fit py-0"
                                type="link"
                                onClick={clearFile}
                                iconBeforeProps={{
                                    name: 'x',
                                    size: 'md',
                                    hasText: false,
                                }}
                            />
                        </div>
                        <span className="text-sm text-gray-600">
                            {formatFileSize(uploadedFile.size)}
                        </span>
                    </div>
                </div>
            ) : (
                children
            )}
        </label>
    )
}

export default FileUploadCard
