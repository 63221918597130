import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteOrganizationCookieModal from './DeleteOrganizationCookieModal'
import useDeleteOrganizationCookieMutation from './useDeleteOrganizationCookieMutation'

const DeleteOrganizationCookieModalWithState = ({
    title,
    description,
    cookie,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteCookie, { loading }] = useDeleteOrganizationCookieMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteOrganizationCookieModal
            title={title}
            description={description}
            cookie={cookie}
            isLoading={loading}
            onDismiss={onDismiss}
            onConfirm={async () => {
                try {
                    const variables = { id: cookie.id }
                    await deleteCookie({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{cookie.name} successfully deleted.</Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteOrganizationCookieModalWithState
