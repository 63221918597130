import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import StaffScansPage from './StaffScansPage'
import useStaffScansPageQuery from './useStaffScansPageQuery'
import useFilters from '../../hooks/useFilters'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import useRefetch from '../../hooks/useRefetch'

export default function StaffScansPageWithState() {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const dateRangeState = useScanRangeSelect('none')
    const { startDate, endDate } = dateRangeState
    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters()
    const [orderBy, onChangeOrderBy] = useState({ id: 'DESC' })
    const {
        scans,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useStaffScansPageQuery({
        search,
        filters: {
            ...filterValues,
            startDate,
            endDate,
        },
        orderBy,
    })
    useRefetch(refetch)
    return (
        <StaffScansPage
            dateRangeState={dateRangeState}
            search={search}
            scans={scans}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            onSearch={handleSearch}
            isFetching={isFetching}
            error={error}
            activeFilters={activeFilters}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            onRemoveAllFilters={onRemoveAllFilters}
            initialOrderBy={orderBy}
            onChangeOrderBy={(value, direction) =>
                onChangeOrderBy({ [value]: direction })
            }
            onDeleteScanCompleted={() => refetch()}
        />
    )
}
