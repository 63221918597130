import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const OrganizationCookiesPageTableRowActionButton = ({
    isLoading,
    canDelete,
    canUpdate,
    onDelete,
    onUpdate,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <>
                <DropdownMenuItem
                    iconName="bin"
                    disabled={!canDelete}
                    onClick={(e) => {
                        onDelete()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Delete cookie</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={!canUpdate}
                    onClick={(e) => {
                        onUpdate()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Update cookie</Trans>
                </DropdownMenuItem>
            </>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default OrganizationCookiesPageTableRowActionButton
