import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import Button from '../../utils/Button'
import ButtonLink from '../../utils/ButtonLink'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import FormButtonGroup from '../../utils/FormButtonGroup'
import FormSelect from '../../utils/FormSelect'
import UpdateCustomCmpFormResolverGroup from './UpdateCustomCmpFormResolverGroup'

const UpdateCustomCmpForm = ({
    consentProfiles,
    formState,
    isLoading,
    onSubmit,
    onDisableResolver,
    onEnableProfile,
}) => {
    const { values, getNativeInputProps, getErrorMessages } = formState
    const profilesMissingResolver = consentProfiles.filter(
        (profile) =>
            !values.resolvers.some(
                (resolver) => resolver.profile.id === profile.id
            )
    )
    return (
        <Card>
            <form>
                <CardHeader
                    title={<Trans>Consent management platform</Trans>}
                    description={
                        <Trans>
                            All information related to the consent management
                            platform.
                        </Trans>
                    }
                />
                <div className="divide-y">
                    <FormGroup
                        isHorizontal
                        label={<Trans>Name</Trans>}
                        htmlFor="name"
                    >
                        <FormInput
                            id="name"
                            className="max-w-lg"
                            errors={getErrorMessages('name')}
                            {...getNativeInputProps('name')}
                        />
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Profiles</Trans>}
                        htmlFor="domains"
                    >
                        {values.resolvers &&
                            values.resolvers.map((resolver, index) => (
                                <UpdateCustomCmpFormResolverGroup
                                    key={resolver.id || index}
                                    resolver={resolver}
                                    formState={formState}
                                    index={index}
                                    onDisable={onDisableResolver}
                                />
                            ))}
                        {profilesMissingResolver.map((profile, index) => (
                            <UpdateCustomCmpFormResolverGroup
                                key={profile.id}
                                formState={formState}
                                consentProfile={profile}
                                index={index}
                                onEnable={onEnableProfile}
                            />
                        ))}
                    </FormGroup>
                </div>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                    <Link to="/settings/cmps">
                        <Button type="white">
                            <Trans>Cancel</Trans>
                        </Button>
                    </Link>
                </FormButtonGroup>
            </form>
        </Card>
    )
}

export default UpdateCustomCmpForm
