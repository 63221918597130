import React from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import TableCell from '../../utils/TableCell'
import ButtonLink from '../../utils/ButtonLink'
import PurposesTags from '../../utils/PurposesTags'
import OrganizationTrackersPageTableRowActionButtonWithState from './OrganizationTrackersPageTableRowActionButtonWithState'
import Avatar from '../../utils/Avatar'

const FRAGMENT = gql`
    fragment OrganizationTrackersPageTableRow on Tracker {
        id
        domain
        purposes {
            id
            name
        }
        vendor {
            ...PopoverVendorInfo
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const OrganizationTrackersPageTableRow = ({ tracker, onDeleteCompleted }) => {
    const { domain, purposes, vendor, description } = tracker
    return (
        <tr data-testid="OrganizationTrackersPageTableRow">
            <TableCell hasText className="max-w-xs truncate">
                {domain}
            </TableCell>
            <TableCell>
                <PurposesTags purposes={purposes} />
            </TableCell>
            <TableCell hasText>{description}</TableCell>
            <TableCell>
                <PopoverVendorInfo vendor={vendor}>
                    <Avatar src={vendor.imageUrl} iconName="building-07" />
                </PopoverVendorInfo>
            </TableCell>
            <TableCell className="text-right">
                <OrganizationTrackersPageTableRowActionButtonWithState
                    tracker={tracker}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </tr>
    )
}

OrganizationTrackersPageTableRow.FRAGMENT = FRAGMENT

export default OrganizationTrackersPageTableRow
