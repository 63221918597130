import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import AlertsPageDelegateDropdown from './AlertsPageDelegateDropdown'
import AlertExternalDelegationModalWithState from './AlertExternalDelegationModalWithState'
import useNotifications from '../../hooks/useNotifications'
import useAlertDelegateQuery from './useAlertDelegateQuery'
import useDelegateAlertInternalMutation from './useAlertDelegateInternalMutation'
import useClearAlertDelegationMutation from './useClearAlertDelegationMutation'
import useSearch from '../../hooks/useSearch'
import * as constants from '../../../constants'

const { ALERT_STATUS_LEVEL_AUDIT: AUDIT } = constants

const AlertsPageDelegateDropdownWithState = ({
    alert,
    onAlertStatusChanged,
    level,
    children,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
    const [search, setSearch] = useSearch('')
    const { users, isFetching } = useAlertDelegateQuery(
        { search },
        {
            skip: !dropdownIsOpen,
        }
    )
    const [delegateAlert] = useDelegateAlertInternalMutation()
    const [clearDelegation] = useClearAlertDelegationMutation()
    const { dispatchGraphqlError, dispatchSuccess } = useNotifications()
    const { groupingId, audit, currentDelegation } = alert

    const variables = {
        groupingIds: [groupingId],
        level,
    }
    if (level === AUDIT) {
        variables.auditId = audit.id
    }

    const handleSelectUser = async (user) => {
        try {
            await delegateAlert({
                variables: {
                    ...variables,
                    input: {
                        userId: user.id,
                    },
                },
            })
            dispatchSuccess({
                message: (
                    <>
                        <Trans>Successfully assigned alert to</Trans>{' '}
                        {user.fullName}!
                    </>
                ),
            })
            onAlertStatusChanged()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }
    const handleClearDelegation = async () => {
        try {
            await clearDelegation({
                variables,
            })
            dispatchSuccess({
                message: <Trans>Successfully cleared delegation!</Trans>,
            })
            onAlertStatusChanged()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }
    return (
        <>
            {modalIsOpen && (
                <AlertExternalDelegationModalWithState
                    groupingIds={[alert.groupingId]}
                    audit={alert.audit}
                    onDismiss={() => setModalIsOpen(false)}
                    onCompleted={() => {
                        onAlertStatusChanged()
                        setModalIsOpen(false)
                    }}
                    level={level}
                />
            )}
            <AlertsPageDelegateDropdown
                users={users}
                isFetching={isFetching}
                onOpenDropdownMenu={() => setDropdownIsOpen(true)}
                onDelegateExternal={() => setModalIsOpen(true)}
                onSelectUser={handleSelectUser}
                onClearDelegation={handleClearDelegation}
                canClearDelegation={
                    currentDelegation !== null &&
                    (currentDelegation.user !== null ||
                        currentDelegation.externalEmail !== null)
                }
                onSearch={(value) => setSearch(value)}
            >
                {children}
            </AlertsPageDelegateDropdown>
        </>
    )
}

export default AlertsPageDelegateDropdownWithState
