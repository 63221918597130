import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateLoginProfileModal from './CreateLoginProfileModal'
import useCreateLoginProfileMutation from './useCreateLoginProfileMutation'
import useCreateLoginProfileFormState from './useCreateLoginProfileFormState'

const CreateLoginProfileModalWithState = ({
    onDismiss,
    onCompleted,
    onError,
}) => {
    const [createLoginProfile] = useCreateLoginProfileMutation({
        onCompleted,
        onError,
    })
    const formState = useCreateLoginProfileFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateLoginProfileModal
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await createLoginProfile({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>New login profile created!</Trans>,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateLoginProfileModalWithState
