import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import TableCellAvatar from '../../utils/TableCellAvatar'
import VendorTypeTags from '../../utils/VendorTypeTags'
import Tooltip from '../../utils/Tooltip'
import useDrawerStack from '../../hooks/useDrawerStack'
import Tag from '../../utils/Tag'
import VendorTableItemTableRowActionButtonWithState from './VendorTableItemTableRowActionButtonWithState'
import IdentifiedVendorDetailsDrawer from '../vendors/IdentifiedVendorDetailsDrawer'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const FRAGMENT = gql`
    fragment VendorTableItemPageTableRow on VendorTableItem {
        id
        occurrence
        status
        identifiedVendor {
            id
            vendor {
                name
            }
        }
        vendor {
            id
            name
            domain
            types
            imageUrl
            description
            externalId
            privacyPolicyUrl
            cookiesCount
            trackersCount
            createdAt
            updatedAt
        }
    }
`

const VendorTableItemPageTableRow = ({
    auditIds,
    vendorTableItem,
    onUpdateVendorTableItemsCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const { name, types, domain, privacyPolicyUrl, imageUrl, description } =
        vendorTableItem.vendor
    const dateRange = 'LAST_30_DAYS'

    const { startDate, endDate } = useScanRangeSelect(dateRange)

    return (
        <TableRow
            data-testid="CookieTablesPageTableRow"
            isSelectable
            selectionValue={vendorTableItem}
            selectionCriteria={(row) => row.id === vendorTableItem.id}
            isActive={currentDrawerProps?.vendorId === vendorTableItem.id}
            onClick={() => {
                if (vendorTableItem?.identifiedVendor !== null)
                    open(IdentifiedVendorDetailsDrawer, {
                        identifiedVendorId: vendorTableItem.identifiedVendor.id,
                        auditIds,
                        startDate,
                        endDate,
                    })
            }}
        >
            <TableCellAvatar
                truncate
                columnId="name"
                avatarSrc={imageUrl}
                size="sm"
                avatarIconName="building-07"
                title={
                    name || (
                        <span className="text-slate-300">
                            <Trans>No name found</Trans>
                        </span>
                    )
                }
                text={
                    domain || (
                        <span className="text-slate-300">
                            <Trans>No domain found</Trans>
                        </span>
                    )
                }
            />
            <TableCell columnId="types" size="sm" truncate>
                <VendorTypeTags types={types} />
            </TableCell>
            <TableCell hasText columnId="description" size="sm" truncate>
                {description && (
                    <Tooltip
                        className="block overflow-hidden text-ellipsis"
                        contentClassName="max-w-lg"
                        title={<Trans>Description</Trans>}
                        content={description}
                    >
                        {description}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm" truncate>
                <Tag>{vendorTableItem.occurrence}</Tag>
            </TableCell>
            <TableCell hasText columnId="privacyPolicyUrl" size="sm" truncate>
                {privacyPolicyUrl && (
                    <Tooltip
                        className="block overflow-hidden text-ellipsis"
                        contentClassName="max-w-lg"
                        title={<Trans>Link to privacy policy</Trans>}
                        content={privacyPolicyUrl}
                    >
                        {privacyPolicyUrl}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell className="text-right" size="sm">
                <VendorTableItemTableRowActionButtonWithState
                    vendorTableItem={vendorTableItem}
                    onUpdateVendorTableItemsCompleted={
                        onUpdateVendorTableItemsCompleted
                    }
                />
            </TableCell>
        </TableRow>
    )
}

VendorTableItemPageTableRow.FRAGMENT = FRAGMENT

export default VendorTableItemPageTableRow
