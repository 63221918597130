import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import VendorTypeTags from '../../utils/VendorTypeTags'
import useDrawerStack from '../../hooks/useDrawerStack'
import AuditAvatarGroup from '../../utils/AuditAvatarGroup'
import IdentifiedVendorsPageTableRowActionsWithState from './IdentifiedVendorsPageTableRowActionsWithState'
import IdentifiedVendorDetailsDrawer from './IdentifiedVendorDetailsDrawer'
import ActionButton from '../../utils/ActionButton'
import AlertCountTag from '../../utils/AlertCountTag'

const FRAGMENT = gql`
    fragment IdentifiedVendorsPageTableRow on IdentifiedVendor {
        id
        lastSeenAt
        occurrence
        audits {
            id
            name
            imageUrl
        }
        identifiedCookiesCount
        identifiedTrackersCount
        vendor {
            id
            domain
            name
            types
            imageUrl
            belongsToOrganization
        }
        alerts {
            severityType
        }
    }
`

const IdentifiedVendorsPageTableRow = ({
    vendor: auditVendor,
    onUpdateCompleted,
    startDate,
    endDate,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        audits,
        identifiedTrackersCount,
        identifiedCookiesCount,
        lastSeenAt,
        occurrence,
        alerts,
        vendor,
    } = auditVendor
    const { name, domain, imageUrl, types, belongsToOrganization } = vendor
    return (
        <TableRow
            isSelectable
            selectionValue={auditVendor}
            isActive={currentDrawerProps?.vendorId === id}
            selectionCriteria={(row) => {
                return row.id === vendor.id
            }}
            data-testid="VendorsPageTableRow"
            onClick={() => {
                open(IdentifiedVendorDetailsDrawer, {
                    identifiedVendorId: id,
                    onUpdateCompleted,
                    startDate,
                    endDate,
                })
            }}
        >
            <TableCellAvatar
                columnId="name"
                size="sm"
                avatarSrc={imageUrl}
                avatarIconName="building-07"
                title={name}
                text={
                    domain || (
                        <span className="text-slate-300">
                            <Trans>No Domain</Trans>
                        </span>
                    )
                }
            />
            <TableCell columnId="alerts" hasText>
                <AlertCountTag alerts={alerts} />
            </TableCell>
            <TableCell columnId="cookiesAndTrackers" size="sm">
                <div className="flew-row flex gap-2">
                    <Tag iconNameBefore="cookie">{identifiedCookiesCount}</Tag>
                    <Tag iconNameBefore="tracker">
                        {identifiedTrackersCount}
                    </Tag>
                </div>
            </TableCell>
            <TableCell columnId="types">
                <VendorTypeTags types={types} />
            </TableCell>
            <TableCell columnId="audits">
                <AuditAvatarGroup audits={audits} />
            </TableCell>
            <TableCell columnId="occurrence" hasText className="text-center">
                <Tag>{occurrence || 'Unknown'}</Tag>
            </TableCell>
            <TableCell hasText columnId="lastSeenAt">
                <DateTime dateOnly>{lastSeenAt}</DateTime>
            </TableCell>
            <TableCell columnId="belongsToOrganization">
                {belongsToOrganization && <Tag>Self-classified</Tag>}
            </TableCell>
            <TableCell className="text-right">
                <IdentifiedVendorsPageTableRowActionsWithState
                    vendor={vendor}
                    onUpdateCompleted={onUpdateCompleted}
                >
                    <ActionButton />
                </IdentifiedVendorsPageTableRowActionsWithState>
            </TableCell>
        </TableRow>
    )
}

IdentifiedVendorsPageTableRow.FRAGMENT = FRAGMENT

export default IdentifiedVendorsPageTableRow
