import React from 'react'
import FormInputErrors from './FormInputErrors'
import Input from './Input'

const FormInput = ({ errors, className, inputClassName, ...props }) => (
    <div className={className}>
        <Input className={inputClassName} {...props} />
        <FormInputErrors errors={errors} />
    </div>
)

export default FormInput
