import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query updateLoginProfilePage($id: ID!) {
        loginProfile(id: $id) {
            id
            name
            resolverActions {
                type
                selector
            }
        }
    }
`

const useUpdateLoginProfilePageQuery = () => {
    const { id } = useParams()
    const variables = { id }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const loginProfile = data.loginProfile
        ? {
              ...data.loginProfile,
              resolverActions:
                  data.loginProfile.resolverActions &&
                  data.loginProfile.resolverActions.map(
                      ({ type, selector }) => ({
                          type,
                          selector,
                      })
                  ),
          }
        : {}
    return {
        error,
        loginProfile,
        isFetching,
        refetch,
    }
}

export default useUpdateLoginProfilePageQuery
