import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import VendorsPageTableHeaderRow from './VendorsPageTableHeaderRow'
import VendorsPageTableRow from './VendorsPageTableRow'

const VendorsPageTable = ({
    vendors = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onDeleteVendorCompleted,
    onUpdateVendorCompleted,
}) => {
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                <thead>
                    <VendorsPageTableHeaderRow />
                </thead>
                <TableBody>
                    {vendors.map((vendor) => (
                        <VendorsPageTableRow
                            key={vendor.id}
                            vendor={vendor}
                            onDeleteVendorCompleted={onDeleteVendorCompleted}
                            onUpdateVendorCompleted={onUpdateVendorCompleted}
                        />
                    ))}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}

export default VendorsPageTable
