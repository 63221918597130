import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TrackersPageTableRow from './TrackersPageTableRow'
import TrackersPageTableHeaderRow from './TrackersPageTableHeaderRow'

const TrackersPageTable = ({
    trackers = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onDeleteTrackerCompleted,
    onUpdateTrackerCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <TrackersPageTableHeaderRow />
            </thead>
            <TableBody>
                {trackers.map((tracker) => (
                    <TrackersPageTableRow
                        key={tracker.id}
                        tracker={tracker}
                        onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                        onUpdateTrackerCompleted={onUpdateTrackerCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default TrackersPageTable
