import React from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import TableCell from '../../utils/TableCell'
import ButtonLink from '../../utils/ButtonLink'
import PurposesTags from '../../utils/PurposesTags'
import OrganizationCookiesPageTableRowActionButtonWithState from './OrganizationCookiesPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment OrganizationCookiesPageTableRow on Cookie {
        id
        name
        purposes {
            id
            name
        }
        domain
        description
        vendor {
            ...PopoverVendorInfo
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const OrganizationCookiesPageTableRow = ({ cookie, onDeleteCompleted }) => {
    const { name, purposes, vendor, description } = cookie
    return (
        <tr data-testid="OrganizationCookiesPageTableRow">
            <TableCell hasText className="max-w-xs truncate">
                {name}
            </TableCell>
            <TableCell>
                <PurposesTags purposes={purposes} />
            </TableCell>
            <TableCell hasText>{description}</TableCell>
            <TableCell>
                <PopoverVendorInfo vendor={vendor}>
                    {vendor.name}
                </PopoverVendorInfo>
            </TableCell>
            <TableCell className="text-right">
                <OrganizationCookiesPageTableRowActionButtonWithState
                    cookie={cookie}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </tr>
    )
}

OrganizationCookiesPageTableRow.FRAGMENT = FRAGMENT

export default OrganizationCookiesPageTableRow
