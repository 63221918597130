import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import useSwitchOrganizationMutation from '../my-organizations/useSwitchOrganizationMutation'
import CreateOrganizationModal from './CreateOrganizationModal'
import useCreateOrganizationMutation from './useCreateOrganizationMutation'
import useCreateOrganizationFormState from './useCreateOrganizationFormState'
import useCreateOrganizationModalQuery from './useCreateOrganizationModalQuery'

const CreateOrganizationModalWithState = ({ onDismiss, onCompleted }) => {
    const history = useHistory()
    const appState = useAppState()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const formState = useCreateOrganizationFormState()
    const { cmps } = useCreateOrganizationModalQuery()
    const [switchOrganization] = useSwitchOrganizationMutation()
    const [createOrganization, { loading }] = useCreateOrganizationMutation({
        onCompleted,
    })

    const handleCreateOrganization = async () => {
        try {
            if (formState.validate()) {
                const variables = { input: formState.valuesToInput() }
                const {
                    data: {
                        createOrganization: { organization },
                    },
                } = await createOrganization({ variables })
                formState.resetForm()
                dispatchSuccess({
                    message: (
                        <Trans>Organization {organization.name} created!</Trans>
                    ),
                })
                await switchOrganization({
                    variables: { organizationId: organization.id },
                })
                await appState.reset('/')
            }
        } catch (error) {
            const { message } = error.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    return (
        <CreateOrganizationModal
            cmps={cmps}
            isLoading={loading}
            formState={formState}
            onDismiss={onDismiss}
            onSubmit={handleCreateOrganization}
        />
    )
}

export default CreateOrganizationModalWithState
