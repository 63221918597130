import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteInvitationModal = ({
    invitation,
    isLoading,
    onDismiss,
    onConfirm,
}) => (
    <ConfirmModal
        title={<Trans>Delete Invitation</Trans>}
        description={
            <Trans>
                Are you sure you want to delete the invitation to{' '}
                <strong>{invitation.email}</strong>?
            </Trans>
        }
        confirmButtonText={<Trans>Delete Invitation</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteInvitationModal
