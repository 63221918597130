import React from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'

export const CONSENT = 'CONSENT'
export const FLEXIBLE = 'FLEXIBLE'
export const LEGITIMATE_INTEREST = 'LEGITIMATE_INTEREST'

const allSpecialPurposes = [
    {
        value: 1,
        label: <Trans>Ensure security, prevent fraud, and debug</Trans>,
    },
    {
        value: 2,
        label: <Trans>Technically deliver ads or content</Trans>,
    },
]

const allFeatures = [
    {
        value: 1,
        label: <Trans>Match and combine offline data sources</Trans>,
    },
    {
        value: 2,
        label: <Trans>Link different devices</Trans>,
    },
    {
        value: 3,
        label: (
            <Trans>
                Receive and use automatically-sent device characteristics for
                identification
            </Trans>
        ),
    },
]

const allSpecialFeatures = [
    {
        value: 1,
        label: <Trans>Use precise geolocation data</Trans>,
    },
    {
        value: 2,
        label: (
            <Trans>
                Actively scan device characteristics for identification
            </Trans>
        ),
    },
]

const allPurposes = [
    {
        value: 1,
        label: <Trans>Store and/or access information on a device</Trans>,
    },
    {
        value: 2,
        label: <Trans>Select basic ads</Trans>,
    },
    {
        value: 3,
        label: <Trans>Create a personalised ads profile</Trans>,
    },
    {
        value: 4,
        label: <Trans>Select personalised ads</Trans>,
    },
    {
        value: 5,
        label: <Trans>Create a personalised content profile</Trans>,
    },
    {
        value: 6,
        label: <Trans>Select personalised content</Trans>,
    },
    {
        value: 7,
        label: <Trans>Measure ad performance</Trans>,
    },
    {
        value: 8,
        label: <Trans>Measure content performance</Trans>,
    },
    {
        value: 9,
        label: (
            <Trans>Apply market research to generate audience insights</Trans>
        ),
    },
    {
        value: 10,
        label: <Trans>Develop and improve products</Trans>,
    },
]

export default function VendorDetailsDrawerIabPurposesTab({ vendor }) {
    const { purposes, flexiblePurposes, legIntPurposes } = vendor
    const vendorPurposes = allPurposes.reduce((acc, { label, value }) => {
        const purpose = purposes?.find((v) => v === value)
        if (typeof purpose !== 'undefined') {
            return [
                ...acc,
                {
                    label,
                    type: CONSENT,
                },
            ]
        }
        const flexPurpose = flexiblePurposes?.find((v) => v === value)
        if (typeof flexPurpose !== 'undefined') {
            return [
                ...acc,
                {
                    label,
                    type: FLEXIBLE,
                },
            ]
        }
        const legIntPurpose = legIntPurposes?.find((v) => v === value)
        if (typeof legIntPurpose !== 'undefined') {
            return [
                ...acc,
                {
                    label,
                    type: LEGITIMATE_INTEREST,
                },
            ]
        }
        return acc
    }, [])
    return (
        <>
            <div>
                <div>
                    <h3 className="text-md leading-6 font-medium text-slate-900">
                        <Trans>IAB purposes</Trans>
                    </h3>
                    <p className="mt-1 text-sm leading-5 text-slate-500 mb-6">
                        <Trans>
                            The IAB Framework is to create a standardized
                            cooperation between online publishers, advertisers,
                            and the tech companies.
                        </Trans>
                    </p>
                    {vendorPurposes.length > 0 && (
                        <Table hasMinWidth={false} hasHeader={false} isRounded>
                            <TableBody>
                                {vendorPurposes.map(({ label, type }) => (
                                    <TableRow key={label}>
                                        <TableCell size="xs">{label}</TableCell>
                                        <TableCell
                                            size="xs"
                                            className="text-right"
                                        >
                                            {(() => {
                                                if (type === CONSENT) {
                                                    return (
                                                        <Tag type="green">
                                                            <Trans>
                                                                Consent
                                                            </Trans>
                                                        </Tag>
                                                    )
                                                }
                                                if (
                                                    type === LEGITIMATE_INTEREST
                                                ) {
                                                    return (
                                                        <Tag type="blue">
                                                            <Trans>
                                                                Leg. Int.
                                                            </Trans>
                                                        </Tag>
                                                    )
                                                }
                                                if (type === FLEXIBLE) {
                                                    return (
                                                        <Tag type="yellow">
                                                            <Trans>
                                                                Flexible
                                                            </Trans>
                                                        </Tag>
                                                    )
                                                }
                                                return null
                                            })()}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </div>
            </div>
            <div>
                <div className="pb-8">
                    <h3 className="text-md leading-6 font-medium text-slate-900">
                        <Trans>IAB processing activities</Trans>
                    </h3>
                    <p className="mt-1 text-sm leading-5 text-slate-500 mb-6">
                        <Trans>
                            The IAB Framework is to create a standardized
                            cooperation between online publishers, advertisers,
                            and the tech companies.
                        </Trans>
                    </p>
                    {(vendor.specialPurposes.length > 0 ||
                        vendor.features.length > 0 ||
                        vendor.specialFeatures.length > 0) && (
                        <Table hasMinWidth={false} isRounded>
                            <TableBody>
                                {allSpecialPurposes.map(({ label, value }) => {
                                    const specialPurpose =
                                        vendor.specialPurposes.find(
                                            (v) => v === value
                                        )
                                    if (typeof specialPurpose !== 'undefined') {
                                        return (
                                            <TableRow key={label}>
                                                <TableCell size="xs">
                                                    {label}
                                                </TableCell>
                                                <TableCell
                                                    size="xs"
                                                    className="text-right"
                                                >
                                                    <Tag type="red">
                                                        <Trans>
                                                            Special purposes
                                                        </Trans>
                                                    </Tag>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    return null
                                })}
                                {allFeatures.map(({ label, value }) => {
                                    const feature = vendor.features.find(
                                        (v) => v === value
                                    )
                                    if (typeof feature !== 'undefined') {
                                        return (
                                            <TableRow key={label}>
                                                <TableCell size="xs">
                                                    {label}
                                                </TableCell>
                                                <TableCell
                                                    size="xs"
                                                    className="text-right"
                                                >
                                                    <Tag type="yellow">
                                                        <Trans>Feature</Trans>
                                                    </Tag>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    return null
                                })}
                                {allSpecialFeatures.map(({ label, value }) => {
                                    const feature = vendor.specialFeatures.find(
                                        (v) => v === value
                                    )
                                    if (typeof feature !== 'undefined') {
                                        return (
                                            <TableRow key={label}>
                                                <TableCell size="xs">
                                                    {label}
                                                </TableCell>
                                                <TableCell
                                                    size="xs"
                                                    className="text-right"
                                                >
                                                    <Tag type="red">
                                                        <Trans>
                                                            Special feature
                                                        </Trans>
                                                    </Tag>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    return null
                                })}
                            </TableBody>
                        </Table>
                    )}
                </div>
            </div>
        </>
    )
}
