import React from 'react'
import clsx from 'clsx'

const ButtonLink = ({
    as = 'button',
    type = 'primary',
    disabled,
    className,
    ...props
}) => {
    const Component = as
    return (
        <Component
            type={as === 'button' ? 'button' : null}
            className={clsx(
                'inline-flex items-center text-sm font-medium truncate transition duration-150 ease-in-out',
                type === 'primary' && 'text-primary-500 hover:text-primary-600',
                type === 'danger' && 'text-red-600 hover:text-red-500',
                disabled && 'opacity-50 cursor-not-allowed ',
                className
            )}
            {...props}
        />
    )
}

export default ButtonLink
