import React from 'react'
import { twMerge } from 'tailwind-merge'

const DropdownMenuItemDivider = ({ className }) => (
    <hr
        className={twMerge('w-full border-t border-slate-200 my-2', className)}
    />
)

export default DropdownMenuItemDivider
