import React from 'react'
import { Trans } from '@lingui/macro'
import TableFilterClearButton from '../../utils/TableFilterClearButton'
import TableFiltersMenu from '../../utils/TableFiltersMenu'
import TableFiltersMenuItem from '../../utils/TableFiltersMenuItem'

export default function CookiesPageTableActionsFilters({
    activeFilters = [],
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
}) {
    return (
        <div className="flex gap-2 items-center">
            <TableFilterClearButton
                name="hasNoDescription"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No description</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoVendor"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No Vendor</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoDomain"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No Domain</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoPurpose"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No Purpose</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoRegex"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No Regex</Trans>
            </TableFilterClearButton>
            <TableFiltersMenu
                onRemoveAll={onRemoveAllFilters}
                activeFilters={activeFilters}
            >
                <TableFiltersMenuItem
                    iconName="help-octagon"
                    name="hasNoDescription"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No description</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="cookie"
                    name="hasNoVendor"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No vendor</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="tag"
                    name="hasNoPurpose"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No purpose</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="globe"
                    name="hasNoDomain"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No domain</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="text-input"
                    name="hasNoRegex"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No regex</Trans>
                </TableFiltersMenuItem>
            </TableFiltersMenu>
        </div>
    )
}
