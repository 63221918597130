import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import StaffUnclassifiedCookiesPage from './StaffUnclassifiedCookiesPage'
import useStaffUnclassifiedCookiesPageQuery from './useStaffUnclassifiedCookiesPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const StaffUnclassifiedCookiesPageWithState = () => {
    const [search, handleSearch] = useSearch()
    const dateRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = dateRangeState
    const [orderBy, onChangeOrderBy] = useState({ name: 'ASC' })
    const {
        unclassifiedCookies,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useStaffUnclassifiedCookiesPageQuery({
        search,
        orderBy,
        startDate,
        endDate,
        period,
    })
    useRefetch(refetch)
    const clearState = () => {
        onChangeOrderBy({ name: 'ASC' })
    }
    return (
        <StaffUnclassifiedCookiesPage
            search={search}
            dateRangeState={dateRangeState}
            unclassifiedCookies={unclassifiedCookies}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            onSearch={handleSearch}
            isFetching={isFetching}
            error={error}
            orderBy={orderBy}
            onChangeOrderBy={(value, direction) =>
                onChangeOrderBy({ [value]: direction })
            }
            onDeleteStaffUnclassifiedCookiesCompleted={() => refetch()}
            onReset={clearState}
            onClassifyCookieCompleted={() => refetch()}
        />
    )
}

export default StaffUnclassifiedCookiesPageWithState
