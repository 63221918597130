import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateVendorTable($id: ID!, $input: VendorTableInput!) {
        updateVendorTable(id: $id, input: $input) {
            vendorTable {
                id
            }
        }
    }
`

const useUpdateVendorTableMutation = (options) => useMutation(MUTATION, options)

export default useUpdateVendorTableMutation
