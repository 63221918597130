import React from 'react'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import LoadingIndicator from '../../utils/LoadingIndicator'
import AlertsBySubjectListItem from './AlertsBySubjectListItem'
import ButtonLink from '../../utils/ButtonLink'

const DashboardPageAlertsByAuditChart = ({ isFetching, chartData }) => (
    <div className="bg-white gap-4 p-6 rounded-2xl border border-gray-200 mb-8">
        {(() => {
            if (isFetching) {
                return (
                    <div className="h-[300px] flex items-center justify-center">
                        <LoadingIndicator />
                    </div>
                )
            }
            const mostAlerts = chartData.reduce(
                (prev, current) =>
                    prev.alertCount > current.alertCount ? prev : current,
                0
            )
            return (
                <>
                    <div className="flex justify-between mb-8">
                        <div>
                            <h3 className="font-semibold ">
                                <Trans>Alerts by audit</Trans>
                            </h3>
                        </div>
                        <div />
                    </div>
                    <div className="min-h-[300px]">
                        <ul className="w-full">
                            {chartData.map(({ audit, alertCount }) => (
                                <AlertsBySubjectListItem
                                    iconName="clock"
                                    key={audit.id}
                                    name={
                                        <Link
                                            exact="true"
                                            to={`/audits/${audit.id}/alerts`}
                                        >
                                            <ButtonLink as="span">
                                                {audit.name}
                                            </ButtonLink>
                                        </Link>
                                    }
                                    imageUrl={audit.imageUrl}
                                    alertCount={alertCount}
                                    maxAlertCount={mostAlerts.alertCount}
                                />
                            ))}
                        </ul>
                    </div>
                </>
            )
        })()}
    </div>
)

export default DashboardPageAlertsByAuditChart
