import React, { useEffect } from 'react'
import { I18nProvider } from '@lingui/react'
import { LANGUAGE_EN as EN } from '../../constants'

const LinguiProvider = ({ locales, currentUser, children }) => {
    const languageCode =
        currentUser === null ? EN : currentUser.languageCode || EN
    useEffect(() => {
        locales.activate(languageCode)
    }, [languageCode, locales])
    return <I18nProvider i18n={locales}>{children}</I18nProvider>
}

export default LinguiProvider
