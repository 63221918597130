import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import StaffUnclassifiedCookiesPageTableRow from './StaffUnclassifiedCookiesPageTableRow'

const QUERY = gql`
    query staffUnclassifiedCookiesPage(
        $after: String
        $search: String
        $orderBy: StaffUnclassifiedCookieOrderByInput
        $filters: StaffUnclassifiedCookiesFilters
    ) {
        staffUnclassifiedCookies(
            after: $after
            search: $search
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                cursor
                node {
                    ...StaffUnclassifiedCookiesPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${StaffUnclassifiedCookiesPageTableRow.FRAGMENT}
`

const useStaffUnclassifiedCookiesPageQuery = ({
    search,
    orderBy,
    startDate,
    endDate,
    period,
}) => {
    const filters = { startDate, endDate, period }
    const variables = { search, filters, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip:
            filters.startDate === null ||
            filters.endDate === null ||
            filters.period === null,
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'staffUnclassifiedCookies'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            unclassifiedCookies: [],
            isFetching,
        }
    }
    return {
        unclassifiedCookies: connectionToCollection(
            data.staffUnclassifiedCookies
        ),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: data.staffUnclassifiedCookies?.pageInfo.hasNextPage,
    }
}

export default useStaffUnclassifiedCookiesPageQuery
