import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import VendorTablesPageTableRow from './VendorTablesPageTableRow'
import VendorsTablesPageTableHeaderRow from './VendorTablesPageTableHeaderRow'

const VendorTablesPageTable = ({
    vendorTables,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="VendorTablesPageTable">
            <thead>
                <VendorsTablesPageTableHeaderRow />
            </thead>
            <TableBody>
                {vendorTables.map((vendorTable) => (
                    <VendorTablesPageTableRow
                        key={vendorTable.id}
                        vendorTable={vendorTable}
                        onDeleteCompleted={onDeleteCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default VendorTablesPageTable
