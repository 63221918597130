import React from 'react'
import Button from '../../utils/Button'

const BulkPasteCard = ({
    errors,
    children,
    onChange,
    onReset,
    value,
    ...props
}) => {
    return (
        <div className="flex flex-col items-center border-1 border-gray-200 rounded-lg p-4">
            {value !== '' ? (
                <Button onClick={onReset} className="m-2 text-red-500">
                    Clear Pasted Data
                </Button>
            ) : (
                children
            )}
            <textarea
                className="w-full p-2 border-1 border-gray-200 rounded-lg p-4 hover:border-gray-400 cursor-pointer"
                onChange={(e) => onChange(e.target.value)}
                {...props}
            />
        </div>
    )
}

export default BulkPasteCard
