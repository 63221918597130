import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import OrganizationCookiesPageTableRowActionButton from './OrganizationCookiesPageTableRowActionButton'
import DeleteOrganizationCookieModalWithState from './DeleteOrganizationCookieModalWithState'
import UpdateOrganizationCookieModalWithState from './UpdateOrganizationCookieModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const OrganizationVendorsPageTableRowActionButtonWithState = ({
    cookie,
    onDeleteCompleted,
    onUpdateCompleted,
}) => {
    const history = useHistory()
    const { currentUser } = useAppState()
    const deleteModalIsOpen = useRouteMatch({
        path: `/classifications/organization/cookies/${cookie.id}/delete`,
    })
    const updateModalIsOpen = useRouteMatch({
        path: `/classifications/organization/cookies/${cookie.id}/update`,
    })
    return (
        <>
            {deleteModalIsOpen && (
                <DeleteOrganizationCookieModalWithState
                    cookie={cookie}
                    onDismiss={() =>
                        history.push('/classifications/organization/cookies')
                    }
                    onCompleted={onDeleteCompleted}
                />
            )}
            {updateModalIsOpen && (
                <UpdateOrganizationCookieModalWithState
                    cookie={cookie}
                    onDismiss={() =>
                        history.push('/classifications/organization/cookies')
                    }
                    onCompleted={onUpdateCompleted}
                />
            )}
            <OrganizationCookiesPageTableRowActionButton
                cookie={cookie}
                canDelete={currentUser.role === OWNER}
                canUpdate={currentUser.role === OWNER}
                onDelete={() =>
                    history.push(
                        `/classifications/organization/cookies/${cookie.id}/delete`
                    )
                }
                onUpdate={() =>
                    history.push(
                        `/classifications/organization/cookies/${cookie.id}/update`
                    )
                }
            />
        </>
    )
}

export default OrganizationVendorsPageTableRowActionButtonWithState
