import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useCreateVendorTableVersionMutation from './useCreateVendorTableVersionMutation'
import useUpdateVendorTableVersionMutation from './useUpdateVendorTableVersionMutation'
import useDeleteVendorTableVersionMutation from './useDeleteVendorTableVersionMutation'
import useCreateVendorTableVersionFormState from './useCreateVendorTableVersionFormState'
import CreateVendorTableVersionModal from './CreateVendorTableVersionModal'

const CreateVendorTableVersionModalWithState = ({
    onDismiss,
    onCompleted,
    vendorTable,
}) => {
    const { id: vendorTableId } = useParams()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()

    const [selectedAudits, setSelectedAudits] = useState(
        vendorTable?.latestVersion?.audits || []
    )
    const [versionId, setVersionId] = useState(null)

    const [createVendorTableVersion, mutationData] =
        useCreateVendorTableVersionMutation()
    const [updateVendorTableVersion] = useUpdateVendorTableVersionMutation()
    const [deleteVendorTableVersion] = useDeleteVendorTableVersionMutation()
    const formState = useCreateVendorTableVersionFormState()

    const { values } = formState

    const [step, setStep] = useState(1)
    const handleNext = () => {
        if (values.name !== '') {
            setStep(step + 1)
        }
    }

    const handleCreateVersion = async () => {
        if (formState.validate()) {
            try {
                const { name } = formState.values
                const input = {
                    name,
                    vendorTableId,
                    audits: selectedAudits.map(({ id }) => id),
                }
                const newCookieVersion = await createVendorTableVersion({
                    variables: { input },
                })
                setVersionId(
                    newCookieVersion.data.createVendorTableVersion
                        .vendorTableVersion.id
                )
            } catch (error) {
                dispatchGraphqlError(error)
            }
        }
    }
    const handleUpdateVersion = async () => {
        try {
            await updateVendorTableVersion({
                variables: {
                    id: versionId,
                    input: {
                        status: 'FINISHED',
                        name: formState.values.name,
                        vendorTableId,
                    },
                },
            })
            dispatchSuccess(
                <Trans>
                    Your vendor table version was created successfully
                </Trans>
            )
            onCompleted()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }
    const handleCancelVersion = async () => {
        try {
            await deleteVendorTableVersion({
                variables: { id: versionId },
            })
            setVersionId(null)
            formState.resetForm()
            onDismiss()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }

    return (
        <CreateVendorTableVersionModal
            vendorTable={vendorTable}
            selectedAudits={selectedAudits}
            initialAudits={vendorTable?.latestVersion?.audits || []}
            formState={formState}
            versionId={versionId}
            isLoading={mutationData.loading}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onAddAudit={(audit) => {
                const newAudits = [...selectedAudits, audit]
                const uniqueAudits = [
                    ...new Map(
                        newAudits.map((item) => [item.id, item])
                    ).values(),
                ]
                setSelectedAudits(uniqueAudits)
            }}
            onRemoveAudit={(id) => {
                const newAudits = selectedAudits.filter(
                    (cookie) => cookie.id !== id
                )
                setSelectedAudits(newAudits)
            }}
            currentStep={step}
            onNextStep={handleNext}
            onSubmit={handleCreateVersion} // This Function allow us to create the initial version that needs to be reviewd
            onCreate={handleUpdateVersion} // This Function allow us to update the version & finish the creation of the version
            onCancel={handleCancelVersion} // This Function allow us to delete the version
        />
    )
}

export default CreateVendorTableVersionModalWithState
