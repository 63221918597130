import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteOrganizationVendorModal = ({
    vendor,
    isLoading,
    onDismiss,
    onConfirm,
}) => (
    <ConfirmModal
        title={<Trans>Delete vendor</Trans>}
        description={
            <Trans>
                Are you sure you want to delete <strong>{vendor.name}</strong>{' '}
                from you organization? Carefull, all cookies and trackers linked
                to this vendor will also be delete.
            </Trans>
        }
        confirmButtonText={<Trans>Delete vendor</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteOrganizationVendorModal
