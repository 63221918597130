import React from 'react'
import { Trans } from '@lingui/macro'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import UpdateTrackerModal from './UpdateTrackerModal'
import useUpdateTrackerMutation from './useUpdateTrackerMutation'
import useUpdateTrackerFormState from './useUpdateTrackerFormState'
import useUpdateTrackerModalQuery from './useUpdateTrackerModalQuery'

const UpdateTrackerModalWithState = ({
    tracker,
    purposes = null,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const canSkipDataFetching = purposes !== null
    const query = useUpdateTrackerModalQuery(tracker.id, {
        skip: canSkipDataFetching,
    })
    const finalPurposes = purposes || query.purposes
    const [updateTracker] = useUpdateTrackerMutation({ onCompleted, onError })
    const formState = useUpdateTrackerFormState(query.tracker)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <UpdateTrackerModal
            isFetching={query.isFetching}
            initialVendor={query.tracker.vendor}
            purposes={finalPurposes}
            canCreateVendor={currentUser.isStaff}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        const variables = { id: tracker.id, input }
                        await updateTracker({ variables })
                        await query.refetch()
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Tracker updated successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default UpdateTrackerModalWithState
