/* eslint-disable react/no-array-index-key */
import React from 'react'
import Avatar from './Avatar'
import { timeAgo } from '../../utils/dates'

const AlertComment = ({ comment }) => {
    const { text, author, createdAt } = comment
    return (
        <div className="flex gap-2">
            <Avatar name={author.fullName} />
            <div className="border border-gray-200 w-full rounded-lg">
                <div className="flex items-center justify-between py-2 px-4 border-b border-gray-200">
                    <div className="text-sm font-medium">{author.fullName}</div>
                    <div className="text-gray-500 text-sm">
                        {timeAgo(createdAt)}
                    </div>
                </div>
                <div className="w-full min-h-12 py-2 px-4 text-sm" value={text}>
                    {text}
                </div>
            </div>
        </div>
    )
}

export default AlertComment
