import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { connectionToCollection } from '../../../utils/graphql'
import IdentifiedVendorsPageTableRow from './IdentifiedVendorsPageTableRow'

const QUERY = gql`
    query identifiedVendorsPage(
        $search: String
        $after: String
        $offset: Int
        $orderBy: VendorOrderByInput
        $filters: IdentifiedVendorsFilters
    ) {
        identifiedVendors(
            search: $search
            after: $after
            offset: $offset
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                node {
                    ...IdentifiedVendorsPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
        audits {
            edges {
                cursor
                node {
                    id
                    name
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${IdentifiedVendorsPageTableRow.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useIdentifiedVendorsPageQuery = (
    search,
    auditId,
    startDate,
    endDate,
    period
) => {
    const orderBy = { occurrence: 'DESC' }
    const filters = { auditId, startDate, endDate, period }
    const variables = { search, filters, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip: period === null || startDate === null,
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'identifiedVendors'
    )
    return {
        error,
        audits: connectionToCollection(data.audits),
        vendors: connectionToCollection(data.identifiedVendors),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useIdentifiedVendorsPageQuery
