import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteTrackerModal from './DeleteTrackerModal'
import useDeleteTrackerMutation from './useDeleteTrackerMutation'

const DeleteTrackerModalWithState = ({
    tracker,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteTracker, { loading }] = useDeleteTrackerMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteTrackerModal
            onDismiss={onDismiss}
            tracker={tracker}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await deleteTracker({ variables: { id: tracker.id } })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {tracker.domain} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteTrackerModalWithState
