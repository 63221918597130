import React from 'react'

const LoginPageTitle = ({ children, ...props }) => (
    <div className="sm:mx-auto sm:w-full sm:max-w-md" {...props}>
        <h2 className="text-3xl leading-9 font-bold text-gray-900 mb-8">
            {children}
        </h2>
    </div>
)

export default LoginPageTitle
