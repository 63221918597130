import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation validateVendors($input: UploadVendorInput!) {
        validateVendors(input: $input) {
            errors {
                field
                row
                type
                message
            }
            warnings {
                field
                row
                type
                message
            }
            totalCount
        }
    }
`

const useBulkUploadValidateVendorsMutation = (options) =>
    useMutation(MUTATION, options)

export default useBulkUploadValidateVendorsMutation
