import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import FormButtonGroup from '../../utils/FormButtonGroup'
import FormGroup from '../../utils/FormGroup'
import CookieTableSearchSelect from '../../utils/CookieTableSearchSelect'
import FormSelectList from '../../utils/FormSelectList'

const CreateCookieTableReportModal = ({
    isFetching = false,
    setSelectedCookieTable,
    selectedCookieTable,
    onDismiss,
    onSubmit,
}) => {
    return (
        <Modal className="max-w-2xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create cookie table report</Trans>}
                description={
                    <Trans>
                        Export all cookies included in the selected cookie table
                    </Trans>
                }
                onClose={onDismiss}
                newModalTitle
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        <FormGroup
                            label={<Trans>Select Cookie table</Trans>}
                            isHorizontal
                            className="mb-4"
                        >
                            <FormSelectList>
                                <CookieTableSearchSelect
                                    className="h-[400px]"
                                    onHandleSelectCookieTable={
                                        setSelectedCookieTable
                                    }
                                    selectedCookieTable={selectedCookieTable}
                                />
                            </FormSelectList>
                        </FormGroup>
                        <FormButtonGroup className="mt-10 flex w-full flex-row-reverse gap-2">
                            <Button onClick={onSubmit} className="w-full">
                                <Trans>Create</Trans>
                            </Button>
                            <Button
                                type="white"
                                className="w-full"
                                onClick={onDismiss}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                        </FormButtonGroup>
                    </>
                )
            })()}
        </Modal>
    )
}

export default CreateCookieTableReportModal
