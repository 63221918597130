import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import useNotifications from '../../hooks/useNotifications'
import useAppState from '../../hooks/useAppState'
import CreateOrganizationTrackerModal from './CreateOrganizationTrackerModal'
import useCreateOrganizationTrackerFormState from './useCreateOrganizationTrackerFormState'
import useCreateOrganizationTrackerMutation from './useCreateOrganizationTrackerMutation'

const { USER_ROLE_OWNER: OWNER } = constants

const CreateOrganizationTrackerModalWithState = ({
    tracker,
    scanTracker,
    title,
    description,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const [createTracker] = useCreateOrganizationTrackerMutation({
        onCompleted,
        onError,
    })
    const formState = useCreateOrganizationTrackerFormState(
        scanTracker,
        tracker
    )
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateOrganizationTrackerModal
            title={title}
            description={description}
            scanTracker={scanTracker}
            initialVendor={tracker && tracker.vendor}
            canCreateVendor={currentUser.role === OWNER}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await createTracker({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Tracker created successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateOrganizationTrackerModalWithState
