/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import clsx from 'clsx'
import { cva } from 'cva'
import LoadingIndicator from './LoadingIndicator'
import Alert from './Alert'
import PageLoading from './PageLoading'

const sizeVariants = cva('mx-auto py-4', {
    variants: {
        size: {
            sm: 'max-w-3xl px-6 ',
            md: 'max-w-4xl px-6',
            default: 'max-w-7xl px-6',
            full: 'w-full px-6',
        },
    },
    defaultVariants: {
        size: 'default',
    },
})

const PageContent = ({
    centerContent = false,
    size = 'default',
    children,
    isFetching,
    error,
}) => {
    const shouldCenterContent = centerContent || isFetching || error
    return (
        <main
            className={clsx(
                'overflow-auto w-full flex-1 relative z-0 ',
                shouldCenterContent && 'flex items-center justify-center'
            )}
        >
            {(() => {
                if (isFetching) {
                    return <PageLoading showNavigation={false} />
                }
                if (error) {
                    return <Alert title="Error">{error.message}</Alert>
                }
                return (
                    <div className="min-w-[1024px]">
                        <div className={sizeVariants({ size })}>{children}</div>
                    </div>
                )
            })()}
        </main>
    )
}

export default PageContent
