/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import Portal from './Portal'
import useDragResize from '../hooks/useDragResize'

export default function DrawerStack({
    className,
    children,
    initialWidth = 650,
    canResize = true,
    ...props
}) {
    const ref = useRef()
    const { width, isDragging, handleDragStart } = useDragResize(ref, {
        initialWidth,
        isEnabled: canResize,
        reverse: true,
    })

    return (
        <Portal querySelector="#drawers">
            <motion.div
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                aria-modal="true"
                role="dialog"
                tabIndex="-1"
                className="fixed right-0 inset-y-0"
                {...props}
            >
                <div
                    ref={ref}
                    style={{ width: `${width}px` }}
                    className={clsx(
                        'relative bg-white border-l border-gray-200 h-full shadow-xl',
                        className
                    )}
                >
                    {canResize && (
                        <button
                            type="button"
                            className={clsx(
                                'absolute top-0 left-0 bottom-0 w-1 cursor-col-resize hover:bg-gray-300',
                                isDragging && 'bg-primary'
                            )}
                            onMouseDown={handleDragStart}
                        />
                    )}
                    <AnimatePresence mode="wait">{children}</AnimatePresence>
                </div>
            </motion.div>
        </Portal>
    )
}
