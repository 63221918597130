import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import AuditScansPageTableRow from './AuditScansPageTableRow'

const QUERY = gql`
    query auditScansPage($id: ID!, $after: String, $filters: ScanFilters) {
        audit(id: $id) {
            id
            name
            imageUrl
            scanFrequency
            isWeb
            isMobile
        }
        scans(after: $after, filters: $filters) {
            edges {
                node {
                    ...AuditScansPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${AuditScansPageTableRow.FRAGMENT}
`

const useAuditScansPageQuery = () => {
    const { id } = useParams()
    const filters = { auditId: id }
    const variables = { id, filters }
    const {
        data = {},
        error,
        loading,
        fetchMore,
        ...result
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'scans'
    )
    return {
        error,
        audit: data.audit || {},
        scans: connectionToCollection(data.scans),
        isFetching: loading,
        isFetchingMore,
        handleFetchMore,
        hasMore: !loading && !error && hasNextPage,
        ...result,
    }
}

export default useAuditScansPageQuery
