import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query createDomainPage($id: ID!) {
        audit(id: $id) {
            id
            name
        }
    }
`

const useCreateDomainPageQuery = () => {
    const { id } = useParams()
    const variables = { id }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        audit: data.audit || {},
        isFetching,
        refetch,
    }
}

export default useCreateDomainPageQuery
