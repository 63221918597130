import React, { useEffect } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import UploadSessionModal from './UploadSessionModal'
import useUploadSessionFormState from './useUploadSessionFormState'
import useUploadSessionMutation from './useUploadSessionMutation'

const useSelectedConsentProfile = (formState, consentProfiles = []) => {
    const selected = formState.values.cmpId || null
    const [first] = consentProfiles
    useEffect(() => {
        if (selected === null && first) {
            formState.handleChange('consentProfileId', first.id)
        }
    }, [first, selected])
    return selected
}

const UploadSessionModalWithState = ({ audit, onDismiss, onCompleted }) => {
    const formState = useUploadSessionFormState(audit)
    const [uploadSession, { loading }] = useUploadSessionMutation({
        onCompleted,
    })
    const { dispatchSuccess, dispatchError } = useNotifications()
    useSelectedConsentProfile(formState, audit.consentProfiles)
    return (
        <UploadSessionModal
            onDismiss={onDismiss}
            audit={audit}
            formState={formState}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { consentProfileId, harFile, deviceType } =
                            formState.values
                        const input = {
                            deviceType,
                            auditId: audit.id,
                            consentProfileId,
                            harFile,
                        }
                        const variables = { input }
                        await uploadSession({ variables })
                        // await addConsentProfile({ variables: { auditId: audit.id, input } })
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>New session added!</Trans>,
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
            isUploading={loading}
        />
    )
}

export default UploadSessionModalWithState
