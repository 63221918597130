import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

const UserInvitationsPageTableHeaderRow = () => (
    <TableHeaderRow>
        <TableHeaderCell columnId="email">
            <Trans>Email</Trans>
        </TableHeaderCell>
        <TableHeaderCell columnId="role">
            <Trans>Role</Trans>
        </TableHeaderCell>
        <TableHeaderCell columnId="status">
            <Trans>Status</Trans>
        </TableHeaderCell>
        <TableHeaderCell />
    </TableHeaderRow>
)

export default UserInvitationsPageTableHeaderRow
