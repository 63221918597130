import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const ScanTrackersPageTableRowActionButton = ({
    tracker,
    isLoading,
    canClassify,
    canDelete,
    canUpdate,
    onClassify,
    onDelete,
    onUpdate,
    children,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <>
                <DropdownMenuItem
                    iconName="tracker"
                    disabled={!canClassify}
                    onClick={(e) => {
                        onClassify()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Classify tracker</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={!canUpdate}
                    onClick={(e) => {
                        onUpdate(tracker)
                        close()
                        e.stopPropagation()
                    }}
                >
                    {tracker !== null && tracker.belongsToOrganization ? (
                        <Trans>Update your classification</Trans>
                    ) : (
                        <Trans>Change classification</Trans>
                    )}
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="x"
                    disabled={!canDelete}
                    onClick={(e) => {
                        onDelete()
                        close()
                        e.stopPropagation()
                    }}
                >
                    {tracker !== null && tracker.belongsToOrganization ? (
                        <Trans>Remove your classification</Trans>
                    ) : (
                        <Trans>Remove classification</Trans>
                    )}
                </DropdownMenuItem>
            </>
        )}
    >
        {children}
    </DropdownMenu>
)

export default ScanTrackersPageTableRowActionButton
