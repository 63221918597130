import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const RemoveConsentProfileModal = ({
    consentProfile,
    isLoading,
    onDismiss,
    onConfirm,
}) => (
    <ConfirmModal
        title={<Trans>Remove consent profile</Trans>}
        description={
            <Trans>
                Are you sure you want to remove{' '}
                <strong>{consentProfile.name}</strong> from this audit?
            </Trans>
        }
        confirmButtonText={<Trans>Remove consent profile</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default RemoveConsentProfileModal
