/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import * as constants from '../../constants'
import Icon from './Icon'

const {
    ALERT_SEVERITY_TYPE_ISSUE: ISSUE,
    ALERT_SEVERITY_TYPE_CRITICAL: CRITICAL,
    ALERT_SEVERITY_TYPE_WARNING: WARNING,
} = constants

const AlertIcon = ({ type, alert, size = 'xs', className, ...props }) => {
    let iconName = 'warning-triangle'
    let textClassName = 'text-gray-600'
    if (type === 'critical' || alert?.severityType === CRITICAL) {
        textClassName = 'text-red-600'
        iconName = 'warning-hexagon'
    }
    if (type === 'issue' || alert?.severityType === ISSUE) {
        textClassName = 'text-amber-600'
        iconName = 'warning-triangle'
    }
    if (type === 'warning' || alert?.severityType === WARNING) {
        textClassName = 'text-gray-500'
        iconName = 'warning-circle'
    }
    return (
        <Icon
            name={iconName}
            size={size}
            className={clsx(textClassName, className)}
            {...props}
        />
    )
}

export default AlertIcon
