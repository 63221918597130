import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageHeader from '../../layout/PageHeader'

const StaffOrganizationsPageHeader = ({ onCreateOrganization }) => (
    <PageHeader
        title={<Trans>Organizations</Trans>}
        className="mb-4"
        rightChildren={
            <Button
                onClick={onCreateOrganization}
                iconBeforeProps={{ name: 'plus' }}
                size="sm"
            >
                <Trans>Create organization</Trans>
            </Button>
        }
    />
)

export default StaffOrganizationsPageHeader
