import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import useAppState from '../../hooks/useAppState'
import SearchInput from '../../utils/SearchInput'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'
import Select from '../../utils/Select'
import PurposesSelect from './PurposesSelect'
import ClassifyCookiesModalWithState from './ClassifyCookiesModalWithState'
import UpdateCookiesModalWithState from './UpdateCookiesModalWithState'

const NewIdentifiedCookiesPageActions = ({
    search,
    audits,
    purposes,
    selectedAuditId,
    selectedPurposeId,
    scanRangeState,
    onChangeAudit,
    onChangePurpose,
    onSearch,
    onClassifyCompleted,
    onUpdateCompleted,
}) => {
    const {
        isSelectingRows,
        startSelectingRows,
        stopSelectingRows,
        selectedRows,
    } = useTableState()
    const { currentUser } = useAppState()
    const [classifyModalIsOpen, setClassifyModalIsOpen] = useState(false)
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)

    return (
        <>
            <TableActionsContainer isSticky>
                {isSelectingRows && (
                    <div className="flex flex-row gap-2">
                        <Button
                            type="white"
                            size="sm"
                            onClick={() => setClassifyModalIsOpen(true)}
                        >
                            <Trans>Classify</Trans>
                        </Button>
                        <Button
                            type="white"
                            size="sm"
                            onClick={() => setUpdateModalIsOpen(true)}
                        >
                            <Trans>Update Classification</Trans>
                        </Button>
                        <Button
                            type="white"
                            size="sm"
                            onClick={() => stopSelectingRows()}
                        >
                            <Trans>Cancel</Trans>
                        </Button>
                    </div>
                )}
                {!isSelectingRows && (
                    <div className="flex justify-between w-full">
                        <div className="flex gap-2 items-center">
                            {currentUser.isStaff && (
                                <Button
                                    type="white"
                                    size="sm"
                                    iconBeforeProps={{
                                        name: 'check-done-01',
                                        size: 'sm',
                                    }}
                                    onClick={() => startSelectingRows()}
                                />
                            )}

                            <SearchInput value={search} onChange={onSearch} />
                            <Select
                                className="w-[140px]"
                                onChange={(e) => onChangeAudit(e.target.value)}
                                value={selectedAuditId}
                            >
                                <option value="ALL">All audits</option>
                                {audits.map(({ id, name }) => (
                                    <option value={id} key={id}>
                                        {name}
                                    </option>
                                ))}
                            </Select>
                            <PurposesSelect
                                purposes={purposes}
                                className="w-[140px]"
                                onChange={(e) =>
                                    onChangePurpose(e.target.value)
                                }
                                value={selectedPurposeId}
                            />
                        </div>
                        <div>
                            <ScanRangeSelect
                                onChange={scanRangeState.onChangeDate}
                                period={scanRangeState.selectedPeriod}
                                startDate={scanRangeState.startDate}
                                endDate={scanRangeState.endDate}
                            />
                        </div>
                    </div>
                )}
            </TableActionsContainer>
            {classifyModalIsOpen && (
                <ClassifyCookiesModalWithState
                    scanCookies={selectedRows.map((row) => row.name)}
                    onDismiss={() => setClassifyModalIsOpen(false)}
                    onCompleted={onClassifyCompleted}
                />
            )}
            {updateModalIsOpen && (
                <UpdateCookiesModalWithState
                    scanCookies={selectedRows.map((row) => row.name)}
                    onDismiss={() => setUpdateModalIsOpen(false)}
                    onCompleted={onUpdateCompleted}
                />
            )}
        </>
    )
}

export default NewIdentifiedCookiesPageActions
