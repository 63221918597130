/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import SelectListCreateInput from './SelectListCreateInput'
import SelectListOption from './SelectListOption'
import SelectList from './SelectList'
import FormSelectList from './FormSelectList'
import Tag from './Tag'

const TagSelect = ({ value = [], errors = [], onChange }) => {
    const [newTag, setNewTag] = useState('')
    const [hasError, setHasError] = useState('')
    return (
        <FormSelectList errors={errors}>
            <SelectList
                className="h-[200px]"
                searchInput={
                    <SelectListCreateInput
                        value={newTag}
                        hasError={hasError}
                        onChange={(e) => setNewTag(e.target.value)}
                        onAdd={(e) => {
                            e.preventDefault()
                            if (newTag !== '' && !value.includes(newTag)) {
                                onChange([...value, newTag])
                                setHasError(false)
                                setNewTag('')
                            } else {
                                setHasError(true)
                            }
                        }}
                        buttonText={<Trans>Tag</Trans>}
                    />
                }
            >
                {value.map((tag) => (
                    <SelectListOption
                        key={tag}
                        canRemove
                        onRemove={() => {
                            onChange(value.filter((v) => v !== tag))
                        }}
                    >
                        <Tag>{tag}</Tag>
                    </SelectListOption>
                ))}
            </SelectList>
        </FormSelectList>
    )
}

export default TagSelect
