import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createCookie($input: CookieInput!) {
        createCookie(input: $input) {
            cookie {
                id
                name
            }
        }
    }
`

const useCreateCookieMutation = (options) => useMutation(MUTATION, options)

export default useCreateCookieMutation
