import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isValidEmail } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'email',
        validate: isValidEmail,
        message: <Trans>The email field is required</Trans>,
    },
])

const useResetPasswordFormState = () =>
    useFormState(
        {
            email: '',
        },
        {
            validation,
        }
    )

export default useResetPasswordFormState
