import React from 'react'
import { Trans } from '@lingui/macro'
import HeaderContainer from './HeaderContainer'
import HeaderMenu from './HeaderMenu'
import HeaderMenuItem from './HeaderMenuItem'
import * as constants from '../../constants'
import HeaderProfileWithState from './HeaderProfileWithState'
import HeaderLogo from './HeaderLogo'
import useAppState from '../hooks/useAppState'
import OrganizationHeaderSwitcher from './OrganizationHeaderSwitcher'
import OrganizationHeaderMenu from './OrganizationHeaderMenu'

export default function OrganizationHeader() {
    const { currentUser } = useAppState()
    const { currentOrganization = {} } = currentUser
    return (
        <>
            <HeaderContainer>
                <div className="flex items-center w-96">
                    <HeaderLogo />
                    <div className="border-l h-4 border-slate-300 mx-4" />
                    <OrganizationHeaderSwitcher
                        iconRight
                        showName
                        currentOrganization={currentOrganization}
                    />
                </div>
                <OrganizationHeaderMenu
                    className="hidden lg:flex"
                    currentUser={currentUser}
                />
                <div className="flex justify-end w-96">
                    <HeaderProfileWithState />
                </div>
            </HeaderContainer>
            <HeaderContainer className="lg:hidden">
                <div />
                <OrganizationHeaderMenu currentUser={currentUser} />
                <div />
            </HeaderContainer>
        </>
    )
}
