import React from 'react'
import * as constants from '../../constants'
import Select from './Select'
import Input from './Input'

const {
    WAIT_FOR_NAVIGATION,
    CLICK_XPATH_SELECTOR,
    WAIT_FOR_XPATH_SELECTOR,
    WAIT_FOR_COOKIE_NAME,
    ENTER_EMAIL,
    ENTER_PASSWORD,
    FOCUS_NEW_TAB,
} = constants

const getPlaceholderText = (type) => {
    if (type === WAIT_FOR_NAVIGATION) {
        return ''
    }
    if (type === ENTER_EMAIL) {
        return 'User email'
    }
    if (type === ENTER_PASSWORD) {
        return 'User password'
    }
    if (type === WAIT_FOR_COOKIE_NAME) {
        return 'Cookie name'
    }
    if (type === WAIT_FOR_XPATH_SELECTOR || type === CLICK_XPATH_SELECTOR) {
        return 'Specify the xpath selector'
    }
    if (type === FOCUS_NEW_TAB) {
        return 'Go to new tab'
    }
    return 'Specify the css selector'
}

export default function ActionSelectorInput({
    type,
    selector,
    onChangeType,
    onChangeSelector,
    options,
    name,
}) {
    const noInput = type === WAIT_FOR_NAVIGATION || type === FOCUS_NEW_TAB
    return (
        <div className="flex flex-1">
            <Select
                selectClassName="rounded-r-none border-r-0 "
                value={type}
                onChange={(e) => onChangeType(e.target.value)}
            >
                {Object.entries(options).map(([actionType, label]) => (
                    <option key={actionType} value={actionType}>
                        {label}
                    </option>
                ))}
            </Select>
            <Input
                className="flex-1"
                inputClassName="rounded-l-none "
                placeholder={getPlaceholderText(type)}
                disabled={noInput}
                value={selector}
                onChange={(e) => onChangeSelector(e.target.value)}
                name={name}
            />
        </div>
    )
}
