import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import CURRENT_USER_FRAGMENT from '../../../apollo/fragments/currentUser'

export const CHOOSE_PASSWORD_MUTATION = gql`
    mutation choosePassword($input: ChoosePasswordInput!) {
        choosePassword(input: $input) {
            accessToken
            refreshToken
            me {
                ...CurrentUser
            }
        }
    }
    ${CURRENT_USER_FRAGMENT}
`

export default function useChoosePasswordMutation() {
    return useMutation(CHOOSE_PASSWORD_MUTATION)
}
