import React from 'react'
import Table from '../../utils/Table'
import VendorTrackersPageTableHeaderRow from './VendorTrackersPageTableHeaderRow'
import VendorTrackersPageTableRow from './VendorTrackersPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'

const VendorTrackersPageTable = ({
    trackers = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table data-testid="VendorTrackersPageTable" isFetching={isFetching}>
            <thead>
                <VendorTrackersPageTableHeaderRow />
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {trackers.map((tracker) => (
                    <VendorTrackersPageTableRow
                        key={tracker.id}
                        tracker={tracker}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default VendorTrackersPageTable
