import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteVendorTableItemModal = ({ isLoading, onDismiss, onConfirm }) => (
    <ConfirmModal
        title={<Trans>Remove Vendor from list</Trans>}
        description={
            <Trans>
                Are you sure you want to remove this Vendor from your
                Vendortable?
            </Trans>
        }
        confirmButtonText={<Trans>Remove vendor</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteVendorTableItemModal
