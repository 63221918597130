import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this Vendor table version.</Trans>,
    },
])

const useCreateVendorTableVersionFormState = () => {
    const formState = useFormState({ name: '', audits: [] }, { validation })
    return formState
}

export default useCreateVendorTableVersionFormState
