/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'
import Button from '../../utils/Button'
import Card from '../../utils/Card'
import RadioButton from '../../utils/RadioButton'
import CardHeader from '../../utils/CardHeader'
import FormButtonGroup from '../../utils/FormButtonGroup'
import SelectListOption from '../../utils/SelectListOption'
import SelectList from '../../utils/SelectList'
import FormSelectList from '../../utils/FormSelectList'
import MultiInput from '../../utils/MultiInput'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import { CmpResolverActionLabels } from '../settings-consent-profiles/CreateConsentProfileModal'

const UpdateConsentProfileForm = ({
    cmps,
    purposes,
    formState,
    isLoading,
    onSubmit,
}) => {
    const { values, getNativeInputProps, getErrorMessages, handleChange } =
        formState
    const { cmpId, type } = values
    const customCmp = cmps.find(({ externalId }) => externalId === 'custom')
    const customCmpId = customCmp && customCmp.id
    return (
        <Card>
            <form>
                <CardHeader
                    title={<Trans>Consent Profile</Trans>}
                    description={
                        <Trans>
                            A consent profile represents a specific set of user
                            choices. You can reject all purposes, accept all
                            purposes, close the CMP without a choice (No
                            consent) or set a specific combination of individual
                            purposes (Custom).
                        </Trans>
                    }
                />
                <div className="divide-y">
                    <FormGroup label={<Trans>Name</Trans>} isHorizontal>
                        <FormInput
                            errors={getErrorMessages('name')}
                            {...getNativeInputProps('name')}
                        />
                    </FormGroup>
                    <FormGroup label={<Trans>Type</Trans>} isHorizontal>
                        <RadioButton.Group>
                            <RadioButton
                                value="NO_CONSENT"
                                checked={type === 'NO_CONSENT'}
                                onChange={() => formState.handleNoConsent()}
                            >
                                <Trans>No consent</Trans>
                            </RadioButton>
                            <RadioButton
                                value="REJECT_ALL"
                                checked={type === 'REJECT_ALL'}
                                onChange={() => formState.handleRejectAll()}
                            >
                                <Trans>Reject all</Trans>
                            </RadioButton>
                            <RadioButton
                                value="ACCEPT_ALL"
                                checked={type === 'ACCEPT_ALL'}
                                onChange={() => formState.handleAcceptAll()}
                            >
                                <Trans>Accept all</Trans>
                            </RadioButton>
                            <RadioButton
                                value="CUSTOM"
                                checked={type === 'CUSTOM'}
                                onChange={() => formState.handleCustom()}
                            >
                                <Trans>Custom</Trans>
                            </RadioButton>
                        </RadioButton.Group>
                    </FormGroup>
                    <FormGroup label={<Trans>CMP</Trans>} isHorizontal>
                        <FormSelect
                            id="role"
                            errors={getErrorMessages('cmpId')}
                            {...getNativeInputProps('cmpId')}
                        >
                            {cmps.map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </FormSelect>
                        <div className="mt-1 text-sm text-gray-500">
                            <Trans>
                                If you don’t find the right vendor in this
                                dropdown menu, please contact support.
                            </Trans>
                        </div>
                    </FormGroup>
                    {type === 'CUSTOM' && cmpId !== customCmpId && (
                        <FormGroup
                            label={<Trans>CMP purpose IDs</Trans>}
                            isHorizontal
                        >
                            <MultiInput
                                onAdd={() => formState.handleAddCustomPurpose()}
                            >
                                {values.customPurposes.map((purpose, index) => (
                                    <MultiInput.Item
                                        key={index}
                                        {...getNativeInputProps(
                                            `customPurposes.${index}`
                                        )}
                                        onRemove={() =>
                                            formState.handleRemoveCustomPurpose(
                                                index
                                            )
                                        }
                                    />
                                ))}
                            </MultiInput>
                        </FormGroup>
                    )}
                    {cmpId === customCmpId && (
                        <FormGroup
                            label={<Trans>Custom Navigation Actions</Trans>}
                            className="py-4"
                        >
                            <MultiInput
                                onAdd={() =>
                                    formState.handleAddCmpResolverAction()
                                }
                            >
                                {values.cmpResolverActions.map(
                                    (action, index) => {
                                        const isActionSelected =
                                            action && action.type
                                        return (
                                            <div
                                                key={index}
                                                className="z-10 mb-4 flex items-center rounded-md border border-gray-100/50 bg-white p-4 px-6 py-5 text-left shadow hover:border-gray-300/50"
                                            >
                                                <DropdownMenu
                                                    as="div"
                                                    position="top-start"
                                                    className="z-10 bg-white"
                                                    content={
                                                        <>
                                                            {Object.entries(
                                                                CmpResolverActionLabels
                                                            ).map(
                                                                ([
                                                                    actionType,
                                                                    label,
                                                                ]) => (
                                                                    <DropdownMenuItem
                                                                        key={
                                                                            actionType
                                                                        }
                                                                        onClick={() =>
                                                                            handleChange(
                                                                                `cmpResolverActions.${index}.type`,
                                                                                actionType
                                                                            )
                                                                        }
                                                                    >
                                                                        <Trans>
                                                                            {
                                                                                label
                                                                            }
                                                                        </Trans>
                                                                    </DropdownMenuItem>
                                                                )
                                                            )}
                                                        </>
                                                    }
                                                >
                                                    <Button
                                                        type="gray"
                                                        grouped="left"
                                                        className="w-48 whitespace-nowrap p-2"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        {isActionSelected
                                                            ? CmpResolverActionLabels[
                                                                  action.type
                                                              ]
                                                            : 'Action ▼'}
                                                    </Button>
                                                </DropdownMenu>
                                                <MultiInput.Item
                                                    key={index}
                                                    grouped="right"
                                                    {...getNativeInputProps(
                                                        `cmpResolverActions.${index}.selector`
                                                    )}
                                                    onRemove={() =>
                                                        formState.handleRemoveCmpResolverAction(
                                                            index
                                                        )
                                                    }
                                                />
                                            </div>
                                        )
                                    }
                                )}
                            </MultiInput>
                        </FormGroup>
                    )}
                    <FormGroup
                        label={<Trans>Webclew Purposes</Trans>}
                        isHorizontal
                    >
                        <FormSelectList disabled={type !== 'CUSTOM'}>
                            <SelectList>
                                {purposes.map(({ id, name }) => (
                                    <SelectListOption
                                        key={id}
                                        disabled
                                        isCheckbox
                                        checked={formState.values.purposeIds.includes(
                                            id
                                        )}
                                        onClick={() =>
                                            formState.handleChangePurpose(id)
                                        }
                                    >
                                        {name}
                                    </SelectListOption>
                                ))}
                            </SelectList>
                        </FormSelectList>
                    </FormGroup>
                </div>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                        type="primary"
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                    <Link to="/settings/consent-profiles">
                        <Button type="white">
                            <Trans>Cancel</Trans>
                        </Button>
                    </Link>
                </FormButtonGroup>
            </form>
        </Card>
    )
}

export default UpdateConsentProfileForm
