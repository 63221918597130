import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function VendorsPageTableHeaderRow() {
    return (
        <TableHeaderRow isSticky stickyPosition="below-actions">
            <TableHeaderCell
                columnId="name"
                canResize
                canSort
                initialWidth={300}
            >
                <Trans>Name & Domain</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="types"
                canHide
                canResize
                initialWidth={200}
            >
                <Trans>Types</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="cookiesCount"
                canResize
                canHide
                canSort
                initialWidth={120}
            >
                <Trans>Cookies</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="trackersCount"
                canResize
                canHide
                canSort
                initialWidth={130}
            >
                <Trans>Trackers</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="occurrence"
                canResize
                canHide
                canSort
                initialWidth={150}
            >
                <Trans>Occurrence</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="description"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={200}
            >
                <Trans>Description</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="privacyPolicyUrl"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={200}
            >
                <Trans>Privacy policy</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="externalId"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={150}
            >
                <Trans>External ID</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="domain"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={150}
            >
                <Trans>Domain</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="createdAt"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={150}
                canSort
            >
                <Trans>Created at</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="updatedAt"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={150}
                canSort
            >
                <Trans>Updated at</Trans>
            </TableHeaderCell>
            <TableHeaderCell />
        </TableHeaderRow>
    )
}
