import React from 'react'
import clsx from 'clsx'
import Button from '../../utils/Button'

export default function AuditsPageActionsViewButtonGroup({ value, onChange }) {
    return (
        <div className="flex items-center">
            <Button
                type="white"
                grouped="left"
                iconBeforeProps={{
                    name: 'grid',
                    size: 'sm',
                    color: 'none',
                    hasText: false,
                    className: clsx(
                        value === 'GRID' ? 'text-gray-800' : 'text-gray-500'
                    ),
                }}
                onClick={() => onChange('GRID')}
                className={clsx(value === 'GRID' && 'bg-gray-50')}
            />
            <Button
                type="white"
                grouped="right"
                iconBeforeProps={{
                    name: 'lines',
                    size: 'sm',
                    color: 'none',
                    hasText: false,
                    className: clsx(
                        value === 'LIST' ? 'text-gray-800' : 'text-gray-500'
                    ),
                }}
                onClick={() => onChange('LIST')}
                className={clsx(value === 'LIST' && 'bg-gray-50')}
            />
        </div>
    )
}
