import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import StaffOrganizationUsersPageTableRow from './StaffOrganizationUsersPageTableRow'

const QUERY = gql`
    query staffOrganizationUsersPage($after: String) {
        staffOrganizationUsers(after: $after) {
            edges {
                cursor
                node {
                    ...StaffOrganizationUsersPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${StaffOrganizationUsersPageTableRow.FRAGMENT}
`

const useStaffOrganizationUsersPageQuery = () => {
    const variables = {}
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'staffOrganizationUsers'
    )
    return {
        error,
        users: connectionToCollection(data.staffOrganizationUsers),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useStaffOrganizationUsersPageQuery
