import clsx from 'clsx'
import React from 'react'
import TableLoading from './TableLoading'

const Table = ({
    children,
    className,
    hasMinWidth = true,
    isFullWidth = false,
    isRounded = false,
    hasHeader = true,
    isFetching,
    ...props
}) => (
    <div
        className={clsx(
            hasMinWidth && 'overflow-x-auto overflow-y-auto',
            isFullWidth && '-mx-6 border-t border-slate-200',
            !isFullWidth && 'border border-slate-200 shadow-xs',
            hasHeader && 'border-t-0',
            isRounded && 'overflow-hidden rounded-lg',
            className
        )}
        {...props}
    >
        {!isFetching && (
            <table
                className={clsx(
                    'relative w-full',
                    hasMinWidth && 'min-w-[800px]'
                )}
            >
                {children}
            </table>
        )}
        {isFetching && <TableLoading />}
    </div>
)

export default Table
