import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import AuditsPageTableRow from '../audits/AuditsPageTableRow'

export const CREATE_AUDIT_MUTATION = gql`
    mutation createAudit($input: CreateAuditInput!) {
        createAudit(input: $input) {
            audit {
                ...AuditsPageTableRow
            }
        }
    }
    ${AuditsPageTableRow.FRAGMENT}
`

const useCreateAuditMutation = () => useMutation(CREATE_AUDIT_MUTATION)

export default useCreateAuditMutation
