import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../utils/PageHeader'
import Tabs, { TabLink } from '../../utils/Tabs'

const SettingsPageHeader = () => (
    <>
        <PageHeader title={<Trans>Settings</Trans>} />
        <Tabs>
            <TabLink exact to="/settings">
                <Trans>My Organization</Trans>
            </TabLink>
            <TabLink to="/settings/vendor-tables">
                <Trans>Vendor tables</Trans>
            </TabLink>
            <TabLink to="/settings/cookie-tables">
                <Trans>Cookie tables</Trans>
            </TabLink>
            <TabLink to="/settings/consent-profiles">
                <Trans>Consent profiles</Trans>
            </TabLink>
            <TabLink to="/settings/login-profiles">
                <Trans>Login profiles</Trans>
            </TabLink>
            <TabLink to="/settings/vendors">
                <Trans>Known vendors</Trans>
            </TabLink>
            <TabLink to="/settings/cookies">
                <Trans>Known cookies</Trans>
            </TabLink>
            <TabLink to="/settings/trackers">
                <Trans>Known trackers</Trans>
            </TabLink>
        </Tabs>
    </>
)

export default SettingsPageHeader
