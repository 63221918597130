import React, { useState } from 'react'
import gql from 'graphql-tag'
import { format, addDays } from 'date-fns'
import { useQuery } from '@apollo/client'
import DashboardPageAlertsChart from './DashboardPageAlertsChart'
import * as constants from '../../../constants'

const { DATE_FORMAT_GRAPHQL_DATE: FORMAT } = constants

const QUERY = gql`
    query alertsPage($auditId: ID, $filters: AlertFilters) {
        alertsByDayChart(auditId: $auditId, filters: $filters) {
            startDate
            warningAlerts
            issueAlerts
            criticalAlerts
        }
    }
`

const DashboardPageAlertsChartWithState = ({ startDate, endDate }) => {
    const filters = { startDate, endDate }
    const variables = { filters }
    const { data = {}, loading: isFetching } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return (
        <DashboardPageAlertsChart
            isFetching={isFetching}
            alertsByDayChartData={data.alertsByDayChart || []}
        />
    )
}

export default DashboardPageAlertsChartWithState
