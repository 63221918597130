import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import BulkUploadModal from '../staff-vendors/BulkUploadModal'
import useBulkUploadCookiesFormState from './useBulkUploadCookiesFormState'
import useBulkUploadValidateCookiesMutation from '../staff-vendors/useBulkUploadValidateCookiesMutation'
import useBulkUploadUpdateCookiesMutation from './useBulkUploadUpdateCookiesMutation'
import useBulkUploadCreateCookiesMutation from './useBulkUploadCreateCookiesMutation'

const CookiesBulkUploadModalWithState = ({ onDismiss }) => {
    const formState = useBulkUploadCookiesFormState()
    const [updateCookies] = useBulkUploadUpdateCookiesMutation()
    const [createCookies] = useBulkUploadCreateCookiesMutation()
    const [validateCookies, { loading, data, reset }] =
        useBulkUploadValidateCookiesMutation()
    const [isFileValidated, setIsFileValidated] = useState(false)
    const { dispatchSuccess, dispatchError } = useNotifications()
    return (
        <BulkUploadModal
            isValidated={isFileValidated}
            alertRecords={data?.validateCookies}
            formState={formState}
            onReset={() => {
                setIsFileValidated(false)
                reset()
                formState.resetForm()
            }}
            onDismiss={onDismiss}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const {
                            action,
                            csvFile: csvUploadFile,
                            csvPasteFile,
                        } = formState.values
                        let csvFile = csvUploadFile
                        if (csvPasteFile !== '') {
                            csvFile = new Blob([csvPasteFile], {
                                type: 'text/csv',
                            })
                        }
                        const input = { action, csvFile }
                        const variables = { input }
                        if (!isFileValidated) {
                            validateCookies({ variables })
                            setIsFileValidated(true)
                        }
                        if (data?.validateCookies.errors.length === 0) {
                            if (action === 'UPDATE') {
                                await updateCookies({ variables })
                                setIsFileValidated(false)
                                dispatchSuccess({
                                    message: (
                                        <Trans>All Cookies are updated</Trans>
                                    ),
                                })
                                onDismiss()
                            } else if (action === 'CREATE') {
                                await createCookies({ variables })
                                setIsFileValidated(false)
                                dispatchSuccess({
                                    message: (
                                        <Trans>All Vendors are created</Trans>
                                    ),
                                })
                                onDismiss()
                            } else {
                                dispatchError({
                                    message: (
                                        <Trans>
                                            Oops, Something went wrong
                                        </Trans>
                                    ),
                                })
                            }
                        }
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
            isUploading={loading}
        />
    )
}

export default CookiesBulkUploadModalWithState
