import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import TableStateProvider from '../../providers/TableStateProvider'
import ReportsPageTableActions from './ReportsPageTableActions'
import ReportsPageCreateReportButtonWithState from './ReportsPageCreateReportButtonWithState'
import ReportsPageTable from './ReportsPageTable'

export default function ReportsPage({
    hasMoreRecords,
    isFetching,
    isFetchingMore,
    isLoading,
    onFetchMore,
    onDeleteReport,
    onDeleteReports,
    onReportCreated,
    reports,
    onReportRecreated,
    onChangeOrderBy,
    initialOrderBy,
}) {
    return (
        <PageContainer data-testid="ReportsPage">
            <OrganizationHeader />
            <SidebarContainer>
                <PageContent isFetching={isFetching} size="full">
                    <PageHeader
                        title={<Trans>Reports</Trans>}
                        description={
                            <Trans>
                                Download Excel reports of observed cookies,
                                requests trackers and pages.
                            </Trans>
                        }
                        rightChildren={
                            <ReportsPageCreateReportButtonWithState
                                onReportCreated={onReportCreated}
                            />
                        }
                    />
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <ReportsPageTableActions
                            onDeleteReports={onDeleteReports}
                        />
                        {reports.length > 0 ? (
                            <ReportsPageTable
                                reports={reports}
                                isFetchingMore={isFetchingMore}
                                onFetchMore={onFetchMore}
                                hasMoreRecords={hasMoreRecords}
                                isLoading={isLoading}
                                onDeleteReport={onDeleteReport}
                                onDeleteReports={onDeleteReports}
                                onReportRecreated={onReportRecreated}
                            />
                        ) : (
                            <EmptyPageCard
                                iconName="report"
                                title={<Trans>No reports created</Trans>}
                                description={
                                    <Trans>
                                        No reports were found for this
                                        organization.
                                        <br />
                                        Start by adding new reports here.
                                    </Trans>
                                }
                            >
                                <ReportsPageCreateReportButtonWithState
                                    onReportCreated={onReportCreated}
                                />
                            </EmptyPageCard>
                        )}
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
