import React from 'react'
import PageHeader from '../../utils/PageHeader'
import OrganizationHeader from '../../layout/OrganizationHeader'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import SidebarContainer from '../../layout/SidebarContainer'

const GlossaryPage = () => (
    <PageContainer data-testid="GlossaryPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent>
                <PageHeader
                    title="Glossary"
                    breadcrumbsItems={[
                        {
                            text: 'Glossary',
                            to: '/glossary',
                        },
                    ]}
                />
                <EmptyPageCard
                    iconName="book"
                    title="Coming soon..."
                    description="Stay tuned, we are in the process of adding this page."
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default GlossaryPage
