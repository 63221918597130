import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createVendor($input: OrganizationVendorInput!) {
        createOrganizationVendor(input: $input) {
            vendor {
                id
                name
            }
        }
    }
`

const useCreateOrganizationVendorMutation = (options) =>
    useMutation(MUTATION, options)

export default useCreateOrganizationVendorMutation
