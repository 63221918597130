import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import PurposesTags from '../../utils/PurposesTags'
import Button from '../../utils/Button'
import DrawerDetailsItem, {
    DrawerDetailsItemText,
} from '../../utils/DrawerDetailsItem'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import Tabs, { Tab } from '../../utils/Tabs'
import TrackersPageActionsDropdownMenu from './TrackersPageActionsDropdownMenu'

const QUERY = gql`
    query trackerDetailsDrawer($id: ID!) {
        tracker(id: $id) {
            id
            purposes {
                id
                name
            }
            domain
            description
            vendor {
                id
                name
                imageUrl
            }
        }
    }
`

export default function TrackerDetailsDrawer({ trackerId }) {
    const variables = { id: trackerId }
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    const { id, domain, purposes, description, vendor } = data.tracker
    return (
        <>
            <DrawerHeader
                avatarSrc={vendor?.imageUrl}
                avatarIconName="tracker"
                title={domain}
                afterTitle={<PurposesTags purposes={purposes} />}
                description={description}
                buttons={
                    <TrackersPageActionsDropdownMenu
                        tracker={data.tracker}
                        onDeleteTrackerCompleted={() => refetch()}
                        onUpdateTrackerCompleted={() => refetch()}
                    >
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{
                                name: 'menu-dots',
                                size: 'sm',
                            }}
                        />
                    </TrackersPageActionsDropdownMenu>
                }
            />
            <Tabs className="px-6">
                <Tab isActive>
                    <Trans>Details</Trans>
                </Tab>
            </Tabs>
            <DrawerContent className="flex flex-col gap-4">
                <DrawerDetailsItem isHorizontal label={<Trans>Types</Trans>}>
                    <PurposesTags purposes={purposes} />
                </DrawerDetailsItem>
                <DrawerDetailsItemText
                    isHorizontal
                    truncate={false}
                    label={<Trans>Description</Trans>}
                >
                    {description}
                </DrawerDetailsItemText>
            </DrawerContent>
        </>
    )
}
