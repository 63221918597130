import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteCmpModal from './DeleteCmpModal'
import useDeleteCustomCmpMutation from './useDeleteCustomCmpMutation'

const DeleteCmpModalWithState = ({ cmp, onDismiss, onCompleted, onError }) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteCustomCmp, { loading }] = useDeleteCustomCmpMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteCmpModal
            onDismiss={onDismiss}
            cmp={cmp}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { id: cmp.id }
                    await deleteCustomCmp({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{cmp.name} successfully removed.</Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteCmpModalWithState
