import React, { useState } from 'react'
import gql from 'graphql-tag'
import { format, addDays } from 'date-fns'
import { useQuery } from '@apollo/client'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import DashboardPageAlertsByVendorChart from './DashboardPageAlertsByVendorChart'
import * as constants from '../../../constants'

const { DATE_FORMAT_GRAPHQL_DATE: FORMAT } = constants

const QUERY = gql`
    query alertsByAuditChart($filters: AlertFilters) {
        alertsByVendorChart(filters: $filters) {
            id
            vendor {
                ...PopoverVendorInfo
            }
            alertCount
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const DashboardPageAlertsByVendorChartWithState = ({ startDate, endDate }) => {
    const filters = { startDate, endDate }
    const variables = { filters }
    const { data = {}, loading: isFetching } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return (
        <DashboardPageAlertsByVendorChart
            isFetching={isFetching}
            chartData={data.alertsByVendorChart || []}
            startDate={startDate}
            endDate={endDate}
        />
    )
}

export default DashboardPageAlertsByVendorChartWithState
