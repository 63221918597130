import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from '../../utils/ConfirmModal'
import CreateOrganizationForm from './CreateOrganizationForm'

const CreateOrganizationModal = ({
    cmps,
    isLoading,
    onDismiss,
    onSubmit,
    formState,
}) => (
    <Modal
        onDismiss={onDismiss}
        onConfirm={onSubmit}
        title={<Trans>Create organization</Trans>}
        isLoading={isLoading}
        type="primary"
        confirmButtonText={<Trans>Create</Trans>}
    >
        <CreateOrganizationForm cmps={cmps} formState={formState} />
    </Modal>
)

export default CreateOrganizationModal
