import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import StaffOrganizationsPageTableRowActionButton from './StaffOrganizationsPageTableRowActionButton'
import ClearIdentifiedPeriodsModal from './ClearIdentifiedPeriodsModal'
import DeleteOrganizationModal from './DeleteOrganizationModal'

const { USER_TYPE_STAFF: STAFF } = constants

const StaffOrganizationsPageTableRowActionButtonWithState = ({
    organization,
    onDeleteCompleted,
    onClearIdentifiedPeriodsCompleted,
}) => {
    const [isClearing, setIsClearing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const history = useHistory()
    const { currentUser } = useAppState()
    return (
        <>
            {isClearing && (
                <ClearIdentifiedPeriodsModal
                    organization={organization}
                    onDismiss={() => setIsClearing(false)}
                    onCompleted={onClearIdentifiedPeriodsCompleted}
                />
            )}
            {isDeleting && (
                <DeleteOrganizationModal
                    organization={organization}
                    onDismiss={() => setIsDeleting(false)}
                    onCompleted={onDeleteCompleted}
                />
            )}
            <StaffOrganizationsPageTableRowActionButton
                organization={organization}
                canDelete={currentUser.type === STAFF}
                onClearIdentifiedPeriods={() => setIsClearing(true)}
                onDelete={() => setIsDeleting(true)}
            />
        </>
    )
}

export default StaffOrganizationsPageTableRowActionButtonWithState
