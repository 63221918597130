import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

export const STAFF_ASSIGN_ORGANIZATIONS_TO_USER_MUTATION = gql`
    mutation staffAssignOrganizationsToUser(
        $input: StaffAssignOrganizationsToUserInput!
    ) {
        staffAssignOrganizationsToUser(input: $input)
    }
`

export function useStaffAssignOrganizationsToUserMutation(options) {
    return useMutation(STAFF_ASSIGN_ORGANIZATIONS_TO_USER_MUTATION, options)
}

const STAFF_ORGANIZATIONS_SEARCH_QUERY = gql`
    query staffOrganizationsSearch($after: String, $search: String) {
        staffOrganizations(after: $after, search: $search) {
            edges {
                cursor
                node {
                    id
                    name
                    imageUrl
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export function useStaffOrganizationsSearchQuery({ search }) {
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(STAFF_ORGANIZATIONS_SEARCH_QUERY, {
        variables: { search },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'staffOrganizations'
    )
    return {
        error,
        organizations: connectionToCollection(data.staffOrganizations),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

const USER_ORGANIZATIONS_QUERY = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            organizations {
                id
                organization {
                    id
                }
            }
        }
    }
`

export function useUserOrganizationsQuery({ id }) {
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(USER_ORGANIZATIONS_QUERY, {
        variables: { id },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        userOrganizations: data.user?.organizations || [],
        isFetching,
    }
}
