import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function AuditSessionsPageTableHeaderRow() {
    return (
        <TableHeaderRow isSticky>
            <TableHeaderCell columnId="session" initialWidth={200}>
                <Trans>Session</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="consentProfile" initialWidth={100}>
                <Trans>Consent Profile</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="uploaded" initialWidth={120}>
                <Trans>Upploaded</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="duration" initialWidth={100}>
                <Trans>Duration</Trans>
            </TableHeaderCell>
            <TableHeaderCell />
        </TableHeaderRow>
    )
}
