import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useEvents from '../../hooks/useEvents'
import useRefetch from '../../hooks/useRefetch'
import AuditsReportsPage from './AuditsReportsPage'
import useAuditsReportsPageQuery from './useAuditsReportsPageQuery'
import useNotifications from '../../hooks/useNotifications'
import useCollectionState from '../../hooks/useCollectionState'
import useDeleteReportsMutation from '../reports/useDeleteReportsMutation'
import useCreateCookieTableReportMutation from '../reports/useCreateCookieTableReportMutation'
import useCreateVendorTableReportMutation from '../reports/useCreateVendorTableReportMutation'
import useCreateScanReportMutation from '../reports/useCreateScanReportMutation'
import useCreateAlertReportMutation from '../reports/useCreateAlertReportMutation'
import useCreateReportMutation from '../audits-scans/useCreateReportMutation'
import * as constants from '../../../constants'

const {
    REPORT_TYPE_AUDITS_COOKIE_TABLE,
    REPORT_TYPE_AUDITS_VENDOR_TABLE,
    REPORT_TYPE_SCAN,
    REPORT_TYPE_ALERT,
} = constants

const AuditScansPageWithState = () => {
    const {
        collection,
        addToCollection,
        addAllToCollection,
        clearCollection,
        removeFromCollection,
    } = useCollectionState()
    const [isLoading, setLoading] = useState(false)
    const { dispatchSuccess, dispatchError } = useNotifications()
    const {
        audit,
        reports,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditsReportsPageQuery()

    useEvents(['report:completed', 'report:failed'], () => refetch())
    useEvents(['report:completed'], (event) => {
        const { url } = event.report.file
        setLoading(false)
        window.open(url, '_blank', 'noopener')
    })
    useRefetch(refetch)

    const [createCookieTableReport] = useCreateCookieTableReportMutation()
    const [createVendorTableReport] = useCreateVendorTableReportMutation()
    const [createScanReport] = useCreateScanReportMutation()
    const [createAlertReport] = useCreateAlertReportMutation()

    const createReportTypes = {
        [REPORT_TYPE_AUDITS_COOKIE_TABLE]: createCookieTableReport,
        [REPORT_TYPE_AUDITS_VENDOR_TABLE]: createVendorTableReport,
        [REPORT_TYPE_SCAN]: createScanReport,
        [REPORT_TYPE_ALERT]: createAlertReport,
    }

    const [deleteReport] = useDeleteReportsMutation()
    const [deleteReports] = useDeleteReportsMutation({
        variables: {
            input: {
                ids: collection.map(({ id }) => id),
            },
        },
    })
    const handleDeleteReports = async (
        selectedRows,
        clearSelectedRows,
        stopSelectingRows
    ) => {
        const ids = selectedRows.map(({ id }) => id)
        try {
            await deleteReports({
                variables: {
                    input: {
                        ids,
                    },
                },
            })
            dispatchSuccess({
                message: <Trans>Deleted Reports!</Trans>,
            })
            clearSelectedRows()
            stopSelectingRows()
            refetch()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }
    const handleDeleteReport = async (reportId) => {
        try {
            await deleteReport({
                variables: {
                    input: {
                        ids: [reportId],
                    },
                },
            })
            dispatchSuccess({
                message: <Trans>Deleted Report!</Trans>,
            })
            refetch()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    const handleRecreateReport = async (reportId) => {
        const selectedReport = reports.find(({ id }) => id === reportId)
        const { startDate, endDate, audits, type, period } = selectedReport
        const filteredAudits = audits.filter(
            (selectAudit) => selectAudit !== null
        )
        const input = {
            auditIds: filteredAudits.map(({ id }) => id),
            startDate,
            endDate,
            period,
        }
        const variables = { input }
        await createReportTypes[type]({ variables })
    }

    return (
        <AuditsReportsPage
            audit={audit}
            reports={reports}
            isLoading={isLoading}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            onScanCompleted={() => refetch()}
            onReportRecreated={async (reportId) => {
                await handleRecreateReport(reportId)
                refetch()
            }}
            onReportCreated={() => refetch()}
            onDeleteReports={handleDeleteReports}
            onDeleteReport={handleDeleteReport}
        />
    )
}

export default AuditScansPageWithState
