import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import OrganizationHeader from '../../layout/OrganizationHeader'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AlertsDashboardWithState from './AlertsDashboardWithState'
import TableStateProvider from '../../providers/TableStateProvider'
import AlertsPageTableWithActions from './AlertsPageTableWithActions'

const AlertsPage = ({
    scanRangeState,
    selectedAlertStatus,
    alerts,
    totalAlertsCount,
    ignoredAlertsCount,
    reviewedAlertsCount,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onFilterAlerts,
    onAlertStatusChanged,
    onIgnoreAlerts,
    onDelegateAlertsInternal,
    onClearAlertsDelegate,
    onDelegateAlertsExternal,
    onRestoreAlerts,
    filters,
    selectedFilters,
    onChangeFilter,
    onClearFilters,
}) => (
    <PageContainer data-testid="AlertsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent size="full">
                <PageHeader className="mb-4" title={<Trans>Alerts</Trans>} />
                <AlertsDashboardWithState
                    selectedFilters={selectedFilters}
                    startDate={scanRangeState.startDate}
                    endDate={scanRangeState.endDate}
                />
                <TableStateProvider>
                    <AlertsPageTableWithActions
                        onIgnoreAlerts={onIgnoreAlerts}
                        onDelegateAlertsInternal={onDelegateAlertsInternal}
                        onClearAlertsDelegate={onClearAlertsDelegate}
                        onDelegateAlertsExternal={onDelegateAlertsExternal}
                        onRestoreAlerts={onRestoreAlerts}
                        selectedAlertStatus={selectedAlertStatus}
                        totalAlertsCount={totalAlertsCount}
                        ignoredAlertsCount={ignoredAlertsCount}
                        reviewedAlertsCount={reviewedAlertsCount}
                        scanRangeState={scanRangeState}
                        onFilterAlerts={onFilterAlerts}
                        filters={filters}
                        selectedFilters={selectedFilters}
                        onChangeFilter={onChangeFilter}
                        onClearFilters={onClearFilters}
                        startDate={scanRangeState.startDate}
                        endDate={scanRangeState.endDate}
                        alerts={alerts}
                        isFetchingMore={isFetchingMore}
                        onFetchMore={onFetchMore}
                        hasMoreRecords={hasMoreRecords}
                        isFetching={isFetching}
                        onAlertStatusChanged={onAlertStatusChanged}
                    />
                </TableStateProvider>
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AlertsPage
