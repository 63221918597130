/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import Tag from '../../utils/Tag'

const ReportsPageCreateReportButton = ({
    isLoading,
    onCreateCookieObservationsReport,
    onCreateVendorObservationsReport,
    onCreateAlertReport,
    onCreateCookieTableReport,
    onCreateVendorTableReport,
    onCreateScanReport,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <>
                <DropdownMenuItem
                    iconName="cookie"
                    onClick={() => {
                        onCreateCookieObservationsReport()
                        close()
                    }}
                >
                    <Trans>Cookie observations</Trans>
                    <Tag type="green" className="ml-2">
                        .xlsx
                    </Tag>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="building-07"
                    onClick={() => {
                        onCreateVendorObservationsReport()
                        close()
                    }}
                >
                    <Trans>Vendor observations</Trans>
                    <Tag type="green" className="ml-2">
                        .xlsx
                    </Tag>
                </DropdownMenuItem>

                <DropdownMenuItem
                    iconName="cookie"
                    onClick={() => {
                        onCreateCookieTableReport()
                        close()
                    }}
                >
                    <Trans>Cookie table</Trans>
                    <Tag type="green" className="ml-2">
                        .xlsx
                    </Tag>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="building-07"
                    onClick={() => {
                        onCreateVendorTableReport()
                        close()
                    }}
                >
                    <Trans>Vendor table</Trans>
                    <Tag type="green" className="ml-2">
                        .xlsx
                    </Tag>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="clock"
                    onClick={() => {
                        onCreateScanReport()
                        close()
                    }}
                >
                    <Trans>Scan report</Trans>
                    <Tag type="green" className="ml-2">
                        .xlsx
                    </Tag>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="bell"
                    onClick={() => {
                        onCreateAlertReport()
                        close()
                    }}
                >
                    <Trans>Alert summary</Trans>
                    <Tag type="green" className="ml-2">
                        .xlsx
                    </Tag>
                </DropdownMenuItem>
            </>
        )}
    >
        <Button className="my-3" loading={isLoading}>
            <Trans>Create new report</Trans>
        </Button>
    </DropdownMenu>
)

export default ReportsPageCreateReportButton
