import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired, isValidEmail } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'email',
        validate: (value) => isRequired(value) && isValidEmail(value),
        message: <Trans>Choose a valid email.</Trans>,
    },
])

const valuesToInput = (values) => values

const useAlertExternalDelegationFormState = (initialValues) => {
    const formState = useFormState(
        {
            email: '',
            title: '',
            body: '',
            attachReport: true,
            ...initialValues,
        },
        {
            validation,
            valuesToInput,
        }
    )
    return formState
}

export default useAlertExternalDelegationFormState
