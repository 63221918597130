import React from 'react'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import StaffHeader from '../../layout/StaffHeader'
import EmptyPageCard from '../../utils/EmptyPageCard'
import VendorsPageSidebar from '../staff-vendors/VendorsPageSidebar'

export default function StaffVendorsOrganizationVendors() {
    return (
        <PageContainer>
            <StaffHeader />
            <SidebarContainer>
                <VendorsPageSidebar />
                <PageContent>
                    <EmptyPageCard
                        className="mt-8"
                        iconName="building-07"
                        title="Coming soon..."
                        description="Stay tuned, we are in the process of adding this page."
                    />
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
