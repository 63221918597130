import React from 'react'
import { Trans } from '@lingui/macro'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import UpdateTrackersModal from './UpdateTrackersModal'
import useUpdateTrackersMutation from './useUpdateTrackersMutation'
import useUpdateTrackersFormState from './useUpdateTrackersFormState'

const UpdateTrackersModalWithState = ({
    rootDomains,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const [updateTrackers] = useUpdateTrackersMutation({ onCompleted, onError })
    const formState = useUpdateTrackersFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <UpdateTrackersModal
            canCreateVendor={currentUser.isStaff}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        const variables = { ids: rootDomains, input }
                        await updateTrackers({ variables })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Tracker updated successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default UpdateTrackersModalWithState
