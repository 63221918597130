/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DeleteVendorModalWithState from './DeleteVendorModalWithState'
import UpdateVendorModal from '../../utils/UpdateVendorModal'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'

export default function VendorsPageActionsDropdownMenu({
    vendor,
    onDeleteVendorCompleted,
    onUpdateVendorCompleted,
    children,
}) {
    const [isUpdateVendorModalOpen, setUpdateVendorModalOpen] = useState(false)
    const [isDeleteVendorModalOpen, setDeleteVendorModalIsOpen] =
        useState(false)
    return (
        <>
            {isUpdateVendorModalOpen && (
                <UpdateVendorModal
                    vendorId={vendor.id}
                    onDismiss={() => setUpdateVendorModalOpen(false)}
                    onCompleted={onUpdateVendorCompleted}
                />
            )}
            {isDeleteVendorModalOpen && (
                <DeleteVendorModalWithState
                    vendor={vendor}
                    onDismiss={() => setDeleteVendorModalIsOpen(false)}
                    onCompleted={onDeleteVendorCompleted}
                />
            )}
            <DropdownMenu
                as="span"
                position="bottom-start"
                content={({ close }) => (
                    <>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={() => {
                                setDeleteVendorModalIsOpen(true)
                                close()
                            }}
                        >
                            <Trans>Delete Vendor</Trans>
                        </DropdownMenuItem>

                        <DropdownMenuItem
                            iconName="edit"
                            onClick={() => {
                                setUpdateVendorModalOpen(true)
                                close()
                            }}
                        >
                            <Trans>Update Vendor</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                {children}
            </DropdownMenu>
        </>
    )
}
