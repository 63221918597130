import { useEffect } from 'react'
import useDrawerStack from './useDrawerStack'

export default function useCloseDrawerOnLeave() {
    const { close } = useDrawerStack()
    useEffect(() => {
        return () => {
            close()
        }
    }, [])
}
