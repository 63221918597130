import React from 'react'
import { Trans } from '@lingui/macro'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import StaffUsersPageTableRowActionButton from './StaffUsersPageTableRowActionButton'
import useStaffUsersDeleteUserMutation from './useStaffUsersDeleteUserMutation'

export default function StaffUsersPageTableRowActionButtonWithState({
    user,
    onDeleteUserSuccess,
    isYou,
    refetch,
}) {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { currentUser } = useAppState()
    const [deleteUser, { loading }] = useStaffUsersDeleteUserMutation()

    return (
        <StaffUsersPageTableRowActionButton
            isLoading={loading}
            isYou={isYou}
            userId={user.id}
            refetch={refetch}
            canDeleteUser={currentUser.isStaff}
            canAssignOrganizations={currentUser.isStaff}
            onDeleteUser={async () => {
                try {
                    await deleteUser({ variables: { userId: user.id } })
                    refetch()
                    dispatchSuccess({
                        message: <Trans>User successfully deleted.</Trans>,
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}
