import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const ConsentProfilesPageTableRowActionButton = ({
    isLoading,
    canDelete,
    onDelete,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <DropdownMenuItem
                iconName="bin"
                disabled={!canDelete}
                onClick={(e) => {
                    onDelete()
                    close()
                    e.stopPropagation()
                }}
            >
                <Trans>Delete consent profile</Trans>
            </DropdownMenuItem>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default ConsentProfilesPageTableRowActionButton
