import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation classifyTracker($input: TrackerInput!) {
        classifyTracker(input: $input) {
            tracker {
                id
            }
        }
    }
`

const useClassifyTrackerMutation = (options) => useMutation(MUTATION, options)

export default useClassifyTrackerMutation
