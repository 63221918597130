import React, { useState } from 'react'
import StaffUsersPage from './StaffUsersPage'
import useStaffUsersPageQuery from './useStaffUsersPageQuery'
import StaffCreateUserModalWithState from './StaffCreateUserModalWithState'

const StaffUsersPageWithState = () => {
    const [isModalOpen, setModalIsOpen] = useState()
    const {
        users,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useStaffUsersPageQuery()
    return (
        <>
            {isModalOpen && (
                <StaffCreateUserModalWithState
                    onDismiss={() => setModalIsOpen(false)}
                    onCompleted={() => refetch()}
                />
            )}
            <StaffUsersPage
                users={users}
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                onCreateStaffUser={() => setModalIsOpen(true)}
                hasMoreRecords={hasMore}
                isFetching={isFetching}
                refetch={refetch}
            />
        </>
    )
}

export default StaffUsersPageWithState
