import React, { useEffect, useState, useContext } from 'react'
import { Trans } from '@lingui/macro'
import { NotificationsContext } from '../providers/NotificationsProvider'
import * as constants from '../../constants'
import { getGraphqlErrorMessage } from '../../utils/graphql'

const {
    NOTIFICATION_TYPE_ERROR: ERROR,
    NOTIFICATION_TYPE_SUCCESS: SUCCESS,
    NOTIFICATION_TYPE_WARNING: WARNING,
} = constants

const useNotifications = () => {
    const notificationDispatcher = useContext(NotificationsContext)
    const [notifications, setNotifications] = useState([])
    useEffect(
        () =>
            notificationDispatcher.subscribe((newNotifications) => {
                setNotifications(newNotifications)
            }),
        [notificationDispatcher]
    )

    const dispatchSuccess = (data) =>
        notificationDispatcher.dispatch({
            type: SUCCESS,
            title: <Trans>Success</Trans>,
            message: <Trans>Your request was handled successfully.</Trans>,
            ...data,
        })

    const dispatchError = (data = {}) =>
        notificationDispatcher.dispatch({
            type: ERROR,
            title: <Trans>Error</Trans>,
            message: (
                <Trans>
                    Oops, looks like something went wrong, please try again
                    later.
                </Trans>
            ),
            ...data,
        })

    const dispatchWarning = (data) =>
        notificationDispatcher.dispatch({
            type: WARNING,
            title: <Trans>Warning</Trans>,
            message: <Trans>Careful a warning was triggered.</Trans>,
            ...data,
        })

    const dispatchGraphqlError = (error, data) => {
        const message = getGraphqlErrorMessage(error)
        return notificationDispatcher.dispatch({
            type: ERROR,
            title: <Trans>Error</Trans>,
            message,
            ...data,
        })
    }

    const removeNotification = (notification) => {
        notificationDispatcher.removeNotification(notification)
    }

    return {
        notifications,
        dispatchError,
        dispatchWarning,
        dispatchSuccess,
        dispatchGraphqlError,
        dispatch: (data) => notificationDispatcher.dispatch(data),
        removeNotification,
    }
}

export default useNotifications
