import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation deleteDomain($id: ID!) {
        deleteDomain(id: $id)
    }
`

const useDeleteDomainMutation = (options) => useMutation(MUTATION, options)

export default useDeleteDomainMutation
