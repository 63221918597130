import React from 'react'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import MyOrganizationsPage from './MyOrganizationsPage'
import useMyOrganizationsPageQuery from './useMyOrganizationsPageQuery'
import useSwitchOrganizationMutation from './useSwitchOrganizationMutation'

const MyOrganizationsPageWithState = () => {
    const appState = useAppState()
    const { dispatchError } = useNotifications()
    const { organizations, isFetching } = useMyOrganizationsPageQuery()
    const [switchOrganization, { loading }] = useSwitchOrganizationMutation()
    if (isFetching) {
        return null
    }
    return (
        <MyOrganizationsPage
            organizations={organizations}
            isLoading={loading}
            currentUser={appState.currentUser}
            onSelectOrganization={async (organizationId) => {
                try {
                    await switchOrganization({
                        variables: { organizationId },
                    })
                    await appState.reset('/')
                } catch (error) {
                    const { message } = error.graphQLErrors[0]
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default MyOrganizationsPageWithState
