import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation useDeleteVendorTableVersionMutation($id: ID!) {
        deleteVendorTableVersion(id: $id)
    }
`

const useDeleteVendorTableVersionMutation = (options) =>
    useMutation(MUTATION, options)

export default useDeleteVendorTableVersionMutation
