import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'
import { cmpResolverActionOptions } from '../settings-consent-profiles/useCreateConsentProfileFormState'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this consent profile.</Trans>,
    },
    {
        path: 'type',
        validate: isRequired,
        message: <Trans>Select a cmp.</Trans>,
    },
    {
        path: 'cmpId',
        validate: isRequired,
        message: <Trans>Select a cmp.</Trans>,
    },
])

const toFormValues = ({
    name,
    type,
    cmp = {},
    purposes = [],
    customPurposes,
    cmpResolverActions = [],
}) => ({
    name,
    type,
    cmpId: cmp.id,
    purposeIds: purposes.map(({ id }) => id),
    customPurposes,
    cmpResolverActions,
})

const useUpdateConsentProfileFormState = (
    consentProfile,
    allPurposes,
    customCmpId
) => {
    const defaultAction = {
        type: Object.keys(cmpResolverActionOptions)[0],
        selector: '',
    }
    const formState = useFormState(toFormValues(consentProfile), { validation })
    formState.handleNoConsent = () => {
        formState.handleChange('type', 'NO_CONSENT')
        formState.handleChange(
            'purposeIds',
            allPurposes.filter(({ externalId }) => externalId === 'essential')
        )
        formState.handleChange('customPurposes', [])
    }
    formState.handleRejectAll = () => {
        formState.handleChange('type', 'REJECT_ALL')
        formState.handleChange(
            'purposeIds',
            allPurposes.filter(({ externalId }) => externalId === 'essential')
        )
        formState.handleChange('customPurposes', [])
    }
    formState.handleAcceptAll = () => {
        formState.handleChange('type', 'ACCEPT_ALL')
        formState.handleChange(
            'purposeIds',
            allPurposes.map(({ id }) => id)
        )
        formState.handleChange('customPurposes', [])
    }
    formState.handleCustom = () => {
        formState.handleChange('type', 'CUSTOM')
        formState.handleChange('purposeIds', [])
        formState.handleChange('customPurposes', [''])
    }
    formState.handleChangePurpose = (purposeId) => {
        formState.handleChange('purposeIds', (purposeIds) => {
            if (purposeIds.includes(purposeId)) {
                return purposeIds.filter((id) => id !== purposeId)
            }
            return [...purposeIds, purposeId]
        })
    }
    formState.handleAddCustomPurpose = () => {
        formState.handleChange('customPurposes', (customPurposes) => [
            ...customPurposes,
            '',
        ])
    }
    formState.handleRemoveCustomPurpose = (index) => {
        formState.handleChange('customPurposes', (customPurposes) => {
            if (customPurposes.length === 1) {
                return ['']
            }
            return customPurposes.filter((_, i) => i !== index)
        })
    }
    formState.handleAddCmpResolverAction = () => {
        formState.handleChange('cmpResolverActions', (cmpResolverActions) => [
            ...cmpResolverActions,
            defaultAction,
        ])
    }
    formState.handleRemoveCmpResolverAction = (index) => {
        formState.handleChange('cmpResolverActions', (cmpResolverActions) => {
            if (cmpResolverActions.length === 1) {
                return [defaultAction]
            }
            return cmpResolverActions.filter((_, i) => i !== index)
        })
    }
    formState.handleChangeCmpId = (value) => {
        formState.handleChange('cmpId', value)
        if (value === customCmpId) {
            formState.handleChange('cmpResolverActions', [defaultAction])
        } else {
            formState.handleChange('cmpResolverActions', [])
        }
    }
    return formState
}

export default useUpdateConsentProfileFormState
