import React from 'react'
import useCookieTablesPageQuery from '../pages/vendors-cookie-tables/useCookieTablesPageQuery'
import SearchInput from './SearchInput'
import SelectListOption from './SelectListOption'
import InfiniteScroll from './InfiniteScroll'
import SelectList from './SelectList'
import useSearch from '../hooks/useSearch'

const CookieTableSearchSelect = ({
    className,
    selectedCookieTable,
    onHandleSelectCookieTable,
}) => {
    const [search, handleSearch] = useSearch('')

    const {
        cookieTables,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useCookieTablesPageQuery(search)

    return (
        <SelectList
            className={className}
            isFetching={isFetching}
            searchInput={
                <SearchInput
                    value={search}
                    onChange={handleSearch}
                    className="w-full"
                />
            }
        >
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore}
            >
                <ul>
                    {cookieTables.map((cookieTable) => (
                        <SelectListOption
                            key={cookieTable.id}
                            disabled={false}
                            isCheckbox
                            checked={selectedCookieTable === cookieTable.id}
                            onClick={() => {
                                if (selectedCookieTable === cookieTable.id) {
                                    onHandleSelectCookieTable(null)
                                } else {
                                    onHandleSelectCookieTable(cookieTable.id)
                                }
                            }}
                        >
                            {cookieTable.name}
                        </SelectListOption>
                    ))}
                </ul>
            </InfiniteScroll>
        </SelectList>
    )
}

export default CookieTableSearchSelect
