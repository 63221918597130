import React from 'react'
import clsx from 'clsx'

const Card = ({ className, ...props }) => (
    <div
        className={clsx(
            'relative rounded-2xl border border-gray-200 bg-white px-8 py-6',
            className
        )}
        {...props}
    />
)

export default Card
