/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useMemo } from 'react'
import Drawer from '../utils/Drawer'
import DrawerStack from '../utils/DrawerStack'

export const DrawerStackContext = React.createContext()

const DrawerWithState = ({
    isOpen,
    drawerComponent,
    drawerProps,
    drawerStackState,
}) => {
    const [state, setState] = useState(drawerProps.initialDrawerState || {})
    const Component = drawerComponent
    return (
        <Drawer isOpen={isOpen}>
            <Component
                {...drawerProps}
                drawerStackState={drawerStackState}
                drawerState={state}
                setDrawerState={setState}
            />
        </Drawer>
    )
}

export default function DrawerStackProvider({ children }) {
    const [stack, setStack] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const canGoBack = currentIndex > 0
    const canGoForward = currentIndex < stack.length - 1
    const state = useMemo(() => {
        function open(component, props) {
            setStack([{ component, props }])
            setCurrentIndex(0)
        }
        function add(component, props) {
            const isAtLast = currentIndex === stack.length - 1
            if (isAtLast) {
                setStack((prev) => [...prev, { component, props }])
            } else {
                setStack((prev) => [
                    ...prev.slice(0, currentIndex + 1),
                    { component, props },
                ])
            }
            setCurrentIndex((prev) => prev + 1)
        }
        function close() {
            setStack([])
            setCurrentIndex(0)
        }
        function forward() {
            if (canGoForward) {
                setCurrentIndex((prev) => prev + 1)
            }
        }
        function back() {
            if (canGoBack) {
                setCurrentIndex((prev) => prev - 1)
            }
        }
        return {
            stack,
            currentIndex,
            currentDrawerProps: stack[currentIndex]?.props,
            canGoBack,
            canGoForward,
            open,
            add,
            close,
            forward,
            back,
        }
    }, [stack, currentIndex, canGoBack, canGoForward])
    return (
        <DrawerStackContext.Provider value={state}>
            {children}
            {stack.length > 0 && (
                <DrawerStack>
                    {stack.map((drawer, index) => {
                        return (
                            <DrawerWithState
                                key={index}
                                isOpen={drawer === stack[currentIndex]}
                                drawerComponent={drawer.component}
                                drawerProps={drawer.props}
                                drawerStackState={state}
                            />
                        )
                    })}
                </DrawerStack>
            )}
        </DrawerStackContext.Provider>
    )
}
