import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import PurposesTags from '../../utils/PurposesTags'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import Duration from '../../utils/Duration'

export default function IdentifiedScanCookieDetailsDrawerTabDetails({
    identifiedCookie,
}) {
    if (!identifiedCookie) {
        return (
            <DrawerEmptyCard
                iconName="scan"
                title={<Trans>No Information</Trans>}
                description={<Trans>There was no cookie find. </Trans>}
            />
        )
    }

    const { cookie, lastScanCookie } = identifiedCookie
    return (
        <div className="flex flex-col gap-4 ">
            <DrawerDetailsItemText
                isHorizontal
                truncate={false}
                label={<Trans>Description</Trans>}
            >
                {cookie?.description}
            </DrawerDetailsItemText>
            <DrawerDetailsItemText isHorizontal label={<Trans>Purposes</Trans>}>
                {cookie?.purposes ? (
                    <PurposesTags showAll purposes={cookie.purposes} />
                ) : (
                    <Tag>Unclassified</Tag>
                )}
            </DrawerDetailsItemText>
            <DrawerDetailsItemText
                isHorizontal
                label={<Trans>Last Observed</Trans>}
            >
                {cookie?.lastSeenAt}
            </DrawerDetailsItemText>
            <DrawerDetailsItemText
                isHorizontal
                className="w-full"
                label={<Trans>Example Value</Trans>}
            >
                <Tooltip
                    event="hover"
                    position="top-end"
                    content={
                        <div className="max-w-[400px] overflow-hidden break-words">
                            {lastScanCookie?.value}
                        </div>
                    }
                    contentClassName="max-w-[400px]"
                >
                    {lastScanCookie?.value}
                </Tooltip>
            </DrawerDetailsItemText>
            <DrawerDetailsItemText
                isHorizontal
                label={<Trans>Example Retention</Trans>}
            >
                <Duration format={Duration.DAYS}>
                    {lastScanCookie?.retention}
                </Duration>
            </DrawerDetailsItemText>
            <DrawerDetailsItemText
                isHorizontal
                label={<Trans>Example Domain</Trans>}
            >
                {lastScanCookie?.domain}
            </DrawerDetailsItemText>
        </div>
    )
}
