import React from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import Flag from '../../utils/Flag'
import DetailsCard from '../../utils/DetailsCard'
import DetailsList from '../../utils/DetailsList'
import PageContent from '../../utils/PageContent'
import DetailsHeader from '../../utils/DetailsHeader'
import PageContainer from '../../layout/PageContainer'
import DetailsListItem from '../../utils/DetailsListItem'
import VendorDetailsPageHeader from './VendorDetailsPageHeader'
import StaffHeader from '../../layout/StaffHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import VendorsPageSidebar from '../staff-vendors/VendorsPageSidebar'
import LoadingIndicator from '../../utils/LoadingIndicator'

export const CONSENT = 'CONSENT'
export const FLEXIBLE = 'FLEXIBLE'
export const LEGITIMATE_INTEREST = 'LEGITIMATE_INTEREST'

const allSpecialPurposes = [
    {
        value: 1,
        label: <Trans>Ensure security, prevent fraud, and debug</Trans>,
    },
    {
        value: 2,
        label: <Trans>Technically deliver ads or content</Trans>,
    },
]

const allFeatures = [
    {
        value: 1,
        label: <Trans>Match and combine offline data sources</Trans>,
    },
    {
        value: 2,
        label: <Trans>Link different devices</Trans>,
    },
    {
        value: 3,
        label: (
            <Trans>
                Receive and use automatically-sent device characteristics for
                identification
            </Trans>
        ),
    },
]

const allSpecialFeatures = [
    {
        value: 1,
        label: <Trans>Use precise geolocation data</Trans>,
    },
    {
        value: 2,
        label: (
            <Trans>
                Actively scan device characteristics for identification
            </Trans>
        ),
    },
]

const VendorDetailsPage = ({ vendor, vendorPurposes, isFetching }) => (
    <PageContainer data-testid="VendorDetailsPage">
        <StaffHeader />
        <SidebarContainer>
            <VendorsPageSidebar />
            <PageContent>
                <VendorDetailsPageHeader vendor={vendor} />
                {isFetching ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <DetailsCard>
                            <DetailsHeader
                                title={<Trans>General info</Trans>}
                            />
                            <DetailsList>
                                <DetailsListItem
                                    label={<Trans>Legal name</Trans>}
                                >
                                    {vendor.legalName}
                                </DetailsListItem>
                                <DetailsListItem
                                    label={<Trans>Activities</Trans>}
                                >
                                    {vendor.activities !== null &&
                                        vendor.activities.map((activity) => (
                                            <Tag
                                                className="mr-2"
                                                type="primary"
                                            >
                                                {activity}
                                            </Tag>
                                        ))}
                                </DetailsListItem>
                                <DetailsListItem
                                    label={<Trans>Description</Trans>}
                                >
                                    {vendor.description}
                                </DetailsListItem>
                                <DetailsListItem label={<Trans>Website</Trans>}>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={vendor.websiteUrl}
                                    >
                                        {vendor.websiteUrl}
                                    </a>
                                </DetailsListItem>
                                <DetailsListItem
                                    label={<Trans>Privacy policy</Trans>}
                                >
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={vendor.privacyPolicyUrl}
                                    >
                                        {vendor.privacyPolicyUrl}
                                    </a>
                                </DetailsListItem>
                                <DetailsListItem
                                    label={<Trans>Cookie policy</Trans>}
                                >
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={vendor.cookiePolicyUrl}
                                    >
                                        {vendor.cookiePolicyUrl}
                                    </a>
                                </DetailsListItem>
                            </DetailsList>
                        </DetailsCard>
                        <DetailsCard>
                            <DetailsHeader
                                title={<Trans>Processing location</Trans>}
                            />
                            <DetailsListItem
                                label={<Trans>Headquarters</Trans>}
                            >
                                <div className="flex">
                                    {vendor.headQuarterLocations &&
                                        vendor.headQuarterLocations.map(
                                            (countryCode) => (
                                                <Flag
                                                    key={countryCode}
                                                    className="mr-2"
                                                    countryCode={countryCode}
                                                />
                                            )
                                        )}
                                </div>
                            </DetailsListItem>
                            <DetailsListItem
                                label={<Trans>Data centers</Trans>}
                            >
                                <div className="flex">
                                    {vendor.dataCenterLocations &&
                                        vendor.dataCenterLocations.map(
                                            (countryCode) => (
                                                <Flag
                                                    key={countryCode}
                                                    className="mr-2"
                                                    countryCode={countryCode}
                                                />
                                            )
                                        )}
                                </div>
                            </DetailsListItem>
                            <DetailsListItem
                                label={<Trans>Other locations</Trans>}
                            >
                                <div className="flex">
                                    {vendor.otherLocations &&
                                        vendor.otherLocations.map(
                                            (countryCode) => (
                                                <Flag
                                                    key={countryCode}
                                                    className="mr-2"
                                                    countryCode={countryCode}
                                                />
                                            )
                                        )}
                                </div>
                            </DetailsListItem>
                        </DetailsCard>
                        <DetailsCard>
                            <DetailsHeader
                                title={<Trans>IAB purposes</Trans>}
                                description={
                                    <Trans>
                                        The IAB Framework is to create a
                                        standardized cooperation between online
                                        publishers, advertisers, and the tech
                                        companies.
                                    </Trans>
                                }
                            />
                            {vendorPurposes.map(({ label, type }) => (
                                <DetailsListItem key={label} label={label}>
                                    {(() => {
                                        if (type === CONSENT) {
                                            return (
                                                <Tag type="green">
                                                    <Trans>Consent</Trans>
                                                </Tag>
                                            )
                                        }
                                        if (type === LEGITIMATE_INTEREST) {
                                            return (
                                                <Tag type="blue">
                                                    <Trans>Leg. Int.</Trans>
                                                </Tag>
                                            )
                                        }
                                        if (type === FLEXIBLE) {
                                            return (
                                                <Tag type="yellow">
                                                    <Trans>Flexible</Trans>
                                                </Tag>
                                            )
                                        }
                                        return null
                                    })()}
                                </DetailsListItem>
                            ))}
                        </DetailsCard>
                        <DetailsCard>
                            <DetailsHeader
                                title={<Trans>IAB processing activities</Trans>}
                                description={
                                    <Trans>
                                        The IAB Framework is to create a
                                        standardized cooperation between online
                                        publishers, advertisers, and the tech
                                        companies.
                                    </Trans>
                                }
                            />
                            {allSpecialPurposes.map(({ label, value }) => {
                                const specialPurpose =
                                    vendor.specialPurposes.find(
                                        (v) => v === value
                                    )
                                if (typeof specialPurpose !== 'undefined') {
                                    return (
                                        <DetailsListItem
                                            key={label}
                                            label={label}
                                        >
                                            <Tag type="red">
                                                <Trans>Special purposes</Trans>
                                            </Tag>
                                        </DetailsListItem>
                                    )
                                }
                                return null
                            })}
                            {allFeatures.map(({ label, value }) => {
                                const feature = vendor.features.find(
                                    (v) => v === value
                                )
                                if (typeof feature !== 'undefined') {
                                    return (
                                        <DetailsListItem
                                            key={label}
                                            label={label}
                                        >
                                            <Tag type="yellow">
                                                <Trans>Feature</Trans>
                                            </Tag>
                                        </DetailsListItem>
                                    )
                                }
                                return null
                            })}
                            {allSpecialFeatures.map(({ label, value }) => {
                                const feature = vendor.specialFeatures.find(
                                    (v) => v === value
                                )
                                if (typeof feature !== 'undefined') {
                                    return (
                                        <DetailsListItem
                                            key={label}
                                            label={label}
                                        >
                                            <Tag type="red">
                                                <Trans>Special feature</Trans>
                                            </Tag>
                                        </DetailsListItem>
                                    )
                                }
                                return null
                            })}
                        </DetailsCard>
                    </>
                )}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default VendorDetailsPage
