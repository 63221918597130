import React from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import AlertTypeSelect from '../alerts/AlertTypeSelect'
import SelectAlertsActions from '../alerts/SelectAlertsActions'
import TableActionsContainer from '../../utils/TableActionsContainer'
import AlertStatusFilterButtonGroup from '../alerts/AlertStatusFilterButtonGroup'
import Button from '../../utils/Button'
import ScanRangeSelect from '../alerts/ScanRangeSelect'

const AuditAlertsPageActions = ({
    selectedAlertStatus,
    totalAlertsCount,
    ignoredAlertsCount,
    reviewedAlertsCount,
    scanRangeState,
    onFilterAlerts,
    onIgnoreAlerts,
    onDelegateAlertsInternal,
    onClearAlertsDelegate,
    onDelegateAlertsExternal,
    onRestoreAlerts,
    onChangeType,
    selectedType,
}) => {
    const {
        isSelectingRows,
        startSelectingRows,
        stopSelectingRows,
        selectedRows,
        clearSelectedRows,
    } = useTableState()
    return (
        <TableActionsContainer isSticky>
            {isSelectingRows && (
                <div className="flex flex-row gap-2">
                    <SelectAlertsActions
                        hasSelectedAlerts={selectedRows.length <= 0}
                        selectedAlertStatus={selectedAlertStatus}
                        onIgnoreAlerts={onIgnoreAlerts}
                        onDelegateAlertsInternal={onDelegateAlertsInternal}
                        onClearAlertsDelegate={onClearAlertsDelegate}
                        onDelegateAlertsExternal={onDelegateAlertsExternal}
                        onStopSelectingAlerts={stopSelectingRows}
                        onRestoreAlerts={onRestoreAlerts}
                        selectedAlerts={selectedRows}
                    />
                </div>
            )}
            {!isSelectingRows && (
                <div className="flex w-full flex-row justify-between items-center">
                    <div className="flex gap-2 items-center">
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{
                                name: 'check-done-01',
                                size: 'sm',
                            }}
                            onClick={() => startSelectingRows()}
                        />
                        <AlertStatusFilterButtonGroup
                            selectedAlertStatus={selectedAlertStatus}
                            totalAlertsCount={totalAlertsCount}
                            ignoredAlertsCount={ignoredAlertsCount}
                            reviewedAlertsCount={reviewedAlertsCount}
                            onFilterAlerts={onFilterAlerts}
                        />
                        <AlertTypeSelect
                            className="w-[150px]"
                            onChange={(e) => onChangeType(e.target.value)}
                            value={selectedType}
                        />
                    </div>
                    <div>
                        <ScanRangeSelect
                            onChange={scanRangeState.onChangeDate}
                            period={scanRangeState.selectedPeriod}
                            startDate={scanRangeState.startDate}
                            endDate={scanRangeState.endDate}
                        />
                    </div>
                </div>
            )}
        </TableActionsContainer>
    )
}

export default AuditAlertsPageActions
