import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../hooks/useNotifications'
import CreateVendorModal from './CreateVendorModal'
import useCreateVendorFormState from './useCreateVendorFormState'
import useCreateVendorMutation from './useCreateVendorMutation'

const CreateVendorModalWithState = ({ onDismiss, onCompleted, onError }) => {
    const [createVendor] = useCreateVendorMutation({ onCompleted, onError })
    const formState = useCreateVendorFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateVendorModal
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await createVendor({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Vendor created successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateVendorModalWithState
