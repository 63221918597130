/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react'
import clsx from 'clsx'

const DropdownMenuTitle = ({ className, rightChildren, ...props }) => (
    <div className="flex justify-between">
        <h3
            className={clsx('px-4 py-2 font-medium text-sm text-gray-600', className)}
            {...props}
        />
        <div>{rightChildren}</div>
    </div>
)

export default DropdownMenuTitle
