import React, { useReducer, useState, useMemo } from 'react'
import useCollectionState from '../hooks/useCollectionState'

const REGISTER_COLUMN = 'REGISTER_COLUMN'
const UNREGISTER_COLUMN = 'UNREGISTER_COLUMN'
const SHOW_COLUMN = 'SHOW_COLUMN'
const HIDE_COLUMN = 'HIDE_COLUMN'
const CHANGE_COLUMN_ORDER = 'CHANGE_COLUMN_ORDER'

const reducer = (state, action) => {
    const { type, columnId, newOrder, options } = action
    const { columnIds, hiddenColumnIds, columnOptions } = state
    if (type === HIDE_COLUMN) {
        return {
            ...state,
            hiddenColumnIds: [...hiddenColumnIds, columnId],
        }
    }
    if (type === SHOW_COLUMN) {
        return {
            ...state,
            hiddenColumnIds: hiddenColumnIds.filter((id) => id !== columnId),
        }
    }
    if (type === REGISTER_COLUMN) {
        const { isInitiallyHidden = false } = options
        let newHiddenColumnIds = hiddenColumnIds
        if (isInitiallyHidden) {
            newHiddenColumnIds = [...hiddenColumnIds, columnId]
        }
        return {
            ...state,
            columnOptions: {
                ...columnOptions,
                [columnId]: options,
            },
            columnIds: [...columnIds, columnId],
            hiddenColumnIds: newHiddenColumnIds,
        }
    }
    if (type === UNREGISTER_COLUMN) {
        const newColumnOptions = { ...columnOptions }
        delete newColumnOptions[columnId]
        return {
            ...state,
            columnOptions: newColumnOptions,
            columnIds: columnIds.filter((id) => id !== columnId),
        }
    }
    if (type === CHANGE_COLUMN_ORDER) {
        return {
            ...state,
            columnIds: newOrder,
        }
    }
    return state
}

export const TableStateContext = React.createContext()

const TableStateProvider = ({
    initialOrderBy,
    onChangeOrderBy,
    onStartSelectingRows,
    onStopSelectingRows,
    onShowColumn,
    onHideColumn,
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, {
        columnIds: [],
        columnOptions: {},
        hiddenColumnIds: [],
    })
    const [isSelectingRows, setIsSelectingRows] = useState(false)
    const [orderBy, setOrderBy] = useState(initialOrderBy)
    const {
        collection: selectedRows,
        addToCollection: selectRow,
        addAllToCollection: selectManyRows,
        clearCollection: clearSelectedRows,
        removeFromCollection: deselectRow,
    } = useCollectionState()
    const value = useMemo(() => {
        return {
            ...state,
            canReorderColumns: false,
            isSelectingRows,
            selectedRows,
            selectRow,
            selectManyRows,
            clearSelectedRows,
            deselectRow,
            orderBy,
            sortColumnId: orderBy ? Object.keys(orderBy)[0] : null,
            sortDirection: orderBy ? Object.values(orderBy)[0] : null,
            changeOrderBy: (columnId, direction) => {
                setOrderBy({ [columnId]: direction })
                if (typeof onChangeOrderBy === 'function') {
                    onChangeOrderBy(columnId, direction)
                }
            },
            startSelectingRows: () => {
                setIsSelectingRows(true)
                if (typeof onStartSelectingRows === 'function') {
                    onStartSelectingRows()
                }
            },
            stopSelectingRows: () => {
                clearSelectedRows()
                setIsSelectingRows(false)
                if (typeof onStopSelectingRows === 'function') {
                    onStopSelectingRows()
                }
            },
            registerColumn: (columnId, options) =>
                dispatch({ type: REGISTER_COLUMN, columnId, options }),
            unregisterColumn: (columnId) =>
                dispatch({ type: UNREGISTER_COLUMN, columnId }),
            showColumn: (columnId) => {
                dispatch({ type: SHOW_COLUMN, columnId })
                if (typeof onShowColumn === 'function') {
                    onShowColumn(columnId)
                }
            },
            hideColumn: (columnId) => {
                dispatch({ type: HIDE_COLUMN, columnId })
                if (typeof onHideColumn === 'function') {
                    onHideColumn(columnId)
                }
            },
            changeColumnOrder: (newOrder) =>
                dispatch({ type: CHANGE_COLUMN_ORDER, newOrder }),
            reset: () => {},
        }
    }, [
        state,
        orderBy,
        isSelectingRows,
        selectedRows,
        selectRow,
        selectManyRows,
        clearSelectedRows,
        deselectRow,
        onChangeOrderBy,
        onStartSelectingRows,
        onStopSelectingRows,
        onShowColumn,
        onHideColumn,
    ])
    return (
        <TableStateContext.Provider value={value}>
            {children}
        </TableStateContext.Provider>
    )
}

export default TableStateProvider
