import React from 'react'
import { Trans } from '@lingui/macro'
import ModalTitle from '../../utils/ModalTitle'
import Modal from '../../utils/Modal'
import Button from '../../utils/Button'
import useNotifications from '../../hooks/useNotifications'

const EmbedCookieTableModal = ({ onDismiss, onBack, onCompleted, link }) => {
    const { dispatchSuccess } = useNotifications()
    const handleCopy = () => {
        const codeBlock = document.getElementById('codeBlock')
        const textToCopy = codeBlock.innerText || codeBlock.textContent

        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                dispatchSuccess({ message: 'Copied to clipboard' })
                onDismiss()
            })
            .catch((err) => {
                console.error('Failed to copy code: ', err)
            })
    }

    return (
        <Modal className="max-w-2xl">
            <ModalTitle
                title={<Trans>Embed your table!</Trans>}
                description={
                    <Trans>You can embed the table on your own website</Trans>
                }
                iconName="palette"
                iconColor="none"
                iconSize="md"
                onClose={onDismiss}
                onCompleted={onCompleted}
                newModalTitle
            />

            <div className="w-full lg:max-w-2xl mx-auto bg-gray-900 px-4 rounded-md shadow-lg relative">
                <div className="flex justify-between items-center px-4 py-4 rounded-t-lg">
                    <span className="text-xs font-semibold text-gray-400">
                        html
                    </span>
                </div>
                <pre className="text-sm text-white overflow-x-auto pb-6">
                    <code id="codeBlock" className="block">
                        &lt;iframe src=&quot;{link}&quot; /&gt;
                    </code>
                </pre>
            </div>

            <div className="flex flex-row gap-2 mt-4">
                <Button type="white" className="w-full" onClick={onBack || onDismiss}>
                    <Trans>Back</Trans>
                </Button>
                <Button
                    iconAfterProps={{
                        name: 'code-01',
                        className: 'text-gray-800',
                        size: 'sm',
                    }}
                    className="w-full"
                    type="white"
                    onClick={handleCopy} // Attach the copy handler here
                >
                    <Trans>Copy</Trans>
                </Button>
            </div>
        </Modal>
    )
}

export default EmbedCookieTableModal
