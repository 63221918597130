import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateVendors($input: UploadVendorInput!) {
        updateVendors(input: $input)
    }
`

const useBulkUploadUpdateVendorsMutation = (options) =>
    useMutation(MUTATION, options)

export default useBulkUploadUpdateVendorsMutation
