import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createIdentifiedVendorsReport(
        $input: CreateIdentifiedVendorsReportInput!
    ) {
        createIdentifiedVendorsReport(input: $input) {
            report {
                id
            }
        }
    }
`

const useCreateIdentifiedVendorsReportMutation = (options) =>
    useMutation(MUTATION, options)

export default useCreateIdentifiedVendorsReportMutation
