import React from 'react'
import clsx from 'clsx'
import Avatar from './Avatar'
import ButtonLink from './ButtonLink'
import PopoverVendorInfo from './PopoverVendorInfo'
import ModalCloseButton from './ModalCloseButton'
// From here of on the "newModalTitle" imports start
import { PurposesTag } from './PurposesTags'
import InfoIcon from './InfoIcon'

const ModalTitle = ({
    title,
    isDisabled,
    description,
    descriptionClassName,
    purposeName,
    vendor,
    onClose,
    iconName,
    iconBgColor = 'default',
    iconColor = 'default',
    iconSize = 'lg',
    newModalTitle = false,
    purpose,
    iconTheme = 'modern',
}) => (
    <>
        {!newModalTitle && (
            <>
                {' '}
                <ModalCloseButton onClick={onClose} disabled={isDisabled} />
                <div className="mt-3 mb-6 text-center sm:mt-0 sm:text-left">
                    <div className="flex items-center">
                        {iconName && (
                            <Avatar
                                color={iconBgColor}
                                iconColor={iconColor}
                                iconName={iconName}
                                size={iconSize}
                                className="flex-shrink-0 mr-4"
                            />
                        )}
                        <div>
                            <h3
                                className="text-xl leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                {title}
                            </h3>
                            <div className="flex items-center">
                                {vendor && (
                                    <PopoverVendorInfo vendor={vendor}>
                                        <div className="flex items-center">
                                            <ButtonLink className="text-left font-bold">
                                                {vendor.name}
                                            </ButtonLink>
                                        </div>
                                    </PopoverVendorInfo>
                                )}
                                {purposeName && (
                                    <p className="text-sm ml-3 font-medium text-gray-600">
                                        {purposeName}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    {description && (
                        <p
                            className={clsx(
                                'mt-1 text-sm leading-5 text-gray-500',
                                descriptionClassName
                            )}
                        >
                            {description}
                        </p>
                    )}
                </div>
            </>
        )}

        {newModalTitle && (
            <>
                <ModalCloseButton onClick={onClose} disabled={isDisabled} />
                <div className="mt-3 mb-6 text-center sm:mt-0 sm:text-left">
                    <div className="flex items-center gap-4">
                        {iconName && (
                            <InfoIcon
                                iconName={iconName}
                                size={iconSize}
                                color={iconColor}
                                theme={iconTheme}
                            />
                        )}
                        <div>
                            <div className="flex items-center">
                                <h3
                                    className="text-xl leading-6 font-medium text-gray-900"
                                    id="modal-headline"
                                >
                                    {title}
                                </h3>
                                {purpose && <PurposesTag purpose={purpose} />}
                            </div>

                            {description && (
                                <p
                                    className={clsx(
                                        'mt-1 text-sm leading-5 line-clamp-1 text-gray-500',
                                        descriptionClassName
                                    )}
                                >
                                    {description}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="absolute top-[85px] left-0 w-full h-[1px] bg-gray-200 mb-4" />
            </>
        )}
    </>
)

export default ModalTitle
