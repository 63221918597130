import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function IdentifiedCookiesPageTableHeader() {
    return (
        <TableHeaderRow isSticky stickyPosition="below-actions">
            <TableHeaderCell columnId="nameAndRootdomain" initialWidth={300}>
                <Trans>Name & Rootdomain</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="purposes" initialWidth={200}>
                <Trans>Purposes</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="vendors" initialWidth={100}>
                <Trans>Vendor</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="audits" initialWidth={100}>
                <Trans>Audits</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="belongsToOrganization" />
            <TableHeaderCell columnId="alerts" initialWidth={100}>
                <Trans>Alerts</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="occurrence" initialWidth={150}>
                <Trans>Occurrence</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="lastSeenAt" initialWidth={150}>
                <Trans>Last Seen At</Trans>
            </TableHeaderCell>
            <TableHeaderCell initialWidth={50} />
        </TableHeaderRow>
    )
}
