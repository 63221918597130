import React from 'react'
import clsx from 'clsx'

const DetailsHeader = ({ title, description, className }) => (
    <div
        className={clsx(
            'px-4 py-5 border-b border-gray-200 sm:px-6',
            className
        )}
    >
        <h3 className="text-md leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-sm leading-5 text-gray-500">{description}</p>
    </div>
)

export default DetailsHeader
