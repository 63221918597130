import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import CookiesPage from './CookiesPage'
import useCookiesPageQuery from './useCookiesPageQuery'
import useFilters from '../../hooks/useFilters'
import CookiesBulkUploadModalWithState from './CookiesBulkUploadModalWithState'
import CreateCookieModalWithState from '../../utils/CreateCookieModalWithState'

const CookiesPageWithState = () => {
    const [search, handleSearch] = useSearch()
    const [isCreateCookieModalOpen, setIsCreateCookieModalOpen] =
        useState(false)
    const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false)
    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        hasNoDescription: true,
        hasNoVendor: true,
        hasNoDomain: true,
        hasNoPurpose: true,
        hasNoRegex: true,
    })
    const [orderBy, onChangeOrderBy] = useState({
        occurrence: 'DESC',
    })
    const {
        cookies,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useCookiesPageQuery({ search, filters: filterValues, orderBy })

    useRefetch(refetch)

    return (
        <>
            <CookiesPage
                search={search}
                cookies={cookies}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onSearch={handleSearch}
                isFetching={isFetching}
                error={error}
                activeFilters={activeFilters}
                onAddFilter={onAddFilter}
                onRemoveFilter={onRemoveFilter}
                onRemoveAllFilters={onRemoveAllFilters}
                onChangeOrderBy={(value, direction) =>
                    onChangeOrderBy({ [value]: direction })
                }
                initialOrderBy={orderBy}
                onCreateCookie={() => setIsCreateCookieModalOpen(true)}
                onBulkUpload={() => setIsBulkUploadModalOpen(true)}
                onDeleteCookiesCompleted={() => refetch()}
            />

            {isBulkUploadModalOpen && (
                <CookiesBulkUploadModalWithState
                    onDismiss={() => setIsBulkUploadModalOpen(false)}
                />
            )}
            {isCreateCookieModalOpen && (
                <CreateCookieModalWithState
                    onDismiss={() => setIsCreateCookieModalOpen(false)}
                />
            )}
        </>
    )
}

export default CookiesPageWithState
