import React from 'react'
import { Trans } from '@lingui/macro'
import Tabs, { TabLink } from '../../utils/Tabs'
import PageHeader from '../../layout/PageHeader'

const OrganizationVendorsPageHeader = () => (
    <>
        <PageHeader
            className="pb-4"
            title={<Trans>Classifications for your organization</Trans>}
            description={
                <Trans>
                    Webclew already has a large dataset of classified vendors,
                    cookies and tracks build in. If you want to define your own
                    vendors you can do that here.
                </Trans>
            }
        />
        <Tabs hasMarginBottom={false}>
            <TabLink exact to="/classifications/organization">
                <Trans>Vendors</Trans>
            </TabLink>
            <TabLink exact to="/classifications/organization/cookies">
                <Trans>Cookies</Trans>
            </TabLink>
            <TabLink exact to="/classifications/organization/trackers">
                <Trans>Trackers</Trans>
            </TabLink>
        </Tabs>
    </>
)

export default OrganizationVendorsPageHeader
