import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import CURRENT_USER_FRAGMENT from '../../../apollo/fragments/currentUser'

export const LOGIN_MUTATION = gql`
    mutation loginWithPassword($input: LoginWithPasswordInput!) {
        loginWithPassword(input: $input) {
            accessToken
            refreshToken
            me {
                ...CurrentUser
            }
        }
    }
    ${CURRENT_USER_FRAGMENT}
`

const useLoginMutation = () => useMutation(LOGIN_MUTATION)

export default useLoginMutation
