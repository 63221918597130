/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import AddConsentProfileModal from './AddConsentProfileModal'
import useAddConsentProfileMutation from './useAddConsentProfileMutation'
import useAddConsentProfileFormState from './useAddConsentProfileFormState'
import useAddConsentProfileModalQuery from './useAddConsentProfileModalQuery'

const useSelectedConsentProfile = (formState, consentProfiles) => {
    const selected = formState.values.cmpId || null
    const [first] = consentProfiles
    useEffect(() => {
        if (selected === null && first) {
            formState.handleChange('consentProfileId', first.id)
        }
    }, [first, selected])
    return selected
}

const AddConsentProfileModalWithState = ({
    audit,
    consentProfiles = null,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const history = useHistory()
    const canSkipDataFetching = consentProfiles !== null
    const query = useAddConsentProfileModalQuery(audit.id, {
        skip: canSkipDataFetching,
    })
    const [addConsentProfile] = useAddConsentProfileMutation({
        onCompleted,
        onError,
    })
    const formState = useAddConsentProfileFormState()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const finalConsentProfiles = consentProfiles || query.consentProfiles
    const { currentConsentProfiles } = query
    const filteredConsentProfiles = finalConsentProfiles.filter(
        ({ id }) => !currentConsentProfiles.some((profile) => profile.id === id)
    )
    useSelectedConsentProfile(formState, filteredConsentProfiles)
    return (
        <AddConsentProfileModal
            consentProfiles={filteredConsentProfiles}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { consentProfileId } = formState.values
                        const consentProfile = finalConsentProfiles.find(
                            ({ id }) => id === consentProfileId
                        )
                        const input = { consentProfileId }
                        await addConsentProfile({
                            variables: { auditId: audit.id, input },
                        })
                        await query.refetch()
                        formState.resetForm()
                        history.push(`/audits/${audit.id}/settings`)
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    New consent profile {consentProfile.name}{' '}
                                    assigned!
                                </Trans>
                            ),
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default AddConsentProfileModalWithState
