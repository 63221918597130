import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function CookieTableItemPageHeaderRow() {
    return (
        <TableHeaderRow isSticky>
            <TableHeaderCell
                columnId="name"
                canSort
                canResize
                initialWidth={250}
            >
                <Trans>Name</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="purposes"
                canHide
                canResize
                initialWidth={180}
            >
                <Trans>Purposes</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="audits"
                isInitiallyHidden
                canHide
                canResize
                initialWidth={200}
            >
                <Trans>Vendor</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="vendorTypes"
                isInitiallyHidden
                canHide
                canResize
                initialWidth={200}
            >
                <Trans>Vendor types</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="description"
                canHide
                canResize
                initialWidth={250}
            >
                <Trans>Description</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="occurrence"
                canSort
                canHide
                canResize
                initialWidth={150}
            >
                <Trans>Occurrence</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="privacyPolicyUrl"
                canHide
                canResize
                initialWidth={250}
            >
                <Trans>Privacy Policy</Trans>
            </TableHeaderCell>
            <TableHeaderCell />
        </TableHeaderRow>
    )
}
