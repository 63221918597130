import React from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import ErrorPage from '../../utils/ErrorPage'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import PageLoading from '../../utils/PageLoading'
import CreateOrganizationVendorModalWithState from './CreateOrganizationVendorModalWithState'
import OrganizationVendorsPage from './OrganizationVendorsPage'
import useOrganizationVendorPageQuery from './useOrganizationVendorPageQuery'

const OrganizationVendorsPageWithState = () => {
    const history = useHistory()
    const [search, handleSearch] = useSearch('')
    const createModalIsOpen = useRouteMatch({
        path: '/classifications/organization/create',
    })
    const {
        vendors,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useOrganizationVendorPageQuery(search)
    useRefetch(refetch)
    return (
        <>
            <OrganizationVendorsPage
                search={search}
                vendors={vendors}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onSearch={handleSearch}
                onCreateVendor={() =>
                    history.push('/classifications/organization/create')
                }
                onDeleteCompleted={() => refetch()}
            />
            {createModalIsOpen && (
                <CreateOrganizationVendorModalWithState
                    onDismiss={() =>
                        history.push('/classifications/organization')
                    }
                    onCompleted={() => refetch()}
                />
            )}
        </>
    )
}

export default OrganizationVendorsPageWithState
