import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'
import * as constants from '../../../constants'

const { BULK_UPLOAD_CREATE: CREATE, BULK_UPLOAD_UPDATE: UPDATE } = constants

const validation = createFormValidation([
    {
        path: 'action',
        validate: isRequired,
        message: <Trans>Select an action.</Trans>,
    }
])

const useBulkUploadCookiesFormState = () =>
    useFormState(
        {
            action: 'CREATE',
            csvFile: null,
            csvPasteFile: '',
        },
        {
            validation,
        }
    )

export default useBulkUploadCookiesFormState
