import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query updateTrackerModalQuery($id: ID) {
        tracker(id: $id) {
            id
            domain
            purposes {
                id
            }
            vendor {
                id
                name
            }
        }
        purposes {
            id
            name
            children {
                id
                name
                children {
                    id
                    name
                }
            }
        }
    }
`

const useUpdateTrackerModalQuery = (trackerId, options) => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        variables: { id: trackerId },
        ...options,
    })
    return {
        error,
        purposes: data.purposes || [],
        tracker: data.tracker || { purposes: [], vendor: {} },
        isFetching,
        refetch,
    }
}

export default useUpdateTrackerModalQuery
