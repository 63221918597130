import React from 'react'
import { Trans } from '@lingui/macro'
import TableLoading from '../../utils/TableLoading'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import SectionHeader from '../../utils/SectionHeader'
import PageHeader from '../../layout/PageHeader'
import LoginProfilesPageTable from './LoginProfilesPageTable'
import SettingsPagePageSidebar from '../settings/SettingsPageSidebar'
import TableStateProvider from '../../providers/TableStateProvider'

const LoginProfilesPage = ({
    loginProfiles = [],
    isFetchingMore,
    isFetching,
    hasMoreRecords,
    onFetchMore,
    onDeleteCompleted,
    onCreate,
}) => (
    <PageContainer data-testid="SettingsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPagePageSidebar />
            <PageContent isFetching={isFetching}>
                <PageHeader
                    className="pb-4"
                    title={<Trans>Login profiles</Trans>}
                    description={
                        <Trans>
                            Build a resolver to authenticate for your domain.
                            You can then assign <br /> a login profiles to an
                            audit in the ‘Domains & Profiles’ tab of that audit.{' '}
                        </Trans>
                    }
                    rightChildren={
                        <Button onClick={onCreate}>
                            <Trans>Create login profile</Trans>
                        </Button>
                    }
                />
                {(() => {
                    if (loginProfiles.length === 0) {
                        return (
                            <EmptyPageCard
                                iconName="settings"
                                title={<Trans>No login profiles</Trans>}
                                description={
                                    <Trans>
                                        No login profiles where found for this
                                        organization.
                                        <br />
                                        Start by adding new login profiles here.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider>
                            <LoginProfilesPageTable
                                loginProfiles={loginProfiles}
                                isFetchingMore={isFetchingMore}
                                hasMoreRecords={hasMoreRecords}
                                onFetchMore={onFetchMore}
                                onDeleteCompleted={onDeleteCompleted}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default LoginProfilesPage
