import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { connectionToCollection } from '../../../utils/graphql'
import AuditAlertsPageTableRow from './AuditAlertsPageTableRow'

const QUERY = gql`
    query alertsPage(
        $auditId: ID!
        $after: String
        $orderBy: AlertOrderByInput
        $filters: AlertFilters
    ) {
        audit(id: $auditId) {
            id
            name
            imageUrl
            isMobile
            isWeb
        }
        alertsByPeriod(auditId: $auditId, filters: $filters) {
            warningAlerts
            issueAlerts
            criticalAlerts
        }
        alerts(
            auditId: $auditId
            after: $after
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                node {
                    ...AuditAlertsPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${AuditAlertsPageTableRow.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useAuditAlertsPageQuery = (type, status, startDate, endDate) => {
    const { id: auditId } = useParams()
    const orderBy = {}
    const filters = {
        type,
        status,
        startDate,
        endDate,
    }
    const variables = { auditId, orderBy, filters }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'alerts'
    )
    return {
        error,
        alertsByPeriod: data.alertsByPeriod,
        alerts: connectionToCollection(data.alerts),
        audit: data.audit || {},
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useAuditAlertsPageQuery
