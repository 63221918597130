import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import StaffUnclassifiedTrackersPage from './StaffUnclassifiedTrackersPage'
import useStaffUnclassifiedTrackersPageQuery from './useStaffUnclassifiedTrackersPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const StaffUnclassifiedTrackersPageWithState = () => {
    const [search, handleSearch] = useSearch()
    const dateRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = dateRangeState
    const [orderBy, onChangeOrderBy] = useState({
        rootDomain: 'ASC',
    })
    const {
        unclassifiedTrackers,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useStaffUnclassifiedTrackersPageQuery({
        search,
        orderBy,
        startDate,
        endDate,
        period,
    })
    useRefetch(refetch)
    const clearState = () => {
        onChangeOrderBy({ name: 'ASC' })
    }

    return (
        <StaffUnclassifiedTrackersPage
            search={search}
            dateRangeState={dateRangeState}
            unclassifiedTrackers={unclassifiedTrackers}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            onSearch={handleSearch}
            isFetching={isFetching}
            error={error}
            orderBy={orderBy}
            onChangeOrderBy={(value, direction) =>
                onChangeOrderBy({ [value]: direction })
            }
            onDeleteUnclassifiedTrackersCompleted={() => refetch()}
            onReset={clearState}
            onClassifyTrackerCompleted={() => refetch()}
        />
    )
}

export default StaffUnclassifiedTrackersPageWithState
