import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import ScanPageTableHeaderRow from './ScanPageTableHeaderRow'
import ScanPageTableRow from './ScanPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function ScanPageTable({
    scans = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onDeleteScanCompleted,
}) {
    const hasScans = isFetching || scans.length > 0
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            {hasScans ? (
                <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                    <thead>
                        <ScanPageTableHeaderRow />
                    </thead>
                    <TableBody>
                        {scans.map((scan) => (
                            <ScanPageTableRow
                                key={scan.id}
                                scan={scan}
                                onDeleteScanCompleted={onDeleteScanCompleted}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyPageCard
                    iconName="scan"
                    title={<Trans>No scans</Trans>}
                    description={
                        <Trans>
                            No scans found. Try to expand your filters or wait
                            for a scan to run.
                        </Trans>
                    }
                />
            )}
        </InfiniteScroll>
    )
}
