import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

export default function IdentifiedScanDetailsDrawerTabInstances({
    identifiedScanTrackerId,
}) {
    if (!identifiedScanTrackerId) {
        return (
            <DrawerEmptyCard
                iconName="scan"
                title={<Trans>No Information</Trans>}
                description={<Trans>There was no instnces found. </Trans>}
            />
        )
    }

    return (
        <DrawerEmptyCard
            iconName="scan"
            title={<Trans>No Information</Trans>}
            description={<Trans>There was no instnces found. </Trans>}
        />
    )
}
