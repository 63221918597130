import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import BulkUploadModal from '../staff-vendors/BulkUploadModal'
import useBulkUploadTrackersFormState from './useBulkUploadTrackersFormState'
import useBulkUploadUpdateTrackersMutation from './useBulkUploadUpdateTrackersMutation'
import useBulkUploadValidateTrackersMutation from './useBulkUploadValidateTrackersMutation'
import useBulkUploadCreateTrackersMutation from './useBulkUploadCreateTrackersMutation'

const TrackersBulkUploadModalWithState = ({ onDismiss }) => {
    const formState = useBulkUploadTrackersFormState()
    const [updateTrackers] = useBulkUploadUpdateTrackersMutation()
    const [createTrackers] = useBulkUploadCreateTrackersMutation()
    const [validateTrackers, { loading, data, reset }] =
        useBulkUploadValidateTrackersMutation()
    const [isFileValidated, setIsFileValidated] = useState(false)
    const { dispatchSuccess, dispatchError } = useNotifications()
    return (
        <BulkUploadModal
            isValidated={isFileValidated}
            alertRecords={data?.validateTrackers}
            formState={formState}
            onReset={() => {
                setIsFileValidated(false)
                reset()
                formState.resetForm()
            }}
            onDismiss={onDismiss}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { action, csvFile } = formState.values
                        const input = { action, csvFile }
                        const variables = { input }

                        if (!isFileValidated) {
                            validateTrackers({ variables })
                            setIsFileValidated(true)
                        }
                        if (data?.validateTrackers.errors.length === 0) {
                            if (action === 'UPDATE') {
                                await updateTrackers({ variables })
                                setIsFileValidated(false)
                                dispatchSuccess({
                                    message: (
                                        <Trans>All Cookies are updated</Trans>
                                    ),
                                })
                                onDismiss()
                            } else if (action === 'CREATE') {
                                await createTrackers({ variables })
                                setIsFileValidated(false)
                                dispatchSuccess({
                                    message: (
                                        <Trans>All Vendors are created</Trans>
                                    ),
                                })
                                onDismiss()
                            } else {
                                dispatchError({
                                    message: (
                                        <Trans>
                                            Oops, Something went wrong
                                        </Trans>
                                    ),
                                })
                            }
                        }
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
            isUploading={loading}
        />
    )
}

export default TrackersBulkUploadModalWithState
