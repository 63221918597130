/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'

const StaffJobsPageStartJobButton = ({
    isLoading,
    onCreateIdentifiedPeriod,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <DropdownMenuItem
                iconName="clock"
                onClick={async () => {
                    onCreateIdentifiedPeriod()
                    close()
                }}
            >
                <Trans>Identified period</Trans>
            </DropdownMenuItem>
        )}
    >
        <Button className="my-3" loading={isLoading}>
            <Trans>Start job manually</Trans>
        </Button>
    </DropdownMenu>
)

export default StaffJobsPageStartJobButton
