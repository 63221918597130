/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DropdownMenuTitle from '../../utils/DropdownMenuTitle'
import ActionButton from '../../utils/ActionButton'

const StaffUnclassifiedTrackersPageTableRowActionButton = ({
    isLoading,
    onClassify,
}) => (
    <DropdownMenu
        as="span"
        position="bottom-start"
        content={({ close }) => (
            <>
                <DropdownMenuTitle>
                    <Trans>Staff actions</Trans>
                </DropdownMenuTitle>
                <DropdownMenuItem
                    iconName="tracker"
                    onClick={(e) => {
                        onClassify()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Classify Tracker</Trans>
                </DropdownMenuItem>
            </>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default StaffUnclassifiedTrackersPageTableRowActionButton
