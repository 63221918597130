import React from 'react'
import clsx from 'clsx'

const FormButtonGroup = ({ className, ...props }) => (
    <div
        className={clsx(
            'pt-5 flex flex-row-reverse sm:border-gray-200 gap-4',
            className
        )}
        {...props}
    />
)

export default FormButtonGroup
