import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageHeader from '../../layout/PageHeader'
import PageContainer from '../../layout/PageContainer'
import StaffHeader from '../../layout/StaffHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import StaffOrganizationsSidebar from '../staff-organizations/StaffOrganizationsSidebar'
import StaffOrganizationUsersPageTable from './StaffOrganizationUsersPageTable'

const StaffOrganizationUsersPage = ({
    users,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteUserSuccess,
    isFetching,
}) => (
    <PageContainer data-testid="StaffOrganizationUsersPage">
        <StaffHeader />
        <SidebarContainer>
            <StaffOrganizationsSidebar />
            <PageContent>
                <PageHeader
                    title={<Trans>Organization Users</Trans>}
                    className="mb-8"
                />
                <StaffOrganizationUsersPageTable
                    users={users}
                    isFetchingMore={isFetchingMore}
                    onFetchMore={onFetchMore}
                    hasMoreRecords={hasMoreRecords}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                    isFetching={isFetching}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default StaffOrganizationUsersPage
