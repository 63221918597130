import React from 'react'
import AppRouter from './AppRouter'

const App = ({
    error, // Do something with this
    history,
    isLoading,
    isAuthenticated,
    currentUser,
}) => {
    if (isLoading) {
        return null
    }
    if (isAuthenticated && !currentUser) {
        return null
    }
    return (
        <AppRouter
            history={history}
            isAuthenticated={isAuthenticated}
            currentUser={currentUser}
        />
    )
}

export default App
