import decodeJwtToken from 'jwt-decode'
import * as storage from './storage'
import refreshAccessToken from '../apollo/mutations/refreshAccessToken'

export const isTokenExpired = (token) => {
    const decodedToken = decodeJwtToken(token)
    const tokenExpiresAt = decodedToken.exp
    return tokenExpiresAt < new Date().getTime()
}

export const loadAndValidateTokens = async () => {
    let accessToken = await storage.getAccessToken()
    let refreshToken = await storage.getRefreshToken()
    const tokensAreSet = accessToken !== null && refreshToken !== null
    if (tokensAreSet) {
        if (isTokenExpired(refreshToken)) {
            await storage.removeAccessToken()
            await storage.removeRefreshToken()
            accessToken = null
            refreshToken = null
        } else if (isTokenExpired(accessToken)) {
            accessToken = await refreshAccessToken(refreshToken)
            if (accessToken !== null) {
                await storage.setAccessToken(accessToken)
            }
        }
    }
    return {
        refreshToken,
        accessToken,
    }
}
