import { gql, useMutation } from '@apollo/client'

export const LOGIN_MUTATION = gql`
    mutation createOrganization($input: CreateOrganizationInput!) {
        createOrganization(input: $input) {
            organization {
                id
                name
            }
        }
    }
`

export default function useCreateOrganizationMutation(options) {
    return useMutation(LOGIN_MUTATION, options)
}
