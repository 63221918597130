import React from 'react'
import clsx from 'clsx'

export default function Skeleton({ className, rounding = 'md', ...props }) {
    return (
        <div
            className={clsx(
                'animate-pulse bg-slate-100',
                rounding === 'md' && 'rounded-md',
                rounding === 'full' && 'rounded-full',
                className
            )}
            {...props}
        />
    )
}
