import React from 'react'
import BulkPasteCard from './BulkPasteCard'
import FormInputErrors from '../../utils/FormInputErrors'
import InfoIcon from '../../utils/InfoIcon'

const FormPasteCard = ({ errors, className, inputClassName, ...props }) => (
    <div className={className}>
        <BulkPasteCard className={inputClassName} {...props}>
            <InfoIcon iconName="edit" size="md" className="mb-2" />
            <h1 className="text-sm">
                <b className="text-primary-600">Paste </b>
                CSV rows to upload
            </h1>
        </BulkPasteCard>
        <FormInputErrors errors={errors} />
    </div>
)

export default FormPasteCard
