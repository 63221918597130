import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import PageHeader from '../../utils/PageHeader'
import PageContent from '../../utils/PageContent'
import EmptyPageCard from '../../utils/EmptyPageCard'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'

const ProfilePage = () => (
    <PageContainer>
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent>
                <PageHeader
                    title={<Trans>Profile</Trans>}
                    breadcrumbsItems={[
                        {
                            text: <Trans>Profile</Trans>,
                            to: '/profile',
                        },
                    ]}
                />
                <EmptyPageCard
                    iconName="profile"
                    title="Coming soon..."
                    description="Stay tuned, we are in the process of adding this page."
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default ProfilePage
