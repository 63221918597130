import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import VendorDetailsPageHeader from '../staff-vendor-details/VendorDetailsPageHeader'
import VendorTrackersPageTable from './VendorTrackersPageTable'
import SidebarContainer from '../../layout/SidebarContainer'
import StaffHeader from '../../layout/StaffHeader'
import VendorsPageSidebar from '../staff-vendors/VendorsPageSidebar'

const VendorsTrackersPage = ({
    vendor,
    trackers,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => (
    <PageContainer data-testid="VendorsTrackersPage">
        <StaffHeader />
        <SidebarContainer>
            <VendorsPageSidebar />
            <PageContent>
                <VendorDetailsPageHeader vendor={vendor} />
                <VendorTrackersPageTable
                    trackers={trackers}
                    isFetchingMore={isFetchingMore}
                    onFetchMore={onFetchMore}
                    hasMoreRecords={hasMoreRecords}
                    isFetching={isFetching}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default VendorsTrackersPage
