import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>The name is required</Trans>,
    },
])

const valuesToInput = (values) => values

const useUpdateSettingsFormState = ({ name = '', websiteUrl = '' }) =>
    useFormState(
        {
            name,
            websiteUrl,
        },
        {
            validation,
            valuesToInput,
        }
    )

export default useUpdateSettingsFormState
