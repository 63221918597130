import React from 'react'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import * as constants from '../../../constants'
import PageContainer from '../../layout/PageContainer'
import PageContent from '../../utils/PageContent'
import Button from '../../utils/Button'
import Icon from '../../utils/Icon'
import OrganizationListItem from './OrganizationListItem'

const { USER_TYPE_STAFF: STAFF } = constants

const MyOrganizationsPage = ({
    organizations,
    onSelectOrganization,
    currentUser,
}) => (
    <PageContainer>
        <PageContent>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 mb-16 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    <Trans>My Organizations</Trans>
                </h2>
                <div className="mb-10">
                    <ul className="bg-white shadow overflow-hidden rounded-lg max-w-lg mb-10">
                        {organizations.map((organization) => (
                            <OrganizationListItem
                                key={organization.id}
                                organization={organization}
                                onClick={() =>
                                    onSelectOrganization(organization.id)
                                }
                            />
                        ))}
                        {organizations.length === 0 && (
                            <div className="shadow rounded-md bg-white px-6 py-14 ">
                                <Icon
                                    name="warning-triangle"
                                    color="primary"
                                    size="2xl"
                                    className="m-auto"
                                />
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4 text-center">
                                    <Trans>No organizations</Trans>
                                </h3>
                                <p className="mt-2 max-w-lg m-auto text-sm leading-5 text-gray-500 text-center">
                                    <Trans>
                                        It looks like no organizations have been
                                        linked to your account. Please contact
                                        your admin or support.
                                    </Trans>
                                </p>
                            </div>
                        )}
                    </ul>
                    {currentUser.type === STAFF && (
                        <Link to="/staff">
                            <Button className="w-full">
                                <Trans>Staff portal</Trans>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
        </PageContent>
    </PageContainer>
)

export default MyOrganizationsPage
