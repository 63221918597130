import React, { useState } from 'react'
import gql from 'graphql-tag'
import AlertActivityListItem from './AlertActivityListItem'
import AlertDetailsDrawer from '../staff-scans/AlertDetailsDrawer'
import useDrawerStack from '../../hooks/useDrawerStack'

export const ALERT_DELEGATION_LIST_ITEM_FRAGMENT = gql`
    fragment AlertDelegationListItem on AlertDelegation {
        id
        groupingId
        createdAt
        externalEmail
        type
        user {
            id
            fullName
        }
        author {
            id
            fullName
        }
        audit {
            id
            name
            imageUrl
        }
        lastKnownAlert {
            id
            subjectName
        }
    }
`
export const ALERT_COMMENT_LIST_ITEM_FRAGMENT = gql`
    fragment AlertCommentListItem on AlertComment {
        id
        groupingId
        createdAt
        author {
            id
            fullName
        }
        lastKnownAlert {
            id
            subjectName
        }
    }
`

export default function AlertActivityListItemWithState({
    activity,
    onAlertStatusChanged,
    onComment,
    startDate,
    endDate,
}) {
    const { open } = useDrawerStack()
    const handleClick = activity.lastKnownAlert?.id
        ? () => {
              open(AlertDetailsDrawer, {
                  page: 'dashboard',
                  alertId: activity.lastKnownAlert.id,
              })
          }
        : undefined
    return <AlertActivityListItem activity={activity} onClick={handleClick} />
}
