/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormButtonGroup from '../../utils/FormButtonGroup'
import VendorSearchSelect from '../../utils/VendorSearchSelect'
import PurposesSelect from '../../utils/PurposesSelect'

const UpdateOrganizationTrackerModal = ({
    title = <Trans>Update tracker</Trans>,
    description = <Trans>Attach a vendor and purpose to this tracker</Trans>,
    initialVendor,
    formState,
    isFetching,
    canCreateVendor,
    onDismiss,
    onSubmit,
}) => {
    const { values, handleChange, getErrorMessages, getNativeInputProps } =
        formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                title={title}
                description={description}
                onClose={onDismiss}
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        <FormGroup
                            label={<Trans>Domain</Trans>}
                            className="mb-4"
                        >
                            <FormInput
                                errors={getErrorMessages('domain')}
                                {...getNativeInputProps('domain')}
                            />
                        </FormGroup>
                        <FormGroup
                            label={<Trans>Description</Trans>}
                            className="mb-4"
                        >
                            <FormInput
                                errors={getErrorMessages('description')}
                                {...getNativeInputProps('description')}
                            />
                        </FormGroup>
                        <FormGroup
                            label={<Trans>Vendor</Trans>}
                            className="mb-4"
                        >
                            <VendorSearchSelect
                                isOrganizationContext
                                value={values.vendorId}
                                initialVendor={initialVendor}
                                onChange={(id) => handleChange('vendorId', id)}
                                errors={getErrorMessages('vendorId')}
                                canCreateVendor={canCreateVendor}
                            />
                        </FormGroup>
                        <FormGroup label={<Trans>Purposes</Trans>}>
                            <PurposesSelect
                                value={values.purposeIds}
                                errors={getErrorMessages('purposeIds')}
                                onChange={(ids) =>
                                    handleChange('purposeIds', ids)
                                }
                            />
                        </FormGroup>
                        <FormButtonGroup>
                            <Button onClick={onSubmit}>
                                <Trans>Done</Trans>
                            </Button>
                            <Button type="white" onClick={onDismiss}>
                                <Trans>Cancel</Trans>
                            </Button>
                        </FormButtonGroup>
                    </>
                )
            })()}
        </Modal>
    )
}

export default UpdateOrganizationTrackerModal
