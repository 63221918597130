/* eslint-disable react/no-danger */
import React from 'react'
import NextHead from 'next/head'

const TITLE = 'Webclew'
const DESCRIPTION = 'Webclew makes compliance monitoring made easy.'
const THEME_COLOR = '#5200FF'
const OG_URL = 'https://app.webclew.com'

export default function PageHead({
    title = TITLE,
    description = DESCRIPTION,
    keywords,
    authors,
    ogUrl = OG_URL,
    ogTitle = title,
    ogDescription = description,
    ogImageUrl,
    ogImageWidth = 1200,
    ogImageHeight = 627,
}) {
    const faviconPostfix = ''
    // if (process.env.NEXT_PUBLIC_APP_ENV === 'staging') {
    //     faviconPostfix = '-staging'
    // }
    return (
        <NextHead>
            <meta name="robots" content="index, follow" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
            />

            <title>{title}</title>

            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="author" content={authors} />
            <meta name="theme-color" content={THEME_COLOR} />

            <meta property="og:url" content={ogUrl} />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={ogDescription} />
            <meta property="og:image" content={ogImageUrl} />
            <meta property="og:image:width" content={ogImageWidth} />
            <meta property="og:image:height" content={ogImageHeight} />

            <link rel="manifest" href="/site.webmanifest" />

            <link
                rel="shortcut icon"
                type="image/x-icon"
                href={`/favicon${faviconPostfix}.ico`}
            />
            <link
                rel="shortcut icon"
                type="image/png"
                href={`/favicon${faviconPostfix}-16x16.png`}
                sizes="16x16"
            />
            <link
                rel="shortcut icon"
                type="image/png"
                href={`/favicon${faviconPostfix}-32x32.png`}
                sizes="32x32"
            />
            <link
                rel="shortcut icon"
                type="image/png"
                href={`/favicon${faviconPostfix}-48x48.png`}
                sizes="48x48"
            />
            <link
                rel="shortcut icon"
                type="image/png"
                href={`/favicon${faviconPostfix}-96x96.png`}
                sizes="96x96"
            />
            <link
                rel="shortcut icon"
                type="image/png"
                href={`/favicon${faviconPostfix}-144x144.png`}
                sizes="144x144"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`/apple-touch-icon${faviconPostfix}.png`}
            />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin="anonymous"
            />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=auto"
            />
        </NextHead>
    )
}
