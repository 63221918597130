import React from 'react'
import { Trans } from '@lingui/macro'
import Tabs, { Tab } from '../../utils/Tabs'
import StaffJobsPageStartJobButtonWithState from './StaffJobsPageStartJobButtonWithState'
import PageHeader from '../../layout/PageHeader'

const StaffJobsPageHeader = ({ activeStatus, onChangeStatus, title }) => (
    <>
        <PageHeader
            title={title}
            description={
                <Trans>
                    Below you can find the global vendor directory of Webclew.
                </Trans>
            }
            rightChildren={<StaffJobsPageStartJobButtonWithState />}
        />
        <Tabs className="px-6 -mx-6">
            <Tab
                onClick={() => onChangeStatus('completed')}
                isActive={activeStatus === 'completed'}
            >
                <Trans>Completed</Trans>
            </Tab>
            <Tab
                onClick={() => onChangeStatus('active')}
                isActive={activeStatus === 'active'}
            >
                <Trans>Active</Trans>
            </Tab>
            <Tab
                onClick={() => onChangeStatus('failed')}
                isActive={activeStatus === 'failed'}
            >
                <Trans>Failed</Trans>
            </Tab>
            <Tab
                onClick={() => onChangeStatus('repeat')}
                isActive={activeStatus === 'repeat'}
            >
                <Trans>Repeat</Trans>
            </Tab>
            <Tab
                onClick={() => onChangeStatus('waiting')}
                isActive={activeStatus === 'waiting'}
            >
                <Trans>Waiting</Trans>
            </Tab>
        </Tabs>
    </>
)

export default StaffJobsPageHeader
