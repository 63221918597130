import React, { useState } from 'react'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import CustomCmpsPageTableRowActionButton from './CustomCmpsPageTableRowActionButton'
import DeleteCmpModalWithState from './DeleteCmpModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const CustomCmpsPageTableRowActionButtonWithState = ({
    cmp,
    onDeleteCmpCompleted,
}) => {
    const { currentUser } = useAppState()
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    return (
        <>
            {isDeleteModalOpen && (
                <DeleteCmpModalWithState
                    cmp={cmp}
                    onDismiss={() => setDeleteModalOpen(false)}
                    onCompleted={onDeleteCmpCompleted}
                />
            )}
            <CustomCmpsPageTableRowActionButton
                cmp={cmp}
                canDeleteCmp={currentUser.role === OWNER}
                onDeleteCmp={() => setDeleteModalOpen(true)}
            />
        </>
    )
}

export default CustomCmpsPageTableRowActionButtonWithState
