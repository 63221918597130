import React from 'react'
import { Trans } from '@lingui/macro'
import dotProp from 'dot-prop-immutable'

export const connectionToCollection = (connection = { edges: [] }) =>
    connection.edges.map((edge) => edge.node)

export const nestedConnectionToCollection = (key) => (entity) => ({
    ...entity,
    [key]: connectionToCollection(entity[key]),
})

export const mergeQueryResults = (previousResult, fetchMoreResult, path) => {
    const { edges: newEdges, totalCount, pageInfo } = fetchMoreResult[path]

    return newEdges.length
        ? {
              [path]: {
                  // eslint-disable-next-line no-underscore-dangle
                  __typename: previousResult[path].__typename,
                  edges: [...previousResult[path].edges, ...newEdges],
                  pageInfo,
                  totalCount,
              },
          }
        : previousResult
}

export const graphqlErrorsToFormErrors = (errors) =>
    errors.reduce(
        (invalidFields, error) => ({
            ...invalidFields,
            ...error.extensions.exception.invalidFields,
        }),
        {}
    )

export const isUnauthenticatedError = (error) =>
    error.extensions && error.extensions.code === 'UNAUTHENTICATED'

export const createQueryUpdater =
    (client, query, variables) => (path, value) => {
        const data = client.readQuery({
            query,
            variables,
        })
        const mutatedData = dotProp.set(data, path, value)
        client.writeQuery({
            query,
            variables,
            data: mutatedData,
        })
    }

export const getGraphqlErrorMessage = (error) => {
    let message = (
        <Trans>
            Oops, looks like something went wrong, please try again later.
        </Trans>
    )
    if (error.networkError) {
        if (error.networkError.message) {
            message = error.networkError.message
        }
        if (error.networkError.result) {
            message = error.networkError.result.errors[0].message
        }
    }
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        message = error.graphQLErrors[0].message
    }
    return message
}
