import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import * as constants from '../../../constants'
import { isRequired, isValidUrl } from '../../../utils/validation'

export const { DOMAIN_CONSENT_RESOLVER_RUNTIME: RUNTIME } = constants

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>The name is required</Trans>,
    },
    {
        path: 'domains.*.name', // TODO broken
        validate: (value) => isRequired(value) && isValidUrl(value),
        message: <Trans>Please choose a valid domain url.</Trans>,
    },
])

const valuesToInput = ({
    name,
    type,
    takeScreenshots,
    cmpId,
    domains,
    appleAppStoreUrl,
    googlePlayStoreUrl,
    tags,
}) => ({
    name,
    cmpId,
    type,
    takeScreenshots,
    appleAppStoreUrl,
    googlePlayStoreUrl,
    domains,
    tags,
})

const useCreateAuditFormState = (cmps = []) => {
    const [firstCmp = {}] = cmps
    const formState = useFormState(
        {
            name: '',
            type: 'WEB',
            takeScreenshots: false,
            appleAppStoreUrl: 'https://apps.apple.com/app/',
            googlePlayStoreUrl: 'https://play.google.com/store/apps/',
            cmpId: firstCmp.id,
            domains: ['https://'],
            tags: [],
        },
        {
            validation,
            valuesToInput,
        }
    )
    const handleAddDomain = () => {
        formState.handleChange('domains', (domains) => ['https://', ...domains])
    }

    const handleRemoveDomain = (domainIndex) => {
        formState.handleChange('domains', (domains) => {
            if (domains.length === 1) {
                return ['https://']
            }
            return domains.filter((_, i) => i !== domainIndex)
        })
    }

    return {
        ...formState,
        handleAddDomain,
        handleRemoveDomain,
    }
}

export default useCreateAuditFormState
