import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import CookieTableItemPageTableRow from './CookieTableItemPageTableRow'
import CookieTableItemPageTableHeaderRow from './CookieTableItemPageTableHeaderRow'

const CookieTableItemsPageTable = ({
    auditIds,
    cookieTableItems,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onUpdateCookieTableItemsCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table
            data-testid="CookieTablesPageTable"
            isFullWidth
            hasMinWidth={false}
            isFetching={isFetchingMore}
        >
            <thead>
                <CookieTableItemPageTableHeaderRow />
            </thead>
            <TableBody>
                {cookieTableItems
                    .filter((tableItem) => tableItem.status === 'ADDED')
                    .map((cookieTableItem) => (
                        <CookieTableItemPageTableRow
                            auditIds={auditIds}
                            key={cookieTableItem.id}
                            cookieTableItem={cookieTableItem}
                            onUpdateCookieTableItemsCompleted={
                                onUpdateCookieTableItemsCompleted
                            }
                        />
                    ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default CookieTableItemsPageTable
