/* eslint-disable import/prefer-default-export */
let instance = null
export class Events {
    constructor() {
        this.eventSource = null
    }

    static getInstance() {
        if (instance === null) {
            instance = new Events()
        }
        return instance
    }

    init(token) {
        const url = `${process.env.NEXT_PUBLIC_EVENTS_URI}?token=${token}`
        this.eventSource = new window.EventSource(url)
    }

    close() {
        if (this.eventSource) {
            this.eventSource.close()
            this.eventSource = null
        }
    }
}

export const parseEvent = (message = {}) => {
    const { lastEventId, data } = message
    if (!data || !lastEventId) {
        return {}
    }
    return {
        id: lastEventId,
        data: JSON.parse(data, {}),
    }
}
