import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PageLoading from '../../utils/PageLoading'
import useRefetch from '../../hooks/useRefetch'
import CustomCmpsPage from './CustomCmpsPage'
import useCustomCmpsPageQuery from './useCustomCmpsPageQuery'
import CreateCustomCmpModalWithState from './CreateCustomCmpModalWithState'

const CustomCmpsPageWithState = () => {
    const history = useHistory()
    const { isFetching, cmps, refetch } = useCustomCmpsPageQuery()
    useRefetch(refetch)
    const [createModalIsOpen, setCreateModalOpen] = useState(false)
    if (isFetching) {
        return <PageLoading />
    }
    const finalCmps = cmps.filter(({ isCustom }) => isCustom)
    return (
        <>
            {createModalIsOpen && (
                <CreateCustomCmpModalWithState
                    onDismiss={() => setCreateModalOpen(false)}
                    onCompleted={async (data) => {
                        const { id } = data.createCustomCmp.cmp
                        await refetch()
                        history.push(`/settings/cmps/${id}`)
                    }}
                />
            )}
            <CustomCmpsPage
                cmps={finalCmps}
                onCreateCmp={() => setCreateModalOpen(true)}
                onDeleteCmpCompleted={() => refetch()}
            />
        </>
    )
}

export default CustomCmpsPageWithState
