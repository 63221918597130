import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import Tag from '../../utils/Tag'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import AlertTag from '../../utils/AlertTag'
import TableCellAvatar from '../../utils/TableCellAvatar'

export default function IdentifiedScanCookieDetailsDrawerTabAlerts({
    identifiedCookieAlerts,
}) {
    if (!identifiedCookieAlerts || identifiedCookieAlerts.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="alert-triangle"
                title={<Trans>No Information</Trans>}
                description={<Trans>There was no cookie find. </Trans>}
            />
        )
    }

    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {identifiedCookieAlerts.map((alert) => {
                    const { id, subjectName, subjectType, occurrences } = alert
                    return (
                        <TableRow
                            key={id}
                            onClick={() => {
                                add()
                            }}
                        >
                            <TableCellAvatar
                                columnId="subjectName"
                                py="xs"
                                px="sm"
                                avatarIconName={subjectType?.toLowerCase()}
                                title={subjectName}
                                text={subjectType?.toLowerCase()}
                            />
                            <TableCell py="sm">
                                <div className="flex gap-1 justify-end">
                                    <AlertTag alert={alert} />
                                    <Tag>{occurrences}x</Tag>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
