import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageHeader from '../../layout/PageHeader'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import IdentifiedCookiesPageTable from './IdentifiedCookiesPageTable'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedCookiesPageActions from './IdentifiedCookiesPageActions'
import TableStateProvider from '../../providers/TableStateProvider'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'

export default function IdentifiedCookiesPage({
    cookies,
    audits,
    purposes,
    search,
    selectedAuditId,
    selectedPurposeId,
    scanRangeState,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onSearch,
    onChangeDate,
    onChangeAudit,
    onChangePurpose,
    onFetchMore,
    onClassifyCompleted,
    onUpdateCompleted,
    onDeleteCompleted,
    selectedRows,
    scanCookieNames,
}) {
    return (
        <PageContainer data-testid="IdentifiedCookiesPage">
            <OrganizationHeader />
            <SidebarContainer>
                <IdentifiedVendorsPageSidebar />
                <PageContent size="full">
                    <PageHeader
                        title={<Trans>Cookies observations</Trans>}
                        description={
                            <Trans>
                                Below you can find all cookies we observed on
                                your domains.
                            </Trans>
                        }
                    />
                    <TableStateProvider>
                        <IdentifiedCookiesPageActions
                            audits={audits}
                            purposes={purposes}
                            search={search}
                            scanRangeState={scanRangeState}
                            selectedAuditId={selectedAuditId}
                            selectedPurposeId={selectedPurposeId}
                            hasSelectedRows={selectedRows.length === 0}
                            onChangeAudit={onChangeAudit}
                            onChangeDate={onChangeDate}
                            onSearch={onSearch}
                            onChangePurpose={onChangePurpose}
                            onClassifyCompleted={onClassifyCompleted}
                            onUpdateCompleted={onUpdateCompleted}
                            scanCookieNames={scanCookieNames}
                        />
                        <IdentifiedCookiesPageTable
                            cookies={cookies}
                            isFetchingMore={isFetchingMore}
                            isFetching={isFetching}
                            hasMoreRecords={hasMoreRecords}
                            startDate={scanRangeState.startDate}
                            endDate={scanRangeState.endDate}
                            onFetchMore={onFetchMore}
                            onClassifyCompleted={onClassifyCompleted}
                            onUpdateCompleted={onUpdateCompleted}
                            onDeleteCompleted={onDeleteCompleted}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
