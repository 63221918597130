import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalLoading from '../../utils/ModalLoading'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import AuditSearchSelect from '../../utils/AuditSearchSelect'
import VendorTableVersionCompareModal from './VendorTableVersionCompareModal'
import ModalTitle from '../../utils/ModalTitle'
import FormSelectList from '../../utils/FormSelectList'
import ModalFooter from '../../utils/ModalFooter'
import SelectedAvatarGroup from '../../utils/SelectedAvatarGroup'

const CreateVendorTableVersionModal = ({
    vendorTable,
    isFetching,
    selectedAudits,
    initialAudits,
    formState,
    isLoading,
    versionId,
    onAddAudit,
    onRemoveAudit,
    currentStep,
    onNextStep,
    onDismiss,
    onCancel,
    onSubmit,
    onCreate,
}) => {
    const { getNativeInputProps, getErrorMessages } = formState

    return (
        <>
            {currentStep === 1 && (
                <Modal className="max-w-3xl" onDismiss={onDismiss}>
                    <ModalTitle
                        title={<Trans>Create new vendor table version</Trans>}
                        description={
                            <Trans>
                                Create a new vendor table version, the vendor
                                table will update.
                            </Trans>
                        }
                        iconName="building-07"
                        iconColor="none"
                        iconSize="md"
                        onClose={onDismiss}
                        newModalTitle
                    />
                    {isFetching ? (
                        <ModalLoading />
                    ) : (
                        <>
                            <FormGroup
                                label={<Trans>Version name</Trans>}
                                isHorizontal
                            >
                                <FormInput
                                    errors={getErrorMessages('name')}
                                    {...getNativeInputProps('name')}
                                />
                            </FormGroup>
                            <FormGroup
                                label={<Trans>Audits</Trans>}
                                isHorizontal
                                className="mb-4"
                            >
                                <FormSelectList
                                    selectedItems={
                                        <SelectedAvatarGroup
                                            selectedItems={selectedAudits}
                                        />
                                    }
                                >
                                    <AuditSearchSelect
                                        className="h-[400px]"
                                        value={selectedAudits.map(
                                            ({ id }) => id
                                        )}
                                        onSelect={(id, audit) =>
                                            onAddAudit(audit)
                                        }
                                        onDeselect={(id) => onRemoveAudit(id)}
                                        selectedAudits={selectedAudits}
                                    />
                                </FormSelectList>
                            </FormGroup>
                            <ModalFooter>
                                <Button type="white" onClick={onDismiss}>
                                    <Trans>Cancel</Trans>
                                </Button>
                                <Button
                                    onClick={() => {
                                        onSubmit()
                                        onNextStep()
                                    }}
                                >
                                    <Trans>Next</Trans>
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </Modal>
            )}
            {currentStep === 2 && (
                <VendorTableVersionCompareModal
                    versionId={versionId}
                    vendorTableId={vendorTable.id}
                    onDismiss={onDismiss}
                    onCreate={onCreate}
                    onCancel={onCancel}
                />
            )}
        </>
    )
}

export default CreateVendorTableVersionModal
