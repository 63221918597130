import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const DELETE_USER_MUTATION = gql`
    mutation deleteUser($userId: ID!) {
        deleteUser(userId: $userId)
    }
`

const useStaffUsersDeleteUserMutation = (options) =>
    useMutation(DELETE_USER_MUTATION, options)

export default useStaffUsersDeleteUserMutation
