/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import Button from './Button'

export default function TableFilterClearButton({
    activeFilters = [],
    name,
    onRemove,
    ...props
}) {
    if (!activeFilters.includes(name)) {
        return null
    }
    return (
        <Button
            className="text-nowrap"
            type="white"
            size="sm"
            iconAfterProps={{
                name: 'x',
                size: 'sm',
            }}
            onClick={() => onRemove(name)}
            {...props}
        />
    )
}
