import React from 'react'
import clsx from 'clsx'
import { cva } from 'cva'
import Icon from './Icon'
import Tooltip from './Tooltip'

const variant = cva('', {
    variants: {
        size: {
            '2xs': 'h-5 w-5 text-xs',
            xs: 'h-6 w-6 text-sm',
            sm: 'h-8 w-8',
            md: 'h-10 w-10',
            lg: 'h-12 w-12',
            xl: 'h-14 w-14',
            '2xl': 'h-16 w-16',
        },
        color: {
            default: 'border-gray-300 bg-gray-100 text-gray-500',
            yellow: 'border-yellow-300 bg-yellow-50',
            red: 'border-red-300 bg-red-100',
            primary: 'bg-primary-600',
        },
        hasImage: {
            false: 'border',
        },
    },
})

const getIconSize = (size) => {
    if (size === 'xl') return 'lg'
    if (size === '2xl') return 'lg'
    return size
}

const statusVariant = cva('', {
    variants: {
        size: {
            '2xs': 'hidden',
            xs: 'hidden',
            sm: 'hidden',
            md: '-bottom-1 -right-1 p-0.5',
            lg: '-bottom-1 -right-1 p-1',
            xl: '-bottom-1 -right-1 p-1',
            '2xl': '-bottom-1 -right-1 p-1',
        },
    },
})

const Avatar = ({
    className,
    size = 'md',
    color = 'default',
    iconColor = 'default',
    iconName = 'user',
    avatarStatus = null,
    avatarStatusSrc = null,
    src = null,
    name = null,
    tooltipContent,
    tooltipPosition = 'right',
    ...props
}) => {
    const hasImage = src !== null && src !== ''
    const hasStatusImage = avatarStatusSrc !== null && avatarStatusSrc !== ''
    const hasStatusIcon =
        !hasStatusImage && avatarStatus !== null && avatarStatus !== ''
    const style = {}
    if (hasImage) {
        style.backgroundImage = `url(${src})`
    }
    return (
        <Tooltip
            asChild
            enabled={!!tooltipContent}
            content={tooltipContent}
            position={tooltipPosition}
        >
            <span
                {...props}
                className={clsx(
                    'relative inline-flex items-center justify-center rounded-full bg-cover',
                    variant({ size, color, hasImage }),
                    className
                )}
                style={style}
            >
                {hasImage && (
                    <div className="absolute inset-0 rounded-full border border-gray-300" />
                )}
                {!hasImage && name === null && (
                    <Icon
                        name={iconName}
                        color={iconColor}
                        size={getIconSize(size)}
                    />
                )}
                {!hasImage && name && name[0]}
                {(hasStatusImage || hasStatusIcon) && (
                    <span
                        className={clsx(
                            'absolute flex items-center justify-center rounded-full bg-white',
                            statusVariant({ size })
                        )}
                    >
                        {hasStatusImage ? (
                            <img
                                src={avatarStatusSrc}
                                alt="status"
                                className={clsx(
                                    'rounded-full',
                                    variant({ size: '2xs' })
                                )}
                            />
                        ) : (
                            <Icon
                                name={avatarStatus}
                                color={iconColor}
                                size="xs"
                            />
                        )}
                    </span>
                )}
            </span>
        </Tooltip>
    )
}

export default Avatar
