import React from 'react'
import AuditHeaderSwitcherDropdownWithState from './AuditHeaderSwitcherDropdownWithState'
import DropdownMenu from '../utils/DropdownMenu'
import Avatar from '../utils/Avatar'
import Icon from '../utils/Icon'

export default function AuditHeaderSwitcher({ currentAudit }) {
    const { imageUrl, name } = currentAudit
    return (
        <DropdownMenu
            as="button"
            className="flex items-center gap-2 group"
            dropdownClassName="min-w-[340px]"
            content={
                <AuditHeaderSwitcherDropdownWithState
                    currentAudit={currentAudit}
                />
            }
        >
            <Avatar
                src={imageUrl}
                iconName={currentAudit.isMobile ? 'app' : 'domain'}
                size="sm"
            />
            <span className="truncate max-w-44 hidden xl:inline">{name}</span>
            <div className="px-0.5 py-2 group-hover:bg-slate-100 rounded-md">
                <Icon
                    name="chevron-selector-vertical"
                    size="sm"
                    className="text-slate-400"
                />
            </div>
        </DropdownMenu>
    )
}
