import React from 'react'
import clsx from 'clsx'
import Breadcrumbs from './Breadcrumbs'
import Avatar from './Avatar'

const PageHeader = ({
    title,
    description,
    avatarUrl,
    avatarIconName,
    breadcrumbsItems,
    rightChildren,
    hasMarginBottom = true,
    className,
    isFetching,
}) => (
    <div className={clsx(hasMarginBottom && 'mb-8', className)}>
        {breadcrumbsItems && <Breadcrumbs items={breadcrumbsItems} />}
        <div className="mt-4 md:flex md:items-center md:justify-between h-10">
            {isFetching ? (
                <div className="skeleton w-1/4 h-full" />
            ) : (
                <div className="flex-1">
                    <div className="flex">
                        {avatarUrl && (
                            <Avatar
                                className="mr-6"
                                src={avatarUrl}
                                iconName={avatarIconName}
                            />
                        )}
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate flex items-center">
                            {title}
                        </h2>
                    </div>
                    {description && (
                        <p className="mt-2 text-sm leading-5 text-gray-500">
                            {description}
                        </p>
                    )}
                </div>
            )}
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
                {rightChildren}
            </div>
        </div>
    </div>
)

export default PageHeader
