import React from 'react'
import Table from '../../utils/Table'
import useDrawerStack from '../../hooks/useDrawerStack'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import PurposesTags from '../../utils/PurposesTags'
import TrackerDetailsDrawer from '../staff-vendors-trackers/TrackerDetailsDrawer'

export default function VendorDetailsDrawerTrackersTab({ vendor }) {
    const { add } = useDrawerStack()
    const { trackers } = vendor
    if (trackers.length === 0) {
        return null
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {trackers.map(({ id, domain, purposes }) => (
                    <TableRow
                        isSelectable
                        key={id}
                        onClick={() => {
                            add(TrackerDetailsDrawer, {
                                trackerId: id,
                            })
                        }}
                    >
                        <TableCell size="sm" title={domain}>
                            {domain}
                        </TableCell>
                        <TableCell size="sm" className="text-right">
                            <PurposesTags purposes={purposes} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
