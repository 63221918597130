import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateVendorTableVersion(
        $id: ID!
        $input: VendorTableVersionInput!
    ) {
        updateVendorTableVersion(id: $id, input: $input) {
            vendorTableVersion {
                id
            }
        }
    }
`

const useUpdateVendorTableVersionMutation = (options) =>
    useMutation(MUTATION, options)

export default useUpdateVendorTableVersionMutation
