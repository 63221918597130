import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import AvatarGroup from '../../utils/AvatarGroup'
import CookieTablePageTableRowActionButtonWithState from './CookieTablesPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment CookieTablesPageTableRow on CookieTable {
        id
        name
        latestVersion {
            id
            audits {
                id
                imageUrl
            }
        }
    }
`

const CookieTablesPageTableRow = ({ cookieTable, onDeleteCompleted }) => {
    const history = useHistory()
    const { id, name, latestVersion } = cookieTable
    const auditImageUrls =
        latestVersion?.audits?.map((audit) => audit.imageUrl) || []
    return (
        <TableRow
            data-testid="CookieTablesPageTableRow"
            isSelectable
            onClick={() => {
                history.push(`/vendors/cookie-table-items/${id}`)
            }}
        >
            <TableCell columnId="name">
                <div className="flex items-center gap-2">
                    <AvatarGroup imageUrls={auditImageUrls} />
                    <div>{name}</div>
                </div>
            </TableCell>
            <TableCell columnId="actions" className="text-right">
                <CookieTablePageTableRowActionButtonWithState
                    cookieTable={cookieTable}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

CookieTablesPageTableRow.FRAGMENT = FRAGMENT

export default CookieTablesPageTableRow
