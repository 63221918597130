import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation removeConsentProfile(
        $auditId: ID!
        $input: RemoveConsentProfileInput!
    ) {
        removeConsentProfile(auditId: $auditId, input: $input) {
            audit {
                id
            }
        }
    }
`

const useRemoveConsentProfileMutation = (options) =>
    useMutation(MUTATION, options)

export default useRemoveConsentProfileMutation
