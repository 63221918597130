import React from 'react'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import InviteUserForm from './InviteUserForm'
import SettingsPagePageSidebar from '../settings/SettingsPageSidebar'

const UsersInvitePage = ({
    isFetching,
    roles,
    formState,
    onSubmit,
    isLoading,
}) => (
    <PageContainer>
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPagePageSidebar />
            <PageContent isFetching={isFetching}>
                <InviteUserForm
                    formState={formState}
                    roles={roles}
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default UsersInvitePage
