import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useBulkUploadVendorFormState from './useBulkUploadVendorFormState'
import useBulkUploadValidateVendorsMutation from './useBulkUploadValidateVendorsMutation'
import useBulkUploadUpdateVendorsMutation from './useBulkUploadUpdateVendorsMutation'
import useBulkUploadCreateVendorsMutation from './useBulkUploadCreateVendorsMutation'
import BulkUploadModal from './BulkUploadModal'

const VendorsBulkUploadModalWithState = ({ onDismiss }) => {
    const formState = useBulkUploadVendorFormState()

    const [updateVendors] = useBulkUploadUpdateVendorsMutation()
    const [createVendors] = useBulkUploadCreateVendorsMutation()

    const [validateVendors, { loading, data, reset }] =
        useBulkUploadValidateVendorsMutation()

    const [isFileValidated, setIsFileValidated] = useState(false)
    const { dispatchSuccess, dispatchError } = useNotifications()
    return (
        <BulkUploadModal
            isValidated={isFileValidated}
            alertRecords={data?.validateVendors}
            formState={formState}
            onDismiss={() => {
                onDismiss()
            }}
            onReset={() => {
                setIsFileValidated(false)
                reset()
                formState.resetForm()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { action, csvFile } = formState.values
                        const input = { action, csvFile }
                        const variables = { input }

                        if (!isFileValidated) {
                            await validateVendors({ variables })
                            setIsFileValidated(true)
                        }
                        if (data?.validateVendors.errors.length === 0) {
                            if (action === 'UPDATE') {
                                await updateVendors({ variables })
                                setIsFileValidated(false)
                                dispatchSuccess({
                                    message: (
                                        <Trans>All Vendors are updated</Trans>
                                    ),
                                })
                                onDismiss()
                            } else if (action === 'CREATE') {
                                await createVendors({ variables })
                                setIsFileValidated(false)
                                dispatchSuccess({
                                    message: (
                                        <Trans>All Vendors are created</Trans>
                                    ),
                                })
                                onDismiss()
                            } else {
                                dispatchError({
                                    message: (
                                        <Trans>
                                            Oops, Something went wrong
                                        </Trans>
                                    ),
                                })
                            }
                        }
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
            isUploading={loading}
        />
    )
}

export default VendorsBulkUploadModalWithState
