import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from '../../utils/Modal'
import FormGroup from '../../utils/FormGroup'
import SelectListOption from '../../utils/SelectListOption'
import Button from '../../utils/Button'
import ModalTitle from '../../utils/ModalTitle'
import ModalFooter from '../../utils/ModalFooter'
import FormSelectList from '../../utils/FormSelectList'
import SelectList from '../../utils/SelectList'
import Avatar from '../../utils/Avatar'
import InfiniteScroll from '../../utils/InfiniteScroll'
import SearchInput from '../../utils/SearchInput'
import FormInputErrors from '../../utils/FormInputErrors'

export default function StaffAssignOrganizationsToUserModal({
    isSubmitting,
    onDismiss,
    onSubmit,
    searchOrganizations,
    handleSearchOrganizations,
    organizations,
    selectedOrganizations,
    onToggleOrganization,
    hasMoreOrganizations,
    handleFetchMoreOrganizations,
    isFetchingMoreOrganizations,
    isLoadingOrganizations,
    formState,
}) {
    return (
        <Modal className="max-w-2xl" onDismiss={onDismiss} type="primary">
            <ModalTitle
                title={<Trans>Assign organizations</Trans>}
                description={<Trans>Assign organizations to staff user</Trans>}
                onClose={onDismiss}
            />
            <form
                onSubmit={async (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    await onSubmit()
                }}
            >
                <FormGroup
                    label={<Trans>Selected Organizations</Trans>}
                    className="mb-4"
                >
                    <FormInputErrors />
                    <FormSelectList
                        errors={formState.getErrorMessages('organizations')}
                    >
                        <SelectList
                            className="h-[400px]"
                            searchInput={
                                <SearchInput
                                    value={searchOrganizations}
                                    onChange={handleSearchOrganizations}
                                />
                            }
                            isFetching={isLoadingOrganizations}
                        >
                            <InfiniteScroll
                                isFetchingMore={isFetchingMoreOrganizations}
                                onFetchMore={handleFetchMoreOrganizations}
                                hasMoreRecords={hasMoreOrganizations}
                            >
                                {organizations.map((organization) => (
                                    <SelectListOption
                                        key={organization.id}
                                        isCheckbox
                                        checked={selectedOrganizations.some(
                                            (selectedOrg) =>
                                                selectedOrg === organization.id
                                        )}
                                        onClick={() =>
                                            onToggleOrganization(
                                                organization.id
                                            )
                                        }
                                    >
                                        <div className="flex items-center gap-2">
                                            <Avatar
                                                src={organization.imageUrl}
                                                size="sm"
                                            />
                                            {organization.name}
                                        </div>
                                    </SelectListOption>
                                ))}
                            </InfiniteScroll>
                        </SelectList>
                    </FormSelectList>
                </FormGroup>
                <ModalFooter>
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        type="primary"
                        onClick={onSubmit}
                        loading={isSubmitting}
                    >
                        <Trans>Assign organizations</Trans>
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}
