import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useStartAuditMutation from '../audits-details/useStartAuditMutation'
import AuditAlertsPage from './AuditAlertsPage'
import useAuditAlertsPageQuery from './useAuditAlertsPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const AuditAlertsPageWithState = () => {
    useCloseDrawerOnLeave()
    const history = useHistory()
    const { id: auditId } = useParams()
    const [startAudit] = useStartAuditMutation()
    const [selectedAlertStatus, setSelectedAlertStatus] = useState('ONGOING')
    const [selectedType, setSelectedType] = useState('ALL')
    const scanRangeState = useScanRangeSelect()
    const { startDate, endDate } = scanRangeState
    const {
        alerts,
        audit,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch,
    } = useAuditAlertsPageQuery(
        selectedType === 'ALL' ? null : selectedType,
        selectedAlertStatus,
        startDate,
        endDate
    )

    return (
        <AuditAlertsPage
            scanRangeState={scanRangeState}
            selectedType={selectedType}
            selectedAlertStatus={selectedAlertStatus}
            startDate={startDate}
            endDate={endDate}
            alerts={alerts}
            audit={audit}
            hasMoreRecords={hasMore}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            onChangeType={(type) => setSelectedType(type)}
            onClearFilters={() => {
                setSelectedType('ALL')
                setSelectedAlertStatus('ALL')
            }}
            onAlertStatusChanged={() => refetch()}
            onFilterAlerts={(status) => setSelectedAlertStatus(status)}
            onStartScan={async () => {
                await startAudit()
                history.push(`/audits/${auditId}/scans`)
            }}
            onClassifyCompleted={() => refetch()}
        />
    )
}

export default AuditAlertsPageWithState
