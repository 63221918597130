import React from 'react'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import VendorCookiesPageTableRow from './VendorCookiesPageTableRow'
import VendorCookiesPageTableHeaderRow from './VendorCookiesPageTableHeaderRow'

const VendorCookiesPageTable = ({
    cookies = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table data-testid="VendorCookiesPageTable" isFetching={isFetching}>
            <thead>
                <VendorCookiesPageTableHeaderRow />
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {cookies.map((cookie) => (
                    <VendorCookiesPageTableRow
                        key={cookie.id}
                        cookie={cookie}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default VendorCookiesPageTable
