import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import AvatarGroup from '../../utils/AvatarGroup'
import VendorTablesPageTableRowActionButtonWithState from './VendorTablesPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment VendorTablesPageTableRow on VendorTable {
        id
        name
        latestVersion {
            id
            audits {
                id
                imageUrl
            }
        }
    }
`

const VendorTablesPageTableRow = ({ vendorTable, onDeleteCompleted }) => {
    const history = useHistory()
    const { id, name, latestVersion } = vendorTable
    const auditImageUrls =
        latestVersion?.audits?.map((audit) => audit.imageUrl) || []
    return (
        <TableRow
            data-testid="VendorTablesPageTableRow"
            isSelectable
            onClick={() => {
                history.push(`/vendors/vendor-table-items/${id}`)
            }}
        >
            <TableCell columnId="name">
                <div className="flex items-center gap-2">
                    <AvatarGroup imageUrls={auditImageUrls} />
                    <div>{name}</div>
                </div>
            </TableCell>
            <TableCell className="text-right">
                <VendorTablesPageTableRowActionButtonWithState
                    vendorTable={vendorTable}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

VendorTablesPageTableRow.FRAGMENT = FRAGMENT

export default VendorTablesPageTableRow
