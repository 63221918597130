import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const DELETE_REPORTS_MUTATION = gql`
    mutation deleteReports($input: DeleteReportsInput!) {
        deleteReports(input: $input)
    }
`

const useDeleteReportsMutation = (options) =>
    useMutation(DELETE_REPORTS_MUTATION, options)

export default useDeleteReportsMutation
