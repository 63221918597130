import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageHeader from '../../layout/PageHeader'
import OrganizationHeader from '../../layout/OrganizationHeader'
import CookieTablesPageTable from './CookieTablesPageTable'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'
import TableStateProvider from '../../providers/TableStateProvider'

const CookieTablesPage = ({
    cookieTables = [],
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onDeleteCompleted,
    onCreate,
}) => (
    <PageContainer data-testid="CookieTablesPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent>
                <PageHeader
                    className="pb-6"
                    title={<Trans>Cookie tables</Trans>}
                    description={
                        <Trans>
                            Cookie tables contain cookies that you expect to
                            appear on one or more domains of your organization.
                            <br /> By creating a cookie table, you can get
                            alerts for any new cookie.
                        </Trans>
                    }
                    rightChildren={
                        <Button onClick={onCreate}>
                            <Trans>Create cookie table</Trans>
                        </Button>
                    }
                />
                {(() => {
                    if (isFetching) {
                        return null
                    }
                    if (cookieTables.length === 0) {
                        return (
                            <EmptyPageCard
                                iconName="cookie"
                                title={<Trans>No cookie tables</Trans>}
                                description={
                                    <Trans>
                                        No cookie tables were found for this
                                        organization.
                                        <br />
                                        Start by adding new consent profiles
                                        here.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider>
                            <CookieTablesPageTable
                                cookieTables={cookieTables}
                                isFetchingMore={isFetchingMore}
                                hasMoreRecords={hasMoreRecords}
                                onFetchMore={onFetchMore}
                                onDeleteCompleted={onDeleteCompleted}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default CookieTablesPage
