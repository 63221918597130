import React from 'react'
import SearchInput from './SearchInput'
import SelectListOption from './SelectListOption'
import InfiniteScroll from './InfiniteScroll'
import useSearch from '../hooks/useSearch'
import SelectList from './SelectList'
import useVendorTablesPageQuery from '../pages/vendors-vendor-tables/useVendorTablesPageQuery'

const VendorTableSearchSelect = ({
    className,
    selectedVendorTable,
    onHandleSelectVendorTable,
}) => {
    const [search, handleSearch] = useSearch('')
    const {
        vendorTables,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useVendorTablesPageQuery(search)
    return (
        <SelectList
            className={className}
            isFetching={isFetching}
            searchInput={
                <SearchInput
                    value={search}
                    onChange={handleSearch}
                    className="w-full"
                />
            }
        >
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore}
            >
                <ul>
                    {vendorTables.map((vendorTable) => (
                        <SelectListOption
                            key={vendorTable.id}
                            disabled={false}
                            isCheckbox
                            checked={selectedVendorTable === vendorTable.id}
                            onClick={() => {
                                if (selectedVendorTable === vendorTable.id) {
                                    onHandleSelectVendorTable(null)
                                } else {
                                    onHandleSelectVendorTable(vendorTable.id)
                                }
                            }}
                        >
                            {vendorTable.name}
                        </SelectListOption>
                    ))}
                </ul>
            </InfiniteScroll>
        </SelectList>
    )
}

export default VendorTableSearchSelect
