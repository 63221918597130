import React from 'react'
import { t } from '@lingui/macro'
import * as constants from '../../constants'
import Tag from './Tag'
import AlertIcon from './AlertIcon'
import AlertTooltip from './AlertTooltip'

const {
    ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION: MAX_RETENTION,
    ALERT_TYPE_COOKIE_NO_CONSENT: COOKIE_NO_CONSENT,
    ALERT_TYPE_TRACKER_NO_CONSENT: TRACKER_NO_CONSENT,
    ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING: TRACKER_CONSENTLESS_ADVERTISING,
    ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE: VENDOR_NOT_IN_VENDOR_TABLE,
    ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE: COOKIE_NOT_IN_COOKIE_TABLE,
    ALERT_SEVERITY_TYPE_ISSUE: ISSUE,
    ALERT_SEVERITY_TYPE_CRITICAL: CRITICAL,
    ALERT_SEVERITY_TYPE_WARNING: WARNING,
} = constants

export const getAlertStatusText = ({ type }) => {
    if (type === MAX_RETENTION) {
        return t`Cookie retention Period`
    }
    if (type === COOKIE_NO_CONSENT) {
        return t`Cookie with invalid legal Basis`
    }
    if (type === TRACKER_NO_CONSENT) {
        return t`Tracker with invalid legal Basis`
    }
    if (type === TRACKER_CONSENTLESS_ADVERTISING) {
        return t`Technical ad delivery violation`
    }
    if (type === VENDOR_NOT_IN_VENDOR_TABLE) {
        return t`Not in vendor table`
    }
    if (type === COOKIE_NOT_IN_COOKIE_TABLE) {
        return t`Not in cookie table`
    }
    return t`Unknown`
}

const AlertTag = ({ alert, type, children }) => {
    let tagType = 'default'
    if (type === 'critical' || alert?.severityType === CRITICAL) {
        tagType = 'red'
    }
    if (type === 'issue' || alert?.severityType === ISSUE) {
        tagType = 'yellow'
    }
    if (type === 'warning' || alert?.severityType === WARNING) {
        tagType = 'gray'
    }
    return (
        <AlertTooltip enabled={!!alert} alert={alert}>
            <Tag className="gap-1" type={tagType}>
                <AlertIcon type={type} alert={alert} />
                {children || getAlertStatusText(alert)}
            </Tag>
        </AlertTooltip>
    )
}

export default AlertTag
