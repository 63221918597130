import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const RESEND_USER_INVITE_MUTATION = gql`
    mutation resendUserInvite($invitationId: ID!) {
        resendUserInvite(invitationId: $invitationId)
    }
`

const useResendUserInviteMutation = () =>
    useMutation(RESEND_USER_INVITE_MUTATION)

export default useResendUserInviteMutation
