import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteInvitationModal from './DeleteInvitationModal'
import useDeleteInvitationMutation from './useDeleteInvitationMutation'

const DeleteInvitationModalWithState = ({
    invitation,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteInvitation, { loading }] = useDeleteInvitationMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteInvitationModal
            invitation={invitation}
            onDismiss={onDismiss}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { invitationId: invitation.id }
                    await deleteInvitation({ variables })
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {invitation.fullName} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteInvitationModalWithState
