import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateCookieTableVersionModal from './CreateCookieTableVersionModal'
import useCreateCookieTableVersionFormState from './useCreateCookieTableVersionFormState'
import useCreateCookieTableVersionMutation from './useCreateCookieTableVersionMutation'
import useUpdateCookieTableVersionMutation from './useUpdateCookieTableVersionMutation'
import useDeleteCookieTableVersionMutation from './useDeleteCookieTableVersionMutation'

const CreateCookieTableVersionModalWithState = ({
    onDismiss,
    onCompleted,
    cookieTable,
}) => {
    const { id: cookieTableId } = useParams()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()

    const [selectedAudits, setSelectedAudits] = useState(
        cookieTable?.latestVersion?.audits || []
    )
    const [versionId, setVersionId] = useState(null)

    const [createCookieTableVersion, mutationData] =
        useCreateCookieTableVersionMutation()
    const [updateCookieTableVersion] = useUpdateCookieTableVersionMutation()
    const [deleteCookieTableVersion] = useDeleteCookieTableVersionMutation()
    const formState = useCreateCookieTableVersionFormState()

    const { values } = formState

    const [step, setStep] = useState(1)
    const handleNext = () => {
        if (values.name !== '') {
            setStep(step + 1)
        }
    }

    const handleCreateVersion = async () => {
        if (formState.validate()) {
            try {
                const { name } = formState.values
                const input = {
                    name,
                    cookieTableId,
                    audits: selectedAudits.map(({ id }) => id),
                }
                const newCookieVersion = await createCookieTableVersion({
                    variables: { input },
                })

                setVersionId(
                    newCookieVersion.data.createCookieTableVersion
                        .cookieTableVersion.id
                )
            } catch (error) {
                dispatchGraphqlError(error)
            }
        }
    }
    const handleUpdateVersion = async () => {
        try {
            await updateCookieTableVersion({
                variables: {
                    id: versionId,
                    input: {
                        status: 'FINISHED',
                        name: formState.values.name,
                        cookieTableId,
                    },
                },
            })
            dispatchSuccess(
                <Trans>
                    Your cookie table version was created successfully
                </Trans>
            )
            onCompleted()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }
    const handleCancelVersion = async () => {
        try {
            await deleteCookieTableVersion({
                variables: { id: versionId },
            })
            setVersionId(null)
            formState.resetForm()
            dispatchSuccess('Deleted the new cookie table version succesfully')
            onDismiss()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }

    return (
        <CreateCookieTableVersionModal
            cookieTable={cookieTable}
            selectedAudits={selectedAudits}
            initialAudits={cookieTable?.latestVersion?.audits || []}
            formState={formState}
            versionId={versionId}
            isLoading={mutationData.loading}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onAddAudit={(audit) => {
                const newAudits = [...selectedAudits, audit]
                const uniqueAudits = [
                    ...new Map(
                        newAudits.map((item) => [item.id, item])
                    ).values(),
                ]
                setSelectedAudits(uniqueAudits)
            }}
            onRemoveAudit={(id) => {
                const newAudits = selectedAudits.filter(
                    (cookie) => cookie.id !== id
                )
                setSelectedAudits(newAudits)
            }}
            currentStep={step}
            onNextStep={handleNext}
            onSubmit={handleCreateVersion} // This Function allow us to create the initial version that needs to be reviewd
            onCreate={handleUpdateVersion} // This Function allow us to update the version & finish the creation of the version
            onCancel={handleCancelVersion} // This Function allow us to delete the version
        />
    )
}

export default CreateCookieTableVersionModalWithState
