import React from 'react'
import Icon from '../../utils/Icon'

const AcceptInvitationCard = ({
    iconName = null,
    title,
    description,
    children,
}) => (
    <div className="shadow rounded-md bg-white px-6 py-14 ">
        {iconName !== null && (
            <Icon
                name={iconName}
                color="primary"
                size="2xl"
                className="m-auto"
            />
        )}
        <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4 text-center">
            {title}
        </h3>
        <p className="mt-2 max-w-lg m-auto text-sm leading-5 text-gray-500 text-center">
            {description}
        </p>
        {children}
    </div>
)

export default AcceptInvitationCard
