import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function IdentifiedTrackersPageTableHeader() {
    return (
        <TableHeaderRow isSticky stickyPosition="below-actions">
            <TableHeaderCell columnId="domain" initialWidth={300}>
                <Trans>Domain</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="purposes" initialWidth={200}>
                <Trans>Purposes</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="vendor" initialWidth={200}>
                <Trans>Vendor</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="audtis" initialWidth={250}>
                <Trans>Audits</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="alerts" initialWidth={100}>
                <Trans>Alerts</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="occurrence" initialWidth={150}>
                <Trans>Occurrence</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="belongsToOrganization" />
            <TableHeaderCell columnId="lastSeenAt" initialWidth={150}>
                <Trans>Last seen At</Trans>
            </TableHeaderCell>
            <TableHeaderCell initialWidth={250} />
        </TableHeaderRow>
    )
}
