import React from 'react'
import clsx from 'clsx'

const PageHeader = ({
    avatar,
    title,
    description,
    rightChildren,
    topChildren,
    bottomChildren,
    className,
}) => (
    <div className={clsx('w-full', className)}>
        {topChildren && <div className="mb-4">{topChildren}</div>}
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
                {avatar}
                <div>
                    {title && (
                        <h2 className="text-lg font-semibold text-slate-900">
                            {title}
                        </h2>
                    )}
                    {description && (
                        <p className="max-w-3xl text-sm text-slate-600">
                            {description}
                        </p>
                    )}
                </div>
            </div>
            {rightChildren && (
                <div className="flex-shrink-0">{rightChildren}</div>
            )}
        </div>
        {bottomChildren && <div className="mb-4">{bottomChildren}</div>}
    </div>
)

export default PageHeader
