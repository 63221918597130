import React, { useState } from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useSearch from '../../hooks/useSearch'
import AuditTrackersPage from './AuditTrackersPage'
import useAuditTrackersPageQuery from './useAuditTrackersPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const AuditTrackersPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const [selectedPurposeId, setSelectedPurpose] = useState('ALL')
    const scanRangeState = useScanRangeSelect()
    const [orderBy, onChangeOrderBy] = useState({ occurrence: 'DESC' })
    const { period, startDate, endDate } = scanRangeState
    const {
        audit,
        purposes,
        trackers,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditTrackersPageQuery(
        search,
        selectedPurposeId === 'ALL' ? null : selectedPurposeId,
        startDate,
        endDate,
        period,
        orderBy
    )
    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)
    return (
        <AuditTrackersPage
            audit={audit}
            purposes={purposes}
            selectedPurposeId={selectedPurposeId}
            scanRangeState={scanRangeState}
            startDate={startDate}
            endDate={endDate}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            trackers={trackers}
            search={search}
            initialOrderBy={orderBy}
            onChangeOrderBy={(value, direction) =>
                onChangeOrderBy({ [value]: direction })
            }
            onSearch={handleSearch}
            onChangePurpose={(id) => setSelectedPurpose(id)}
            onClassifyTrackerCompleted={() => refetch()}
            onDeleteTrackerCompleted={() => refetch()}
            onScanCompleted={() => refetch()}
        />
    )
}

export default AuditTrackersPageWithState
