import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteOrganizationTrackerModal from './DeleteOrganizationTrackerModal'
import useDeleteOrganizationTrackerMutation from './useDeleteOrganizationTrackerMutation'

const DeleteOrganizationTrackerModalWithState = ({
    title,
    description,
    tracker,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteTracker, { loading }] = useDeleteOrganizationTrackerMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteOrganizationTrackerModal
            title={title}
            description={description}
            tracker={tracker}
            isLoading={loading}
            onDismiss={onDismiss}
            onConfirm={async () => {
                try {
                    const variables = { id: tracker.id }
                    await deleteTracker({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{tracker.name} successfully deleted.</Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteOrganizationTrackerModalWithState
