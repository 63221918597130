import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import VendorTableVersionCompareModalTable from './VendorTableVersionCompareModalTable'
import ModalTitle from '../../utils/ModalTitle'
import Modal from '../../utils/Modal'
import useVendorTableItemPageQuery from './useVendorTableItemPageQuery'
import ModalFooter from '../../utils/ModalFooter'

const compareVendorTables = (newArray, oldArray) => {
    const isEqual = (item1, item2) => {
        if (item1.vendor && item2.vendor) {
            return (
                item1.vendor.name === item2.vendor.name &&
                item1.vendor.domain === item2.vendor.domain
            )
        }
        return false
    }

    // First find all deprecated items
    const deprecatedStatusVendors = newArray.filter(
        (newItem) => newItem.status === 'DEPRECATED'
    )

    // Find truly new items (not in old array AND not deprecated)
    const newComparedVendors = newArray.filter(
        (newItem) =>
            (!oldArray.some((oldItem) => isEqual(newItem, oldItem)) || // Completely new items
                oldArray.some(
                    (oldItem) =>
                        isEqual(newItem, oldItem) && oldItem.status !== 'ADDED'
                )) && // OR items that were re-added
            newItem.status === 'ADDED' &&
            newItem.status !== 'DEPRECATED'
    )

    // Find deleted items (in old array but not in new array)
    // BUT exclude items that were re-added (status changed to ADDED)
    const deletedComparedVendors = oldArray.filter(
        (oldItem) =>
            oldItem.status === 'ADDED' &&
            !newArray.some((newItem) => isEqual(newItem, oldItem))
    )

    const sameComparedVendors = newArray.filter(
        (newItem) =>
            newItem.status !== 'DEPRECATED' &&
            newItem.status === 'ADDED' &&
            !newComparedVendors.some((newVendor) =>
                isEqual(newVendor, newItem)
            ) &&
            !deletedComparedVendors.some((removedItem) =>
                isEqual(removedItem, newItem)
            ) &&
            oldArray.some(
                (oldItem) =>
                    isEqual(newItem, oldItem) && oldItem.status === 'ADDED'
            )
    )

    return {
        newComparedVendors,
        sameComparedVendors,
        deletedComparedVendors,
        deprecatedStatusVendors,
    }
}

const VendorTableVersionCompareModal = ({
    versionId,
    vendorTableId,
    onDismiss,
    onCreate,
    onCancel,
}) => {
    const {
        refetch: refetchLatestVendorTableItems,
        vendorTableItems: latestVendorTableItems,
        isFetching: isFetchingLatestVendor,
    } = useVendorTableItemPageQuery({
        vendorTableId,
    })
    const {
        refetch: refetchComparingVendorTableItems,
        vendorTableItems: comparingVendorTableItems,
        isFetching: isFetchingCompare,
    } = useVendorTableItemPageQuery({
        vendorTableId,
        vendorTableVersionId: versionId,
    })

    const isLoading = isFetchingLatestVendor || isFetchingCompare

    const {
        newComparedVendors,
        sameComparedVendors,
        deletedComparedVendors,
        deprecatedStatusVendors,
    } = compareVendorTables(comparingVendorTableItems, latestVendorTableItems)
    return (
        <Modal className="max-w-3xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create new vendor table version</Trans>}
                description={
                    <Trans>
                        In the overview below you can compare and adjust the
                        differences between the previous version
                    </Trans>
                }
                iconName="building-07"
                iconColor="none"
                iconSize="md"
                onClose={onCancel}
                newModalTitle
            />
            <div>
                <VendorTableVersionCompareModalTable
                    isFetching={isLoading}
                    deletedVendors={deletedComparedVendors}
                    sameVendors={sameComparedVendors}
                    newVendors={newComparedVendors}
                    deprecatedVendors={deprecatedStatusVendors}
                    onUpdateCompleted={() => {
                        refetchLatestVendorTableItems()
                        refetchComparingVendorTableItems()
                    }}
                />

                <ModalFooter>
                    <Button type="white" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={onCreate}>
                        <Trans>Create</Trans>
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    )
}

export default VendorTableVersionCompareModal
