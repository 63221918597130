import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'action',
        validate: isRequired,
        message: <Trans>Select an action.</Trans>,
    },
    {
        path: 'csvFile',
        validate: isRequired,
        message: <Trans>Choose a csv file.</Trans>,
    },
])

const useBulkUploadVendorFormState = () =>
    useFormState(
        {
            action: 'CREATE',
            csvFile: null,
        },
        {
            validation,
        }
    )

export default useBulkUploadVendorFormState
