import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'domain',
        validate: isRequired,
        message: <Trans>Choose a domain for this tracker.</Trans>,
    },
    {
        path: 'purposeIds',
        validate: (purposeIds) => purposeIds.length > 0,
        message: <Trans>Select at least one purpose.</Trans>,
    },
    {
        path: 'vendorId',
        validate: isRequired,
        message: <Trans>Select a vendor.</Trans>,
    },
])

const useUpdateTrackerFormState = ({ domain, purposes, vendor }) => {
    const formState = useFormState(
        {
            domain,
            purposeIds: purposes ? purposes.map(({ id }) => id) : [],
            vendorId: vendor ? vendor.id : null,
        },
        {
            validation,
        }
    )
    return formState
}

export default useUpdateTrackerFormState
