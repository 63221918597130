import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const REMOVE_VENDOR_MUTATION = gql`
    mutation deleteVendor($id: ID!) {
        deleteVendor(id: $id)
    }
`

const useDeleteVendorMutation = (options) =>
    useMutation(REMOVE_VENDOR_MUTATION, options)

export default useDeleteVendorMutation
