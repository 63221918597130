import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import OrganizationVendorsPageTableRowActionButton from './OrganizationVendorsPageTableRowActionButton'
import DeleteOrganizationVendorModalWithState from './DeleteOrganizationVendorModalWithState'
import UpdateOrganizationVendorModalWithState from './UpdateOrganizationVendorModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const OrganizationVendorsPageTableRowActionButtonWithState = ({
    vendor,
    onDeleteCompleted,
}) => {
    const history = useHistory()
    const { currentUser } = useAppState()
    const deleteModalIsOpen = useRouteMatch({
        path: `/classifications/organization/${vendor.id}/delete`,
    })
    const updateModalIsOpen = useRouteMatch({
        path: `/classifications/organization/${vendor.id}/update`,
    })
    return (
        <>
            {deleteModalIsOpen && (
                <DeleteOrganizationVendorModalWithState
                    vendor={vendor}
                    onDismiss={() =>
                        history.push('/classifications/organization')
                    }
                    onCompleted={onDeleteCompleted}
                />
            )}
            {updateModalIsOpen && (
                <UpdateOrganizationVendorModalWithState
                    vendorId={vendor.id}
                    onDismiss={() =>
                        history.push('/classifications/organization')
                    }
                    onCompleted={onDeleteCompleted}
                />
            )}
            <OrganizationVendorsPageTableRowActionButton
                vendor={vendor}
                canDelete={currentUser.role === OWNER}
                canUpdate={currentUser.role === OWNER}
                onDelete={() =>
                    history.push(
                        `/classifications/organization/${vendor.id}/delete`
                    )
                }
                onUpdate={() =>
                    history.push(
                        `/classifications/organization/${vendor.id}/update`
                    )
                }
            />
        </>
    )
}

export default OrganizationVendorsPageTableRowActionButtonWithState
