import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function StaffUnclassifiedCookiesPageTableHeaderRow() {
    return (
        <TableHeaderRow isSticky>
            <TableHeaderCell columnId="name" canResize initialWidth={300}>
                <Trans>Name</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="domain"
                canHide
                canResize
                initialWidth={200}
            >
                <Trans>Domain</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="occurence"
                canResize
                canHide
                initialWidth={150}
            >
                <Trans>Occurence</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="organizations"
                canResize
                canHide
                initialWidth={200}
            >
                <Trans>Organizations</Trans>
            </TableHeaderCell>

            <TableHeaderCell
                columnId="description"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={200}
            >
                <Trans>Description</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="audits"
                canResize
                canHide
                initialWidth={200}
            >
                <Trans>Audits</Trans>
            </TableHeaderCell>
            <TableHeaderCell />
        </TableHeaderRow>
    )
}
