import React from 'react'
import { Redirect, useParams, useHistory, useLocation } from 'react-router-dom'
import ErrorPage from '../../utils/ErrorPage'
import PageLoading from '../../utils/PageLoading'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import {
    INVITATION_STATUS_EXPIRED,
    INVITATION_STATUS_ACCEPTED,
} from '../../../constants'
import AcceptInvitationPage from './AcceptInvitationPage'
import useInvitationQuery from './useInvitationQuery'
import useRegisterUserMutation from './useRegisterUserMutation'
import useRegisterUserFormState from './useRegisterUserFormState'
import useAcceptInvitationMutation from './useAcceptInvitationMutation'

const AcceptInvitationPageWithState = ({ isAuthenticated }) => {
    const { id } = useParams()
    const history = useHistory()
    const location = useLocation()
    const appState = useAppState() // TODO: check if current user === invitation user
    const { dispatchError } = useNotifications()
    const [registerUser] = useRegisterUserMutation()
    const [acceptInvitation] = useAcceptInvitationMutation()
    const { invitation, isFetching, error } = useInvitationQuery({
        variables: { id },
    })
    const formState = useRegisterUserFormState({
        email: invitation?.email ? invitation.email : '',
    })

    if (isFetching) {
        return <PageLoading  />
    }
    if (typeof error !== 'undefined') {
        return <ErrorPage />
    }
    if (invitation === null) {
        return <Redirect to="/" />
    }
    const isRegisteredUser = invitation.user !== null
    const isAuthorized =
        isAuthenticated &&
        isRegisteredUser &&
        invitation.user.id === appState?.currentUser.id
    const isExpired = invitation.status === INVITATION_STATUS_EXPIRED
    const isAlreadyAccepted = invitation.status === INVITATION_STATUS_ACCEPTED

    if (!isAuthenticated && isRegisteredUser) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { referrer: location.pathname },
                }}
            />
        )
    }

    const handleAcceptInvitation = async () => {
        try {
            await acceptInvitation({ variables: { id } })
            history.push('/')
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    const handleRegisterUser = async () => {
        try {
            if (formState.validate()) {
                const { data } = await registerUser({
                    variables: {
                        id,
                        input: formState.valuesToInput(),
                    },
                })
                const {
                    accessToken,
                    refreshToken,
                    me: currentUser,
                } = data.registerUser
                await appState.login(accessToken, refreshToken, currentUser)
                history.push('/')
            }
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    const handleNavigateHome = () => history.push('/')

    return (
        <AcceptInvitationPage
            formState={formState}
            invitation={invitation}
            isExpired={isExpired}
            isAuthorized={isAuthorized}
            isAuthenticated={isAuthenticated}
            isAlreadyAccepted={isAlreadyAccepted}
            onRegisterUser={handleRegisterUser}
            onNavigateHome={handleNavigateHome}
            onAcceptInvitation={handleAcceptInvitation}
        />
    )
}

export default AcceptInvitationPageWithState
