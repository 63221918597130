import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query inviteUserPage {
        roles
    }
`

const useInviteUserPageQuery = () => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        roles: data.roles || [],
        isFetching,
        refetch,
    }
}

export default useInviteUserPageQuery
