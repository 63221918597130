import React from 'react'
import clsx from 'clsx'

const FormGroup = ({
    label,
    htmlFor,
    isHorizontal = false,
    className,
    children,
}) => (
    <div
        className={clsx(
            'mb-4',
            isHorizontal &&
                'sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5',
            className
        )}
    >
        <label
            htmlFor={htmlFor}
            className={clsx(
                'block text-sm font-medium leading-5 text-gray-700',
                isHorizontal && 'sm:mt-px sm:pt-2'
            )}
        >
            {label}
        </label>
        <div
            className={clsx(
                'my-1 sm:mt-0 sm:col-span-3',
                !isHorizontal && 'sm:mt-1'
            )}
        >
            {children}
        </div>
    </div>
)

export default FormGroup
