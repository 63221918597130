import React, { useState } from 'react'
import StaffUnclassifiedTrackersPageTableRowActionButton from './StaffUnclassifiedTrackersPageTableRowActionButton'
import ClassifyTrackerModalWithState from '../audits-scans-trackers/ClassifyTrackerModalWithState'

const StaffUnclassifiedTrackersPageTableRowActionButtonWithState = ({
    scanTracker,
    onClassifyTrackerCompleted,
}) => {
    const [classifyModalIsOpen, setClassifyModalIsOpen] = useState(false)
    return (
        <>
            {classifyModalIsOpen && (
                <ClassifyTrackerModalWithState
                    scanTracker={scanTracker}
                    onDismiss={() => setClassifyModalIsOpen(false)}
                    onCompleted={onClassifyTrackerCompleted}
                />
            )}
            <StaffUnclassifiedTrackersPageTableRowActionButton
                isLoading={classifyModalIsOpen}
                onClassify={() => setClassifyModalIsOpen(true)}
            />
        </>
    )
}

export default StaffUnclassifiedTrackersPageTableRowActionButtonWithState
