import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation deleteCustomCmp($id: ID!) {
        deleteCustomCmp(id: $id)
    }
`

const useDeleteCustomCmpMutation = (options) => useMutation(MUTATION, options)

export default useDeleteCustomCmpMutation
