import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import InviteUserPage from './InviteUserPage'
import useInviteUserMutation from './useInviteUserMutation'
import useInviteUserPageQuery from './useInviteUserPageQuery'
import useInviteUserFormState from './useInviteUserFormState'

export const useSelectedRole = (formState, roles) => {
    const selectedRole = formState.values.role || null
    const [firstRole] = roles
    useEffect(() => {
        if (selectedRole === null && firstRole) {
            formState.handleChange('role', firstRole)
        }
    }, [firstRole, selectedRole])
    return selectedRole
}

const InviteUserPageWithState = () => {
    const history = useHistory()
    const formState = useInviteUserFormState()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const { roles, isFetching } = useInviteUserPageQuery()
    const [inviteUser, { loading }] = useInviteUserMutation()
    useSelectedRole(formState, roles)
    return (
        <InviteUserPage
            isFetching={isFetching}
            roles={roles}
            formState={formState}
            isLoading={loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        const { email } = input
                        await inviteUser({ variables: { input } })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Invitation sent to <strong>{email}</strong>!
                                </Trans>
                            ),
                        })
                        history.push('/settings/users/invitations', {
                            refetch: true,
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default InviteUserPageWithState
