import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const ActionButton = ({
    className,
    childrenBefore,
    childrenAfter,
    disabled,
    size = 'md',
    iconName = 'menu-dots',
    iconColor = 'default',
    ...props
}) => (
    <button
        type="button"
        className={clsx(
            'inline-flex rounded-md transition duration-150 ease-in-out hover:bg-gray-200',
            size === 'md' && 'p-1.5',
            size === 'sm' && 'p-0.5',
            className,
            disabled && 'cursor-not-allowed opacity-75'
        )}
        {...props}
    >
        {childrenBefore}
        <Icon
            className="text-gray-600 group-hover:text-gray-500"
            name={iconName}
            color={iconColor}
        />
        {childrenAfter}
    </button>
)

export default ActionButton
