import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import AcceptInvitationCard from './AcceptInvitationCard'
import AcceptInvitationUnauthorized from './AcceptInvitationUnauthorized'
import AcceptInvitationAuthenticated from './AcceptInvitationAuthenticated'
import AcceptInvitationUnauthenticated from './AcceptInvitationUnauthenticated'

const AcceptInvitationPage = ({
    formState,
    invitation,
    isExpired,
    isAuthorized,
    isAuthenticated,
    isAlreadyAccepted,
    onAcceptInvitation,
    onRegisterUser,
    onNavigateHome,
}) => (
    <PageContainer>
        <PageContent centerContent>
            <div className="mx-auto w-full md:w-[600px]">
                {(() => {
                    if (isExpired) {
                        return (
                            <AcceptInvitationCard
                                iconName="invitation"
                                title={<Trans>Expired</Trans>}
                                description={
                                    <>
                                        <Trans>
                                            It looks like this invitation has
                                            expired and is not valid anymore.
                                        </Trans>{' '}
                                        <Trans>
                                            Please contact your organization
                                            contact to send a new invite.
                                        </Trans>
                                    </>
                                }
                            />
                        )
                    }
                    if (isAlreadyAccepted) {
                        return (
                            <AcceptInvitationCard
                                iconName="invitation"
                                title={<Trans>Accepted</Trans>}
                                description={
                                    <Trans>
                                        It looks like this invitation has
                                        already been accepted.
                                    </Trans>
                                }
                            />
                        )
                    }
                    if (isAuthenticated) {
                        if (isAuthorized) {
                            return (
                                <AcceptInvitationAuthenticated
                                    organization={invitation.organization}
                                    onAcceptInvitation={onAcceptInvitation}
                                />
                            )
                        }
                        return (
                            <AcceptInvitationUnauthorized
                                onNavigateHome={onNavigateHome}
                            />
                        )
                    }
                    return (
                        <AcceptInvitationUnauthenticated
                            formState={formState}
                            organization={invitation.organization}
                            onRegisterUser={onRegisterUser}
                        />
                    )
                })()}
            </div>
        </PageContent>
    </PageContainer>
)

export default AcceptInvitationPage
