import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import ModalFooter from '../../utils/ModalFooter'
import Button from '../../utils/Button'

export default function StaffCreateUserModal({
    isLoading,
    onDismiss,
    onSubmit,
    formState,
}) {
    return (
        <Modal onDismiss={onDismiss} className="max-w-lg">
            <ModalTitle
                onClose={onDismiss}
                title={<Trans>Create Staff User</Trans>}
            />
            <form
                onSubmit={async (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    await onSubmit()
                }}
            >
                <div className="grid grid-cols-2 gap-4">
                    <FormGroup
                        label={<Trans>First name</Trans>}
                        htmlFor="firstName"
                    >
                        <FormInput
                            id="firstName"
                            type="firstName"
                            className="max-w-lg"
                            errors={formState.getErrorMessages('firstName')}
                            {...formState.getNativeInputProps('firstName')}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Last name</Trans>}
                        htmlFor="lastName"
                    >
                        <FormInput
                            id="lastName"
                            type="lastName"
                            className="max-w-lg"
                            errors={formState.getErrorMessages('lastName')}
                            {...formState.getNativeInputProps('lastName')}
                        />
                    </FormGroup>
                </div>
                <FormGroup label={<Trans>Email</Trans>} htmlFor="email">
                    <FormInput
                        id="email"
                        type="email"
                        className="max-w-lg"
                        errors={formState.getErrorMessages('email')}
                        {...formState.getNativeInputProps('email')}
                    />
                </FormGroup>
                <ModalFooter>
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        type="primary"
                        onClick={onSubmit}
                        loading={isLoading}
                    >
                        <Trans>Create</Trans>
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}
