import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import OrganizationHeader from '../../layout/OrganizationHeader'
import PageHeader from '../../layout/PageHeader'
import VendorTablesPageTable from './VendorTablesPageTable'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'
import TableStateProvider from '../../providers/TableStateProvider'

const VendorTablesPage = ({
    vendorTables = [],
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onDeleteCompleted,
    onCreate,
}) => (
    <PageContainer data-testid="VendorTablesPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent>
                <PageHeader
                    className="pb-6"
                    title={<Trans>Vendor Tables</Trans>}
                    description={
                        <Trans>
                            Vendor tables contain vendors that you expect to
                            appear on one or more domains of your organization.{' '}
                            <br /> By creating a vendor table, you can get
                            alerts for any new vendor.
                        </Trans>
                    }
                    rightChildren={
                        <Button onClick={onCreate}>
                            <Trans>Create vendor table</Trans>
                        </Button>
                    }
                />
                {(() => {
                    if (isFetching) {
                        return null
                    }
                    if (vendorTables.length === 0) {
                        return (
                            <EmptyPageCard
                                iconName="building-07"
                                title={<Trans>No vendor tables</Trans>}
                                description={
                                    <Trans>
                                        No vendor tables were found for this
                                        organization.
                                        <br />
                                        Start by adding new consent profiles
                                        here.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider>
                            <VendorTablesPageTable
                                vendorTables={vendorTables}
                                isFetchingMore={isFetchingMore}
                                hasMoreRecords={hasMoreRecords}
                                onFetchMore={onFetchMore}
                                onDeleteCompleted={onDeleteCompleted}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default VendorTablesPage
