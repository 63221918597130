import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createCookies($input: UploadCookieInput!) {
        createCookies(input: $input)
    }
`

const useBulkUploadCreateCookiesMutation = (options) =>
    useMutation(MUTATION, options)

export default useBulkUploadCreateCookiesMutation
