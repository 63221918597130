import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateOrganizationCookie($id: ID!, $input: CookieInput!) {
        updateOrganizationCookie(id: $id, input: $input) {
            cookie {
                id
            }
        }
    }
`

const useUpdateOrganizationCookieMutation = (options) =>
    useMutation(MUTATION, options)

export default useUpdateOrganizationCookieMutation
