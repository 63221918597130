import React from 'react'
import Table from '../../utils/Table'
import AuditScansPageTableRow from '../audits-sessions/AuditSessionsPageTableRow'
import AuditSessionsPageTableHeaderRow from '../audits-sessions/AuditSessionsPageTableHeaderRow'

const AuditDetailsPageSessionsTable = ({ audit, sessions = [] }) => (
    <Table data-testid="AuditDetailsPageSessionsTable">
        <thead>
            <AuditSessionsPageTableHeaderRow />
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {sessions.map((session) => (
                <AuditScansPageTableRow
                    key={session.id}
                    audit={audit}
                    session={session}
                />
            ))}
        </tbody>
    </Table>
)

export default AuditDetailsPageSessionsTable
