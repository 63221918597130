import React, { useState } from 'react'
import useNotifications from '../../hooks/useNotifications'
import CreateCookieTableReportModal from './CreateCookieTableReportModal'
import useCreateCookieTableReportMutation from './useCreateCookieTableReportMutation'

const CreateCookieTableReportModalWithState = ({
    onDismiss,
    onCompleted,
    onError,
}) => {
    const [selectedCookieTable, setSelectedCookieTable] = useState()
    const [createCookieReport] = useCreateCookieTableReportMutation({
        onCompleted,
        onError,
    })
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateCookieTableReportModal
            setSelectedCookieTable={setSelectedCookieTable}
            selectedCookieTable={selectedCookieTable}
            onDismiss={onDismiss}
            onSubmit={async () => {
                try {
                    const input = {
                        cookieTableId: selectedCookieTable,
                    }
                    const variables = { input }
                    await createCookieReport({ variables })
                    onDismiss()
                    dispatchSuccess('Cookie table report created')
                } catch (submitError) {
                    dispatchGraphqlError(submitError)
                }
            }}
        />
    )
}

export default CreateCookieTableReportModalWithState
