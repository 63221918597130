import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateCustomCmp($id: ID!, $input: ConsentProfileInput!) {
        updateConsentProfile(id: $id, input: $input) {
            consentProfile {
                id
            }
        }
    }
`

const useUpdateConsentProfileMutation = (options) =>
    useMutation(MUTATION, options)

export default useUpdateConsentProfileMutation
