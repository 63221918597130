import React from 'react'
import clsx from 'clsx'

export default function ModalFooter({ className, children }) {
    return (
        <div className={clsx('mt-10 flex w-full justify-end gap-2', className)}>
            {children}
        </div>
    )
}
