import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query customCmpsPageQuery {
        cmps {
            edges {
                node {
                    id
                    name
                    isCustom
                }
            }
        }
    }
`

const useCustomCmpsPageQuery = (options) => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        cmps: connectionToCollection(data.cmps),
        isFetching,
        refetch,
    }
}

export default useCustomCmpsPageQuery
