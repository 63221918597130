import React, { useState } from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useSearch from '../../hooks/useSearch'
import IdentifiedTrackersPage from './IdentifiedTrackersPage'
import useIdentifiedTrackersPageQuery from './useIdentifiedTrackersPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import useCollectionState from '../../hooks/useCollectionState'

const IdentifiedTrackersPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const {
        collection,
        addToCollection,
        addAllToCollection,
        clearCollection,
        removeFromCollection,
    } = useCollectionState()
    const [isSelecting, onSetSelecting] = useState(false)
    const [selectedAuditId, setSelectedAuditId] = useState('ALL')
    const [selectedPurposeId, setSelectedPurpose] = useState('ALL')
    const scanRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = scanRangeState
    const {
        purposes,
        audits,
        trackers,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useIdentifiedTrackersPageQuery(
        search,
        selectedAuditId === 'ALL' ? null : selectedAuditId,
        selectedPurposeId === 'ALL' ? null : selectedPurposeId,
        startDate,
        endDate,
        period
    )
    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)
    return (
        <IdentifiedTrackersPage
            purposes={purposes}
            audits={audits}
            selectedAuditId={selectedAuditId}
            selectedPurposeId={selectedPurposeId}
            scanRangeState={scanRangeState}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            trackers={trackers}
            search={search}
            onSearch={handleSearch}
            onChangeAudit={(id) => setSelectedAuditId(id)}
            onChangePurpose={(id) => setSelectedPurpose(id)}
            onClassifyTrackerCompleted={() => refetch()}
            onUpdateTrackerCompleted={() => refetch()}
            onDeleteTrackerCompleted={() => refetch()}
            isSelecting={isSelecting}
            onStartSelectingTrackers={() => onSetSelecting(true)}
            onStopSelectingTrackers={() => {
                onSetSelecting(false)
                clearCollection()
            }}
            onSelectAllTrackers={(e) => {
                if (e.target.checked) {
                    addAllToCollection(trackers)
                } else {
                    clearCollection()
                }
            }}
            onSelectTracker={(isSelected, tracker) => {
                if (isSelected) {
                    addToCollection(tracker)
                } else {
                    removeFromCollection(tracker)
                }
            }}
            selectedTrackers={collection}
            onSort={(columnId, direction) => {
                refetch({
                    orderBy: {
                        [columnId]: direction,
                    },
                })
            }}
        />
    )
}

export default IdentifiedTrackersPageWithState
