/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useAppState from '../../hooks/useAppState'
import ScanCookiesPageTableRowActionButton from './ScanCookiesPageTableRowActionButton'
import ScanCookiesPageTableRowActionButtonStaff from './ScanCookiesPageTableRowActionButtonStaff'
import ClassifyCookieModalWithState from './ClassifyCookieModalWithState'
import DeleteCookieModalWithState from './DeleteCookieModalWithState'
import UpdateCookieModalWithState from './UpdateCookieModalWithState'
import CreateOrganizationCookieModalWithState from '../classifications-organization-cookies/CreateOrganizationCookieModalWithState'
import UpdateOrganizationCookieModalWithState from '../classifications-organization-cookies/UpdateOrganizationCookieModalWithState'
import DeleteOrganizationCookieModalWithState from '../classifications-organization-cookies/DeleteOrganizationCookieModalWithState'

const ScanCookiesPageTableRowActionButtonWithState = ({
    cookie,
    scanCookie,
    onClassifyCookieCompleted,
    onDeleteCookieCompleted,
    onUpdateCookieCompleted,
    children,
}) => {
    const { currentUser } = useAppState()
    const [classifyModalIsOpen, setClassifyModalIsOpen] = useState(false)
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
    const [
        classifyOrganizationModalIsOpen,
        setClassifyOrganizationModalIsOpen,
    ] = useState(false)
    const [deleteOrganizationModalIsOpen, setDeleteOrganizationModalIsOpen] =
        useState(false)
    const [updateOrganizationModalIsOpen, setUpdateOrganizationModalIsOpen] =
        useState(false)
    if (currentUser.isStaff) {
        return (
            <>
                {classifyModalIsOpen && (
                    <ClassifyCookieModalWithState
                        scanCookie={scanCookie}
                        onDismiss={() => setClassifyModalIsOpen(false)}
                        onCompleted={onClassifyCookieCompleted}
                    />
                )}
                {deleteModalIsOpen && (
                    <DeleteCookieModalWithState
                        cookie={cookie}
                        onDismiss={() => setDeleteModalIsOpen(false)}
                        onCompleted={onDeleteCookieCompleted}
                    />
                )}
                {updateModalIsOpen && (
                    <UpdateCookieModalWithState
                        scanCookie={scanCookie}
                        cookie={cookie}
                        onDismiss={() => setUpdateModalIsOpen(false)}
                        onCompleted={onUpdateCookieCompleted}
                    />
                )}
                {classifyOrganizationModalIsOpen && (
                    <CreateOrganizationCookieModalWithState
                        title={<Trans>Classify cookie</Trans>}
                        cookie={cookie}
                        scanCookie={scanCookie}
                        onDismiss={() =>
                            setClassifyOrganizationModalIsOpen(false)
                        }
                        onCompleted={onClassifyCookieCompleted}
                    />
                )}
                {deleteOrganizationModalIsOpen && (
                    <DeleteOrganizationCookieModalWithState
                        title={<Trans>Remove your classification</Trans>}
                        cookie={cookie}
                        onDismiss={() =>
                            setDeleteOrganizationModalIsOpen(false)
                        }
                        onCompleted={onDeleteCookieCompleted}
                    />
                )}
                {updateOrganizationModalIsOpen && (
                    <UpdateOrganizationCookieModalWithState
                        title={<Trans>Update your classification</Trans>}
                        cookie={cookie}
                        scanCookie={scanCookie}
                        onDismiss={() =>
                            setUpdateOrganizationModalIsOpen(false)
                        }
                        onCompleted={onUpdateCookieCompleted}
                    />
                )}
                <ScanCookiesPageTableRowActionButtonStaff
                    cookie={cookie}
                    canClassify={cookie === null}
                    canDelete={cookie !== null}
                    canUpdate={cookie !== null}
                    onClassify={() => setClassifyModalIsOpen(true)}
                    onDelete={() => setDeleteModalIsOpen(true)}
                    onUpdate={() => setUpdateModalIsOpen(true)}
                    onClassifyOrganization={() =>
                        setClassifyOrganizationModalIsOpen(true)
                    }
                    onDeleteOrganization={() =>
                        setDeleteOrganizationModalIsOpen(true)
                    }
                    onUpdateOrganization={() =>
                        setUpdateOrganizationModalIsOpen(true)
                    }
                >
                    {children}
                </ScanCookiesPageTableRowActionButtonStaff>
            </>
        )
    }
    return (
        <>
            {classifyOrganizationModalIsOpen && (
                <CreateOrganizationCookieModalWithState
                    title={<Trans>Classify cookie</Trans>}
                    scanCookie={scanCookie}
                    onDismiss={() => setClassifyOrganizationModalIsOpen(false)}
                    onCompleted={onClassifyCookieCompleted}
                />
            )}
            {deleteOrganizationModalIsOpen && (
                <DeleteOrganizationCookieModalWithState
                    title={<Trans>Remove your classification</Trans>}
                    cookie={cookie}
                    onDismiss={() => setDeleteOrganizationModalIsOpen(false)}
                    onCompleted={onDeleteCookieCompleted}
                />
            )}
            {updateOrganizationModalIsOpen && (
                <>
                    {cookie.belongsToOrganization ? (
                        <UpdateOrganizationCookieModalWithState
                            title={<Trans>Update your classification</Trans>}
                            cookie={cookie}
                            scanCookie={scanCookie}
                            onDismiss={() =>
                                setUpdateOrganizationModalIsOpen(false)
                            }
                            onCompleted={onUpdateCookieCompleted}
                        />
                    ) : (
                        <CreateOrganizationCookieModalWithState
                            title={<Trans>Change classification</Trans>}
                            cookie={cookie}
                            scanCookie={scanCookie}
                            onDismiss={() =>
                                setUpdateOrganizationModalIsOpen(false)
                            }
                            onCompleted={onClassifyCookieCompleted}
                        />
                    )}
                </>
            )}
            <ScanCookiesPageTableRowActionButton
                cookie={cookie}
                canClassify={cookie === null}
                canDelete={cookie !== null && cookie.belongsToOrganization}
                canUpdate={cookie !== null}
                onClassify={() => setClassifyOrganizationModalIsOpen(true)}
                onDelete={() => setDeleteOrganizationModalIsOpen(true)}
                onUpdate={() => setUpdateOrganizationModalIsOpen(true)}
            >
                {children}
            </ScanCookiesPageTableRowActionButton>
        </>
    )
}

export default ScanCookiesPageTableRowActionButtonWithState
