import React from 'react'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'
import LoadingIndicator from '../../utils/LoadingIndicator'
import Select from '../../utils/Select'
import { AlertSummaryTooltip } from '../../utils/AuditAlertSummary'

// const FRAGMENT = gql`
//     fragment AuditDetailsPageAlertsChart on Audit {

//     }
// `

const AlertTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const warningAlerts = payload.find(
            (item) => item.dataKey === 'warningAlerts'
        )
        const issueAlerts = payload.find(
            (item) => item.dataKey === 'issueAlerts'
        )
        const criticalAlerts = payload.find(
            (item) => item.dataKey === 'criticalAlerts'
        )
        return (
            <div className="bg-black text-white text-sm rounded px-4 py-2">
                <AlertSummaryTooltip
                    title={label}
                    startDate={new Date()}
                    endDate={new Date()}
                    warningAlerts={warningAlerts.value}
                    issueAlerts={issueAlerts.value}
                    criticalAlerts={criticalAlerts.value}
                />
            </div>
        )
    }
    return null
}

const AuditDetailsPageAlertsChart = ({
    isFetching,
    onChangeDate,
    selectedDate,
    alertsByDayChart,
}) => (
    <div className="bg-white gap-4 p-6 rounded-2xl border border-gray-200 mb-8">
        {(() => {
            if (isFetching) {
                return (
                    <div className="h-[300px] flex items-center justify-center">
                        <LoadingIndicator />
                    </div>
                )
            }
            return (
                <>
                    <div className="flex justify-between mb-12">
                        <div />
                        <div>
                            <Select
                                className="w-[200px]"
                                onChange={onChangeDate}
                                value={selectedDate}
                            >
                                <option value="last-7">Last 7 days</option>
                                <option value="last-14">Last 14 days</option>
                                <option value="last-30">Last 30 days</option>
                            </Select>
                        </div>
                    </div>
                    <div className="h-[300px] text-sm text-gray-400">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={alertsByDayChart.map(
                                    ({
                                        startDate,
                                        warningAlerts,
                                        issueAlerts,
                                        criticalAlerts,
                                    }) => ({
                                        name: format(
                                            new Date(startDate),
                                            'MMM dd'
                                        ),
                                        warningAlerts,
                                        issueAlerts,
                                        criticalAlerts,
                                    })
                                )}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <XAxis
                                    dataKey="name"
                                    axisLine={false}
                                    tickLine={false}
                                    stroke="#6B7280"
                                />
                                <YAxis
                                    axisLine={false}
                                    tickLine={false}
                                    stroke="#6B7280"
                                />
                                <Tooltip
                                    content={<AlertTooltip />}
                                    cursor={{ fill: '#F9FAFB' }}
                                />
                                <CartesianGrid
                                    stroke="#E5E7EB"
                                    vertical={false}
                                />
                                <Bar
                                    dataKey="issueAlerts"
                                    stackId="a"
                                    fill="#fbbf24"
                                />
                                <Bar
                                    dataKey="criticalAlerts"
                                    stackId="a"
                                    fill="#f87171"
                                />
                                <Bar
                                    dataKey="warningAlerts"
                                    stackId="a"
                                    fill="#9ca3af"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </>
            )
        })()}
    </div>
)

export default AuditDetailsPageAlertsChart
