import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import AuditTrackerPageTableRow from './AuditTrackersPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'
import AuditTrackersPageTableHeader from './AuditTrackersPageTableHeader'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function NewAuditTrackersPageTable({
    audit,
    trackers = [],
    isFetching,
    startDate,
    endDate,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onClassifyTrackerCompleted,
    onDeleteTrackerCompleted,
}) {
    const hasTrackers = isFetching || trackers.length > 0
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            {hasTrackers ? (
                <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                    <thead>
                        <AuditTrackersPageTableHeader />
                    </thead>
                    <TableBody>
                        {trackers.map((tracker) => (
                            <AuditTrackerPageTableRow
                                key={tracker.id}
                                audit={audit}
                                tracker={tracker}
                                startDate={startDate}
                                endDate={endDate}
                                onClassifyTrackerCompleted={
                                    onClassifyTrackerCompleted
                                }
                                onDeleteTrackerCompleted={
                                    onDeleteTrackerCompleted
                                }
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyPageCard
                    iconName="tracker"
                    title={<Trans>No trackers</Trans>}
                    description={
                        <Trans>
                            No trackers found. Try to expand your filters
                        </Trans>
                    }
                />
            )}
        </InfiniteScroll>
    )
}
