import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import useVendorTableItemPageQuery from './useVendorTableItemPageQuery'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import VendorTableItemsPage from './VendorTableItemsPage'

const QUERY = gql`
    query vendorTable($id: ID!) {
        vendorTable(id: $id) {
            id
            name
            latestVersion {
                id
                name
                audits {
                    id
                    name
                    imageUrl
                }
            }
        }
    }
`

const VendorTableItemsPageWithState = () => {
    const { id } = useParams()
    useCloseDrawerOnLeave()
    const history = useHistory()
    const [search, handleSearch] = useSearch()
    const [orderBy, onChangeOrderBy] = useState({
        occurrence: 'DESC',
    })

    const {
        vendorTableItems,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch: refetchVendorTableItems,
    } = useVendorTableItemPageQuery({
        vendorTableId: id,
        search,
        orderBy,
    })

    const { data = {}, refetch: refetchVendorTable } = useQuery(QUERY, {
        variables: { id },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })

    useRefetch(refetchVendorTableItems)

    return (
        <VendorTableItemsPage
            vendorTable={data.vendorTable}
            vendorTableItems={vendorTableItems}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            search={search}
            onSearch={handleSearch}
            initialOrderBy={orderBy}
            onChangeOrderBy={(value, direction) =>
                onChangeOrderBy({ [value]: direction })
            }
            onUpdateVendorTableCompleted={() => refetchVendorTable()}
            onCreateVendorTableVersionCompleted={() => {
                refetchVendorTable()
            }}
            onUpdateVendorTableItemsCompleted={() => {
                refetchVendorTableItems()
            }}
            onDeleteVendorTableCompleted={() => {
                history.push('/vendors/vendor-tables')
            }}
        />
    )
}

export default VendorTableItemsPageWithState
