import React from 'react'
import PageContainer from '../../layout/PageContainer'
import StaffJobsPageSidebar from './StaffJobsPageSidebar'
import StaffJobsPageTable from './StaffJobsPageTable'
import StaffHeader from '../../layout/StaffHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import StaffJobsPageHeader from './StaffJobsPageHeader'
import PageContent from '../../utils/PageContent'

const StaffJobsPage = ({
    title,
    jobs,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    activeQueue,
    activeStatus,
    onChangeStatus,
    onChangeQueue,
    isFetching,
}) => (
    <PageContainer data-testid="StaffJobsPage">
        <StaffHeader />
        <SidebarContainer>
            <StaffJobsPageSidebar
                activeQueue={activeQueue}
                onChangeQueue={onChangeQueue}
            />
            <PageContent size="full">
                <StaffJobsPageHeader
                    title={title}
                    activeStatus={activeStatus}
                    onChangeStatus={onChangeStatus}
                />
                <StaffJobsPageTable
                    activeStatus={activeStatus}
                    jobs={jobs}
                    isFetchingMore={isFetchingMore}
                    hasMoreRecords={hasMoreRecords}
                    onFetchMore={onFetchMore}
                    isFetching={isFetching}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default StaffJobsPage
