/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import UpdateCookieModalWithState from '../audits-scans-cookies/UpdateCookieModalWithState'
import DeleteCookieModalWithState from '../audits-scans-cookies/DeleteCookieModalWithState'

const CookiesPageActionsDropdownMenu = ({
    cookie,
    onDeleteCookiesCompleted,
    onUpdateCookiesCompleted,
    children,
}) => {
    const [isUpdateCookiesModalOpen, setUpdateCookiesModalOpen] =
        useState(false)
    const [isRemoveCookiesModalOpen, setRemoveCookiesModalOpen] =
        useState(false)
    return (
        <>
            {isUpdateCookiesModalOpen && (
                <UpdateCookieModalWithState
                    cookie={cookie}
                    onDismiss={() => setUpdateCookiesModalOpen(false)}
                    onCompleted={onUpdateCookiesCompleted}
                />
            )}
            {isRemoveCookiesModalOpen && (
                <DeleteCookieModalWithState
                    cookie={cookie}
                    onDismiss={() => setRemoveCookiesModalOpen(false)}
                    onCompleted={onDeleteCookiesCompleted}
                />
            )}
            <DropdownMenu
                as="span"
                position="bottom-start"
                content={({ close }) => (
                    <>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={(e) => {
                                setRemoveCookiesModalOpen(true)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Delete Cookie</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="edit"
                            onClick={(e) => {
                                setUpdateCookiesModalOpen(true)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Update Cookie</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                {children}
            </DropdownMenu>
        </>
    )
}

export default CookiesPageActionsDropdownMenu
