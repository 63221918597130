import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>The name is required</Trans>,
    },
    {
        path: 'cmpId',
        validate: isRequired,
        message: <Trans>The cmp is required</Trans>,
    },
])

const valuesToInput = ({ name, websiteUrl, cmpId }) => ({
    name,
    websiteUrl,
    cmpId,
})

const useCreateOrganizationFormState = (initialValues) =>
    useFormState(
        {
            ...initialValues,
            name: '',
            websiteUrl: '',
            cmpId: '',
        },
        {
            validation,
            valuesToInput,
        }
    )

export default useCreateOrganizationFormState
