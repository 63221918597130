import React from 'react'
import Table from '../../utils/Table'
import AuditsPageTableHeaderRow from './AuditsPageTableHeaderRow'
import AuditsPageTableRow from './AuditsPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'

const AuditsPageTable = ({
    hideFavoriteStar,
    audits = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteAuditCompleted,
    onMarkAsFavoriteCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="AuditsPageTable">
            <thead>
                <AuditsPageTableHeaderRow />
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {audits.map((audit) => (
                    <AuditsPageTableRow
                        hideFavoriteStar={hideFavoriteStar}
                        key={audit.id}
                        audit={audit}
                        onDeleteAuditCompleted={onDeleteAuditCompleted}
                        onMarkAsFavoriteCompleted={onMarkAsFavoriteCompleted}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default AuditsPageTable
