import { useState, useEffect } from 'react'

const useDragResize = (
    element,
    { initialWidth, isEnabled = true, reverse = false }
) => {
    const [isDragging, setIsDragging] = useState(false)
    const [dragStartWidth, setDragStartWidth] = useState(null)
    const [dragStartX, setDragStartX] = useState(null)
    const [width, setWidth] = useState(initialWidth)

    const handleDragStart = (e) => {
        setIsDragging(true)
        setDragStartX(e.pageX)
        setDragStartWidth(element.current.clientWidth)
    }

    const handleDrag = (e) => {
        // Calculate the new width based on whether resizing is reversed
        let newWidth = reverse
            ? dragStartWidth - (e.pageX - dragStartX)
            : dragStartWidth + (e.pageX - dragStartX)

        // Ensure the width does not go below 0
        if (newWidth < 0) {
            newWidth = 0
        }

        setWidth(newWidth)
    }

    const handleDragEnd = () => {
        setIsDragging(false)
        setDragStartX(null)
        setDragStartWidth(null)
    }

    useEffect(() => {
        if (isDragging && isEnabled) {
            document.addEventListener('mousemove', handleDrag, {
                passive: true,
            })
            document.addEventListener('mouseup', handleDragEnd, {
                passive: true,
            })
        }
        return () => {
            document.removeEventListener('mousemove', handleDrag)
            document.removeEventListener('mouseup', handleDragEnd)
        }
    }, [isDragging, isEnabled]) // Added isEnabled to dependency array

    return {
        handleDragStart,
        width,
        isDragging,
    }
}

export default useDragResize
