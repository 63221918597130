import React from 'react'
import AlertsDashboard from './AlertsDashboard'
import useAlertsDashboardQuery from './useAlertsDashboardQuery'

const AlertsDashboardWithState = ({ selectedFilters, startDate, endDate }) => {
    const { selectedAuditId, selectedType, selectedTag } = selectedFilters
    const { isFetching, alertsByPeriod } = useAlertsDashboardQuery(
        selectedAuditId === 'ALL' ? null : selectedAuditId,
        selectedType === 'ALL' ? null : selectedType,
        selectedTag === 'ALL' ? null : selectedTag,
        startDate,
        endDate
    )
    return (
        <AlertsDashboard
            isFetching={isFetching}
            alertsByPeriod={alertsByPeriod}
        />
    )
}

export default AlertsDashboardWithState
