import useFormState, { createFormValidation } from 'use-form-state'

const validation = createFormValidation([])

const toFormValues = ({
    textColor,
    font,
    rowSize,
    linkColor,
    borderColor,
    selectedColumns = [],
}) => ({
    textColor,
    font,
    rowSize,
    linkColor,
    borderColor,
    selectedColumns,
})

const useCustomizeCookieTableFormState = (params) => {
    const formState = useFormState(toFormValues(params), { validation })
    return formState
}

export default useCustomizeCookieTableFormState
