/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../constants'
import Tag from './Tag'
import Icon from './Icon'

const {
    ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION: MAX_RETENTION,
    ALERT_TYPE_COOKIE_NO_CONSENT: COOKIE_NO_CONSENT,
    ALERT_TYPE_TRACKER_NO_CONSENT: TRACKER_NO_CONSENT,
    ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING: TRACKER_CONSENTLESS_ADVERTISING,
    ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE: VENDOR_NOT_IN_VENDOR_TABLE,
    ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE: COOKIE_NOT_IN_COOKIE_TABLE,
} = constants

export const AlertTypeText = ({ alert }) => {
    const { type } = alert
    if (
        type === TRACKER_NO_CONSENT ||
        type === TRACKER_CONSENTLESS_ADVERTISING
    ) {
        return <Trans>Tracker</Trans>
    }
    if (
        type === MAX_RETENTION ||
        type === COOKIE_NO_CONSENT ||
        type === COOKIE_NOT_IN_COOKIE_TABLE
    ) {
        return <Trans>Cookie</Trans>
    }
    if (type === VENDOR_NOT_IN_VENDOR_TABLE) {
        return <Trans>Vendor</Trans>
    }
    return <Trans>Unknown</Trans>
}

const AlertTypeTag = ({ alert }) => (
    <Tag className="items-center" type="gray">
        <AlertTypeText alert={alert} />
    </Tag>
)

export default AlertTypeTag
