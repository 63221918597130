import useFormState from 'use-form-state'
import { validation } from '../audits-scans-trackers/useClassifyTrackerFormState'

const useUpdateOrganizationTrackerFormState = ({
    description,
    domain,
    purposes,
    vendor,
}) => {
    const formState = useFormState(
        {
            domain,
            description,
            purposeIds: purposes ? purposes.map(({ id }) => id) : [],
            vendorId: vendor ? vendor.id : null,
        },
        {
            validation,
        }
    )
    return formState
}

export default useUpdateOrganizationTrackerFormState
