import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const DELETE_SCANS_MUTATION = gql`
    mutation deleteScans($input: DeleteScansInput!) {
        deleteScans(input: $input)
    }
`

const useDeleteScansMutation = (options) =>
    useMutation(DELETE_SCANS_MUTATION, options)

export default useDeleteScansMutation
