import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import DateTime from '../../utils/DateTime'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import AlertCountTag from '../../utils/AlertCountTag'
import ScanPageStatusTag from '../../utils/ScanPageStatusTag'
import Tag from '../../utils/Tag'
import Tabs, { Tab } from '../../utils/Tabs'
import ScanPageDetailsDrawerTabAlerts from './ScanPageDetailsDrawerTabAlerts '
import ScanPageDetailsDrawerTabRequests from './ScanPageDetailsDrawerTabRequests'
import ScanPageDetailsDrawerTabCookies from './ScanPageDetailsDrawerTabCookies'

const QUERY = gql`
    query scanPageDetailsDrawer($id: ID!) {
        scanPage(id: $id) {
            id
            url
            status
            duration
            createdAt
            errorMessages
            audit {
                id
                name
                imageUrl
            }
            alerts {
                id
                scan {
                    id
                }
                groupingId
                type
                severityType
                subjectName
                subjectType
                message
                occurrences
                vendor {
                    id
                    name
                    imageUrl
                }
            }
            requestsCount
            cookiesCount
            scan {
                id
                url
                consentProfile {
                    name
                }
                audit {
                    id
                    name
                }
            }
        }
    }
`

export default function ScanPageDetailsDrawer({
    scanPageId,
    drawerState,
    setDrawerState,
}) {
    const TAB_ALERTS = 'alerts'
    const TAB_COOKIES = 'cookies'
    const TAB_REQUESTS = 'requests'

    const activeTab = drawerState.activeTab || 'alerts'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: scanPageId }
    const {
        data = {},
        error,
        loading,
    } = useQuery(QUERY, {
        variables,
    })

    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />
    const { url, audit, createdAt, alerts, requestsCount, cookiesCount } =
        data.scanPage
    const { pathname } = new URL(url)

    return (
        <>
            <DrawerHeader
                avatarSrc={audit.imageUrl}
                avatarIconName="scan"
                title={pathname}
                beforeDescription={
                    <ScanPageStatusTag scanPage={data.scanPage} />
                }
                description={<DateTime>{createdAt}</DateTime>}
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === TAB_ALERTS}
                    onClick={() => setActiveTab(TAB_ALERTS)}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                <Tab
                    isActive={activeTab === TAB_COOKIES}
                    onClick={() => setActiveTab(TAB_COOKIES)}
                >
                    <Trans>Cookies</Trans>
                    <Tag iconNameBefore="cookie">{cookiesCount}</Tag>
                </Tab>
                <Tab
                    isActive={activeTab === TAB_REQUESTS}
                    onClick={() => setActiveTab(TAB_REQUESTS)}
                >
                    <Trans>Requests</Trans>
                    <Tag>{requestsCount}</Tag>
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === TAB_ALERTS && (
                    <ScanPageDetailsDrawerTabAlerts
                        level="SCAN"
                        alerts={alerts}
                    />
                )}
                {activeTab === TAB_COOKIES && (
                    <ScanPageDetailsDrawerTabCookies scanPageId={scanPageId} />
                )}
                {activeTab === TAB_REQUESTS && (
                    <ScanPageDetailsDrawerTabRequests
                        scanPageId={scanPageId}
                        audit={audit}
                        pageUrl={url}
                    />
                )}
            </DrawerContent>
        </>
    )
}
