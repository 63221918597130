import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import VendorCookiesPageTableRow from './VendorCookiesPageTableRow'

const QUERY = gql`
    query vendorCookiesPage($id: ID!, $after: String) {
        vendor(id: $id) {
            id
            name
            types
        }
        cookies(after: $after, vendorId: $id) {
            edges {
                node {
                    ...VendorCookiesPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${VendorCookiesPageTableRow.FRAGMENT}
`

const useVendorCookiesPageQuery = () => {
    const { id } = useParams()
    const variables = { id }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'cookies'
    )
    return {
        error,
        vendor: data.vendor || {},
        cookies: connectionToCollection(data.cookies),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useVendorCookiesPageQuery
