import React from 'react'
import clsx from 'clsx'
import { cva } from 'cva'
import Avatar from './Avatar'

const variant = cva('', {
    variants: {
        size: {
            '2xs': 'h-5 w-5 text-xs',
            xs: 'h-6 w-6 text-sm',
            sm: 'h-8 w-8',
            md: 'h-10 w-10',
            lg: 'h-12 w-12',
            xl: 'h-14 w-14',
            '2xl': 'h-16 w-16',
        },
        default: 'sm',
    },
})

const AvatarGroup = ({ imageUrls = [], showMoreCount = 4, size = 'sm' }) => {
    return (
        <div className="flex items-center pl-3">
            {imageUrls.slice(0, showMoreCount).map((imageUrl) => (
                <Avatar
                    key={imageUrl}
                    iconName="clock"
                    size={size}
                    className="-ml-3 border border-white"
                    src={imageUrl}
                />
            ))}
            {imageUrls.slice(showMoreCount).length > 0 && (
                <span
                    className={clsx(
                        'z-0 -ml-3 inline-flex items-center justify-center overflow-hidden rounded-full border border-white bg-gray-200 bg-cover text-xs font-medium tracking-tighter',
                        variant({ size })
                    )}
                >
                    + {imageUrls.slice(showMoreCount).length}
                </span>
            )}
        </div>
    )
}

export default AvatarGroup
