import useFormState, { createFormValidation } from 'use-form-state'

const validation = createFormValidation([])

const valuesToInput = (values) => values

const useUpdateAuditAlertsFormState = ({
    maxCookieRetention = '',
    vendorTable = null,
    cookieTable = null,
    showVendorTableAlerts = false,
    showCookieTableAlerts = false,
}) =>
    useFormState(
        {
            maxCookieRetention,
            vendorTableId: vendorTable ? vendorTable.id : null,
            cookieTableId: cookieTable ? cookieTable.id : null,
            showVendorTableAlerts,
            showCookieTableAlerts,
        },
        {
            validation,
            valuesToInput,
        }
    )

export default useUpdateAuditAlertsFormState
