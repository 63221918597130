import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

export const resolverActionOptions = {
    CLICK_CSS_SELECTOR: 'Click on CSS',
    CLICK_XPATH_SELECTOR: 'Click on XPath',
    WAIT_FOR_CSS_SELECTOR: 'Wait for CSS',
    WAIT_FOR_XPATH_SELECTOR: 'Wait for XPath',
    WAIT_FOR_NAVIGATION: 'Wait for Navigation',
    WAIT_FOR_COOKIE_NAME: 'Wait for cookie name',
    SELECT_FRAME: 'Select frame',
    ENTER_EMAIL: 'Enter email',
    ENTER_PASSWORD: 'Enter password',
    FOCUS_NEW_TAB: 'Go to new tab',
    RUN_CONSOLE_COMMANDS: 'Enter expression',
}

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this login profile.</Trans>,
    },
])

const useCreateLoginProfileFormState = () => {
    const defaultAction = {
        type: Object.keys(resolverActionOptions)[0],
        selector: '',
    }
    const formState = useFormState(
        {
            name: '',
            resolverActions: [defaultAction],
        },
        {
            validation,
        }
    )
    formState.handleAddResolverAction = () => {
        formState.handleChange('resolverActions', (resolverActions) => [
            ...resolverActions,
            defaultAction,
        ])
    }
    formState.handleRemoveResolverAction = (index) => {
        formState.handleChange('resolverActions', (resolverActions) => {
            if (resolverActions.length === 1) {
                return [defaultAction]
            }
            return resolverActions.filter((_, i) => i !== index)
        })
    }
    return formState
}

export default useCreateLoginProfileFormState
