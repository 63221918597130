import useFormState from 'use-form-state'
import { validation } from '../audits-scans-cookies/useClassifyCookieFormState'

const useCreateOrganizationCookieFormState = (scanCookie, cookie) => {
    const initialState = {
        name: '',
        description: '',
        domain: '',
        regex: '',
        purposeIds: [],
        vendorId: null,
    }
    if (cookie) {
        initialState.name = cookie.name
        initialState.description = cookie.description
        initialState.regex = cookie.regex
        initialState.domain = cookie.domain
        if (cookie.purposes) {
            initialState.purposeIds = cookie.purposes.map(({ id }) => id)
        }
        if (cookie.vendor) {
            initialState.vendorId = cookie.vendor.id
        }
    }
    if (scanCookie) {
        initialState.name = scanCookie.name
        initialState.domain = scanCookie.rootDomain
    }
    let originalCookieName = null
    if (scanCookie) {
        if (scanCookie.originalName) {
            originalCookieName = scanCookie.originalName
        } else {
            originalCookieName = scanCookie.name
        }
    }
    const formState = useFormState(initialState, {
        validation,
        validationOptions: {
            originalCookieName,
        },
    })
    return formState
}

export default useCreateOrganizationCookieFormState
