import React from 'react'
import clsx from 'clsx'
import { Link, useRouteMatch } from 'react-router-dom'

export const Tab = ({ className, isActive, children, onClick }) => (
    <button
        type="button"
        aria-current={isActive && 'page'}
        className={clsx(
            'whitespace-no-wrap pb-4 px-1 border-transparent font-semibold text-sm transition-colors duration-200 relative group flex items-center gap-2',
            isActive
                ? 'text-primary-500'
                : 'text-slate-500 hover:text-slate-700',
            className
        )}
        onClick={onClick}
    >
        {children}
        <div
            className={clsx(
                'absolute bottom-0 h-[2px] bg-primary-500 left-1/2 -translate-x-1/2 transition-width',
                isActive ? 'w-full' : 'w-0 group-hover:w-full'
            )}
        />
    </button>
)

export const TabLink = ({ to, matchPath, exact, className, children }) => {
    const isActive = useRouteMatch({
        path: matchPath || to,
        exact,
    })
    return (
        <Link to={to}>
            <Tab className={className} isActive={isActive}>
                {children}
            </Tab>
        </Link>
    )
}

const Tabs = ({ className, hasMarginBottom = true, children }) => (
    <div
        className={clsx(
            'border-b border-gray-200',
            hasMarginBottom && 'mb-8',
            className
        )}
    >
        <nav className="-mb-px flex space-x-8">{children}</nav>
    </div>
)

export default Tabs
