import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import StaffUnclassifiedTrackersPageTableHeaderRow from './StaffUnclassifiedTrackersPageTableHeaderRow'
import StaffUnclassifiedTrackersPageTableRow from './StaffUnclassifiedTrackersPageTableRow'

const UnclassifiedCookiesTable = ({
    unclassifiedTrackers = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onClassifyTrackerCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <StaffUnclassifiedTrackersPageTableHeaderRow />
            </thead>
            <TableBody>
                {unclassifiedTrackers.map((unclassifiedTracker) => (
                    <StaffUnclassifiedTrackersPageTableRow
                        key={unclassifiedTracker._id}
                        unclassifiedTracker={unclassifiedTracker}
                        onClassifyTrackerCompleted={onClassifyTrackerCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default UnclassifiedCookiesTable
