import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import TrackersPage from './TrackersPage'
import useTrackersPageQuery from './useTrackersPageQuery'

import useFilters from '../../hooks/useFilters'
import TrackersBulkUploadModalWithState from './TrackersBulkUploadModalWithState'
import CreateTrackerModalWithState from '../../utils/CreateTrackerModalWithState'

const TrackersPageWithState = () => {
    const [search, handleSearch] = useSearch()
    const [isCreateTrackerModalOpen, setIsCreateTrackerModalOpen] =
        useState(false)
    const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false)
    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        hasNoDescription: true,
        hasNoVendor: true,
        hasNoDomain: true,
        hasNoPurpose: true,
    })
    const [orderBy, onChangeOrderBy] = useState({
        occurrence: 'DESC',
    })
    const {
        trackers,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useTrackersPageQuery({ search, filters: filterValues, orderBy })
    useRefetch(refetch)

    return (
        <>
            <TrackersPage
                search={search}
                trackers={trackers}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onSearch={handleSearch}
                isFetching={isFetching}
                error={error}
                activeFilters={activeFilters}
                onAddFilter={onAddFilter}
                onRemoveFilter={onRemoveFilter}
                onRemoveAllFilters={onRemoveAllFilters}
                onChangeOrderBy={(value, direction) =>
                    onChangeOrderBy({ [value]: direction })
                }
                initialOrderBy={orderBy}
                onCreateTracker={() => setIsCreateTrackerModalOpen(true)}
                onBulkUpload={() => setIsBulkUploadModalOpen(true)}
                onDeleteTrackerCompleted={() => refetch()}
            />

            {isBulkUploadModalOpen && (
                <TrackersBulkUploadModalWithState
                    onDismiss={() => setIsBulkUploadModalOpen(false)}
                />
            )}
            {isCreateTrackerModalOpen && (
                <CreateTrackerModalWithState
                    onDismiss={() => setIsCreateTrackerModalOpen(false)}
                />
            )}
        </>
    )
}

export default TrackersPageWithState
