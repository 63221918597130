import React from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import Avatar from '../../utils/Avatar'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import AuditDomainsPageTableRowActionButtonWithState from './AuditDomainsPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment AuditDomainsPageTableRow on Domain {
        id
        name
        url
    }
`

const AuditDomainsPageTableRow = ({ domain, onDeleteDomainCompleted }) => {
    const history = useHistory()
    const { id, name, url } = domain
    return (
        <TableRow
            isSelectable
            data-testid="AuditDomainsPageTableRow"
            onClick={() => {
                history.push(`/domains/${id}/update`)
            }}
        >
            <TableCell>
                <div className="flex items-center">
                    <Avatar iconName="globe" />
                    <div className="ml-4">
                        <div className="text-sm leading-5 font-medium text-gray-900">
                            {name}
                        </div>
                        <div>
                            <a
                                href={url}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()}
                                className="text-sm font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {url}
                            </a>
                        </div>
                    </div>
                </div>
            </TableCell>
            <TableCell className="text-right">
                <AuditDomainsPageTableRowActionButtonWithState
                    domain={domain}
                    onDeleteDomainCompleted={onDeleteDomainCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

AuditDomainsPageTableRow.FRAGMENT = FRAGMENT

export default AuditDomainsPageTableRow
