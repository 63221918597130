import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../hooks/useNotifications'
import useCreateCookieFormState from './useCreateCookieFormState'
import useCreateCookieMutation from './useCreateCookieMutation'
import CreateCookieModal from './CreateCookieModal'

const CreateCookieModalWithState = ({ onDismiss, onCompleted, onError }) => {
    const [createCookie] = useCreateCookieMutation({ onCompleted, onError })
    const formState = useCreateCookieFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateCookieModal
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.valuesToInput()
                        await createCookie({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Cookie created successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateCookieModalWithState
