import React from 'react'
import Select from '../../utils/Select'

const PurposesSelect = ({ purposes, ...props }) => (
    <Select {...props}>
        <option value="ALL">All purposes</option>
        <option value="UNCLASSIFIED">Unclassified</option>
        {purposes.map(({ id, name }) => (
            <option value={id} key={id}>
                {name}
            </option>
        ))}
    </Select>
)

export default PurposesSelect
