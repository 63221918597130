/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import AddLoginProfileModal from './AddLoginProfileModal'
import useAddLoginProfileMutation from './useAddLoginProfileMutation'
import useAddLoginProfileFormState from './useAddLoginProfileFormState'
import useAddLoginProfileModalQuery from './useAddLoginProfileModalQuery'

const useSelectedLoginProfile = (formState, loginProfiles) => {
    const selected = formState.values.cmpId || null
    const [first] = loginProfiles
    useEffect(() => {
        if (selected === null && first) {
            formState.handleChange('loginProfileId', first.id)
        }
    }, [first, selected])
    return selected
}

const AddLoginProfileModalWithState = ({
    audit,
    loginProfiles = null,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const history = useHistory()
    const canSkipDataFetching = loginProfiles !== null
    const query = useAddLoginProfileModalQuery(audit.id, {
        skip: canSkipDataFetching,
    })
    const [addLoginProfile] = useAddLoginProfileMutation({
        onCompleted,
        onError,
    })
    const formState = useAddLoginProfileFormState()
    const { dispatchSuccess, dispatchError } = useNotifications()
    const finalLoginProfiles = loginProfiles || query.loginProfiles
    const { currentLoginProfiles } = query
    const filteredLoginProfiles = finalLoginProfiles.filter(
        ({ id }) => !currentLoginProfiles.some((profile) => profile.id === id)
    )
    useSelectedLoginProfile(formState, filteredLoginProfiles)
    return (
        <AddLoginProfileModal
            loginProfiles={filteredLoginProfiles}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { loginProfileId } = formState.values
                        const loginProfile = finalLoginProfiles.find(
                            ({ id }) => id === loginProfileId
                        )
                        const input = { loginProfileId }
                        await addLoginProfile({
                            variables: { auditId: audit.id, input },
                        })
                        await query.refetch()
                        formState.resetForm()
                        history.push(`/audits/${audit.id}/settings`)
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    New login profile {loginProfile.name}{' '}
                                    assigned!
                                </Trans>
                            ),
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default AddLoginProfileModalWithState
