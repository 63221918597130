import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateTracker($id: ID!, $input: TrackerInput!) {
        updateTracker(id: $id, input: $input) {
            tracker {
                id
            }
        }
    }
`

const useUpdateTrackerMutation = (options) => useMutation(MUTATION, options)

export default useUpdateTrackerMutation
