import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SettingsPagePageSidebar from './SettingsPageSidebar'
import SettingsPageFormCard from './SettingsPageFormCard'
import SettingsPageAuditFormCard from './SettingsPageAuditFormCard'
import SettingsPageAuditAlertsFormCard from './SettingsPageAuditAlertsFormCard'

const SettingsPage = ({
    cookieTables,
    vendorTables,
    isUpdating,
    isFetching,
    settingsFormState,
    settingsAuditFormState,
    alertSettingsFormState,
    onUpdateSettings,
    onUpdateAuditSettings,
    onUpdateAlertSettings,
}) => (
    <PageContainer>
        <OrganizationHeader />
        <SidebarContainer>
            <SettingsPagePageSidebar />
            <PageContent size="sm" isFetching={isFetching}>
                <SettingsPageFormCard
                    isLoading={isUpdating}
                    formState={settingsFormState}
                    onSubmit={onUpdateSettings}
                />
                <SettingsPageAuditFormCard
                    isLoading={isUpdating}
                    formState={settingsAuditFormState}
                    onSubmit={onUpdateAuditSettings}
                />
                <SettingsPageAuditAlertsFormCard
                    cookieTables={cookieTables}
                    vendorTables={vendorTables}
                    isLoading={isUpdating}
                    formState={alertSettingsFormState}
                    onSubmit={onUpdateAlertSettings}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default SettingsPage
