import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import ConsentProfilesPageTableRowActionButtonWithState from './ConsentProfilesPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment ConsentProfilesPageTableRow on ConsentProfile {
        id
        name
        cmp {
            id
            name
        }
        purposes {
            id
            name
        }
    }
`

const ConsentProfilesPageTableRow = ({ consentProfile, onDeleteCompleted }) => {
    const history = useHistory()
    const { id, name, cmp } = consentProfile
    return (
        <TableRow
            data-testid="CustomCmpsPageTableRow"
            isSelectable
            onClick={() => {
                history.push(`/settings/consent-profiles/${id}`)
            }}
        >
            <TableCell hasText>{name}</TableCell>
            <TableCell hasText className="text-gray-500">
                {cmp.name}
            </TableCell>
            <TableCell className="text-right">
                <ConsentProfilesPageTableRowActionButtonWithState
                    consentProfile={consentProfile}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

ConsentProfilesPageTableRow.FRAGMENT = FRAGMENT

export default ConsentProfilesPageTableRow
