import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this cookie.</Trans>,
    },
    {
        path: 'vendorId',
        validate: isRequired,
        message: <Trans>Choose a vendor for this tracker.</Trans>,
    },
    {
        path: 'purposeIds',
        validate: isRequired,
        message: <Trans>Choose purpose(s) for this tracker.</Trans>,
    },
    {
        path: 'regex',
        validate: (regex, fields = {}) => {
            const { regexCheck } = fields
            if (regex === '' || regex === null) {
                return true
            }
            if (!regexCheck) {
                return true
            }
            try {
                return new RegExp(regex, 'g').test(regexCheck)
            } catch (e) {
                return false
            }
        },
        message: (
            <Trans>The regex does not match the original cookie name.</Trans>
        ),
    },
])

const valuesToInput = ({ regexCheck, ...values }) => values

const useCreateCookieFormState = () => {
    const formState = useFormState(
        {
            name: '',
            regex: '',
            regexCheck: '',
            domain: '',
            description: '',
            purposeIds: [],
            vendorId: '',
        },
        {
            validation,
            valuesToInput,
        }
    )
    return formState
}

export default useCreateCookieFormState
