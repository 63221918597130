import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const AuditsPageTableRowActionButton = ({
    audit,
    isLoading,
    isMarkingAsFavorite,
    canDeleteAudit,
    onDeleteAudit,
    onMarkAsFavorite,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <>
                <DropdownMenuItem
                    iconName="bin"
                    disabled={!canDeleteAudit}
                    onClick={(e) => {
                        onDeleteAudit()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Delete Audit</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="star"
                    isLoading={isMarkingAsFavorite}
                    disabled={!canDeleteAudit}
                    onClick={(e) => {
                        onMarkAsFavorite()
                        close()
                        e.stopPropagation()
                    }}
                >
                    {audit.isFavorite ? (
                        <Trans>Remove from favorites</Trans>
                    ) : (
                        <Trans>Mark as favorite</Trans>
                    )}
                </DropdownMenuItem>
            </>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default AuditsPageTableRowActionButton
