import useFormState from 'use-form-state'
import { validation } from '../audits-scans-cookies/useClassifyCookieFormState'

const useUpdateOrganizationCookieFormState = (
    { name, regex, description, domain, purposes, vendor },
    scanCookie
) => {
    const formState = useFormState(
        {
            name,
            description,
            domain,
            regex,
            purposeIds: purposes ? purposes.map(({ id }) => id) : [],
            vendorId: vendor ? vendor.id : null,
        },
        {
            validation,
            validationOptions: {
                originalCookieName: scanCookie ? scanCookie.originalName : null,
            },
        }
    )
    return formState
}

export default useUpdateOrganizationCookieFormState
