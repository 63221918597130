import useFormState from 'use-form-state'
import { validation } from './useClassifyCookiesFormState'

const useUpdateCookiesFormState = () => {
    const formState = useFormState(
        {
            purposeIds: [],
            vendorId: null,
        },
        {
            validation,
        }
    )
    return formState
}

export default useUpdateCookiesFormState
