import React, { useState } from 'react'
import useNotifications from '../../hooks/useNotifications'
import CreateVendorTableReportModal from './CreateVendorTableReportModal'
import useCreateVendorTableReportMutation from './useCreateVendorTableReportMutation'

const CreateVendorTableReportModalWithState = ({
    onDismiss,
    onCompleted,
    onError,
}) => {
    const [selectedVendorTable, setSelectedVendorTable] = useState()
    const [createVendorReport] = useCreateVendorTableReportMutation({
        onCompleted,
        onError,
    })
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateVendorTableReportModal
            setSelectedVendorTable={setSelectedVendorTable}
            selectedVendorTable={selectedVendorTable}
            onDismiss={onDismiss}
            onSubmit={async () => {
                try {
                    const input = {
                        vendorTableId: selectedVendorTable,
                    }
                    const variables = { input }
                    await createVendorReport({ variables })
                    onDismiss()
                    dispatchSuccess('Vendor table report created')
                } catch (submitError) {
                    dispatchGraphqlError(submitError)
                }
            }}
        />
    )
}

export default CreateVendorTableReportModalWithState
