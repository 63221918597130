/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalLoading from '../../utils/ModalLoading'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import RadioButton from '../../utils/RadioButton'
import FormSelect from '../../utils/FormSelect'
import FormUpload from '../../utils/FormUpload'

const UploadSessionModal = ({
    audit,
    onDismiss,
    formState,
    isFetching,
    onSubmit,
    isUploading,
}) => {
    const { values, handleChange, getErrorMessages } = formState
    const { deviceType } = values
    const { isMobile } = audit
    return (
        <Modal onDismiss={onDismiss} className="max-w-xl">
            <ModalTitle
                title={<Trans>Upload session</Trans>}
                description={
                    <Trans>Upload a HAR file of a mobile app session</Trans>
                }
                onClose={onDismiss}
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        {isMobile && (
                            <FormGroup label={<Trans>Device type</Trans>}>
                                <RadioButton.Group>
                                    <RadioButton
                                        value="IOS"
                                        checked={deviceType === 'IOS'}
                                        onChange={() =>
                                            formState.handleChange(
                                                'deviceType',
                                                'IOS'
                                            )
                                        }
                                    >
                                        <Trans>iOS</Trans>
                                    </RadioButton>
                                    <RadioButton
                                        value="ANDROID"
                                        checked={deviceType === 'ANDROID'}
                                        onChange={() =>
                                            formState.handleChange(
                                                'deviceType',
                                                'ANDROID'
                                            )
                                        }
                                    >
                                        <Trans>Android</Trans>
                                    </RadioButton>
                                </RadioButton.Group>
                            </FormGroup>
                        )}
                        <FormGroup
                            label={<Trans>Consent profile</Trans>}
                            htmlFor="consentProfileId"
                        >
                            <FormSelect
                                id="consentProfileId"
                                errors={formState.getErrorMessages(
                                    'consentProfileId'
                                )}
                                {...formState.getNativeInputProps(
                                    'consentProfileId'
                                )}
                            >
                                {audit.consentProfiles.map(
                                    ({ id, name, cmp }) => (
                                        <option key={id} value={id}>
                                            {name} [{cmp.name}]
                                        </option>
                                    )
                                )}
                            </FormSelect>
                        </FormGroup>
                        <FormGroup
                            label={<Trans>HAR file</Trans>}
                            className="mb-4"
                            htmlFor="harFile"
                        >
                            <FormUpload
                                id="harFile"
                                accept=".har"
                                errors={getErrorMessages('harFile')}
                                onChange={(e) =>
                                    handleChange('harFile', e.target.files[0])
                                }
                            />
                        </FormGroup>
                        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                            <span className="flex w-full rounded-md sm:ml-3 sm:w-auto">
                                <Button
                                    onClick={onSubmit}
                                    disabled={isUploading}
                                    loading={isUploading}
                                >
                                    <Trans>Upload</Trans>
                                </Button>
                            </span>
                            <span className="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
                                <Button type="white" onClick={onDismiss}>
                                    <Trans>Cancel</Trans>
                                </Button>
                            </span>
                        </div>
                    </>
                )
            })()}
        </Modal>
    )
}

export default UploadSessionModal
