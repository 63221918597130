import { i18n } from '@lingui/core'
import { en, nl } from 'make-plural/plurals'
import enMessages from './en/messages'
import nlMessages from './nl/messages'
import * as constants from '../constants'

const { LANGUAGE_EN: EN, LANGUAGE_NL: NL } = constants

export default function setupLocales() {
    i18n.loadLocaleData(EN, { plurals: en })
    i18n.loadLocaleData(NL, { plurals: nl })
    i18n.load(EN, enMessages.messages)
    i18n.load(NL, nlMessages.messages)
    i18n.activate(EN)
    return i18n
}
