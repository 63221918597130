import React from 'react'
import Select from './Select'
import FormInputErrors from './FormInputErrors'

const FormSelect = ({ errors, className, selectClassName, ...props }) => (
    <div className={className}>
        <Select className={selectClassName} {...props} />
        <FormInputErrors errors={errors} />
    </div>
)

export default FormSelect
