import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteScanModal from './DeleteScanModal'
import useDeleteScansMutation from '../audits-scans/useDeleteScansMutation'

const DeleteScanModalWithState = ({
    scan,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteScan, { loading }] = useDeleteScansMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteScanModal
            onDismiss={onDismiss}
            scan={scan}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await deleteScan({
                        variables: { input: { ids: [scan.id] } },
                    })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{scan.name} successfully deleted.</Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteScanModalWithState
