import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import VendorsPageTableRow from './VendorsPageTableRow'

const QUERY = gql`
    query vendorsPage(
        $search: String
        $offset: Int
        $filters: VendorsFilter
        $orderBy: VendorOrderByInput
    ) {
        vendors(
            search: $search
            offset: $offset
            filters: $filters
            orderBy: $orderBy
        ) {
            edges {
                cursor
                node {
                    ...VendorsPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${VendorsPageTableRow.FRAGMENT}
`

const useVendorPageQuery = ({ search, filters, orderBy }) => {
    const variables = { search, filters, orderBy }
    const {
        data = {},
        error,
        fetchMore,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'vendors'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            vendors: [],
            isFetching,
        }
    }
    return {
        vendors: connectionToCollection(data.vendors),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && data.vendors.pageInfo.hasNextPage,
    }
}

export default useVendorPageQuery
