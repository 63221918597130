import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import {
    useUserOrganizationsQuery,
    useStaffAssignOrganizationsToUserMutation,
    useStaffOrganizationsSearchQuery,
} from './useStaffAssignOrganizationsToUserActions'
import StaffAssignOrganizationsToUserModal from './StaffAssignOrganizationsToUserModal'
import useStaffAssignOrganizationsToUserFormState from './useStaffAssignOrganizationsToUserFormState'
import useSearch from '../../hooks/useSearch'

export default function StaffAssignOrganizationsToUserModalWithState({
    onDismiss,
    onCompleted,
    userId,
}) {
    const [search, handleSearch] = useSearch('')
    const [selectedOrganizations, setSelectedOrganizations] = useState([])
    const formState = useStaffAssignOrganizationsToUserFormState({
        userId,
        organizations: selectedOrganizations,
    })
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [staffAssignOrganizationsToUser, { loading: isSubmitting }] =
        useStaffAssignOrganizationsToUserMutation({
            onCompleted,
        })
    const { userOrganizations } = useUserOrganizationsQuery({ id: userId })
    const {
        organizations,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error: fetchOrganizationsError,
    } = useStaffOrganizationsSearchQuery({ search })
    const finalSelectedOrgs = [
        ...new Set([
            ...selectedOrganizations,
            ...userOrganizations.map((org) => org.organization?.id),
        ]),
    ]

    useEffect(() => {
        if (userOrganizations && userOrganizations.length > 0) {
            setSelectedOrganizations(
                userOrganizations.map((org) => org.organization?.id)
            )
        }
    }, [userOrganizations])

    const handleStaffAssignOrganizationsToUser = async () => {
        try {
            if (formState.validate()) {
                const variables = { input: formState.valuesToInput() }
                await staffAssignOrganizationsToUser({ variables })
                dispatchSuccess({
                    message: <Trans>Organizations assigned to user</Trans>,
                })
                onDismiss()
                onCompleted()
            }
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }
    return (
        <StaffAssignOrganizationsToUserModal
            isSubmitting={isSubmitting}
            formState={formState}
            organizations={organizations}
            hasMoreOrganizations={hasMore}
            handleFetchMoreOrganizations={handleFetchMore}
            isFetchingMoreOrganizations={isFetchingMore}
            isLoadingOrganizations={isFetching}
            searchOrganizations={search}
            handleSearchOrganizations={handleSearch}
            onDismiss={onDismiss}
            onSubmit={handleStaffAssignOrganizationsToUser}
            selectedOrganizations={selectedOrganizations}
            onToggleOrganization={(organizationId) => {
                const isAlreadySelected = selectedOrganizations.some(
                    (selectedOrg) => selectedOrg === organizationId
                )
                if (isAlreadySelected) {
                    const orgs = selectedOrganizations.filter(
                        (selectedOrg) => selectedOrg !== organizationId
                    )
                    setSelectedOrganizations(orgs)
                } else {
                    const orgs = [...selectedOrganizations, organizationId]
                    setSelectedOrganizations(orgs)
                }
            }}
        />
    )
}
