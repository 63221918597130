import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'
import StaffAssignOrganizationsToUserModalWithState from './StaffAssignOrganizationsToUserModalWithState'

export default function StaffUsersPageTableRowActionButton({
    isLoading,
    canDeleteUser,
    canAssignOrganizations,
    onDeleteUser,
    isYou,
    userId,
    refetch,
}) {
    const [isAssignOrganizationsModalOpen, setAssignOrganizationsModalOpen] =
        useState(false)

    return (
        <>
            {isAssignOrganizationsModalOpen && (
                <StaffAssignOrganizationsToUserModalWithState
                    onDismiss={() => setAssignOrganizationsModalOpen(false)}
                    onCompleted={() => {
                        setAssignOrganizationsModalOpen(false)
                        refetch()
                    }}
                    userId={userId}
                />
            )}
            <DropdownMenu
                position="bottom-start"
                content={({ close }) => (
                    <>
                        <DropdownMenuItem
                            iconName="bin"
                            disabled={isYou || !canDeleteUser}
                            onClick={async () => {
                                await onDeleteUser()
                                close()
                            }}
                        >
                            <Trans>Remove User</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="plus"
                            disabled={!canAssignOrganizations}
                            onClick={async () => {
                                setAssignOrganizationsModalOpen(true)
                                close()
                            }}
                        >
                            <Trans>Assign organizations</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                <ActionButton disabled={isLoading} />
            </DropdownMenu>
        </>
    )
}
