import React from 'react'
import { cva } from 'cva'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

// const itemVariant = cva('flex', {
//     variants: {
//         isHorizontal: {
//             true: 'flex-row',
//             false: 'flex-col gap-2',
//         },
//     },
// })

// const labelVariant = cva('text-sm font-medium text-slate-900', {
//     variants: {
//         isHorizontal: {
//             true: 'w-[200px] flex-shrink-0',
//             false: '',
//         },
//         labelWidth: {
//             sm: 'w-[100px]',
//             md: 'w-[200px]',
//             lg: 'w-[300px]',
//             xl: 'w-[400px]',
//         },
//     },
// })

export default function DrawerItem({
    className,
    label,
    labelWidth = 'md',
    truncate = true,
    isHorizontal = false,
    children,
}) {
    return (
        <div className={clsx(twMerge('px-6 py-4'), className)}>{children}</div>
    )
}
