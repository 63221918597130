import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import StaffUnclassifiedTrackersPageTableRow from './StaffUnclassifiedTrackersPageTableRow'

const QUERY = gql`
    query StaffUnclassifiedTrackersPage(
        $after: String
        $search: String
        $orderBy: TrackerOrderByInput
        $filters: StaffUnclassifiedTrackersFilters
    ) {
        staffUnclassifiedTrackers(
            after: $after
            search: $search
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                cursor
                node {
                    ...StaffUnclassifiedTrackersPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${StaffUnclassifiedTrackersPageTableRow.FRAGMENT}
`

const useStaffUnclassifiedTrackersPageQuery = ({
    search,
    orderBy,
    startDate,
    endDate,
    period,
}) => {
    const filters = { startDate, endDate, period }
    const variables = { search, filters, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip:
            filters.startDate === null ||
            filters.endDate === null ||
            filters.period === null,
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'staffUnclassifiedTrackers'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            unclassifiedTrackers: [],
            isFetching,
        }
    }
    return {
        unclassifiedTrackers: connectionToCollection(
            data.staffUnclassifiedTrackers
        ),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: data.staffUnclassifiedTrackers?.pageInfo.hasNextPage,
    }
}

export default useStaffUnclassifiedTrackersPageQuery
