import React from 'react'
import { Trans } from '@lingui/macro'
import AlertIcon from './AlertIcon'
import Tooltip from './Tooltip'
import DateTime from './DateTime'
import * as constants from '../../constants'

const {
    ALERT_SEVERITY_TYPE_WARNING: WARNING,
    ALERT_SEVERITY_TYPE_ISSUE: ISSUE,
    ALERT_SEVERITY_TYPE_CRITICAL: CRITICAL,
} = constants

export const AlertsTag = ({ count, severityType }) => (
    <div className="flex items-center gap-1">
        <AlertIcon alert={{ severityType }} size="xs" />
        <div className="text-xs font-medium">{count}</div>
    </div>
)

export const AlertDashboardTooltipItem = ({
    severityType,
    count,
    children,
}) => (
    <li className="mb-1 flex items-center gap-1">
        <AlertsTag severityType={severityType} size="xs" count={count} />
        {children}
    </li>
)

export const AlertSummaryTooltip = ({
    title,
    startDate,
    endDate,
    warningAlerts,
    criticalAlerts,
    issueAlerts,
}) => (
    <div>
        <h3 className="mb-2 flex items-center gap-2">
            {(() => {
                if (title) {
                    return title
                }
                return (
                    <>
                        <div>
                            <Trans>Alerts between:</Trans>
                        </div>
                        <div className="flex gap-1">
                            <DateTime dateOnly dateFormat="dd/MM">
                                {startDate}
                            </DateTime>
                            <span>-</span>
                            <DateTime dateOnly dateFormat="dd/MM">
                                {endDate}
                            </DateTime>
                        </div>
                    </>
                )
            })()}
        </h3>
        <ul>
            <AlertDashboardTooltipItem
                severityType={WARNING}
                size="xs"
                count={warningAlerts}
            >
                <Trans>Warnings</Trans>
            </AlertDashboardTooltipItem>
            <AlertDashboardTooltipItem
                severityType={ISSUE}
                size="xs"
                count={issueAlerts}
            >
                <Trans>Issues</Trans>
            </AlertDashboardTooltipItem>
            <AlertDashboardTooltipItem
                severityType={CRITICAL}
                size="xs"
                count={criticalAlerts}
            >
                <Trans>Critical alerts</Trans>
            </AlertDashboardTooltipItem>
        </ul>
    </div>
)

const AuditAlertSummary = ({ audit }) => {
    const { startDate, endDate, warningAlerts, issueAlerts, criticalAlerts } =
        audit.alertsByPeriod
    return (
        <Tooltip
            content={
                <AlertSummaryTooltip
                    startDate={startDate}
                    endDate={endDate}
                    warningAlerts={warningAlerts}
                    issueAlerts={issueAlerts}
                    criticalAlerts={criticalAlerts}
                />
            }
        >
            <div className="flex items-center gap-1">
                {warningAlerts > 0 && (
                    <AlertsTag
                        severityType={WARNING}
                        size="xs"
                        count={warningAlerts}
                    />
                )}
                {issueAlerts > 0 && (
                    <AlertsTag
                        severityType={ISSUE}
                        size="xs"
                        count={issueAlerts}
                    />
                )}
                {criticalAlerts > 0 && (
                    <AlertsTag
                        severityType={CRITICAL}
                        size="xs"
                        count={criticalAlerts}
                    />
                )}
            </div>
        </Tooltip>
    )
}

export default AuditAlertSummary
