import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import ReportsPageTableHeaderRow from '../reports/ReportsPageTableHeaderRow'
import ReportsPageTableRow from '../reports/ReportsPageTableRow'

export default function AuditReportsPageTable({
    reports = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    isLoading,
    onDeleteReport,
    onReportRecreated,
}) {
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                <thead>
                    <ReportsPageTableHeaderRow />
                </thead>
                <TableBody>
                    {reports.map((report) => (
                        <ReportsPageTableRow
                            key={report.id}
                            report={report}
                            isLoading={isLoading}
                            onDeleteReport={onDeleteReport}
                            onReportRecreated={onReportRecreated}
                        />
                    ))}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
