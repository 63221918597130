import React from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import useDrawerStack from '../../hooks/useDrawerStack'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'
import TableRow from '../../utils/TableRow'
import DateTime from '../../utils/DateTime'
import Avatar from '../../utils/Avatar'
import PurposesTags from '../../utils/PurposesTags'
import ActionButton from '../../utils/ActionButton'
import TrackersPageActionsDropdownMenu from './TrackersPageActionsDropdownMenu'
import TrackerDetailsDrawer from './TrackerDetailsDrawer'

const FRAGMENT = gql`
    fragment TrackersPageTableRow on Tracker {
        id
        occurrence
        purposes {
            id
            name
        }
        domain
        description
        createdAt
        updatedAt
        vendor {
            ...PopoverVendorInfo
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const TrackersPageTableRow = ({
    tracker,
    onDeleteTrackerCompleted,
    onUpdateTrackerCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        domain,
        purposes,
        description,
        vendor,
        occurrence,
        createdAt,
        updatedAt,
    } = tracker
    return (
        <TableRow
            isSelectable
            isActive={currentDrawerProps?.vendorId === id}
            selectionValue={tracker}
            selectionCriteria={(row) => row.id === tracker.id}
            data-testid="TrackersPageTableRow"
            onClick={() => {
                open(TrackerDetailsDrawer, {
                    trackerId: id,
                })
            }}
        >
            <TableCell columnId="name" size="sm" hasText>
                {domain}
            </TableCell>
            <TableCell columnId="purposes" size="sm">
                <PurposesTags purposes={purposes} />
            </TableCell>
            <TableCell className="text-left" columnId="vendor" size="sm">
                {vendor != null && (
                    <Avatar src={vendor.imageUrl} iconName="building-07" />
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm">
                <Tag>{occurrence}</Tag>
            </TableCell>
            <TableCell hasText size="sm" columnId="description">
                {description}
            </TableCell>
            <TableCell hasText columnId="createdAt" size="sm">
                <DateTime>{createdAt}</DateTime>
            </TableCell>
            <TableCell hasText columnId="updatedAt" size="sm">
                <DateTime>{updatedAt}</DateTime>
            </TableCell>
            <TableCell className="text-right" size="sm">
                <TrackersPageActionsDropdownMenu
                    tracker={tracker}
                    onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                    onUpdateTrackerCompleted={onUpdateTrackerCompleted}
                >
                    <ActionButton />
                </TrackersPageActionsDropdownMenu>
            </TableCell>
        </TableRow>
    )
}

TrackersPageTableRow.FRAGMENT = FRAGMENT

export default TrackersPageTableRow
