import React from 'react'
import clsx from 'clsx'
import LoadingIndicator from './LoadingIndicator'

export default function SelectList({
    searchInput,
    isFetching = false,
    className,
    children,
}) {
    return (
        <div className={clsx('flex flex-col', className)}>
            {searchInput && (
                <div className="z-10 border-b bg-white p-2">{searchInput}</div>
            )}
            {isFetching ? (
                <div className="flex h-full w-full items-center justify-center">
                    <LoadingIndicator />
                </div>
            ) : (
                <div className="w-full overflow-auto">{children}</div>
            )}
        </div>
    )
}
