import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import StaffHeader from '../../layout/StaffHeader'
import VendorsPageSidebar from '../staff-vendors/VendorsPageSidebar'
import PageHeader from '../../layout/PageHeader'
import StaffUnclassifiedTrackersTable from './StaffUnclassifiedTrackersTable'
import StaffUnclassifiedTrackersPageTableActions from './StaffUnclassifiedTrackersPageTableActions'
import TableStateProvider from '../../providers/TableStateProvider'

export default function StaffUnclassifiedTrackersPage({
    search,
    dateRangeState,
    unclassifiedTrackers,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    isFetching,
    onClassifyTrackerCompleted,
    orderBy,
    onReset,
    onChangeOrderBy,
}) {
    return (
        <PageContainer>
            <StaffHeader />
            <SidebarContainer>
                <VendorsPageSidebar />
                <PageContent size="full">
                    <div className="flex justify-between items-center">
                        <PageHeader
                            title={<Trans>Unclassified trackers</Trans>}
                            description={
                                <Trans>
                                    Below you can find the global tracker
                                    directory of Webclew.
                                </Trans>
                            }
                            breadcrumbsItems={[
                                {
                                    text: 'Unclassified Trackers',
                                    to: '/unclassified-trackers',
                                },
                            ]}
                        />
                    </div>
                    <TableStateProvider>
                        <StaffUnclassifiedTrackersPageTableActions
                            search={search}
                            onSearch={onSearch}
                            dateRangeState={dateRangeState}
                            orderBy={orderBy}
                            onChangeOrderBy={onChangeOrderBy}
                            onReset={onReset}
                        />
                        <StaffUnclassifiedTrackersTable
                            onClassifyTrackerCompleted={
                                onClassifyTrackerCompleted
                            }
                            unclassifiedTrackers={unclassifiedTrackers}
                            isFetchingMore={isFetchingMore}
                            isFetching={isFetching}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
