import localforage from 'localforage'

const LANGUAGE_KEY = 'language'
const ACCESS_TOKEN_KEY = 'accessToken_2'
const REFRESH_TOKEN_KEY = 'refreshToken_2'

// In memory cache
let accessToken = null
let refreshToken = null

export const cacheAccessToken = async () => {
    accessToken = await localforage.getItem(ACCESS_TOKEN_KEY)
}

export const getAccessToken = async () => {
    if (accessToken === null) {
        accessToken = await localforage.getItem(ACCESS_TOKEN_KEY)
    }
    return accessToken
}

export const setAccessToken = async (newAccessToken) => {
    await localforage.setItem(ACCESS_TOKEN_KEY, newAccessToken)
    accessToken = newAccessToken
}

export const removeAccessToken = async () => {
    await localforage.removeItem(ACCESS_TOKEN_KEY)
    accessToken = null
}

export const getRefreshToken = async () => {
    if (refreshToken === null) {
        refreshToken = await localforage.getItem(REFRESH_TOKEN_KEY)
    }
    return refreshToken
}

export const setRefreshToken = (newRefreshToken) => {
    refreshToken = newRefreshToken
    return localforage.setItem(REFRESH_TOKEN_KEY, newRefreshToken)
}

export const removeRefreshToken = () => {
    refreshToken = null
    return localforage.removeItem(REFRESH_TOKEN_KEY)
}

export const setLanguage = async (newLanguage) => {
    await localforage.setItem(LANGUAGE_KEY, newLanguage)
}
