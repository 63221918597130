import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import PageContent from '../../utils/PageContent'
import PageHeader from '../../layout/PageHeader'
import CookiesPageTable from './CookiesPageTable'
import StaffHeader from '../../layout/StaffHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import VendorsPageSidebar from '../staff-vendors/VendorsPageSidebar'
import Button from '../../utils/Button'
import TableStateProvider from '../../providers/TableStateProvider'
import CookiesPageTableActions from './CookiesPageTableActions'

const CookiesPage = ({
    search,
    cookies,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    activeFilters,
    initialOrderBy,
    onChangeOrderBy,
    isFetching,
    onDeleteCookiesCompleted,
    onUpdateCookiesCompleted,
    onBulkUpload,
    onCreateCookie,
}) => {
    return (
        <PageContainer data-testid="CookiesPage">
            <StaffHeader />
            <SidebarContainer>
                <VendorsPageSidebar />
                <PageContent size="full">
                    <PageHeader
                        title={<Trans>Cookies</Trans>}
                        description={
                            <Trans>
                                Below you can find the global cookie directory
                                of Webclew.
                            </Trans>
                        }
                        rightChildren={
                            <div className="flex gap-2">
                                <Button
                                    onClick={onBulkUpload}
                                    className="h-[40px]"
                                    type="white"
                                    iconBeforeProps={{
                                        name: 'upload-cloud-02',
                                        size: 'sm',
                                    }}
                                >
                                    <Trans>Bulk Upload</Trans>
                                </Button>
                                <Button
                                    onClick={onCreateCookie}
                                    className="h-fit"
                                    type="primary"
                                    iconBeforeProps={{
                                        name: 'plus',
                                        size: 'sm',
                                    }}
                                >
                                    <Trans>Create Cookie</Trans>
                                </Button>
                            </div>
                        }
                    />
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <CookiesPageTableActions
                            search={search}
                            onSearch={onSearch}
                            activeFilters={activeFilters}
                            onAddFilter={onAddFilter}
                            onRemoveFilter={onRemoveFilter}
                            onRemoveAllFilters={onRemoveAllFilters}
                        />
                        <CookiesPageTable
                            cookies={cookies}
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            isFetching={isFetching}
                            onDeleteCookiesCompleted={onDeleteCookiesCompleted}
                            onUpdateCookiesCompleted={onUpdateCookiesCompleted}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
export default CookiesPage
