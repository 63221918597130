import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'

const OrganizationVendorsPageTableHeaderRow = () => (
    <tr>
        <TableHeaderCell>
            <Trans>vendor</Trans>
        </TableHeaderCell>
        <TableHeaderCell />
        <TableHeaderCell />
    </tr>
)

export default OrganizationVendorsPageTableHeaderRow
