import React, { useState } from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useSearch from '../../hooks/useSearch'
import IdentifiedCookiesPage from './IdentifiedCookiesPage'
import useIdentifiedCookiesPageQuery from './useIdentifiedCookiesPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import useCollectionState from '../../hooks/useCollectionState'

const IdentifiedCookiesPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const {
        collection,
        addToCollection,
        addAllToCollection,
        clearCollection,
        removeFromCollection,
    } = useCollectionState()
    const [isSelecting, onSetSelecting] = useState(false)
    const [selectedAuditId, setSelectedAuditId] = useState('ALL')
    const [selectedPurposeId, setSelectedPurpose] = useState('ALL')
    const scanRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = scanRangeState
    const {
        audits,
        cookies,
        purposes,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useIdentifiedCookiesPageQuery(
        search,
        selectedAuditId === 'ALL' ? null : selectedAuditId,
        selectedPurposeId === 'ALL' ? null : selectedPurposeId,
        startDate,
        endDate,
        period
    )
    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)
    return (
        <IdentifiedCookiesPage
            audits={audits}
            purposes={purposes}
            selectedAuditId={selectedAuditId}
            selectedPurposeId={selectedPurposeId}
            scanRangeState={scanRangeState}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            cookies={cookies}
            search={search}
            onSearch={handleSearch}
            onChangeAudit={(id) => setSelectedAuditId(id)}
            onChangePurpose={(id) => setSelectedPurpose(id)}
            onClassifyCompleted={() => refetch()}
            onUpdateCompleted={() => refetch()}
            onDeleteCompleted={() => refetch()}
            isSelecting={isSelecting}
            onStartSelecting={() => onSetSelecting(true)}
            onStopSelecting={() => {
                onSetSelecting(false)
                clearCollection()
            }}
            onSelectAll={(e) => {
                if (e.target.checked) {
                    addAllToCollection(cookies)
                } else {
                    clearCollection()
                }
            }}
            onSelect={(isSelected, cookie) => {
                if (isSelected) {
                    addToCollection(cookie)
                } else {
                    removeFromCollection(cookie)
                }
            }}
            selectedRows={collection}
            onSort={(columnId, direction) => {
                refetch({
                    orderBy: {
                        [columnId]: direction,
                    },
                })
            }}
        />
    )
}

export default IdentifiedCookiesPageWithState
