import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useStaffCreateUserMutation from './StaffCreateUserMutation'
import StaffCreateUserModal from './StaffCreateUserModal'
import useStaffCreateUserFormState from './useStaffCreateUserFormState'

const StaffCreateUserModalWithState = ({ onDismiss, onCompleted }) => {
    const formState = useStaffCreateUserFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [staffCreateUser, { loading }] = useStaffCreateUserMutation({
        onCompleted,
    })

    const handleStaffCreateUser = async () => {
        try {
            if (formState.validate()) {
                const variables = { input: formState.valuesToInput() }
                await staffCreateUser({ variables })
                dispatchSuccess({
                    message: <Trans>Staff user created</Trans>,
                })
                onDismiss()
                onCompleted()
            }
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }
    return (
        <StaffCreateUserModal
            isLoading={loading}
            formState={formState}
            onDismiss={onDismiss}
            onSubmit={handleStaffCreateUser}
        />
    )
}

export default StaffCreateUserModalWithState
