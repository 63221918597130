import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateCookieTable($id: ID!, $input: CookieTableInput!) {
        updateCookieTable(id: $id, input: $input) {
            cookieTable {
                id
            }
        }
    }
`

const useUpdateCookieTableMutation = (options) => useMutation(MUTATION, options)

export default useUpdateCookieTableMutation
