import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import TableLoading from '../../utils/TableLoading'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import PageHeader from '../../layout/PageHeader'
import AuditHeaderWithState from '../audits-details/AuditHeaderWithState'
import AuditSettingsFormCard from './AuditSettingsFormCard'
import AuditSettingsAlertsFormCard from './AuditSettingsAlertsFormCard'
import AuditDomainsPageTable from './AuditDomainsPageTable'
import AuditDomainsPageConsentProfilesTable from './AuditDomainsPageConsentProfilesTable'
import AuditSettingsPageLoginProfilesTable from './AuditSettingsPageLoginProfilesTable'

const AuditSettingsPage = ({
    organization,
    audit,
    domains,
    loginProfiles,
    cookieTables,
    vendorTables,
    isUpdating,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    settingsFormState,
    alertSettingsFormState,
    onUpdateSettings,
    onUpdateAlertSettings,
    onFetchMore,
    onAddDomain,
    onAddConsentProfile,
    onAddLoginProfile,
    onDeleteDomainCompleted,
    onRemoveConsentProfileCompleted,
    onRemoveLoginProfileCompleted,
}) => (
    <PageContainer data-testid="AuditSettingsPage">
        <AuditHeader />
        <SidebarContainer>
            <PageContent isFetching={isFetching}>
                <PageHeader
                    className="mb-6"
                    title={<Trans>Settings</Trans>}
                    description={
                        <Trans>
                            Here you can configure the settings for this audit.
                        </Trans>
                    }
                />
                {(() => {
                    return (
                        <>
                            <AuditSettingsFormCard
                                organization={organization}
                                isLoading={isUpdating}
                                formState={settingsFormState}
                                loginProfiles={loginProfiles}
                                onSubmit={onUpdateSettings}
                            />
                            <AuditSettingsAlertsFormCard
                                organization={organization}
                                cookieTables={cookieTables}
                                vendorTables={vendorTables}
                                isLoading={isUpdating}
                                formState={alertSettingsFormState}
                                onSubmit={onUpdateAlertSettings}
                            />
                            {audit.isWeb && (
                                <section>
                                    <div className="flex justify-end mb-4">
                                        <Button
                                            onClick={onAddDomain}
                                            iconBeforeProps={{
                                                name: 'plus',
                                                color: 'white',
                                            }}
                                        >
                                            <Trans>Add domain</Trans>
                                        </Button>
                                    </div>
                                    <AuditDomainsPageTable
                                        audit={audit}
                                        domains={domains}
                                        isFetchingMore={isFetchingMore}
                                        onFetchMore={onFetchMore}
                                        hasMoreRecords={hasMoreRecords}
                                        onDeleteDomainCompleted={
                                            onDeleteDomainCompleted
                                        }
                                    />
                                </section>
                            )}
                            <section className="mb-8">
                                <div className="flex justify-end mb-4">
                                    <Button
                                        onClick={onAddConsentProfile}
                                        iconBeforeProps={{
                                            name: 'plus',
                                            color: 'white',
                                        }}
                                    >
                                        <Trans>Add consent profile</Trans>
                                    </Button>
                                </div>
                                <AuditDomainsPageConsentProfilesTable
                                    audit={audit}
                                    consentProfiles={audit.consentProfiles}
                                    onRemoveConsentProfileCompleted={
                                        onRemoveConsentProfileCompleted
                                    }
                                    onAddConsentProfile={onAddConsentProfile}
                                />
                            </section>
                            <section>
                                <div className="flex justify-end mb-4">
                                    <Button
                                        onClick={onAddLoginProfile}
                                        iconBeforeProps={{
                                            name: 'plus',
                                            color: 'white',
                                        }}
                                    >
                                        <Trans>Add login profile</Trans>
                                    </Button>
                                </div>
                                <AuditSettingsPageLoginProfilesTable
                                    audit={audit}
                                    loginProfiles={audit.loginProfiles}
                                    onRemoveLoginProfileCompleted={
                                        onRemoveLoginProfileCompleted
                                    }
                                    onAddLoginProfile={onAddLoginProfile}
                                />
                            </section>
                        </>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default AuditSettingsPage
