import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createVendor($input: VendorInput!) {
        createVendor(input: $input) {
            vendor {
                id
                name
            }
        }
    }
`

const useCreateVendorMutation = (options) => useMutation(MUTATION, options)

export default useCreateVendorMutation
