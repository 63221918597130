import React, { useState } from 'react'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import AuditDomainsPageTableRowActionButton from './AuditDomainsPageTableRowActionButton'
import DeleteDomainModalWithState from './DeleteDomainModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const AuditDomainsPageTableRowActionButtonWithState = ({
    domain,
    onDeleteDomainCompleted,
}) => {
    const { currentUser } = useAppState()
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    return (
        <>
            {isDeleteModalOpen && (
                <DeleteDomainModalWithState
                    domain={domain}
                    onDismiss={() => setDeleteModalOpen(false)}
                    onCompleted={onDeleteDomainCompleted}
                />
            )}
            <AuditDomainsPageTableRowActionButton
                domain={domain}
                canDeleteDomain={currentUser.role === OWNER}
                onDeleteDomain={() => setDeleteModalOpen(true)}
            />
        </>
    )
}

export default AuditDomainsPageTableRowActionButtonWithState
