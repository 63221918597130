import React from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

const ReportsPageTableActions = ({ onDeleteReports }) => {
    const {
        isSelectingRows,
        startSelectingRows,
        stopSelectingRows,
        selectedRows,
        clearSelectedRows,
    } = useTableState()
    return (
        <TableActionsContainer isSticky>
            {isSelectingRows && (
                <div className="flex flex-row gap-2">
                    <Button
                        type="danger"
                        size="sm"
                        disabled={selectedRows.length === 0}
                        onClick={() => {
                            onDeleteReports(
                                selectedRows,
                                clearSelectedRows,
                                stopSelectingRows
                            )
                        }}
                    >
                        <Trans>Delete Reports</Trans>
                    </Button>
                    <Button
                        type="white"
                        size="sm"
                        onClick={() => stopSelectingRows()}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                </div>
            )}
            {!isSelectingRows && (
                <div className="flex gap-2 items-center">
                    <Button
                        type="white"
                        size="sm"
                        iconBeforeProps={{
                            name: 'check-done-01',
                            size: 'sm',
                        }}
                        onClick={() => startSelectingRows()}
                    />
                </div>
            )}
        </TableActionsContainer>
    )
}

export default ReportsPageTableActions
