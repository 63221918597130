import React from 'react'
import { Trans } from '@lingui/macro'
import ButtonLink from '../../utils/ButtonLink'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import LoadingIndicator from '../../utils/LoadingIndicator'
import Select from '../../utils/Select'
import AlertsByVendorListItem from './AlertsByVendorListItem'

const DashboardPageAlertsByVendorChart = ({
    isFetching,
    chartData,
    startDate,
    endDate,
}) => (
    <div className="bg-white gap-4 p-6 rounded-2xl border border-gray-200 mb-8">
        {(() => {
            if (isFetching) {
                return (
                    <div className="h-[300px] flex items-center justify-center">
                        <LoadingIndicator />
                    </div>
                )
            }
            const mostAlerts = chartData.reduce(
                (prev, current) =>
                    prev.alertCount > current.alertCount ? prev : current,
                0
            )
            return (
                <>
                    <div className="flex justify-between mb-12">
                        <div>
                            <h3 className="font-semibold ">
                                <Trans>Alerts by vendor</Trans>
                            </h3>
                        </div>
                        <div />
                    </div>
                    <div className="min-h-[300px]">
                        <ul className="w-full">
                            {chartData.map(({ vendor, alertCount }) => (
                                <AlertsByVendorListItem
                                    key={vendor.id}
                                    vendor={vendor}
                                    alertCount={alertCount}
                                    maxAlertCount={mostAlerts.alertCount}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ))}
                        </ul>
                    </div>
                </>
            )
        })()}
    </div>
)

export default DashboardPageAlertsByVendorChart
