import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import FormInput from '../../utils/FormInput'
import ButtonLink from '../../utils/ButtonLink'
import FormSelect from '../../utils/FormSelect'

const { AVAILABLE_DOMAIN_CONSENT_RESOLVERS: CONSENT_RESOLVERS } = constants

const UpdateCustomCmpFormResolverGroup = ({
    index,
    resolver,
    consentProfile,
    formState,
    onEnable,
    onDisable,
}) => {
    const { getNativeInputProps, getErrorMessages } = formState
    const isDisabled = !!consentProfile
    let id = null
    let profileName = null
    let typeProps = {}
    let typeErrors = []
    let scriptProps = {}
    let scriptErrors = []
    if (isDisabled) {
        id = consentProfile.id
        profileName = consentProfile.name
    } else {
        id = resolver.id
        profileName = resolver.profile.name
        typeProps = getNativeInputProps(`resolvers.${index}.type`)
        typeErrors = getErrorMessages(`resolvers.${index}.type`)
        scriptProps = getNativeInputProps(`resolvers.${index}.script`)
        scriptErrors = getErrorMessages(`resolvers.${index}.script`)
    }
    return (
        <div key={id} className="w-full grid grid-cols-10 gap-4 mb-4">
            <div className="col-span-2 flex items-center">{profileName}</div>
            <FormInput
                id="script"
                className="col-span-5"
                disabled={isDisabled}
                errors={scriptErrors}
                {...scriptProps}
            />
            <FormSelect
                id="cmpId"
                className="col-span-2"
                disabled={isDisabled}
                errors={typeErrors}
                {...typeProps}
            >
                {isDisabled && <option value="" />}
                {CONSENT_RESOLVERS.map((value) => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                ))}
            </FormSelect>
            <div className="col-span-1 flex items-center">
                <ButtonLink
                    type={isDisabled ? 'primary' : 'danger'}
                    onClick={() => {
                        if (isDisabled) {
                            onEnable(consentProfile)
                        } else {
                            onDisable(resolver)
                        }
                    }}
                >
                    {isDisabled ? <Trans>Enable</Trans> : <Trans>Remove</Trans>}
                </ButtonLink>
            </div>
        </div>
    )
}

export default UpdateCustomCmpFormResolverGroup
