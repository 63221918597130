import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import OrganizationCookiesPageTableRow from './OrganizationCookiesPageTableRow'

const QUERY = gql`
    query organizationCookiesPage($after: String, $search: String) {
        organizationCookies(after: $after, search: $search) {
            edges {
                cursor
                node {
                    ...OrganizationCookiesPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${OrganizationCookiesPageTableRow.FRAGMENT}
`

const useOrganizationCookiesPageQuery = (search) => {
    const variables = { search }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'organizationCookies'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            cookies: [],
            isFetching,
        }
    }
    return {
        cookies: connectionToCollection(data.organizationCookies),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: data.organizationCookies.pageInfo.hasNextPage,
    }
}

export default useOrganizationCookiesPageQuery
