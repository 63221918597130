import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateCookies($input: UploadCookieInput!) {
        updateCookies(input: $input)
    }
`

const useBulkUploadUpdateCookiesMutation = (options) =>
    useMutation(MUTATION, options)

export default useBulkUploadUpdateCookiesMutation
