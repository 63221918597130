/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import VendorSearchSelect from '../../utils/VendorSearchSelect'
import PurposesSelect from '../../utils/PurposesSelect'

const UpdateTrackerModal = ({
    initialVendor,
    purposes = [],
    formState,
    isFetching,
    canCreateVendor,
    onDismiss,
    onSubmit,
}) => {
    const { values, handleChange, getErrorMessages, getNativeInputProps } =
        formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                title={<Trans>Update tracker</Trans>}
                // eslint-disable-next-line max-len
                description={
                    <Trans>
                        Attach a vendor and one or more purposes to this tracker
                    </Trans>
                }
                onClose={onDismiss}
            />
            {(() => {
                if (isFetching) {
                    return <ModalLoading />
                }
                return (
                    <>
                        <FormGroup
                            label={<Trans>Domain</Trans>}
                            className="mb-4"
                        >
                            <FormInput
                                errors={getErrorMessages('domain')}
                                {...getNativeInputProps('domain')}
                            />
                        </FormGroup>
                        <FormGroup
                            label={<Trans>Vendor</Trans>}
                            className="mb-4"
                        >
                            <VendorSearchSelect
                                value={values.vendorId}
                                initialVendor={initialVendor}
                                onChange={(id) => handleChange('vendorId', id)}
                                errors={getErrorMessages('vendorId')}
                                canCreateVendor={canCreateVendor}
                            />
                        </FormGroup>
                        <FormGroup label={<Trans>Purposes</Trans>}>
                            <PurposesSelect
                                purposes={purposes}
                                value={values.purposeIds}
                                errors={getErrorMessages('purposeIds')}
                                onChange={(id) =>
                                    handleChange('purposeIds', id)
                                }
                            />
                        </FormGroup>
                        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                                <Button onClick={onSubmit}>
                                    <Trans>Done</Trans>
                                </Button>
                            </span>
                            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                                <Button type="white" onClick={onDismiss}>
                                    <Trans>Cancel</Trans>
                                </Button>
                            </span>
                        </div>
                    </>
                )
            })()}
        </Modal>
    )
}

export default UpdateTrackerModal
