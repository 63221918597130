import React from 'react'
import useAlertCommentFormState from '../../utils/useAlertCommentFormState'
import useCreateAlertCommentMutation from '../../utils/useCreateAlertCommentMutation'
import useNotifications from '../../hooks/useNotifications'
import GroupedAlertDetailsDrawerTabActivity from './GroupedAlertDetailsDrawerTabActivity'

const GroupedAlertDetailsDrawerTabActivityWithState = ({
    onComment,
    alertGroupingId,
    alertComments,
    alertDelegations,
}) => {
    const formState = useAlertCommentFormState()
    const [createAlertComment] = useCreateAlertCommentMutation()
    const { dispatchGraphqlError } = useNotifications()
    const handleSubmit = async () => {
        if (formState.validate()) {
            try {
                const input = {
                    text: formState.values.text,
                }

                await createAlertComment({
                    variables: {
                        groupingId: alertGroupingId,
                        input,
                    },
                })
                formState.resetForm()
                onComment()
            } catch (error) {
                dispatchGraphqlError(error)
            }
        }
    }
    const activity = [...alertComments, ...alertDelegations].sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
    )

    return (
        <GroupedAlertDetailsDrawerTabActivity
            formState={formState}
            onSubmit={handleSubmit}
            activity={activity}
        />
    )
}

export default GroupedAlertDetailsDrawerTabActivityWithState
