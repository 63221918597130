import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useQueryParams, StringParam } from 'use-query-params'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import ChoosePasswordPage from './ChoosePasswordPage'
import useChoosePasswordMutation from './useChoosePasswordMutation'
import useChoosePasswordFormState from './useChoosePasswordFormState'

const ChoosePasswordPageWithState = () => {
    const appState = useAppState()
    const [params] = useQueryParams({
        email: StringParam,
        token: StringParam,
    })
    const { dispatchSuccess } = useNotifications()
    const [errorMessage, setErrorMessage] = useState(null)
    const formState = useChoosePasswordFormState()
    const [choosePassword, { loading }] = useChoosePasswordMutation()
    const { email, token } = params
    return (
        <ChoosePasswordPage
            email={email}
            formState={formState}
            isLoading={loading}
            errorMessage={errorMessage}
            onSave={async () => {
                if (formState.validate()) {
                    try {
                        const { values } = formState
                        const input = {
                            token,
                            email,
                            password: values.password,
                        }
                        const { data } = await choosePassword({
                            variables: { input },
                        })
                        const {
                            accessToken,
                            refreshToken,
                            me: currentUser,
                        } = data.choosePassword
                        await appState.login(
                            accessToken,
                            refreshToken,
                            currentUser
                        )
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Your password was saved successfully.
                                </Trans>
                            ),
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        setErrorMessage(message)
                    }
                }
            }}
        />
    )
}

export default ChoosePasswordPageWithState
