import React, { useState } from 'react'
import CookieTableItemTableRowActionButton from './CookieTableItemTableRowActionButton'
import DeleteCookieTableItemModalWithState from './DeleteCookieTableItemModalWithState'

const CookieTableItemTableRowActionButtonWithState = ({
    cookieTableItem,
    onUpdateCookieTableItemsCompleted,
}) => {
    const [
        isRemoveCookieTableItemModalOpen,
        setRemoveCookieTableItemModalOpen,
    ] = useState(false)
    return (
        <>
            {isRemoveCookieTableItemModalOpen && (
                <DeleteCookieTableItemModalWithState
                    cookieTableItem={cookieTableItem}
                    onDismiss={() => setRemoveCookieTableItemModalOpen(false)}
                    onCompleted={onUpdateCookieTableItemsCompleted}
                />
            )}
            <CookieTableItemTableRowActionButton
                onDeleteCookieTableItem={() =>
                    setRemoveCookieTableItemModalOpen(true)
                }
            />
        </>
    )
}

export default CookieTableItemTableRowActionButtonWithState
