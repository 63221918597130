/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormCheckbox from '../../utils/FormCheckbox'
import FormTextarea from '../../utils/FormTextarea'
import ButtonLink from '../../utils/ButtonLink'

const AlertExternalDelegationModal = ({
    groupingIds,
    formState,
    onDismiss,
    onSubmit,
    isSendingMail,
}) => {
    const history = useHistory()
    const { search } = useLocation()
    const { id: auditId } = useParams()
    const { getErrorMessages, getNativeInputProps } = formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                title={<Trans>Delegate to external</Trans>}
                description={
                    <Trans>
                        Send an email with all alert details to outside
                        collaborators.
                    </Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Email</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('email')}
                    {...getNativeInputProps('email')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Title</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('title')}
                    {...getNativeInputProps('title')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Body</Trans>} className="mb-4">
                <FormTextarea
                    textareaClassName="h-48"
                    errors={getErrorMessages('body')}
                    {...getNativeInputProps('body')}
                />
            </FormGroup>
            <div className="flex items-center gap-2">
                <FormCheckbox
                    label={<Trans>Attach report</Trans>}
                    errors={getErrorMessages('attachReport')}
                    {...getNativeInputProps('attachReport')}
                />
                <ButtonLink
                    onClick={() => {
                        const groupingIdsString = groupingIds.join(',')
                        history.push({
                            pathname: auditId
                                ? `/audits/${auditId}/alerts/${groupingIdsString}`
                                : `/alerts/${groupingIdsString}`,
                            search,
                        })
                    }}
                >
                    <Trans>Preview</Trans>
                </ButtonLink>
            </div>
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit} loading={isSendingMail}>
                        <Trans>Done</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default AlertExternalDelegationModal
