import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import LoginProfilesPageTableRowActionButtonWithState from './LoginProfilesPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment LoginProfilesPageTableRow on LoginProfile {
        id
        name
    }
`

const LoginProfilesPageTableRow = ({ loginProfile, onDeleteCompleted }) => {
    const history = useHistory()
    const { id, name } = loginProfile
    return (
        <TableRow
            data-testid="CustomCmpsPageTableRow"
            isSelectable
            onClick={() => {
                history.push(`/settings/login-profiles/${id}`)
            }}
        >
            <TableCell hasText>{name}</TableCell>
            <TableCell className="text-right">
                <LoginProfilesPageTableRowActionButtonWithState
                    loginProfile={loginProfile}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

LoginProfilesPageTableRow.FRAGMENT = FRAGMENT

export default LoginProfilesPageTableRow
