import React from 'react'
import Tag from './Tag'
import AlertIcon from './AlertIcon'
import * as constants from '../../constants'

const {
    ALERT_SEVERITY_TYPE_ISSUE: ISSUE,
    ALERT_SEVERITY_TYPE_CRITICAL: CRITICAL,
    ALERT_SEVERITY_TYPE_WARNING: WARNING,
} = constants

export default function AlertCountTag({
    alerts = [],
    criticalAlertsCount = 0,
    issueAlertsCount = 0,
    warningAlertsCount = 0,
}) {
    let finalCriticalAlertsCount = criticalAlertsCount
    let finalIssueAlertsCount = issueAlertsCount
    let finalWarningAlertsCount = warningAlertsCount
    if (alerts.length > 0) {
        finalCriticalAlertsCount = alerts.filter(
            (alert) => alert.severityType === CRITICAL
        ).length
        finalWarningAlertsCount = alerts.filter(
            (alert) => alert.severityType === WARNING
        ).length
        finalIssueAlertsCount = alerts.filter(
            (alert) => alert.severityType === ISSUE
        ).length
    }
    const total =
        finalCriticalAlertsCount +
        finalWarningAlertsCount +
        finalIssueAlertsCount
    if (total <= 0) {
        return null
    }
    return (
        <Tag className="flex items-center gap-2">
            {finalCriticalAlertsCount > 0 && (
                <div className="flex items-center gap-1">
                    <AlertIcon size="2xs" type="critical" />
                    {finalCriticalAlertsCount}
                </div>
            )}
            {finalIssueAlertsCount > 0 && (
                <div className="flex items-center gap-1">
                    <AlertIcon size="2xs" type="issue" />
                    {finalIssueAlertsCount}
                </div>
            )}
            {finalWarningAlertsCount > 0 && (
                <div className="flex items-center gap-1">
                    <AlertIcon size="2xs" type="warning" />
                    {finalWarningAlertsCount}
                </div>
            )}
        </Tag>
    )
}
