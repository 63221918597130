import React from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import Avatar from '../../utils/Avatar'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import AuditDomainsPageConsentProfilesTableRowActionButtonWithState from './AuditDomainsPageConsentProfilesTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment AuditDomainsPageConsentProfileTableRow on ConsentProfile {
        id
        name
        purposes {
            id
            name
        }
        cmp {
            id
            name
        }
    }
`

const AuditDomainsPageConsentProfileTableRow = ({
    audit,
    consentProfile,
    onRemoveConsentProfileCompleted,
}) => {
    const history = useHistory()
    const { id, name, purposes, cmp = {} } = consentProfile
    return (
        <TableRow data-testid="AuditDomainsPageConsentProfileTableRow">
            <TableCell hasText>{name}</TableCell>
            <TableCell hasText>{cmp.name}</TableCell>
            <TableCell>
                {purposes?.map((purpose) => (
                    <Tag key={purpose?.id} type="gray" className="mr-2">
                        {purpose?.name}
                    </Tag>
                ))}
            </TableCell>
            <TableCell className="text-right">
                <AuditDomainsPageConsentProfilesTableRowActionButtonWithState
                    audit={audit}
                    consentProfile={consentProfile}
                    onRemoveConsentProfileCompleted={
                        onRemoveConsentProfileCompleted
                    }
                />
            </TableCell>
        </TableRow>
    )
}

AuditDomainsPageConsentProfileTableRow.FRAGMENT = FRAGMENT

export default AuditDomainsPageConsentProfileTableRow
