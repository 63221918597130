import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import AlertsPageActionsDelegateButtonWithState from './AlertsPageActionsDelegateButtonWithState'

const IgnoreButton = ({ onClick, disabled }) => (
    <Button
        type="white"
        onClick={onClick}
        disabled={disabled}
        iconBeforeProps={{
            name: 'mute-notification',
            size: 'sm',
            color: 'none',
            hasText: true,
            className: 'text-gray-500',
        }}
    >
        <Trans>Ignore alerts</Trans>
    </Button>
)

const DelegateButton = ({
    onDelegateAlertsInternal,
    onDelegateAlertsExternal,
    onClearAlertsDelegate,
    disabled,
    selectedAlerts,
}) => (
    <AlertsPageActionsDelegateButtonWithState
        onClearDelegation={onClearAlertsDelegate}
        onDelegateExternal={onDelegateAlertsExternal}
        onDelegateInternal={onDelegateAlertsInternal}
        selectedAlerts={selectedAlerts}
    >
        <Button
            type="white"
            disabled={disabled}
            iconBeforeProps={{
                name: 'user-assign',
                size: 'sm',
                color: 'none',
                hasText: true,
                className: 'text-gray-500',
            }}
        >
            <Trans>Delegate alerts</Trans>
        </Button>
    </AlertsPageActionsDelegateButtonWithState>
)

const RestoreButton = ({ onClick, disabled }) => (
    <Button
        type="white"
        onClick={onClick}
        disabled={disabled}
        iconBeforeProps={{
            name: 'switch-horizontal-01',
            size: 'sm',
            color: 'none',
            hasText: true,
            className: 'text-gray-500',
        }}
    >
        <Trans>Unignore alerts</Trans>
    </Button>
)

const SelectAlertsActions = ({
    hasSelectedAlerts,
    selectedAlertStatus,
    onStopSelectingAlerts,
    onIgnoreAlerts,
    onDelegateAlertsInternal,
    onClearAlertsDelegate,
    onDelegateAlertsExternal,
    onRestoreAlerts,
    selectedAlerts,
}) => {
    if (selectedAlertStatus === 'IGNORED') {
        return (
            <>
                <RestoreButton
                    onClick={onRestoreAlerts}
                    disabled={hasSelectedAlerts}
                />
                <DelegateButton
                    onDelegateAlertsInternal={onDelegateAlertsInternal}
                    onClearAlertsDelegate={onClearAlertsDelegate}
                    onDelegateAlertsExternal={onDelegateAlertsExternal}
                    disabled={hasSelectedAlerts}
                    selectedAlerts={selectedAlerts}
                />
                <Button type="white" onClick={onStopSelectingAlerts}>
                    <Trans>Cancel</Trans>
                </Button>
            </>
        )
    }
    return (
        <>
            <IgnoreButton
                onClick={onIgnoreAlerts}
                disabled={hasSelectedAlerts}
            />
            <DelegateButton
                onDelegateAlertsInternal={onDelegateAlertsInternal}
                onClearAlertsDelegate={onClearAlertsDelegate}
                onDelegateAlertsExternal={onDelegateAlertsExternal}
                disabled={hasSelectedAlerts}
                selectedAlerts={selectedAlerts}
            />
            <Button type="white" onClick={onStopSelectingAlerts}>
                <Trans>Cancel</Trans>
            </Button>
        </>
    )
}

export default SelectAlertsActions
