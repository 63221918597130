import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import LoginProfilesPageTableRowActionButton from './LoginProfilesPageTableRowActionButton'
import DeleteLoginProfileModalWithState from './DeleteLoginProfileModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const LoginProfilesPageTableRowActionButtonWithState = ({
    loginProfile,
    onDeleteCompleted,
}) => {
    const history = useHistory()
    const { currentUser } = useAppState()
    const deleteModalIsOpen = useRouteMatch({
        path: `/settings/login-profiles/${loginProfile.id}/delete`,
    })
    return (
        <>
            {deleteModalIsOpen && (
                <DeleteLoginProfileModalWithState
                    loginProfile={loginProfile}
                    onDismiss={() => history.push('/settings/login-profiles')}
                    onCompleted={onDeleteCompleted}
                />
            )}
            <LoginProfilesPageTableRowActionButton
                loginProfile={loginProfile}
                canDelete={currentUser.role === OWNER}
                onDelete={() =>
                    history.push(
                        `/settings/login-profiles/${loginProfile.id}/delete`
                    )
                }
            />
        </>
    )
}

export default LoginProfilesPageTableRowActionButtonWithState
