import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createConsentProfile($input: ConsentProfileInput!) {
        createConsentProfile(input: $input) {
            consentProfile {
                id
            }
        }
    }
`

const useCreateConsentProfileMutation = (options) =>
    useMutation(MUTATION, options)

export default useCreateConsentProfileMutation
