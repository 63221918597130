import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteCookieTableModal from './DeleteCookieTableModal'
import useDeleteCookieTable from './useDeleteCookieTable'

const DeleteCookieTableModalWithState = ({
    cookieTable,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteCookieTable, { loading }] = useDeleteCookieTable({
        onCompleted,
        onError,
    })
    return (
        <DeleteCookieTableModal
            onDismiss={onDismiss}
            cookieTable={cookieTable}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { id: cookieTable.id }
                    await deleteCookieTable({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {cookieTable.name} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteCookieTableModalWithState
