import React from 'react'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import StaffHeader from '../../layout/StaffHeader'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function StaffGlossaryPage() {
    return (
        <PageContainer>
            <StaffHeader />
            <SidebarContainer>
                <PageContent>
                    <EmptyPageCard
                        iconName="book-closed"
                        title="Coming soon..."
                        description="Stay tuned, we are in the process of adding this page."
                        className="mt-48"
                    />
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
