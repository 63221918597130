import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'consentProfileId',
        validate: isRequired,
        message: <Trans>Select a consent profile.</Trans>,
    },
])

const useAddConsentProfileFormState = () =>
    useFormState(
        {
            consentProfileId: '',
        },
        {
            validation,
        }
    )

export default useAddConsentProfileFormState
