import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'maxCookieRetention',
        validate: isRequired,
        message: <Trans>The max cookie retention is required</Trans>,
    },
])

const valuesToInput = (values) => values

const useUpdateSettingsAuditAlertsFormState = ({
    maxCookieRetention = '',
    vendorTable = null,
    cookieTable = null,
    showVendorTableAlerts = false,
    showCookieTableAlerts = false,
}) =>
    useFormState(
        {
            maxCookieRetention,
            vendorTableId: vendorTable ? vendorTable.id : null,
            cookieTableId: cookieTable ? cookieTable.id : null,
            showVendorTableAlerts,
            showCookieTableAlerts,
        },
        {
            validation,
            valuesToInput,
        }
    )

export default useUpdateSettingsAuditAlertsFormState
