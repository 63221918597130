import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query updateOrganizationVendor($id: ID!) {
        vendor(id: $id) {
            id
            name
            description
            types
            domain
            websiteUrl
            belongsToOrganization
        }
    }
`

const useUpdateOrganizationVendorModalQuery = (id, options) => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        variables: { id },
        ...options,
    })
    return {
        error,
        vendor: data.vendor,
        isFetching,
        refetch,
    }
}

export default useUpdateOrganizationVendorModalQuery
