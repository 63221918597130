import React, { useState } from 'react'
import VendorTableItemTableRowActionButton from './VendorTableItemTableRowActionButton'
import DeleteVendorTableItemModalWithState from './DeleteVendorTableItemModalWithState'

const VendorTableItemTableRowActionButtonWithState = ({
    vendorTableItem,
    onUpdateVendorTableItemsCompleted,
}) => {
    const [
        isRemoveVendorTableItemModalOpen,
        setRemoveVendorTableItemModalOpen,
    ] = useState(false)
    return (
        <>
            {isRemoveVendorTableItemModalOpen && (
                <DeleteVendorTableItemModalWithState
                    vendorTableItem={vendorTableItem}
                    onDismiss={() => setRemoveVendorTableItemModalOpen(false)}
                    onCompleted={onUpdateVendorTableItemsCompleted}
                />
            )}
            <VendorTableItemTableRowActionButton
                onDeleteVendorTableItem={() =>
                    setRemoveVendorTableItemModalOpen(true)
                }
            />
        </>
    )
}

export default VendorTableItemTableRowActionButtonWithState
