import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import CookieTableItemPageTableRow from './CookieTableItemPageTableRow'
import useFetchMore from '../../hooks/useFetchMore'

const QUERY = gql`
    query cookieTableItemPageQuery(
        $cookieTableId: ID!
        $cookieTableVersionId: ID
        $orderBy: CookieTableItemOrderByInput
        $search: String
        $offset: Int
    ) {
        cookieTableItems(
            cookieTableId: $cookieTableId
            cookieTableVersionId: $cookieTableVersionId
            orderBy: $orderBy
            search: $search
            offset: $offset
        ) {
            edges {
                node {
                    ...CookieTableItemPageTableRow
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${CookieTableItemPageTableRow.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useCookieTableItemPageQuery = ({
    cookieTableId,
    cookieTableVersionId,
    search,
    orderBy,
}) => {
    const variables = { search, cookieTableVersionId, cookieTableId, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'cookieTableItems'
    )
    return {
        cookieTableItems: connectionToCollection(data.cookieTableItems) || [],
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && data.cookieTableItems?.pageInfo.hasNextPage,
    }
}

export default useCookieTableItemPageQuery
