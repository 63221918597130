import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import StaffHeader from '../../layout/StaffHeader'
import PageHeader from '../../layout/PageHeader'
import VendorsPageSidebar from '../staff-vendors/VendorsPageSidebar'
import TrackersPageTableActions from './TrackersPageTableActions'
import TrackersPageTable from './TrackersPageTable'
import Button from '../../utils/Button'
import TableStateProvider from '../../providers/TableStateProvider'

const TrackersPage = ({
    search,
    trackers,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    activeFilters,
    initialOrderBy,
    onChangeOrderBy,
    isFetching,
    onDeleteTrackerCompleted,
    onUpdateTrackerCompleted,
    onBulkUpload,
    onCreateTracker,
}) => {
    return (
        <PageContainer data-testid="TrackersPage">
            <StaffHeader />
            <SidebarContainer>
                <VendorsPageSidebar />
                <PageContent size="full">
                    <PageHeader
                        title={<Trans>Trackers</Trans>}
                        description={
                            <Trans>
                                Below you can find the global tracker directory
                                of Webclew.
                            </Trans>
                        }
                        rightChildren={
                            <div className="flex gap-2">
                                <Button
                                    onClick={onBulkUpload}
                                    className="h-[40px]"
                                    type="white"
                                    iconBeforeProps={{
                                        name: 'upload-cloud-02',
                                        size: 'sm',
                                    }}
                                >
                                    <Trans>Bulk Upload</Trans>
                                </Button>
                                <Button
                                    onClick={onCreateTracker}
                                    className="h-fit"
                                    type="primary"
                                    iconBeforeProps={{
                                        name: 'plus',
                                        size: 'sm',
                                    }}
                                >
                                    <Trans>Create Tracker</Trans>
                                </Button>
                            </div>
                        }
                    />
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <TrackersPageTableActions
                            search={search}
                            onSearch={onSearch}
                            activeFilters={activeFilters}
                            onAddFilter={onAddFilter}
                            onRemoveFilter={onRemoveFilter}
                            onRemoveAllFilters={onRemoveAllFilters}
                            onChangeOrderBy={onChangeOrderBy}
                        />
                        <TrackersPageTable
                            trackers={trackers}
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            isFetching={isFetching}
                            onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                            onUpdateTrackerCompleted={onUpdateTrackerCompleted}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}

export default TrackersPage
