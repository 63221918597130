import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import useIgnoreAlertMutation from '../../utils/useIgnoreAlertMutation'
import useRestoreAlertMutation from '../../utils/useRestoreAlertMutation'
import useNotifications from '../../hooks/useNotifications'
import useDelegateAlertInternalMutation from './useAlertDelegateInternalMutation'
import useClearAlertDelegationMutation from './useClearAlertDelegationMutation'
import * as constants from '../../../constants'
import useAlertDelegateExternalMutation from './useAlertDelegateExternalMutation'

const { ALERT_STATUS_LEVEL_AUDIT: AUDIT } = constants
const useSelectAlertsState = ({
    auditId,
    level,
    alerts,
    onAlertStatusChanged,
}) => {
    const {
        isSelectingRows,
        startSelectingRows,
        stopSelectingRows,
        selectedRows,
        clearSelectedRows,
    } = useTableState()

    const { dispatchSuccess, dispatchError } = useNotifications()

    const variables = {
        groupingIds: selectedRows.map(({ id }) => id),
        level,
    }
    if (level === AUDIT) {
        variables.auditId = auditId
    }
    const onCompleted = () => {
        onAlertStatusChanged()
    }
    const [ignoreAlerts] = useIgnoreAlertMutation({ onCompleted })
    const [restoreAlerts] = useRestoreAlertMutation({ onCompleted })
    const [delegateAlert] = useDelegateAlertInternalMutation({ onCompleted })
    const [clearDelegation] = useClearAlertDelegationMutation({ onCompleted })
    const [delegateExternal] = useAlertDelegateExternalMutation({ onCompleted })
    return {
        selectedAlerts: selectedRows,
        isSelectingRows,
        onIgnoreAlerts: async () => {
            try {
                await ignoreAlerts({ variables })
                const message = <Trans>Alert(s) ignored</Trans>
                dispatchSuccess({ message })
                stopSelectingRows()
            } catch (error) {
                const { message } = error.graphQLErrors[0] || {}
                dispatchError({ message })
            }
        },
        onDelegateAlertsInternal: async (user) => {
            try {
                await delegateAlert({
                    variables: {
                        ...variables,
                        input: {
                            userId: user.id,
                        },
                    },
                })
                const message = <Trans>Alert(s) delegated</Trans>
                dispatchSuccess({ message })
                stopSelectingRows()
                clearSelectedRows()
            } catch (error) {
                const { message } = error.graphQLErrors[0] || {}
                dispatchError({ message })
            }
        },
        onClearAlertsDelegate: async () => {
            try {
                await clearDelegation({ variables })
                const message = <Trans>Cleared delegate for alert(s)</Trans>
                dispatchSuccess({ message })
            } catch (error) {
                const { message } = error.graphQLErrors[0] || {}
                dispatchError({ message })
            }
            clearSelectedRows()
            stopSelectingRows()
        },
        onDelegateAlertsExternal: async (input) => {
            try {
                await delegateExternal({
                    variables: {
                        ...variables,
                        input,
                    },
                })
                const message = <Trans>Alert(s) delegated</Trans>
                dispatchSuccess({ message })
                stopSelectingRows()
            } catch (error) {
                const { message } = error.graphQLErrors[0] || {}
                dispatchError({ message })
            }
            clearSelectedRows()
            stopSelectingRows()
        },
        onRestoreAlerts: async () => {
            try {
                await restoreAlerts({ variables })
                const message = <Trans>Alert status cleared</Trans>
                dispatchSuccess({ message })
                stopSelectingRows()
            } catch (error) {
                const { message } = error.graphQLErrors[0] || {}
                dispatchError({ message })
            }
        },
    }
}

export default useSelectAlertsState
