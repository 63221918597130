import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useAppState from '../../hooks/useAppState'
import CreateOrganizationVendorModal from './CreateOrganizationVendorModal'
import useCreateOrganizationVendorFormState from './useCreateOrganizationVendorFormState'
import useCreateOrganizationVendorMutation from './useCreateOrganizationVendorMutation'

const CreateOrganizationVendorModalWithState = ({
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const [createVendor] = useCreateOrganizationVendorMutation({
        onCompleted,
        onError,
    })
    const formState = useCreateOrganizationVendorFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateOrganizationVendorModal
            currentUser={currentUser}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await createVendor({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Vendor created successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateOrganizationVendorModalWithState
