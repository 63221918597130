import React from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCountTag from '../../utils/AlertCountTag'
import TableCellAvatar from '../../utils/TableCellAvatar'
import PurposesTags from '../../utils/PurposesTags'
import IdentifiedTrackersDrawer from '../vendors-trackers/IdentifiedTrackersDetailsDrawer'
import { APPLICATION_LEVEL_AUDIT } from '../../../constants'
import useIdentifiedVendorDrawerTrackersQuery from './useIdentifiedVendorDrawerTabTrackers'
import DrawerTabError from '../../utils/DrawerTabError'
import InfiniteScroll from '../../utils/InfiniteScroll'

export default function IdentifiedVendorDrawerTabTrackers({
    auditId,
    vendorId,
    period,
}) {
    const { period: periodString, startDate, endDate } = period
    const { add } = useDrawerStack()
    const {
        identifiedTrackers,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
    } = useIdentifiedVendorDrawerTrackersQuery({
        filters: {
            period: periodString,
            auditId,
            vendorId,
            startDate,
            endDate,
        },
    })
    if (error) return <DrawerTabError error={error} />
    if (!isFetching && identifiedTrackers.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="tracker"
                title={<Trans>No trackers</Trans>}
                description={
                    <Trans>There were no identified trackers found</Trans>
                }
            />
        )
    }
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
        >
            <Table
                isFetching={isFetching}
                hasMinWidth={false}
                isRounded
                hasHeader={false}
            >
                <TableBody>
                    {identifiedTrackers.map((identifiedTracker) => {
                        const { id, rootDomain, alerts, tracker } =
                            identifiedTracker
                        return (
                            <TableRow
                                isSelectable
                                key={id}
                                onClick={() => {
                                    add(IdentifiedTrackersDrawer, {
                                        auditId,
                                        startDate,
                                        endDate,
                                        level: APPLICATION_LEVEL_AUDIT,
                                        identifiedTrackerId:
                                            identifiedTracker.id,
                                    })
                                }}
                            >
                                <TableCellAvatar
                                    py="sm"
                                    px="sm"
                                    truncate
                                    avatarIconName="tracker"
                                    className="w-2/3"
                                    title={rootDomain}
                                />
                                <TableCell py="sm">
                                    <div className="flex justify-end gap-1">
                                        <PurposesTags
                                            showAll
                                            purposes={tracker?.purposes || []}
                                        />
                                        <AlertCountTag alerts={alerts} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
