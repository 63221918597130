import React from 'react'
import LoadingIndicator from './LoadingIndicator'

const ModalLoading = () => (
    <div className="flex h-[200px] items-center justify-center">
        <LoadingIndicator />
    </div>
)

export default ModalLoading
