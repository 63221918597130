import React from 'react'
import clsx from 'clsx'
import {
    COUNTRY_BE as BE,
    COUNTRY_IE as IE,
    COUNTRY_US as US,
} from '../../constants'

const Belgium = () => (
    <>
        <path d="M16 3H8v18h8V3z" fill="#FFDE00" />
        <path d="M24 20a1 1 0 01-1 1h-7V3h7a1 1 0 011 1v16z" fill="#E00" />
        <path d="M8 21H1a1 1 0 01-1-1V4a1 1 0 011-1h7v18z" fill="#000" />
    </>
)

const Ireland = () => (
    <>
        <path d="M16 3H8v18h8V3z" fill="#E6E6E6" />
        <path d="M24 20a1 1 0 01-1 1h-7V3h7a1 1 0 011 1v16z" fill="#FF833D" />
        <path d="M8 21H1a1 1 0 01-1-1V4a1 1 0 011-1h7v18z" fill="#009A64" />
    </>
)

const USA = () => (
    <>
        <path
            d="M24 4a1 1 0 00-1-1H10v10H0v7a1 1 0 001 1h22a1 1 0 001-1V4z"
            fill="#B41F30"
        />
        <path
            d="M24 5H10v2h14V5zM24 9H10v2h14V9zM0 13v2h24v-2H0zM24 17H0v2h24v-2z"
            fill="#E6E6E6"
        />
        <path d="M10 13H0V4a1 1 0 011-1h9v10z" fill="#00256A" />
        <path
            d="M5 6.961l-.316.684h-.752l.55.57-.203.824L5 8.63l.722.409-.203-.824.55-.57h-.753L5 6.961zM2.553 4.514l-.316.684h-.752l.55.57-.203.825.721-.41.722.41-.203-.824.55-.57h-.753l-.316-.684zM7.447 4.514l.316.684h.752l-.55.57.203.825-.721-.41-.722.41.203-.824-.55-.57h.753l.316-.684zM2.553 11.486l-.316-.684h-.752l.55-.57-.203-.824.721.409.722-.41-.203.825.55.57h-.753l-.316.684zM7.447 11.486l.316-.684h.752l-.55-.57.203-.824-.721.409-.722-.41.203.825-.55.57h.753l.316.684z"
            fill="#fff"
        />
    </>
)

const Flag = ({ countryCode, size = 'md', className, ...props }) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        className={clsx(
            size === 'sm' && 'h-5 w-5', // 20px
            size === 'md' && 'h-6 w-6', // 24px
            size === 'lg' && 'h-8 w-8', // 48px
            size === 'xl' && 'h-12 w-12', // 48px
            size === '2xl' && 'h-16 w-16', // 64px
            className
        )}
        {...props}
    >
        {(() => {
            switch (countryCode) {
                case BE:
                    return <Belgium />
                case IE:
                    return <Ireland />
                case US:
                    return <USA />
                default:
                    return null
            }
        })()}
    </svg>
)

export default Flag
