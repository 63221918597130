import React from 'react'

const LogoWithName = (props) => (
    <svg
        viewBox="0 0 761 162"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M68.1478 0.0767389C67.4587 0.191285 66.7971 0.458943 66.216 0.844832C58.898 5.70389 51.3738 10.3594 43.6466 14.7993C35.9194 19.2393 28.106 23.3964 20.2187 27.274C19.5923 27.5819 19.0286 28.0183 18.5844 28.555C15.4925 32.2901 19.4387 36.7209 23.7987 34.5779C31.8553 30.6179 39.8363 26.3719 47.7292 21.8368C55.6222 17.3016 63.3075 12.546 70.7818 7.58225C74.8266 4.89606 72.9441 -0.720529 68.1478 0.0767389Z"
            fill="#5200FF"
        />
        <path
            d="M99.3422 0.923846C98.0907 0.646493 96.786 0.958493 95.7658 1.73133C82.1855 12.0183 67.7982 21.5654 52.6284 30.2817C37.4586 38.998 21.957 46.6244 6.21524 53.1854C5.03259 53.6783 4.10875 54.6468 3.72434 55.8641C2.66182 59.2289 6.02344 62.0756 9.29261 60.7137C25.3756 54.0139 41.2131 46.224 56.7111 37.3192C72.2091 28.4143 86.9065 18.6594 100.778 8.1478C103.598 6.01112 102.802 1.69047 99.3422 0.923846Z"
            fill="#5200FF"
        />
        <path
            d="M119.911 8.46549C118.406 7.67218 116.581 7.90191 115.261 8.97247C98.657 22.4378 80.757 34.7628 61.6103 45.7641C42.4636 56.7654 22.7884 66.0305 2.76876 73.6085C1.17711 74.211 0.0649246 75.6687 0.00285434 77.3623C-0.104585 80.2938 2.84021 82.2763 5.59544 81.2339C26.0427 73.4981 46.138 64.0374 65.6929 52.8015C85.2478 41.5656 103.528 28.9764 120.483 15.2214C122.768 13.3679 122.515 9.83864 119.911 8.46549Z"
            fill="#5200FF"
        />
        <path
            d="M135.786 19.4148C134.226 18.0387 131.901 18.0931 130.311 19.4348C112.023 34.8683 92.0938 48.892 70.5921 61.2465C49.0904 73.6009 26.9222 83.7658 4.34815 91.8106C2.38571 92.51 1.17576 94.4868 1.59333 96.5193C2.09455 98.9589 4.66899 100.329 7.02477 99.4897C30.0782 91.2786 52.7171 80.9003 74.6747 68.2839C96.6324 55.6675 116.983 41.3448 135.655 25.5812C137.563 23.9704 137.658 21.0666 135.786 19.4148Z"
            fill="#5200FF"
        />
        <path
            d="M148.178 33.1724C146.757 31.1769 143.931 30.9518 142.071 32.5506C123.029 48.9194 102.17 63.7458 79.5739 76.7289C56.9783 89.7119 33.6466 100.277 9.88143 108.504C7.56013 109.308 6.3432 111.856 7.36897 114.079C8.25482 115.998 10.4807 116.902 12.4854 116.208C36.7552 107.811 60.5821 97.0245 83.6566 83.7663C106.731 70.5081 128.031 55.3656 147.473 38.6469C149.079 37.2658 149.406 34.8956 148.178 33.1724Z"
            fill="#5200FF"
        />
        <path
            d="M157.312 49.7536C156.241 47.1313 152.891 46.4564 150.737 48.3043C131.78 64.5656 111.026 79.3 88.5558 92.2112C66.085 105.122 42.8864 115.642 19.2573 123.85C16.5721 124.783 15.4844 128.008 17.2311 130.241C18.3227 131.637 20.1824 132.137 21.8608 131.554C45.9946 123.177 69.6886 112.435 92.6384 99.2487C115.588 86.0622 136.782 71.0116 156.14 54.4002C157.486 53.245 157.981 51.3925 157.312 49.7536Z"
            fill="#5200FF"
        />
        <path
            d="M162.581 69.5659C162.15 66.3198 158.285 65.0282 155.766 67.1338C137.887 82.0744 118.457 95.674 97.5376 107.694C76.6187 119.713 55.0689 129.66 33.1283 137.599C30.0363 138.718 29.2278 142.695 31.837 144.689C32.9733 145.558 34.4713 145.761 35.8179 145.274C58.2335 137.169 80.2495 127.01 101.62 114.731C122.991 102.452 142.839 88.5563 161.1 73.2892C162.197 72.372 162.769 70.9795 162.581 69.5659Z"
            fill="#5200FF"
        />
        <path
            d="M162.112 93.8883C162.764 90.0912 158.392 87.8417 155.355 90.2291C140.119 102.203 123.829 113.23 106.519 123.176C89.2101 133.122 71.4688 141.648 53.4316 148.792C49.8352 150.217 49.607 155.109 53.2371 156.446C54.2347 156.813 55.3307 156.787 56.3191 156.396C74.7629 149.098 92.9038 140.383 110.602 130.213C128.3 120.044 144.955 108.767 160.527 96.5196C161.362 95.8633 161.933 94.9317 162.112 93.8883Z"
            fill="#5200FF"
        />
        <path
            d="M148.188 127.334C151.163 123.163 146.329 119.113 142.088 122.002C133.514 127.843 124.65 133.402 115.501 138.658C106.353 143.915 97.0835 148.775 87.7137 153.244C83.0787 155.455 84.1861 161.646 89.3034 161.168C89.8742 161.114 90.4357 160.957 90.9529 160.711C100.608 156.114 110.159 151.111 119.584 145.696C129.009 140.28 138.138 134.55 146.965 128.527C147.438 128.204 147.856 127.799 148.188 127.334Z"
            fill="#5200FF"
        />
        <path
            d="M229.693 129.67H248.257L265.274 65.886L282.291 129.67H300.855L324.655 41.015H307.281L290.859 107.655L273.723 41.015H256.944L239.689 107.655L223.267 41.015H205.893L229.693 129.67Z"
            fill="#111827"
        />
        <path
            d="M359.918 131.098C366.503 131.098 372.254 129.63 377.173 126.695C382.171 123.68 385.662 119.872 387.645 115.271L375.031 109.083C373.603 111.542 371.62 113.565 369.081 115.152C366.622 116.659 363.607 117.413 360.037 117.413C355.356 117.413 351.429 115.985 348.256 113.129C345.083 110.273 343.258 106.465 342.782 101.705H389.43C389.747 100.832 389.946 99.8407 390.025 98.73C390.184 97.54 390.263 96.35 390.263 95.16C390.263 89.3687 389.033 84.093 386.574 79.333C384.115 74.573 380.545 70.765 375.864 67.909C371.183 64.9737 365.471 63.506 358.728 63.506C352.699 63.506 347.225 64.9737 342.306 67.909C337.467 70.8443 333.619 74.8507 330.763 79.928C327.986 84.926 326.598 90.6777 326.598 97.183C326.598 103.45 327.986 109.162 330.763 114.319C333.54 119.476 337.427 123.561 342.425 126.576C347.423 129.591 353.254 131.098 359.918 131.098ZM358.728 76.358C362.853 76.358 366.265 77.6273 368.962 80.166C371.739 82.6253 373.286 85.8383 373.603 89.805H343.139C344.012 85.521 345.836 82.2287 348.613 79.928C351.469 77.548 354.841 76.358 358.728 76.358Z"
            fill="#111827"
        />
        <path
            d="M437.423 131.098C443.691 131.098 449.284 129.63 454.202 126.695C459.121 123.68 463.008 119.634 465.864 114.557C468.72 109.4 470.148 103.649 470.148 97.302C470.148 90.9553 468.72 85.2433 465.864 80.166C463.008 75.0093 459.121 70.9633 454.202 68.028C449.284 65.0133 443.73 63.506 437.542 63.506C429.133 63.506 422.509 66.1637 417.669 71.479V39.587H402.08V129.67H416.717V122.411C419.097 125.188 422.072 127.33 425.642 128.837C429.292 130.344 433.219 131.098 437.423 131.098ZM435.638 116.818C432.227 116.818 429.133 115.985 426.356 114.319C423.659 112.653 421.517 110.352 419.93 107.417C418.423 104.482 417.669 101.11 417.669 97.302C417.669 93.494 418.423 90.1223 419.93 87.187C421.517 84.2517 423.659 81.951 426.356 80.285C429.133 78.619 432.227 77.786 435.638 77.786C439.208 77.786 442.342 78.6587 445.039 80.404C447.816 82.07 449.998 84.3707 451.584 87.306C453.171 90.2413 453.964 93.5733 453.964 97.302C453.964 101.031 453.171 104.402 451.584 107.417C449.998 110.352 447.816 112.653 445.039 114.319C442.342 115.985 439.208 116.818 435.638 116.818Z"
            fill="#111827"
        />
        <path
            d="M513.211 131.098C517.892 131.098 522.216 130.305 526.182 128.718C530.149 127.052 533.561 124.791 536.417 121.935C539.272 119 541.335 115.707 542.604 112.058L528.919 105.87C527.65 109.281 525.627 111.979 522.85 113.962C520.153 115.866 516.94 116.818 513.211 116.818C509.8 116.818 506.746 115.985 504.048 114.319C501.43 112.653 499.368 110.352 497.861 107.417C496.353 104.402 495.599 101.031 495.599 97.302C495.599 93.494 496.353 90.1223 497.861 87.187C499.368 84.2517 501.43 81.951 504.048 80.285C506.746 78.619 509.8 77.786 513.211 77.786C516.94 77.786 520.153 78.7777 522.85 80.761C525.627 82.7443 527.65 85.3623 528.919 88.615L542.604 82.665C541.256 78.857 539.153 75.525 536.297 72.669C533.441 69.7337 530.03 67.4727 526.064 65.886C522.097 64.2993 517.813 63.506 513.211 63.506C506.785 63.506 500.994 64.9737 495.837 67.909C490.76 70.8443 486.754 74.8507 483.818 79.928C480.883 85.0053 479.415 90.757 479.415 97.183C479.415 103.609 480.883 109.4 483.818 114.557C486.754 119.634 490.76 123.68 495.837 126.695C500.994 129.63 506.785 131.098 513.211 131.098Z"
            fill="#111827"
        />
        <path
            d="M554.433 129.67H570.022V39.587H554.433V129.67Z"
            fill="#111827"
        />
        <path
            d="M615.117 131.098C621.702 131.098 627.454 129.63 632.372 126.695C637.37 123.68 640.861 119.872 642.844 115.271L630.23 109.083C628.802 111.542 626.819 113.565 624.28 115.152C621.821 116.659 618.806 117.413 615.236 117.413C610.556 117.413 606.629 115.985 603.455 113.129C600.282 110.273 598.457 106.465 597.981 101.705H644.629C644.947 100.832 645.145 99.8407 645.224 98.73C645.383 97.54 645.462 96.35 645.462 95.16C645.462 89.3687 644.233 84.093 641.773 79.333C639.314 74.573 635.744 70.765 631.063 67.909C626.383 64.9737 620.671 63.506 613.927 63.506C607.898 63.506 602.424 64.9737 597.505 67.909C592.666 70.8443 588.818 74.8507 585.962 79.928C583.186 84.926 581.797 90.6777 581.797 97.183C581.797 103.45 583.186 109.162 585.962 114.319C588.739 119.476 592.626 123.561 597.624 126.576C602.622 129.591 608.453 131.098 615.117 131.098ZM613.927 76.358C618.053 76.358 621.464 77.6273 624.161 80.166C626.938 82.6253 628.485 85.8383 628.802 89.805H598.338C599.211 85.521 601.036 82.2287 603.812 79.928C606.668 77.548 610.04 76.358 613.927 76.358Z"
            fill="#111827"
        />
        <path
            d="M673.583 129.67H687.387L702.619 85.997L717.613 129.67H731.536L753.789 64.934H737.367L723.801 105.632L709.521 64.934H695.717L681.318 105.751L667.752 64.934H651.33L673.583 129.67Z"
            fill="#111827"
        />
    </svg>
)

export default LogoWithName
