import React from 'react'
import { Route } from 'react-router-dom'
import HasPermission from './HasPermission'
// import AppNotFound from '../AppNotFound'

const HasPermissionRoute = ({ type, role, children, ...props }) => (
    <Route {...props}>
        <HasPermission type={type} role={role} placeholder={<div />}>
            {children}
        </HasPermission>
    </Route>
)

export default HasPermissionRoute
