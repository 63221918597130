/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import { twMerge } from 'tailwind-merge'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import InfoIcon from '../../utils/InfoIcon'
import RadioButton from '../../utils/RadioButton'
import BulkUploadFormUploadCard from './BulkUploadFormUploadCard'
import BulkUploadFormPasteCard from './BulkUploadFormPasteCard'
import BulkUploadAlertCard from './BulkUploadAlertCard'

const BulkUploadModal = ({
    onDismiss,
    onSubmit,
    onReset,
    formState,
    isValidated,
    alertRecords,
}) => {
    const { values, handleChange, getErrorMessages } = formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-3xl">
            <div className="flex gap-3 items-start">
                <InfoIcon size="md" iconName="upload-cloud-02" />
                <ModalTitle
                    title={<Trans>Bulk Upload</Trans>}
                    description={
                        <Trans>
                            Use this action carefully this can cause a lot of
                            problems
                        </Trans>
                    }
                    onClose={onDismiss}
                />
            </div>

            <div className="flex flex-col gap-4">
                <FormGroup>
                    <RadioButton.Group>
                        <RadioButton
                            className={twMerge(
                                'items-start rounded-xl',
                                formState.getValue('action') === 'CREATE' &&
                                'bg-white border-primary-600 border-2'
                            )}
                            value="CREATE"
                            checked={formState.getValue('action') === 'CREATE'}
                            onChange={() => {
                                formState.handleChange('action', 'CREATE')
                            }}
                        >
                            <div className="flex flex-col ml-2 ">
                                <h1 className="text-sm font-medium text-gray-700 text-start">
                                    <Trans>Bulk Create</Trans>
                                </h1>
                                <p className="text-sm font-regular text-gray-600 text-start">
                                    <Trans>
                                        Upload a csv file to create multiple
                                        records
                                    </Trans>
                                </p>
                            </div>
                        </RadioButton>
                        <RadioButton
                            className={twMerge(
                                'items-start rounded-xl',
                                formState.getValue('action') === 'UPDATE' &&
                                'bg-white border-primary-600 border-2'
                            )}
                            value="UPDATE"
                            checked={formState.getValue('action') === 'UPDATE'}
                            onChange={() => {
                                formState.handleChange('action', 'UPDATE')
                            }}
                        >
                            <div className="flex flex-col ml-2 text-gray-700">
                                <h1 className="text-sm font-medium text-start">
                                    <Trans>Bulk Update</Trans>
                                </h1>
                                <p className="text-sm font-regular text-gray-600 text-start">
                                    <Trans>
                                        Upload a csv file to update multiple
                                        records
                                    </Trans>
                                </p>
                            </div>
                        </RadioButton>
                    </RadioButton.Group>
                </FormGroup>
                <FormGroup htmlFor="csvFile" className="mb-4">
                    <BulkUploadFormUploadCard
                        inputClassName="hidden"
                        id="csvFile"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        errors={getErrorMessages('csvFile')}
                        onChange={(file) => handleChange('csvFile', file)}
                        onReset={onReset}
                    />
                </FormGroup>
                <FormGroup htmlFor="csvFile" className="mb-4">
                    <BulkUploadFormPasteCard
                        id="csvPasteFile"
                        inputClassName="w-full"
                        errors={getErrorMessages('csvPasteFile')}
                        onChange={(file) => handleChange('csvPasteFile', file)}
                        onReset={onReset}
                        value={values.csvPasteFile}
                    />
                </FormGroup>
                {alertRecords && (
                    <BulkUploadAlertCard alertRecords={alertRecords} />
                )}
                <div className="pt-4 flex sm:flex-row-reverse gap-4">
                    <Button
                        disabled={
                            alertRecords && alertRecords.errors.length > 0
                        }
                        className="w-full"
                        onClick={() => {
                            onSubmit()
                        }}
                    >
                        {isValidated ? (
                            <Trans>Upload file</Trans>
                        ) : (
                            <Trans>Check file</Trans>
                        )}
                    </Button>

                    <Button type="white" className="w-full" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default BulkUploadModal
