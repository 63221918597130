import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteUserModal = ({ user, isLoading, onDismiss, onConfirm }) => (
    <ConfirmModal
        title={<Trans>Remove user</Trans>}
        description={
            <Trans>
                Are you sure you want to remove <strong>{user.fullName}</strong>{' '}
                from you organization?
            </Trans>
        }
        confirmButtonText={<Trans>Remove User</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteUserModal
