import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteDomainModal from './DeleteDomainModal'
import useDeleteDomainMutation from './useDeleteDomainMutation'

const DeleteDomainModalWithState = ({
    domain,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteDomain, { loading }] = useDeleteDomainMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteDomainModal
            onDismiss={onDismiss}
            domain={domain}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await deleteDomain({ variables: { id: domain.id } })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{domain.name} successfully deleted.</Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteDomainModalWithState
