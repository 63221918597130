import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const STAFF_CREATE_USER_MUTATION = gql`
    mutation staffCreateUser($input: StaffCreateUserInput!) {
        staffCreateUser(input: $input) {
            user {
                id
                email
            }
        }
    }
`

const useStaffCreateUserMutation = () => useMutation(STAFF_CREATE_USER_MUTATION)

export default useStaffCreateUserMutation
