import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import useRefetch from '../../hooks/useRefetch'
import CookieTablesPage from './CookieTablesPage'
import useCookieTablesPageQuery from './useCookieTablesPageQuery'
import CreateCookieTableModalWithState from './CreateCookieTableModalWithState'

const PAGE_PATH = '/vendors/cookie-tables'
const CREATE_PATH = '/vendors/cookie-tables/create'

const CookieTablesPageWithState = () => {
    const history = useHistory()
    const createModalIsOpen = useRouteMatch({
        path: CREATE_PATH,
    })
    const {
        cookieTables,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch,
    } = useCookieTablesPageQuery()
    useRefetch(refetch)
    return (
        <>
            {createModalIsOpen && (
                <CreateCookieTableModalWithState
                    onDismiss={() => history.push(PAGE_PATH)}
                    onCompleted={() => {
                        refetch()
                        history.push(PAGE_PATH)
                    }}
                />
            )}
            <CookieTablesPage
                cookieTables={cookieTables}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onCreate={() => history.push(CREATE_PATH)}
                onCompleted={() => {
                    refetch()
                    history.push(PAGE_PATH)
                }}
                onDeleteCompleted={() => {
                    refetch()
                    history.push(PAGE_PATH)
                }}
            />
        </>
    )
}

export default CookieTablesPageWithState
