import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createAlertComment(
        $groupingId: ID!
        $auditId: ID
        $input: CreateAlertCommentInput!
    ) {
        createAlertComment(
            groupingId: $groupingId
            auditId: $auditId
            input: $input
        ) {
            alertComment {
                text
            }
        }
    }
`

const useCreateAlertCommentMutation = (options) =>
    useMutation(MUTATION, options)

export default useCreateAlertCommentMutation
