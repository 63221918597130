import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import CURRENT_USER_FRAGMENT from '../../apollo/fragments/currentUser'

export const INITIAL_DATA_QUERY = gql`
    query initialDataQuery {
        me {
            ...CurrentUser
        }
    }
    ${CURRENT_USER_FRAGMENT}
`

export const fetchInitialData = async (apollo, options) => {
    const query = INITIAL_DATA_QUERY
    const response = await apollo.query({ query, ...options })
    return response.data
}

export const useInitialDataQuery = () => useQuery(INITIAL_DATA_QUERY)

export default useInitialDataQuery
