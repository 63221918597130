import React from 'react'
import useAppState from '../hooks/useAppState'
import OrganizationDropdown from './OrganizationHeaderSwitcherDropdown'
import useNotifications from '../hooks/useNotifications'
import useMyOrganizationsQuery from '../utils/useMyOrganizationsQuery'
import useSwitchOrganizationMutation from '../utils/useSwitchOrganizationMutation'
import useSearch from '../hooks/useSearch'

export default function OrganizationDropdownWithState() {
    const appState = useAppState()
    const [search, onSearch] = useSearch()
    const { currentUser } = appState
    const { dispatchError } = useNotifications()
    const { organizations, isFetching } = useMyOrganizationsQuery({ search })
    const [switchOrganization] = useSwitchOrganizationMutation()
    const { currentOrganization = {} } = currentUser
    return (
        <OrganizationDropdown
            onSearch={onSearch}
            search={search}
            isFetching={isFetching}
            currentOrganization={currentOrganization}
            organizations={organizations}
            onSelectOrganization={async (organizationId) => {
                try {
                    await switchOrganization({
                        variables: { organizationId },
                    })
                    await appState.reset('/audits')
                } catch (error) {
                    const { message } = error.graphQLErrors[0]
                    dispatchError({ message })
                }
            }}
        />
    )
}
