import React from 'react'
import clsx from 'clsx'

const SectionHeader = ({
    title,
    description,
    className,
    titleClassName,
    descriptionClassName,
    buttons,
    ...props
}) => (
    <div
        className={clsx('flex flex-row justify-between mb-8', className)}
        {...props}
    >
        <div>
            <h3
                className={clsx(
                    'text-lg leading-6 font-medium text-gray-900',
                    titleClassName
                )}
            >
                {title}
            </h3>
            {description && (
                <p
                    className={clsx(
                        'mt-1 text-sm leading-5 text-gray-500',
                        descriptionClassName
                    )}
                >
                    {description}
                </p>
            )}
        </div>
        <div className="flex-shrink-0">{buttons}</div>
    </div>
)

export default SectionHeader
