import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'email',
        validate: isRequired,
        message: <Trans>The email is required</Trans>,
    },
    {
        path: 'role',
        validate: isRequired,
        message: <Trans>Please select a role.</Trans>,
    },
])

const useInviteUserFormState = () =>
    useFormState(
        {
            email: '',
            role: '',
            sendInvitationEmail: true,
        },
        {
            validation,
        }
    )

export default useInviteUserFormState
