import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import InfiniteScroll from '../../utils/InfiniteScroll'
import StaffOrganizationUsersPageTableRow from './StaffOrganizationUsersPageTableRow'

const StaffOrganizationUsersPageTable = ({
    users = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteUserSuccess,
    isFetching,
}) => {
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <Table
                data-testid="StaffOrganizationUsersPageTable"
                isFetching={isFetching}
            >
                <thead>
                    <tr>
                        <TableHeaderCell>
                            <Trans>User</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>Type</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Trans>Organizations</Trans>
                        </TableHeaderCell>
                        <TableHeaderCell />
                        <TableHeaderCell />
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((user) => (
                        <StaffOrganizationUsersPageTableRow
                            key={user.id}
                            user={user}
                            onDeleteUserSuccess={onDeleteUserSuccess}
                        />
                    ))}
                </tbody>
            </Table>
        </InfiniteScroll>
    )
}

export default StaffOrganizationUsersPageTable
