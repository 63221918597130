import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import AvatarGroup from '../../utils/AvatarGroup'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import StaffUnclassifiedCookiesPageTableRowActionButtonWithState from './StaffUnclassifiedCookiesPageTableRowActionButtonWithState'
import useDrawerStack from '../../hooks/useDrawerStack'

const FRAGMENT = gql`
    fragment StaffUnclassifiedCookiesPageTableRow on StaffUnclassifiedCookie {
        id
        name
        rootDomain
        totalCount
        organizations {
            id
            imageUrl
            name
        }
        audits {
            id
            imageUrl
            name
        }
    }
`

const StaffUnclassifiedCookiesPageTableRow = ({
    unclassifiedCookie,
    onClassifyCookieCompleted,
}) => {
    const { open } = useDrawerStack()
    const { name, rootDomain, totalCount, organizations, audits } =
        unclassifiedCookie
    return (
        <TableRow
            isSelectable
            selectionValue={unclassifiedCookie}
            selectionCriteria={(row) => row.name === unclassifiedCookie.name}
            data-testid="unclassifiedCookiePageTableRow"
        >
            <TableCell hasText className="max-w-xs truncate" columnId="name">
                <div className="mx-4">{name}</div>
            </TableCell>
            <TableCell columnId="domain">
                <div className="mx-4 text-slate-400">{rootDomain}</div>
            </TableCell>
            <TableCell
                hasText
                className="max-w-xs truncate"
                columnId="occurence"
            >
                <Tag>{totalCount}</Tag>
            </TableCell>
            <TableCell className="" columnId="organizations">
                {organizations.length > 0 && (
                    <AvatarGroup
                        imageUrls={organizations.map(
                            ({ imageUrl }) => imageUrl
                        )}
                    />
                )}
            </TableCell>
            <TableCell className="" columnId="audtis">
                {audits.length > 0 && (
                    <AvatarGroup
                        imageUrls={audits.map(({ imageUrl }) => imageUrl)}
                    />
                )}
            </TableCell>
            <TableCell className="text-right" size="sm">
                <StaffUnclassifiedCookiesPageTableRowActionButtonWithState
                    scanCookie={unclassifiedCookie}
                    onClassifyCookieCompleted={onClassifyCookieCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

StaffUnclassifiedCookiesPageTableRow.FRAGMENT = FRAGMENT

export default StaffUnclassifiedCookiesPageTableRow
