import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateCustomCmp($id: ID!, $input: UpdateCustomCmpInput!) {
        updateCustomCmp(id: $id, input: $input) {
            cmp {
                id
            }
        }
    }
`

const useUpdateCustomCmpMutation = (options) => {
    return useMutation(MUTATION, options)
}

export default useUpdateCustomCmpMutation
