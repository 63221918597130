import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>The name is required</Trans>,
    },
    {
        path: 'resolvers.*.type',
        validate: isRequired,
        message: <Trans>Please choose a valid type.</Trans>,
    },
    {
        path: 'resolvers.*.script',
        validate: isRequired,
        message: <Trans>The script is required.</Trans>,
    },
])

const useUpdateCustomCmpFormState = (cmp) => {
    const formState = useFormState(
        {
            name: cmp.name || '',
            resolvers: cmp.resolvers || [],
        },
        {
            validation,
        }
    )
    return formState
}

export default useUpdateCustomCmpFormState
