import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import CreateAuditForm from './CreateAuditForm'
import SidebarContainer from '../../layout/SidebarContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'

const CreateAuditPage = ({
    isFetching,
    cmps,
    formState,
    isLoading,
    onSubmit,
}) => (
    <PageContainer data-testid="AuditsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent isFetching={isFetching}>
                <PageHeader
                    className="pb-6"
                    title={<Trans>Create an Audit</Trans>}
                />
                <CreateAuditForm
                    cmps={cmps}
                    formState={formState}
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default CreateAuditPage
