import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateCookieTableItem($id: [ID]!, $input: CookieTableItemInput!) {
        updateCookieTableItem(id: $id, input: $input) {
            cookieTableItem {
                id
                status
            }
        }
    }
`

const useUpdateCookieTableItemMutation = (options) =>
    useMutation(MUTATION, options)

export default useUpdateCookieTableItemMutation
