import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'

const AlertStatusFilterButtonGroup = ({
    selectedAlertStatus,
    totalAlertsCount,
    ignoredAlertsCount,
    reviewedAlertsCount,
    onFilterAlerts,
}) => (
    <div className="flex items-center">
        <Button
            type={selectedAlertStatus === 'ONGOING' ? 'gray' : 'white'}
            grouped="left"
            onClick={() => onFilterAlerts('ONGOING')}
        >
            <span>
                <Trans>Alerts</Trans>
                {totalAlertsCount && (
                    <span className="ml-1">({totalAlertsCount})</span>
                )}
            </span>
        </Button>
        <Button
            type={selectedAlertStatus === 'IGNORED' ? 'gray' : 'white'}
            grouped="center"
            onClick={() => onFilterAlerts('IGNORED')}
        >
            <span>
                <Trans>Ignored</Trans>
                {ignoredAlertsCount && (
                    <span className="ml-1">({ignoredAlertsCount})</span>
                )}
            </span>
        </Button>
        <Button
            type={selectedAlertStatus === 'REVIEWED' ? 'gray' : 'white'}
            grouped="right"
            onClick={() => onFilterAlerts('REVIEWED')}
        >
            <span>
                <Trans>Delegated</Trans>
                {reviewedAlertsCount && (
                    <span className="ml-1">({reviewedAlertsCount})</span>
                )}
            </span>
        </Button>
    </div>
)

export default AlertStatusFilterButtonGroup
