import React from 'react'
import gql from 'graphql-tag'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import TableCell from '../../utils/TableCell'
import Avatar from '../../utils/Avatar'
import Tag from '../../utils/Tag'
import StaffOrganizationUsersPageTableRowActionButtonWithState from './StaffOrganizationUsersPageTableRowActionButtonWithState'

const { USER_TYPE_STAFF: STAFF } = constants

const FRAGMENT = gql`
    fragment StaffOrganizationUsersPageTableRow on User {
        id
        fullName
        email
        type
        organizations {
            id
            organization {
                id
                name
            }
        }
    }
`

const StaffOrganizationUsersPageTableRow = ({ user, onDeleteUserSuccess }) => {
    const { fullName, email, type, organizations } = user
    const { currentUser } = useAppState()
    const isYou = user.id === currentUser.id
    return (
        <tr data-testid="UsersPageTableRow">
            <TableCell>
                <div className="flex items-center">
                    <Avatar iconName="user" className="flex-shrink-0" />
                    <div className="mx-4">
                        <div className="text-sm leading-5 font-medium text-gray-900">
                            {fullName}
                        </div>
                        <div className="text-sm leading-5 text-gray-500">
                            {email}
                        </div>
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <Tag type={type === STAFF ? 'blue' : 'yellow'}>{type}</Tag>
            </TableCell>
            <TableCell>
                {organizations
                    .filter(({ organization }) => organization !== null)
                    .map(({ organization }) => (
                        <span className="mr-2">{organization.name}</span>
                    ))}
            </TableCell>
            <TableCell>{isYou && <Tag type="blue">YOU</Tag>}</TableCell>
            <TableCell hasText className="text-right">
                {!isYou && (
                    <StaffOrganizationUsersPageTableRowActionButtonWithState
                        user={user}
                        onDeleteUserSuccess={onDeleteUserSuccess}
                    />
                )}
            </TableCell>
        </tr>
    )
}

StaffOrganizationUsersPageTableRow.FRAGMENT = FRAGMENT

export default StaffOrganizationUsersPageTableRow
