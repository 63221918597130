import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import ActionButton from '../../utils/ActionButton'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'

export default function AuditHeaderActions({
    isMobile,
    isWeb,
    onStartScan,
    isLoading,
    onUploadSession,
}) {
    if (isWeb) {
        return (
            <div className="flex gap-2 items-center">
                <DropdownMenu
                    position="bottom-start"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    content={({ close }) => (
                        <DropdownMenuItem
                            iconName="upload"
                            disabled={isLoading}
                            onClick={() => {
                                close()
                                onUploadSession()
                            }}
                        >
                            Upload new session
                        </DropdownMenuItem>
                    )}
                >
                    <ActionButton className="border border-slate-300 text-slate-700 bg-white disabled:border-slate-300 active:text-slate-800 enabled:hover:bg-slate-100 shadow-xs" />
                </DropdownMenu>
                <Button
                    onClick={onStartScan}
                    loading={isLoading}
                    disabled={isLoading}
                >
                    <Trans>Start new scan</Trans>
                </Button>
            </div>
        )
    }
    if (isMobile) {
        return (
            <Button
                onClick={onUploadSession}
                loading={isLoading}
                disabled={isLoading}
            >
                <Trans>Upload new session</Trans>
            </Button>
        )
    }
    return null
}
