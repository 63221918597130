import React from 'react'
import clsx from 'clsx'
import Checkbox from './Checkbox'
import Radio from './Radio'
import Icon from './Icon'
import Avatar from './Avatar'

export default function SelectListOption({
    avatarSrc,
    avatarDefaultIcon,
    children,
    className,
    disabled,
    checked,
    isRadio = false,
    isCheckbox = false,
    canRemove = false,
    canCollapse,
    isCollapsed,
    onCollapse,
    onRemove,
    ...props
}) {
    const hasAvatar = avatarSrc || avatarDefaultIcon
    return (
        <div
            className={clsx(
                'group flex cursor-pointer items-center justify-between px-3 py-2 hover:bg-gray-50',
                className
            )}
            {...props}
        >
            {canCollapse && (
                <button
                    type="button"
                    onClick={(e) => {
                        if (typeof onRemove === 'function') {
                            onCollapse()
                            e.stopPropagation()
                        }
                    }}
                    className="mr-2 rounded hover:bg-gray-200"
                >
                    <Icon
                        name={isCollapsed ? 'chevron-right' : 'chevron-down'}
                    />
                </button>
            )}
            <div className="flex items-center gap-2">
                {hasAvatar && (
                    <Avatar
                        iconName={avatarDefaultIcon}
                        src={avatarSrc}
                        size="sm"
                    />
                )}
                <div
                    className={clsx(
                        'flex-1 text-sm',
                        !checked && 'text-gray-400 group-hover:text-gray-600',
                        checked && 'text-black'
                    )}
                >
                    {children}
                </div>
            </div>
            <div className="flex items-center">
                {isRadio && (
                    <Radio checked={checked} className="pointer-events-none" />
                )}
                {isCheckbox && (
                    <Checkbox
                        checked={checked}
                        className="pointer-events-none"
                    />
                )}
                {canRemove && (
                    <button
                        type="button"
                        onClick={(e) => {
                            if (typeof onRemove === 'function') {
                                onRemove()
                                e.stopPropagation()
                            }
                        }}
                        className="ml-3 mr-2 rounded p-0.5 hover:bg-gray-200"
                    >
                        <Icon name="x" size="sm" />
                    </button>
                )}
            </div>
        </div>
    )
}
