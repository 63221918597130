import React from 'react'
import { Trans } from '@lingui/macro'
import Avatar from './Avatar'
import Tooltip from './Tooltip'
import AvatarGroup from './AvatarGroup'

const SelectedAvatarGroup = ({
    selectedItems = [],
    showMoreCount = 4,
    size = 'sm',
}) => {
    const finalselectedItems = selectedItems?.filter((audit) => audit !== null)
    return (
        <Tooltip
            as="span"
            position="bottom-start"
            hasArrow={false}
            asChild
            content={
                <div className="max-h-[200px] min-w-[200px] overflow-y-auto">
                    <ul className="flex flex-col space-y-1">
                        <p className="font-bold">
                            <Trans>Selected items</Trans>
                        </p>
                        {finalselectedItems &&
                            finalselectedItems.map((item) => (
                                <li
                                    className="flex items-center gap-2 py-2"
                                    key={item.id}
                                >
                                    <Avatar
                                        iconName="clock"
                                        size={size}
                                        className=""
                                        src={item.imageUrl}
                                    />
                                    <span className="truncate">
                                        {item.name}
                                    </span>
                                </li>
                            ))}
                    </ul>
                </div>
            }
        >
            <div className="flex items-center">
                <AvatarGroup
                    size={size}
                    showMoreCount={showMoreCount}
                    imageUrls={
                        finalselectedItems
                            ? finalselectedItems.map(({ imageUrl }) => imageUrl)
                            : []
                    }
                />
                <p className="ml-2 font-medium">
                    <Trans>{finalselectedItems.length} Selected</Trans>
                </p>
            </div>
        </Tooltip>
    )
}

export default SelectedAvatarGroup
