import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query updateCookieModalQuery($id: ID) {
        cookie(id: $id) {
            id
            name
            regex
            domain
            description
            purposes {
                id
            }
            vendor {
                id
                name
            }
        }
        purposes {
            id
            name
            children {
                id
                name
                children {
                    id
                    name
                }
            }
        }
    }
`

const useUpdateCookieModalQuery = (cookieId, options) => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        variables: { id: cookieId },
        ...options,
    })
    return {
        error,
        purposes: data.purposes || [],
        cookie: data.cookie || { purposes: [], purpose: {}, vendor: {} },
        isFetching,
        refetch,
    }
}

export default useUpdateCookieModalQuery
