import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteOrganizationCookieModal = ({
    cookie,
    title = <Trans>Delete cookie</Trans>,
    description = (
        <Trans>
            Are you sure you want to delete <strong>{cookie.name}</strong> from
            you organization?
        </Trans>
    ),
    isLoading,
    onDismiss,
    onConfirm,
}) => (
    <ConfirmModal
        title={title}
        description={description}
        confirmButtonText={<Trans>Delete cookie</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteOrganizationCookieModal
