import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'

const FRAGMENT = gql`
    fragment VendorCookiesPageTableRow on Cookie {
        id
        name
        purposes {
            id
            name
        }
        domain
        description
    }
`

const VendorCookiesPageTableRow = ({ cookie }) => {
    const { purpose, name, domain, description } = cookie
    return (
        <tr data-testid="VendorCookiesPageTableRow">
            <TableCell hasText>{name}</TableCell>
            <TableCell hasText>{domain}</TableCell>
            <TableCell hasText className="text-center">
                {purpose && (
                    <Tag key={purpose.id} type="blue" className="mr-2">
                        {purpose.name}
                    </Tag>
                )}
            </TableCell>
            <TableCell.Preview hasText className="max-w-sm">
                {description}
            </TableCell.Preview>
        </tr>
    )
}

VendorCookiesPageTableRow.FRAGMENT = FRAGMENT

export default VendorCookiesPageTableRow
