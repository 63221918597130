import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query createConsentProfileModalQuery {
        purposes {
            id
            externalId
            name
        }
        cmps {
            edges {
                node {
                    id
                    name
                    externalId
                }
            }
        }
    }
`

const useCreateConsentProfileModalQuery = (auditId, options) => {
    const {
        data = { audit: {} },
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables: { auditId },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        cmps: connectionToCollection(data.cmps),
        purposes: data.purposes || [],
        isFetching,
        refetch,
    }
}

export default useCreateConsentProfileModalQuery
