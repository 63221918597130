import React from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

const AuditScanPageTableActions = ({
    scans,
    onDeleteScans,
    onCreateScanReport,
}) => {
    const {
        isSelectingRows,
        startSelectingRows,
        stopSelectingRows,
        selectedRows,
        clearSelectedRows,
    } = useTableState()
    return (
        <TableActionsContainer isSticky>
            {isSelectingRows && (
                <div className="flex flex-row gap-2">
                    <Button
                        type="white"
                        size="sm"
                        onClick={() => stopSelectingRows()}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        type="white"
                        size="sm"
                        disabled={selectedRows.length === 0}
                        onClick={() =>
                            onCreateScanReport(selectedRows, clearSelectedRows)
                        }
                        iconBeforeProps={{
                            name: 'report',
                            color: 'none',
                            hasText: true,
                            className: 'text-gray-500',
                        }}
                    >
                        <Trans>Create Report</Trans>
                    </Button>
                    <Button
                        type="danger"
                        size="sm"
                        disabled={selectedRows.length === 0}
                        onClick={() => {
                            onDeleteScans(selectedRows, clearSelectedRows)
                            stopSelectingRows()
                        }}
                        iconBeforeProps={{
                            name: 'trash-03',
                            color: 'white',
                            hasText: true,
                        }}
                    >
                        <Trans>Delete Scan(s)</Trans>
                    </Button>
                </div>
            )}
            {!isSelectingRows && (
                <div className="flex gap-2 items-center">
                    <Button
                        type="white"
                        size="sm"
                        iconBeforeProps={{
                            name: 'check-done-01',
                            size: 'sm',
                        }}
                        onClick={() => startSelectingRows()}
                    />
                </div>
            )}
        </TableActionsContainer>
    )
}

export default AuditScanPageTableActions
