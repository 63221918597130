import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired, isValidEmail } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'email',
        validate: isRequired && isValidEmail,
        message: <Trans>The email is required</Trans>,
    },
    {
        path: 'firstName',
        validate: isRequired,
        message: <Trans>First name is required</Trans>,
    },
    {
        path: 'lastName',
        validate: isRequired,
        message: <Trans>Last name is required</Trans>,
    },
])

const valuesToInput = ({ email, firstName, lastName }) => ({
    email,
    firstName,
    lastName,
})

const useStaffCreateUserFormState = (initialValues) =>
    useFormState(
        {
            email: '',
            firstName: '',
            lastName: '',
            ...initialValues,
        },
        {
            validation,
            valuesToInput,
        }
    )

export default useStaffCreateUserFormState
