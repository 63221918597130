/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormSelect from '../../utils/FormSelect'
import FormInput from '../../utils/FormInput'
import FormSelectList from '../../utils/FormSelectList'
import SelectListOption from '../../utils/SelectListOption'
import SelectList from '../../utils/SelectList'
import MultiInput from '../../utils/MultiInput'
import RadioButton from '../../utils/RadioButton'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'

export const CmpResolverActionLabels = {
    CLICK_CSS_SELECTOR: 'Click on CSS',
    CLICK_XPATH_SELECTOR: 'Click on XPath',
    WAIT_FOR_CSS_SELECTOR: 'Wait for CSS',
    WAIT_FOR_XPATH_SELECTOR: 'Wait for XPath',
    WAIT_FOR_NAVIGATION: 'Wait for Navigation',
    WAIT_FOR_COOKIE_NAME: 'Wait for cookie name',
    SELECT_FRAME: 'Select frame',
}

const CreateConsentProfileModal = ({
    cmps,
    purposes = [],
    formState,
    onDismiss,
    onSubmit,
}) => {
    const { values, getErrorMessages, getNativeInputProps, handleChange } =
        formState
    const { cmpId, type } = values
    const customCmp = cmps.find(({ externalId }) => externalId === 'custom')
    const customCmpId = customCmp && customCmp.id
    return (
        <Modal onDismiss={onDismiss} className="max-w-3xl">
            <ModalTitle
                title={<Trans>Create new consent profile</Trans>}
                description={
                    <Trans>
                        A consent profile represents a specific set of user
                        choices. You can reject all purposes, accept all
                        purposes, close the CMP without a choice (No consent) or
                        set a specific combination of individual purposes
                        (Custom).
                    </Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Type</Trans>} className="mb-4">
                <RadioButton.Group>
                    <RadioButton
                        value="NO_CONSENT"
                        checked={type === 'NO_CONSENT'}
                        onChange={() => formState.handleNoConsent()}
                    >
                        <Trans>No consent</Trans>
                    </RadioButton>
                    <RadioButton
                        value="REJECT_ALL"
                        checked={type === 'REJECT_ALL'}
                        onChange={() => formState.handleRejectAll()}
                    >
                        <Trans>Reject all</Trans>
                    </RadioButton>
                    <RadioButton
                        value="ACCEPT_ALL"
                        checked={type === 'ACCEPT_ALL'}
                        onChange={() => formState.handleAcceptAll()}
                    >
                        <Trans>Accept all</Trans>
                    </RadioButton>
                    <RadioButton
                        value="CUSTOM"
                        checked={type === 'CUSTOM'}
                        onChange={() => formState.handleCustom()}
                    >
                        <Trans>Custom</Trans>
                    </RadioButton>
                </RadioButton.Group>
            </FormGroup>
            <FormGroup label={<Trans>CMP</Trans>} className="mb-4">
                <FormSelect
                    id="role"
                    errors={getErrorMessages('cmpId')}
                    {...getNativeInputProps('cmpId')}
                >
                    {cmps.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </FormSelect>
                <div className="mt-1 text-sm text-gray-500">
                    <Trans>
                        If you don’t find the right vendor in this dropdown
                        menu, please contact support.
                    </Trans>
                </div>
            </FormGroup>
            {type === 'CUSTOM' && cmpId !== customCmpId && (
                <FormGroup
                    label={<Trans>CMP purpose IDs</Trans>}
                    className="my-4"
                >
                    <MultiInput
                        onAdd={() => formState.handleAddCustomPurpose()}
                    >
                        {values.customPurposes.map((purpose, index) => (
                            <MultiInput.Item
                                key={index}
                                {...getNativeInputProps(
                                    `customPurposes.${index}`
                                )}
                                onRemove={() =>
                                    formState.handleRemoveCustomPurpose(index)
                                }
                            />
                        ))}
                    </MultiInput>
                </FormGroup>
            )}
            {cmpId === customCmpId && (
                <FormGroup
                    label={<Trans>Custom CMP CSS Selectors</Trans>}
                    className="mb-4"
                >
                    <MultiInput
                        onAdd={() => formState.handleAddCmpResolverAction()}
                    >
                        {values.cmpResolverActions.map((action, index) => {
                            const isActionSelected = action && action.type
                            return (
                                <div
                                    key={index}
                                    className="mb-2 flex items-center"
                                >
                                    <DropdownMenu
                                        as="div"
                                        position="top-start"
                                        content={
                                            <>
                                                {Object.entries(
                                                    CmpResolverActionLabels
                                                ).map(([actionType, label]) => (
                                                    <DropdownMenuItem
                                                        key={actionType}
                                                        onClick={() =>
                                                            handleChange(
                                                                `cmpResolverActions.${index}.type`,
                                                                actionType
                                                            )
                                                        }
                                                    >
                                                        {label}
                                                    </DropdownMenuItem>
                                                ))}
                                            </>
                                        }
                                    >
                                        <Button
                                            type="gray"
                                            grouped="left"
                                            className="w-36 whitespace-nowrap p-2"
                                        >
                                            {isActionSelected
                                                ? CmpResolverActionLabels[
                                                      action.type
                                                  ]
                                                : 'Action ▼'}
                                        </Button>
                                    </DropdownMenu>
                                    <MultiInput.Item
                                        key={index}
                                        grouped="right"
                                        {...getNativeInputProps(
                                            `cmpResolverActions.${index}.selector`
                                        )}
                                        onRemove={() =>
                                            formState.handleRemoveCmpResolverAction(
                                                index
                                            )
                                        }
                                    />
                                </div>
                            )
                        })}
                    </MultiInput>
                </FormGroup>
            )}
            <FormGroup label={<Trans>Webclew Purposes</Trans>}>
                <FormSelectList disabled={type !== 'CUSTOM'}>
                    <SelectList>
                        {purposes.map(({ id, name }) => (
                            <SelectListOption
                                key={id}
                                disabled
                                isCheckbox
                                checked={formState.values.purposeIds.includes(
                                    id
                                )}
                                onClick={() =>
                                    formState.handleChangePurpose(id)
                                }
                            >
                                {name}
                            </SelectListOption>
                        ))}
                    </SelectList>
                </FormSelectList>
            </FormGroup>
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit}>
                        <Trans>Create profile</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default CreateConsentProfileModal
