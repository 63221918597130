import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import PageLoading from '../../utils/PageLoading'
import useRefetch from '../../hooks/useRefetch'
import ConsentProfilesPage from './ConsentProfilesPage'
import useConsentProfilesPageQuery from './useConsentProfilesPageQuery'
import CreateConsentProfileModalWithState from './CreateConsentProfileModalWithState'

const PAGE_PATH = '/settings/consent-profiles'
const CREATE_PATH = '/settings/consent-profiles/create'

const ConsentProfilesPageWithState = () => {
    const history = useHistory()
    const createModalIsOpen = useRouteMatch({
        path: CREATE_PATH,
    })
    const {
        consentProfiles,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch,
    } = useConsentProfilesPageQuery()
    useRefetch(refetch)
    return (
        <>
            {createModalIsOpen && (
                <CreateConsentProfileModalWithState
                    onDismiss={() => history.push(PAGE_PATH)}
                    onCompleted={() => {
                        refetch()
                        history.push(PAGE_PATH)
                    }}
                />
            )}
            <ConsentProfilesPage
                consentProfiles={consentProfiles}
                isFetchingMore={isFetchingMore}
                isFetching={isFetching}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onCreate={() => history.push(CREATE_PATH)}
                onCompleted={() => {
                    refetch()
                    history.push(PAGE_PATH)
                }}
                onDeleteCompleted={() => {
                    refetch()
                    history.push(PAGE_PATH)
                }}
            />
        </>
    )
}

export default ConsentProfilesPageWithState
