import React from 'react'
import ScrollSentinel from './ScrollSentinel'
import Icon from './Icon'

const InfiniteScroll = ({
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    children,
}) => (
    <div className="relative">
        {children}
        {isFetchingMore && (
            <div className="flex justify-center items-center h-10">
                <Icon name="spinner" className="animate-spin mr-2" />
            </div>
        )}
        {!isFetchingMore && hasMoreRecords && (
            <ScrollSentinel onIntersect={onFetchMore} />
        )}
    </div>
)
export default InfiniteScroll
