/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import DropdownMenuItem from './DropdownMenuItem'

export default function TableFiltersMenuItem({
    activeFilters = [],
    name,
    onAdd,
    ...props
}) {
    if (activeFilters.includes(name)) {
        return null
    }
    return <DropdownMenuItem onClick={() => onAdd(name)} {...props} />
}
