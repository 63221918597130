import React from 'react'
import gql from 'graphql-tag'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import TableCell from '../../utils/TableCell'
import Avatar from '../../utils/Avatar'
import Tag from '../../utils/Tag'
import StaffUsersPageTableRowActionButtonWithState from './StaffUsersPageTableRowActionButtonWithState'
import AvatarGroup from '../../utils/AvatarGroup'

const { USER_TYPE_STAFF: STAFF } = constants

const FRAGMENT = gql`
    fragment StaffUsersPageTableRow on User {
        id
        fullName
        email
        type
        organizations {
            id
            organization {
                id
                imageUrl
            }
        }
    }
`

const StaffUsersPageTableRow = ({ user, onDeleteUserSuccess, refetch }) => {
    const { fullName, email, type, organizations } = user
    const { currentUser } = useAppState()
    const isYou = user.id === currentUser.id
    return (
        <tr data-testid="StaffUsersPageTableRow">
            <TableCell>
                <div className="flex items-center">
                    <Avatar iconName="user" className="flex-shrink-0" />
                    <div className="mx-4">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                            {fullName}
                        </div>
                        <div className="text-sm leading-5 text-gray-500">
                            {email}
                        </div>
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <Tag type={type === STAFF ? 'blue' : 'yellow'}>{type}</Tag>
            </TableCell>
            <TableCell>
                <AvatarGroup
                    imageUrls={organizations
                        .filter(({ organization }) => organization !== null)
                        .map(({ organization }) => {
                            return organization.imageUrl
                        })}
                />
            </TableCell>
            <TableCell>{isYou && <Tag type="blue">YOU</Tag>}</TableCell>
            <TableCell hasText className="text-right">
                <StaffUsersPageTableRowActionButtonWithState
                    user={user}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                    isYou={isYou}
                    refetch={refetch}
                />
            </TableCell>
        </tr>
    )
}

StaffUsersPageTableRow.FRAGMENT = FRAGMENT

export default StaffUsersPageTableRow
