import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

export const validation = createFormValidation([
    {
        path: 'domain',
        validate: isRequired,
        message: <Trans>Choose a domain for this tracker.</Trans>,
    },
    {
        path: 'purposeIds',
        validate: (purposeIds) => purposeIds.length > 0,
        message: <Trans>Select at least one purpose.</Trans>,
    },
    {
        path: 'vendorId',
        validate: isRequired,
        message: <Trans>Select a vendor.</Trans>,
    },
])

const useClassifyTrackerFormState = (tracker) => {
    const formState = useFormState(
        {
            domain: tracker.rootDomain,
            purposeIds: [],
            vendorId: null,
        },
        {
            validation,
        }
    )
    return formState
}

export default useClassifyTrackerFormState
