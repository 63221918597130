/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import DateTime from '../../utils/DateTime'
import AuditsPageTableRowActionButtonWithState from './AuditsPageTableRowActionButtonWithState'
import AuditAvatar from '../../utils/AuditAvatar'
import AuditAlertSummary from '../../utils/AuditAlertSummary'
import AuditTags from '../../utils/AuditTags'

const AuditsPageGridCard = ({
    hideFavoriteStar,
    audit,
    onDeleteAuditCompleted,
    onMarkAsFavoriteCompleted,
}) => {
    const history = useHistory()
    const { id, tags, alertsByPeriod } = audit
    return (
        <div
            role="button"
            tabIndex="0"
            className="flex min-h-[160px] cursor-pointer flex-col justify-between rounded-xl border border-gray-200 bg-white px-6 py-5 text-left transition-colors duration-500 hover:border-gray-500/50"
            onClick={() => {
                history.push(`/audits/${id}`)
            }}
        >
            <div className="mb-4 flex justify-between">
                <AuditAvatar
                    audit={audit}
                    hideFavoriteStar={hideFavoriteStar}
                />
                <AuditsPageTableRowActionButtonWithState
                    audit={audit}
                    onMarkAsFavoriteCompleted={onMarkAsFavoriteCompleted}
                    onDeleteAuditCompleted={onDeleteAuditCompleted}
                />
            </div>
            {audit.tags && <AuditTags tags={audit.tags} />}
            <div className="flex items-center justify-between">
                <div />
                <AuditAlertSummary audit={audit} />
            </div>
        </div>
    )
}

export default AuditsPageGridCard
