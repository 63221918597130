import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function CookiesPageTableHeaderRow() {
    return (
        <TableHeaderRow isSticky>
            <TableHeaderCell columnId="cookie" canResize initialWidth={300}>
                <Trans>Cookie</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="purposes"
                canHide
                canResize
                initialWidth={200}
            >
                <Trans>Purposes</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="vendors"
                canResize
                canHide
                initialWidth={100}
            >
                <Trans>Vendors</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="occurrence"
                canResize
                canHide
                canSort
                initialWidth={150}
            >
                <Trans>Occurrence</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="description"
                canResize
                canHide
                initialWidth={300}
            >
                <Trans>description</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="createdAt"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={150}
                canSort
            >
                <Trans>Created at</Trans>
            </TableHeaderCell>
            <TableHeaderCell
                columnId="updatedAt"
                canResize
                canHide
                isInitiallyHidden
                initialWidth={150}
                canSort
            >
                <Trans>Updated at</Trans>
            </TableHeaderCell>
            <TableHeaderCell />
        </TableHeaderRow>
    )
}
