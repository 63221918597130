import React, { useState } from 'react'
import TableCell from '../../utils/TableCell'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import AuditAvatar from '../../utils/AuditAvatar'
import AuditAlertSummary from '../../utils/AuditAlertSummary'
import CollapseButton from '../../utils/CollapseButton'
import AuditObservationTablePageTableRow from './AuditObservationTablePageTableRow'

const AuditObservationTableRow = ({
    audit,
    pages = [],
    sessions = [],
    objectName,
    nextActiveTabOnClick,
}) => {
    const { tags } = audit
    const [isCollapsed, setIsCollapsed] = useState(true)
    return (
        <>
            <TableRow>
                <td className="w-[45px] pl-6">
                    <CollapseButton
                        isCollapsed={isCollapsed}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsCollapsed(!isCollapsed)
                        }}
                    />
                </td>
                <TableCell px="none">
                    <AuditAvatar audit={audit} />
                </TableCell>
            </TableRow>
            {!isCollapsed && (
                <tr>
                    <td colSpan="10">
                        <div className="max-h-[400px] overflow-y-scroll">
                            {sessions.length > 0 && (
                                <table className="w-full">
                                    <TableBody>
                                        <AuditObservationTablePageTableRow
                                            page={{
                                                url: 'Sessions',
                                                isSession: true,
                                                scanPages: sessions.map(
                                                    (session) => ({
                                                        ...session,
                                                        scan: session,
                                                    })
                                                ),
                                            }}
                                            nextActiveTabOnClick={
                                                nextActiveTabOnClick
                                            }
                                            isSession
                                            objectName={objectName}
                                        />
                                    </TableBody>
                                </table>
                            )}
                            {pages.length > 0 && (
                                <table className="w-full">
                                    <TableBody>
                                        {pages.map((page) => (
                                            <AuditObservationTablePageTableRow
                                                objectName={objectName}
                                                nextActiveTabOnClick={
                                                    nextActiveTabOnClick
                                                }
                                                key={page.id}
                                                page={page}
                                            />
                                        ))}
                                    </TableBody>
                                </table>
                            )}
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}

export default AuditObservationTableRow
