import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerHeader from './DrawerHeader'
import DrawerContent from './DrawerContent'
import InfoCard from './InfoCard'
import { getGraphqlErrorMessage } from '../../utils/graphql'

export default function DrawerError({ error }) {
    const message = getGraphqlErrorMessage(error)
    return (
        <>
            <DrawerHeader
                avatarColor="red"
                avatarIconName="alert-octagon"
                avatarIconColor="red"
                title={<Trans>Error</Trans>}
                description={<Trans>Something went wrong</Trans>}
            />
            <DrawerContent>
                <InfoCard
                    iconName="alert-octagon"
                    title={<Trans>Something went wrong</Trans>}
                    text={message}
                    type="error"
                />
            </DrawerContent>
        </>
    )
}
