import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import CookiesPageTableRow from './CookiesPageTableRow'
import CookiesPageTableHeaderRow from './CookiesPageTableHeaderRow'

const CookiesPageTable = ({
    cookies = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onDeleteCookiesCompleted,
    onUpdateCookiesCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <CookiesPageTableHeaderRow />
            </thead>
            <TableBody>
                {cookies.map((cookie) => (
                    <CookiesPageTableRow
                        key={cookie.id}
                        cookie={cookie}
                        onDeleteCookiesCompleted={onDeleteCookiesCompleted}
                        onUpdateCookiesCompleted={onUpdateCookiesCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default CookiesPageTable
