import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query createAuditPage {
        auditScanFrequencies
        cmps {
            edges {
                node {
                    id
                    name
                    isCustom
                }
            }
        }
    }
`

const useCreateAuditPageQuery = () => {
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        scanFrequencies: data.auditScanFrequencies || [],
        cmps: connectionToCollection(data.cmps),
        isFetching: loading,
        refetch,
    }
}

export default useCreateAuditPageQuery
