import React from 'react'
import { Trans } from '@lingui/macro'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import UpdateCookieModal from './UpdateCookiesModal'
import useUpdateCookiesMutation from './useUpdateCookiesMutation'
import useUpdateCookiesFormState from './useUpdateCookiesFormState'

const UpdateCookiesModalWithState = ({
    scanCookies,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { currentUser } = useAppState()
    const [updateCookies] = useUpdateCookiesMutation({ onCompleted, onError })
    const formState = useUpdateCookiesFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <UpdateCookieModal
            canCreateVendor={currentUser.isStaff}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        const variables = { ids: scanCookies, input }
                        await updateCookies({ variables })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Cookie updated successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default UpdateCookiesModalWithState
