import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const CREATE_DOMAIN_MUTATION = gql`
    mutation createDomain($auditId: ID!, $input: DomainInput!) {
        createDomain(auditId: $auditId, input: $input) {
            domain {
                id
            }
        }
    }
`

const useCreateDomainMutation = (options) =>
    useMutation(CREATE_DOMAIN_MUTATION, options)

export default useCreateDomainMutation
