import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import useAppState from '../../hooks/useAppState'
import LoginPage from './LoginPage'
import useLoginMutation from './useLoginMutation'
import useLoginFormState from './useLoginFormState'

const LoginPageWithState = () => {
    const location = useLocation()
    const history = useHistory()
    const appState = useAppState()
    const [login, { loading }] = useLoginMutation()
    const formState = useLoginFormState()
    const [errorMessage, setErrorMessage] = useState(null)
    return (
        <LoginPage
            errorMessage={errorMessage}
            formState={formState}
            isLoading={loading}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { email, password } = formState.values
                        const input = { email, password }
                        const { data } = await login({ variables: { input } })
                        const {
                            accessToken,
                            refreshToken,
                            me: currentUser,
                        } = data.loginWithPassword
                        await appState.login(
                            accessToken,
                            refreshToken,
                            currentUser
                        )
                        let referrer = '/'
                        if (typeof location.state.referrer !== 'undefined') {
                            ;({ referrer } = location.state)
                        }
                        history.replace(referrer)
                    } catch (error) {
                        let { message } = error
                        if (error.graphQLErrors) {
                            ;[{ message }] = error.graphQLErrors
                        }
                        setErrorMessage(message)
                    }
                }
            }}
        />
    )
}

export default LoginPageWithState
