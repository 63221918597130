import React from 'react'
import { Trans } from '@lingui/macro'
import Sidebar from '../../layout/Sidebar'
import SidebarMenu from '../../layout/SidebarMenu'
import SidebarTitle from '../../layout/SidebarTitle'
import SidebarMenuItem from '../../layout/SidebarMenuItem'

export default function IdentifiedVendorsPageSidebar() {
    return (
        <Sidebar>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>Observations</Trans>
                </SidebarTitle>
                <SidebarMenuItem exact to="/vendors" iconName="building-07">
                    <Trans>Vendors</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem exact to="/vendors/cookies" iconName="cookie">
                    <Trans>Cookies</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem exact to="/vendors/trackers" iconName="mark">
                    <Trans>Trackers</Trans>
                </SidebarMenuItem>
                <SidebarTitle>
                    <Trans>Tables</Trans>
                </SidebarTitle>
                <SidebarMenuItem
                    exact
                    to="/vendors/vendor-tables"
                    matchPath={[
                        '/vendors/vendor-tables*',
                        '/vendors/vendor-table-items/:id*',
                    ]}
                    iconName="building-07"
                >
                    <Trans>Vendor Tables</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    exact
                    to="/vendors/cookie-tables"
                    matchPath={[
                        '/vendors/cookie-tables*',
                        '/vendors/cookie-table-items/:id*',
                    ]}
                    iconName="cookie"
                >
                    <Trans>Cookie Tables</Trans>
                </SidebarMenuItem>
                <SidebarTitle>
                    <Trans>Classifications</Trans>
                </SidebarTitle>
                <SidebarMenuItem
                    exact
                    to="/classifications/organization"
                    matchPath="/classifications/organization*"
                    iconName="tag"
                >
                    <Trans>Your organization</Trans>
                </SidebarMenuItem>
                {/* <SidebarMenuItem
                    exact
                    to="/classifications/directory/vendors"
                    matchPath="/classifications/directory*"
                    iconName="tag"
                >
                    <Trans>Webclew directory</Trans>
                </SidebarMenuItem> */}
            </SidebarMenu>
        </Sidebar>
    )
}
