import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import VendorTypeTags from '../../utils/VendorTypeTags'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import Tabs, { Tab } from '../../utils/Tabs'
import VendorsPageActionsDropdownMenu from './VendorsPageActionsDropdownMenu'
import VendorDetailsDrawerDetailsTab from './VendorDetailsDrawerDetailsTab'
import VendorDetailsDrawerTrackersTab from './VendorDetailsDrawerTrackersTab'
import VendorDetailsDrawerCookiesTab from './VendorDetailsDrawerCookiesTab'
import VendorDetailsDrawerIabPurposesTab from './VendorDetailsDrawerIabPurposesTab'

const QUERY = gql`
    query vendorDetailsDrawer($id: ID!) {
        vendor(id: $id) {
            id
            externalId
            imageUrl
            name
            types
            domain
            description
            legalName
            purposes
            legIntPurposes
            flexiblePurposes
            specialPurposes
            features
            specialFeatures
            gvlId
            activities
            sourceUrl
            websiteUrl
            privacyPolicyUrl
            cookiePolicyUrl
            headQuarterLocations
            dataCenterLocations
            otherLocations
            vendorType
            belongsToOrganization
            cookiesCount
            trackersCount
            cookies {
                id
                name
                purposes {
                    id
                    name
                }
                domain
                description
            }
            trackers {
                id
                purposes {
                    id
                    name
                }
                domain
                description
            }
        }
    }
`

export default function VendorDetailsDrawer({ vendorId }) {
    const [activeTab, setActiveTab] = useState('details')
    const variables = { id: vendorId }
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    const { vendor } = data
    const { imageUrl, name, description, types, cookiesCount, trackersCount } =
        vendor
    return (
        <>
            <DrawerHeader
                avatarSrc={imageUrl}
                avatarIconName="building"
                title={name}
                afterTitle={<VendorTypeTags types={types} />}
                description={description}
                buttons={
                    <VendorsPageActionsDropdownMenu
                        vendor={vendor}
                        onDeleteVendorCompleted={() => refetch()}
                        onUpdateVendorCompleted={() => refetch()}
                    >
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{
                                name: 'menu-dots',
                                size: 'sm',
                            }}
                        />
                    </VendorsPageActionsDropdownMenu>
                }
            />
            <Tabs className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'cookies'}
                    onClick={() => setActiveTab('cookies')}
                >
                    <Trans>Cookies</Trans> <Tag>{cookiesCount}</Tag>
                </Tab>
                <Tab
                    isActive={activeTab === 'trackers'}
                    onClick={() => setActiveTab('trackers')}
                >
                    <Trans>Tracker</Trans> <Tag>{trackersCount}</Tag>
                </Tab>
                <Tab
                    isActive={activeTab === 'iab-purposes'}
                    onClick={() => setActiveTab('iab-purposes')}
                >
                    <Trans>IAB purposes</Trans>
                </Tab>
            </Tabs>
            <DrawerContent className="flex flex-col gap-4">
                {activeTab === 'details' && (
                    <VendorDetailsDrawerDetailsTab vendor={vendor} />
                )}
                {activeTab === 'cookies' && (
                    <VendorDetailsDrawerCookiesTab vendor={vendor} />
                )}
                {activeTab === 'trackers' && (
                    <VendorDetailsDrawerTrackersTab vendor={vendor} />
                )}
                {activeTab === 'iab-purposes' && (
                    <VendorDetailsDrawerIabPurposesTab vendor={vendor} />
                )}
            </DrawerContent>
        </>
    )
}
