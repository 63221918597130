import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

export const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this cookie.</Trans>,
    },
    {
        path: 'purposeIds',
        validate: (purposeIds) => purposeIds.length > 0,
        message: <Trans>Select at least one purpose.</Trans>,
    },
    {
        path: 'regex',
        validate: (regex, value, { originalCookieName }) => {
            if (regex === '' || regex === null) {
                return true
            }
            if (originalCookieName === null) {
                return true
            }
            try {
                return new RegExp(regex, 'g').test(originalCookieName)
            } catch (e) {
                return false
            }
        },
        message: (
            <Trans>The regex does not match the original cookie name.</Trans>
        ),
    },
    {
        path: 'regex',
        validate: (regex) => {
            if (regex === '' || regex === null) {
                return true
            }
            return regex.length >= 3
        },
        message: <Trans>The regex be at least 3 chars long</Trans>,
    },
    {
        path: 'vendorId',
        validate: isRequired,
        message: <Trans>Select a vendor.</Trans>,
    },
])

const useClassifyCookieFormState = (cookie) => {
    const formState = useFormState(
        {
            name: cookie.name,
            domain: cookie.rootDomain,
            regex: '',
            description: '',
            purposeIds: [],
            vendorId: null,
        },
        {
            validation,
            validationOptions: {
                originalCookieName: cookie.originalName,
            },
        }
    )
    return formState
}

export default useClassifyCookieFormState
