import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import { useSelectedRole } from '../settings-users-invite/InviteUserPageWithState'
import ChangeRoleModal from './ChangeUserRoleModal'
import useChangeUserRoleMutation from './useChangeUserRoleMutation'
import useChangeUserRoleFormState from './useChangeUserRoleFormState'
import useChangeUserRoleModalQuery from './useChangeUserRoleModalQuery'

const ChangeUserRoleModalWithState = ({
    roles = null,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const canSkipDataFetching = roles !== null
    const query = useChangeUserRoleModalQuery({ skip: canSkipDataFetching })
    const finalRoles = roles || query.roles
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const formState = useChangeUserRoleFormState()
    const [changeUserRole] = useChangeUserRoleMutation({
        onCompleted,
        onError,
    })
    useSelectedRole(formState, finalRoles)
    return (
        <ChangeRoleModal
            roles={finalRoles}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { role } = formState.values
                        const input = { role }
                        await changeUserRole({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>New role {role} assigned!</Trans>,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default ChangeUserRoleModalWithState
