import React, { useState } from 'react'
import ResetPasswordPage from './ResetPasswordPage'
import useResetPasswordMutation from './useResetPasswordMutation'
import useResetPasswordFormState from './useResetPasswordFormState'

const ResetPasswordPageWithState = () => {
    const [isPasswordReset, setIsPasswordReset] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const formState = useResetPasswordFormState()
    const [resetPassword, { loading }] = useResetPasswordMutation()
    return (
        <ResetPasswordPage
            formState={formState}
            errorMessage={errorMessage}
            isLoading={loading}
            isPasswordReset={isPasswordReset}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await resetPassword({
                            variables: { input },
                        })
                        setIsPasswordReset(true)
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        setErrorMessage(message)
                    }
                }
            }}
        />
    )
}

export default ResetPasswordPageWithState
