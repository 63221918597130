import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query addLoginProfileModal($auditId: ID!) {
        audit(id: $auditId) {
            id
            loginProfiles {
                id
                name
            }
        }
        loginProfiles {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

const useAddLoginProfileModalQuery = (auditId, options) => {
    const {
        data = { audit: {} },
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables: { auditId },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        loginProfiles: connectionToCollection(data.loginProfiles),
        currentLoginProfiles: data.audit.loginProfiles,
        isFetching,
        refetch,
    }
}

export default useAddLoginProfileModalQuery
