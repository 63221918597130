import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import Icon from '../../utils/Icon'
import Avatar from '../../utils/Avatar'

const FRAGMENT = gql`
    fragment OrganizationListItem on Organization {
        id
        name
        auditsCount
        usersCount
        imageUrl
    }
`

const OrganizationListItem = ({ organization, onClick }) => {
    const { name, auditsCount, usersCount, imageUrl } = organization
    return (
        <li className="border-t border-gray-200">
            <button
                type="button"
                className="block w-full hover:bg-gray-50 transition duration-150 ease-in-out"
                onClick={onClick}
            >
                <div className="px-4 py-4 flex items-center">
                    <div className="flex-shrink-0 mr-4">
                        <Avatar iconName="building-07" src={imageUrl} />
                    </div>
                    <div className="min-w-0 flex-1 sm:flex">
                        <div>
                            <div className="font-medium text-gray-900 truncate text-left">
                                {name}
                            </div>
                            <div className="mt-2 flex">
                                <div className="flex items-center text-sm leading-5 text-gray-500 mr-4">
                                    <Icon
                                        name="users"
                                        size="sm"
                                        className="mr-2"
                                    />
                                    <span>
                                        {usersCount} <Trans>Users</Trans>
                                    </span>
                                </div>
                                <div className="flex items-center text-sm leading-5 text-gray-500">
                                    <Icon
                                        name="dashboard"
                                        size="sm"
                                        className="mr-2"
                                    />
                                    <span>
                                        {auditsCount} <Trans>Audits</Trans>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ml-5 flex-shrink-0">
                        <Icon name="chevron-right" />
                    </div>
                </div>
            </button>
        </li>
    )
}

OrganizationListItem.FRAGMENT = FRAGMENT

export default OrganizationListItem
