import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation addLoginProfile($auditId: ID!, $input: AddLoginProfileInput!) {
        addLoginProfile(auditId: $auditId, input: $input) {
            audit {
                id
            }
        }
    }
`

const useAddLoginProfileMutation = (options) => useMutation(MUTATION, options)

export default useAddLoginProfileMutation
