import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../constants'

const { ALERT_DELEGATION_TYPE_CLEAR: CLEAR } = constants

export const getDelegationText = (author, user, externalEmail, type) => {
    if (type === CLEAR) {
        return (
            <Trans>
                <strong className="font-medium">{author.fullName}</strong>{' '}
                cleared the delegation
            </Trans>
        )
    }
    if (user && author.id === user.id) {
        return (
            <Trans>
                <strong className="font-medium">{author.fullName}</strong>{' '}
                delegated this alert to themselves
            </Trans>
        )
    }

    const delegated = user ? user.fullName : externalEmail

    return (
        <Trans>
            <strong className="font-medium">{author.fullName}</strong> delegated
            this alert to {delegated}
        </Trans>
    )
}

export const getDelegatedByText = (author, user) => {
    if (author.id === user.id) {
        return (
            <Trans>
                Delegated by <strong className="font-medium">yourself</strong>
            </Trans>
        )
    }
    return (
        <Trans>
            Delegated by{' '}
            <strong className="font-medium">{author.fullName}</strong>
        </Trans>
    )
}
