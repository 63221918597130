import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'
import Button from '../../utils/Button'
import FormButtonGroup from '../../utils/FormButtonGroup'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'

const InviteUserForm = ({ formState, roles = [], isLoading, onSubmit }) => (
    <Card>
        <form>
            <CardHeader
                title={<Trans>User invitation</Trans>}
                description={
                    <Trans>
                        Choose an email and a role for the new user you want to
                        invite.
                    </Trans>
                }
            />
            <div className="divide-y">
                <FormGroup
                    isHorizontal
                    label={<Trans>Email</Trans>}
                    htmlFor="email"
                >
                    <FormInput
                        id="email"
                        type="email"
                        className="max-w-lg"
                        errors={formState.getErrorMessages('email')}
                        {...formState.getNativeInputProps('email')}
                    />
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Role</Trans>}
                    htmlFor="roleId"
                >
                    <FormSelect
                        id="role"
                        className="max-w-lg"
                        errors={formState.getErrorMessages('role')}
                        {...formState.getNativeInputProps('role')}
                    >
                        {roles.map((role) => (
                            <option key={role} value={role}>
                                {role}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        <Trans>Invite user</Trans>
                    </Button>
                    <Link to="/settings/users">
                        <Button type="white">
                            <Trans>Cancel</Trans>
                        </Button>
                    </Link>
                </FormButtonGroup>
            </div>
        </form>
    </Card>
)

export default InviteUserForm
