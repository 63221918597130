import React from 'react'
import clsx from 'clsx'
import FormInputErrors from './FormInputErrors'

export default function FormSelectList({
    className,
    children,
    disabled,
    selectedItems = null,
    errors,
    ...props
}) {
    const hasError = errors && errors.length > 0
    return (
        <>
            <div
                className={clsx(
                    'overflow-auto rounded-lg border shadow-xs',
                    !hasError && 'border-gray-300 hover:border-gray-400',
                    hasError &&
                        'border-red-300 text-red-600 placeholder-red-300 hover:border-red-400',
                    disabled && 'pointer-events-none opacity-50',
                    className
                )}
                {...props}
            >
                {selectedItems && (
                    <div className="border-b border-gray-300 px-4 py-3">
                        {selectedItems}
                    </div>
                )}
                {children}
            </div>
            <FormInputErrors errors={errors} />
        </>
    )
}
