import React from 'react'
import { Trans } from '@lingui/macro'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'
import Tag from '../../utils/Tag'
import useTableState from '../../hooks/useTableState'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

const CookieTableVersionCompareModalTableActions = ({
    activeTab = 'all',
    setActiveTab,
    allCount,
    addedCount,
    deletedCount,
    deprecatedCount,
}) => {
    return (
        <TableActionsContainer>
            <AnimatedTabs>
                <AnimatedTab
                    className="px-3"
                    isActive={activeTab === 'all'}
                    onClick={() => setActiveTab('all')}
                >
                    <div className="flex flex-row items-center gap-1">
                        <Trans>All</Trans>
                        <Tag>{allCount}</Tag>
                    </div>
                </AnimatedTab>
                <AnimatedTab
                    className="px-3"
                    isActive={activeTab === 'new'}
                    onClick={() => setActiveTab('new')}
                >
                    <div className="flex flex-row items-center gap-1">
                        <Trans>New</Trans>
                        <Tag type="green">{addedCount}</Tag>
                    </div>
                </AnimatedTab>
                <AnimatedTab
                    className="px-3"
                    isActive={activeTab === 'deleted'}
                    onClick={() => setActiveTab('deleted')}
                >
                    <div className="flex flex-row items-center gap-1">
                        <Trans>Not found</Trans>
                        <Tag type="red">{deletedCount}</Tag>
                    </div>
                </AnimatedTab>
                <AnimatedTab
                    className="px-3"
                    isActive={activeTab === 'deprecated'}
                    onClick={() => setActiveTab('deprecated')}
                >
                    <div className="flex flex-row items-center gap-1">
                        <Trans>Removed</Trans>
                        <Tag type="orange">{deprecatedCount}</Tag>
                    </div>
                </AnimatedTab>
            </AnimatedTabs>
        </TableActionsContainer>
    )
}

export default CookieTableVersionCompareModalTableActions
