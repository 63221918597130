import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import {
    ALERT_COMMENT_LIST_ITEM_FRAGMENT,
    ALERT_DELEGATION_LIST_ITEM_FRAGMENT,
} from './AlertActivityListItemWithState'

const QUERY = gql`
    query alertsActivitiesQuery {
        alertsDelegations {
            ...AlertDelegationListItem
        }
        alertsComments {
            ...AlertCommentListItem
        }
    }
    ${ALERT_DELEGATION_LIST_ITEM_FRAGMENT}
    ${ALERT_COMMENT_LIST_ITEM_FRAGMENT}
`

export default function useDashboardPageAlertsActivitiesQuery() {
    const variables = {}
    const {
        data = {
            alertsDelegations: [],
            alertsComments: [],
        },
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const activities = [...data.alertsDelegations, ...data.alertsComments]
        .sort((a, b) => {
            const aDate = new Date(a.createdAt)
            const bDate = new Date(b.createdAt)
            return bDate - aDate
        })
        .slice(0, 10)
    return {
        isFetching,
        activities,
        refetch,
    }
}
