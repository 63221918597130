import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import Tabs, { Tab } from '../../utils/Tabs'
import AlertCountTag from '../../utils/AlertCountTag'
import VendorTypeTags from '../../utils/VendorTypeTags'
import IdentifiedVendorDrawerTabDetails from './IdentifiedVendorDrawerTabDetails'
import IdentifiedVendorDrawerTabCookies from './IdentifiedVendorDrawerTabCookies'
import IdentifiedVendorDrawerTabTrackers from './IdentifiedVendorDrawerTabTrackers'
import IdentifiedVendorDrawerTabObservations from './IdentifiedVendorDrawerTabObservations'
import IdentifiedVendorDrawerTabAlerts from './IdentifiedVendorDrawerTabAlerts'
import IdentifiedVendorsPageTableRowActionsWithState from './IdentifiedVendorsPageTableRowActionsWithState'

const QUERY = gql`
    query IdentifiedVendorDetailsDrawer($id: ID!, $auditIds: [ID]) {
        identifiedVendor(id: $id, auditIds: $auditIds) {
            id
            lastSeenAt
            period {
                period
                startDate
                endDate
            }
            audits {
                id
                type
                name
                isMobile
                isWeb
                scanFrequency
                createdAt
                imageUrl
                tags
                domains {
                    id
                    url
                }
            }
            identifiedCookiesCount
            identifiedTrackersCount
            pages {
                id
                url
                auditIds
                scanPages {
                    id
                    scan {
                        id
                        startedAt
                        consentProfile {
                            id
                            name
                            type
                        }
                    }
                }
            }
            sessions {
                id
                audit {
                    id
                }
                startedAt
                consentProfile {
                    id
                    name
                    type
                }
            }
            alerts {
                id
                groupingId
                firstCreatedAt
                lastCreatedAt
                message
                type
                subjectName
                severityType
                occurrences
                audits {
                    id
                    name
                    imageUrl
                }
            }
            vendor {
                id
                name
                types
                imageUrl
                description
                legalName
                purposes
                legIntPurposes
                flexiblePurposes
                specialPurposes
                features
                specialFeatures
                gvlId
                privacyPolicyUrl
                vendorType
            }
        }
    }
`

export default function IdentifiedVendorDetailsDrawer({
    auditId,
    auditIds,
    identifiedVendorId,
    onUpdateCompleted,
    startDate,
    endDate,
    drawerState,
    setDrawerState,
}) {
    const TAB_DETAILS = 'details'
    const TAB_COOKIES = 'cookies'
    const TAB_OBSERVATIONS = 'observations'
    const TAB_TRACKERS = 'trackers'
    const TAB_ALERTS = 'alerts'

    const activeTab = drawerState.activeTab || TAB_OBSERVATIONS
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: identifiedVendorId, auditIds }
    const {
        data = {},
        error,
        loading,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />
    const {
        vendor,
        identifiedCookiesCount,
        identifiedTrackersCount,
        pages,
        alerts,
        sessions,
        audits,
        period,
    } = data.identifiedVendor

    return (
        <>
            <DrawerHeader
                avatarSrc={vendor.imageUrl}
                avatarIconName="scan"
                title={vendor.name}
                afterTitle={<VendorTypeTags types={vendor.types} />}
                description={vendor?.description}
                buttons={
                    onUpdateCompleted && (
                        <IdentifiedVendorsPageTableRowActionsWithState
                            vendor={vendor}
                            onUpdateCompleted={onUpdateCompleted}
                        >
                            <Button
                                type="white"
                                size="sm"
                                iconBeforeProps={{
                                    name: 'menu-dots',
                                    size: 'sm',
                                }}
                            />
                        </IdentifiedVendorsPageTableRowActionsWithState>
                    )
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === TAB_DETAILS}
                    onClick={() => setActiveTab(TAB_DETAILS)}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === TAB_COOKIES}
                    onClick={() => setActiveTab(TAB_COOKIES)}
                >
                    <Trans>Cookies</Trans>
                    <Tag iconNameBefore="cookie">{identifiedCookiesCount}</Tag>
                </Tab>
                <Tab
                    isActive={activeTab === TAB_TRACKERS}
                    onClick={() => setActiveTab(TAB_TRACKERS)}
                >
                    <Trans>Trackers</Trans>
                    <Tag iconNameBefore="tracker">
                        {identifiedTrackersCount}
                    </Tag>
                </Tab>
                <Tab
                    isActive={activeTab === TAB_OBSERVATIONS}
                    onClick={() => setActiveTab(TAB_OBSERVATIONS)}
                >
                    <Trans>Observations</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === TAB_ALERTS}
                    onClick={() => setActiveTab(TAB_ALERTS)}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === TAB_DETAILS && (
                    <IdentifiedVendorDrawerTabDetails
                        identifiedVendor={data.identifiedVendor}
                    />
                )}
                {activeTab === TAB_COOKIES && (
                    <IdentifiedVendorDrawerTabCookies
                        period={period}
                        vendorId={vendor.id}
                        auditId={auditId}
                    />
                )}
                {activeTab === TAB_TRACKERS && (
                    <IdentifiedVendorDrawerTabTrackers
                        auditId={auditId}
                        period={period}
                        vendorId={vendor.id}
                    />
                )}
                {activeTab === TAB_OBSERVATIONS && (
                    <IdentifiedVendorDrawerTabObservations
                        auditId={auditId}
                        pages={pages}
                        sessions={sessions}
                        audits={audits}
                    />
                )}
                {activeTab === TAB_ALERTS && (
                    <IdentifiedVendorDrawerTabAlerts
                        alerts={alerts}
                        auditId={auditId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                )}
            </DrawerContent>
        </>
    )
}
