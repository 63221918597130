import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query updateConsentProfilePage($id: ID!) {
        consentProfile(id: $id) {
            id
            name
            type
            customPurposes
            cmpResolverActions {
                type
                selector
            }
            cmp {
                id
            }
            purposes {
                id
            }
        }
        purposes {
            id
            externalId
            name
        }
        cmps {
            edges {
                node {
                    id
                    name
                    externalId
                }
            }
        }
    }
`

const useUpdateConsentProfilePageQuery = () => {
    const { id } = useParams()
    const variables = { id }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const consentProfile = data.consentProfile
        ? {
              ...data.consentProfile,
              cmpResolverActions:
                  data.consentProfile.cmpResolverActions &&
                  data.consentProfile.cmpResolverActions.map(
                      ({ type, selector }) => ({ type, selector })
                  ),
          }
        : {}
    return {
        error,
        consentProfile,
        purposes: data.purposes,
        cmps: connectionToCollection(data.cmps),
        isFetching,
        refetch,
    }
}

export default useUpdateConsentProfilePageQuery
