import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation deleteOrganizationCookie($id: ID!) {
        deleteOrganizationCookie(id: $id)
    }
`

const useDeleteOrganizationCookieMutation = (options) =>
    useMutation(MUTATION, options)

export default useDeleteOrganizationCookieMutation
