import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query auditAlertsDashboard($auditId: ID, $filters: AlertFilters) {
        alertsByPeriod(auditId: $auditId, filters: $filters) {
            warningAlerts
            issueAlerts
            criticalAlerts
        }
    }
`

const useAuditAlertsDashboardQuery = (
    auditId,
    type,
    tag,
    startDate,
    endDate
) => {
    const filters = {
        type,
        tags: tag ? [tag] : [],
        startDate,
        endDate,
    }
    const variables = { auditId, filters }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    return {
        alertsByPeriod: data.alertsByPeriod,
        error,
        isFetching,
        refetch,
    }
}

export default useAuditAlertsDashboardQuery
