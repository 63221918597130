import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import AlertsPage from './AlertsPage'
import useAlertsPageQuery from './useAlertsPageQuery'
import useScanRangeSelect from './useScanRangeSelect'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useFilters from '../../hooks/useFilters'

import * as constants from '../../../constants'
import { getAlertStatusText } from '../../utils/AlertTag'

const {
    ALERT_SEVERITY_TYPE_CRITICAL: CRITICAL,
    ALERT_SEVERITY_TYPE_ISSUE: ISSUE,
    ALERT_SEVERITY_TYPE_WARNING: WARNING,
    OPTION_ALL: ALL,
    AVAILABLE_ALERT_TYPES,
} = constants

const FILTER_AUDIT = 'auditId'
const FILTER_AUDIT_TAG = 'tag'
const FILTER_TYPE = 'type'
const FILTER_SEVERITY_TYPE = 'severityType'

const INITIAL_STATE = {
    [FILTER_AUDIT]: ALL,
    [FILTER_AUDIT_TAG]: ALL,
    [FILTER_TYPE]: ALL,
    [FILTER_SEVERITY_TYPE]: ALL,
}

export default function AlertsPageWithState() {
    useCloseDrawerOnLeave()
    const { selectedFilters, onChangeFilter, onClearFilters } =
        useFilters(INITIAL_STATE)
    const [selectedAlertStatus, setSelectedAlertStatus] = useState('ONGOING')
    const scanRangeState = useScanRangeSelect()
    const { startDate, endDate } = scanRangeState
    const { auditId, type, severityType, tag } = selectedFilters
    const {
        alerts,
        audits,
        auditTags,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch,
    } = useAlertsPageQuery(
        auditId === ALL ? null : auditId,
        type === ALL ? null : type,
        tag === ALL ? null : tag,
        selectedAlertStatus,
        severityType === ALL ? null : severityType,
        startDate,
        endDate
    )
    // const {
    //     selectedAlerts,
    //     isSelecting,
    //     onSelectAlert,
    //     onSelectAllAlerts,
    //     onStartSelectingAlerts,
    //     onStopSelectingAlerts,
    //     onIgnoreAlerts,
    //     onDelegateAlertsInternal,
    //     onClearAlertsDelegate,
    //     onDelegateAlertsExternal,
    //     onRestoreAlerts,
    // } = useSelectAlertsState({
    //     alerts,
    //     level,
    //     onAlertStatusChanged: () => refetch(),
    // })

    const filters = {
        [FILTER_AUDIT]: {
            label: <Trans>Audit</Trans>,
            iconName: 'clock',
            options: [
                { value: ALL, label: 'All audits' },
                ...audits.map(({ id, name }) => ({ value: id, label: name })),
            ],
            className: 'w-[200px]',
        },
        [FILTER_AUDIT_TAG]: {
            label: <Trans>Audit tag</Trans>,
            iconName: 'tag',
            options: [
                { value: ALL, label: 'All tags' },
                ...auditTags.map((auditTag) => ({
                    value: auditTag,
                    label: auditTag,
                })),
            ],
            className: 'w-[150px]',
        },
        [FILTER_TYPE]: {
            label: <Trans>Type</Trans>,
            iconName: 'bell',
            options: [
                { value: ALL, label: 'All types' },

                ...AVAILABLE_ALERT_TYPES.map((alertType) => ({
                    value: alertType,
                    label: getAlertStatusText({ type: alertType }),
                })),
            ],
            className: 'w-[150px]',
        },
        [FILTER_SEVERITY_TYPE]: {
            label: <Trans>Severity type</Trans>,
            iconName: 'warning-triangle',
            options: [
                { value: ALL, label: 'All severity types' },
                { value: WARNING, label: 'Warning' },
                { value: ISSUE, label: 'Issue' },
                { value: CRITICAL, label: 'Critical' },
            ],
            className: 'w-[175px]',
        },
    }

    return (
        <AlertsPage
            scanRangeState={scanRangeState}
            selectedAlertStatus={selectedAlertStatus}
            alerts={alerts}
            audits={audits}
            tags={auditTags}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            onAlertStatusChanged={() => refetch()}
            onFilterAlerts={(status) => setSelectedAlertStatus(status)}
            filters={filters}
            selectedFilters={selectedFilters}
            onChangeFilter={onChangeFilter}
            onClearFilters={onClearFilters}
        />
    )
}
