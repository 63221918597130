import React from 'react'

const ModalCloseButton = (props) => (
    <div className="absolute right-0 top-0 pr-4 pt-4">
        <button
            type="button"
            className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500"
            aria-label="Close"
            {...props}
        >
            <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                />
            </svg>
        </button>
    </div>
)

export default ModalCloseButton
