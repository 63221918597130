/* eslint-disable react/button-has-type */
import React from 'react'
import { cva } from 'cva'
import clsx from 'clsx'
import Icon from './Icon'

const IconBefore = ({ className, hasText = true, ...props }) => (
    <Icon className={className} {...props} />
)

const IconAfter = ({ className, hasText = true, ...props }) => (
    <Icon className={className} {...props} />
)

const buttonVariant = cva(
    'flex items-center justify-center font-semibold transition duration-150 ease-in-out',
    {
        variants: {
            type: {
                primary:
                    'bg-primary text-white shadow-xs enabled:hover:bg-primary-700',
                danger: 'bg-red-600 text-white shadow-xs enabled:hover:bg-red-700',
                white: 'border border-slate-300 bg-white text-slate-700 shadow-xs active:text-slate-800 enabled:hover:bg-slate-100 disabled:border-slate-300',
                gray: 'border border-slate-300 bg-slate-100 text-slate-900 shadow-xs active:text-slate-800 enabled:hover:bg-slate-200 disabled:border-slate-300',
                link: 'font-medium',
            },
            size: {
                sm: 'gap-1 text-sm',
                md: 'gap-2 text-sm',
                lg: 'gap-2 text-lg',
                xl: 'gap-2',
                '2xl': 'gap-2 text-lg',
            },
            grouped: {
                none: 'rounded-lg',
                left: 'rounded-l-lg',
                center: '-ml-px',
                right: '-ml-px rounded-r-lg',
            },
        },
        compoundVariants: [
            {
                size: 'sm',
                isIconButton: true,
                className: 'p-2',
            },
            {
                size: 'sm',
                isIconButton: false,
                className: 'px-3 py-2',
            },
            {
                size: 'md',
                isIconButton: true,
                className: 'p-[0.625rem]',
            },
            {
                size: 'md',
                isIconButton: false,
                className: 'px-[0.875rem] py-[0.625rem]',
            },
            {
                size: 'lg',
                isIconButton: true,
                className: 'p-3',
            },
            {
                size: 'lg',
                isIconButton: false,
                className: 'px-4 py-3',
            },
            {
                size: 'xl',
                isIconButton: true,
                className: 'p-[0.875rem]',
            },
            {
                size: 'xl',
                isIconButton: false,
                className: 'px-[1.125rem] py-[0.875rem]',
            },
            {
                size: '2xl',
                isIconButton: true,
                className: 'p-[1.125rem]',
            },
            {
                size: '2xl',
                isIconButton: false,
                className: 'px-[1.375rem] py-[1.125rem]',
            },
            {
                size: ['sm', 'md', 'lg', 'xl', '2xl'],
                type: 'link',
                className: 'px-0',
            },
        ],
        defaultVariants: {
            size: 'md',
            grouped: 'none',
        },
    }
)

const Button = React.forwardRef(
    (
        {
            as = 'button',
            className,
            children,
            size = 'md',
            type = 'primary',
            grouped = 'none',
            loading,
            disabled,
            iconBeforeProps,
            iconAfterProps,
            ...props
        },
        ref
    ) => {
        const Component = as
        const isIconButton = !children
        return (
            <Component
                ref={ref}
                type={as === 'button' ? type : 'button'}
                className={clsx(
                    buttonVariant({
                        type,
                        size,
                        grouped,
                        isIconButton,
                    }),
                    loading && 'opacity-75',
                    (loading || disabled) && 'cursor-not-allowed opacity-50',
                    className
                )}
                disabled={disabled || loading}
                {...props}
            >
                {loading && (
                    <Icon
                        name="spinner"
                        className="mr-2 animate-spin"
                        color="white"
                    />
                )}
                {!loading && iconBeforeProps && (
                    <IconBefore {...iconBeforeProps} />
                )}
                {children}
                {iconAfterProps && <IconAfter {...iconAfterProps} />}
            </Component>
        )
    }
)
export default Button
