import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import * as constants from '../../../constants'
import { isRequired } from '../../../utils/validation'

const { AUDIT_SCAN_FREQUENCY_DAILY: DAILY } = constants

const validation = createFormValidation([
    {
        path: 'scanFrequency',
        validate: isRequired,
        message: <Trans>Please choose a frequency.</Trans>,
    },
    {
        path: 'takeScreenshots',
        validate: isRequired,
        message: <Trans>Please choose a value for take screenshots.</Trans>,
    },
])

const valuesToInput = (values) => values

const useUpdateSettingsAuditFormState = ({
    scanFrequency = DAILY,
    takeScreenshots,
}) =>
    useFormState(
        {
            scanFrequency,
            takeScreenshots,
        },
        {
            validation,
            valuesToInput,
        }
    )

export default useUpdateSettingsAuditFormState
