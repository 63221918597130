import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteAuditModal from './DeleteAuditModal'
import useDeleteAuditMutation from './useDeleteAuditMutation'

const DeleteAuditModalWithState = ({
    audit,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteAudit, { loading }] = useDeleteAuditMutation({
        onCompleted,
        onError,
    })
    return (
        <DeleteAuditModal
            onDismiss={onDismiss}
            audit={audit}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await deleteAudit({ variables: { id: audit.id } })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>{audit.name} successfully deleted.</Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteAuditModalWithState
