import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import useCookieTableItemPageQuery from './useCookieTableItemPageQuery'
import CookieTableItemsPage from './CookieTableItemsPage'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import * as constants from '../../../constants'

const QUERY = gql`
    query cookieTable($id: ID!) {
        cookieTable(id: $id) {
            id
            name
            latestVersion {
                id
                name
                audits {
                    id
                    name
                    imageUrl
                }
            }
        }
    }
`

const CookieTablesPageWithState = () => {
    const { id } = useParams()
    const history = useHistory()
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const [orderBy, onChangeOrderBy] = useState({
        occurrence: 'DESC',
    })

    const {
        cookieTableItems,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch: refetchCookieTableItems,
    } = useCookieTableItemPageQuery({
        cookieTableId: id,
        search,
        orderBy,
    })

    const { data = {}, refetch: refetchCookieTable } = useQuery(QUERY, {
        variables: { id },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    useRefetch(refetchCookieTableItems)

    return (
        <CookieTableItemsPage
            cookieTable={data.cookieTable}
            cookieTableItems={cookieTableItems}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            search={search}
            onSearch={handleSearch}
            initialOrderBy={orderBy}
            onDeleteCookieTableCompleted={() => {
                history.push('/vendors/cookie-tables')
            }}
            onChangeOrderBy={(value, direction) =>
                onChangeOrderBy({ [value]: direction })
            }
            onUpdateCookieTableCompleted={() => refetchCookieTable()}
            onCreateCookieTableVersionCompleted={() => {
                refetchCookieTable()
            }}
            onUpdateCookieTableItemsCompleted={() => {
                refetchCookieTableItems()
            }}
        />
    )
}

export default CookieTablesPageWithState
