import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import PageHeader from '../../layout/PageHeader'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditTrackerPageActions from './AuditTrackersPageActions'
import AuditTrackersPageTable from './AuditTrackersPageTable'
import AuditVendorPageSidebar from '../audits-vendors/AuditVendorPageSidebar'

export default function AuditTrackersPage({
    audit,
    purposes,
    trackers,
    audits,
    search,
    selectedPurposeId,
    scanRangeState,
    startDate,
    endDate,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    initialOrderBy,
    onChangeOrderBy,
    onSearch,
    onChangePurpose,
    onFetchMore,
    onClassifyTrackerCompleted,
    onDeleteTrackerCompleted,
    onScanCompleted,
}) {
    return (
        <PageContainer data-testid="AuditTrackersPage">
            <AuditHeader />
            <SidebarContainer>
                <AuditVendorPageSidebar id={audit.id} />
                <PageContent size="full">
                    <PageHeader
                        title={<Trans>Trackers</Trans>}
                        description={
                            <Trans>
                                Here you can see the trackers that are present
                                in your domain.
                            </Trans>
                        }
                    />
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <AuditTrackerPageActions
                            purposes={purposes}
                            audits={audits}
                            search={search}
                            scanRangeState={scanRangeState}
                            selectedPurposeId={selectedPurposeId}
                            onSearch={onSearch}
                            onChangePurpose={onChangePurpose}
                        />
                        <AuditTrackersPageTable
                            audit={audit}
                            trackers={trackers}
                            isFetchingMore={isFetchingMore}
                            isFetching={isFetching}
                            hasMoreRecords={hasMoreRecords}
                            onFetchMore={onFetchMore}
                            startDate={startDate}
                            endDate={endDate}
                            onClassifyTrackerCompleted={
                                onClassifyTrackerCompleted
                            }
                            onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
