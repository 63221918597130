import React from 'react'

const LoginPageCard = ({ children, ...props }) => (
    <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md" {...props}>
        <div className="bg-white py-12 px-4 shadow sm:rounded-lg sm:px-10">
            {children}
        </div>
    </div>
)

export default LoginPageCard
