import React from 'react'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import VendorDetailsPageHeader from '../staff-vendor-details/VendorDetailsPageHeader'
import VendorCookiesPageTable from './VendorCookiesPageTable'
import StaffHeader from '../../layout/StaffHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import VendorsPageSidebar from '../staff-vendors/VendorsPageSidebar'

const VendorsCookiesPage = ({
    vendor,
    cookies,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => (
    <PageContainer data-testid="VendorsCookiesPage">
        <StaffHeader />
        <SidebarContainer>
            <VendorsPageSidebar />
            <PageContent>
                <VendorDetailsPageHeader vendor={vendor} />
                <VendorCookiesPageTable
                    cookies={cookies}
                    isFetchingMore={isFetchingMore}
                    onFetchMore={onFetchMore}
                    hasMoreRecords={hasMoreRecords}
                    isFetching={isFetching}
                />
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default VendorsCookiesPage
