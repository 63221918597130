/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DeleteCookieTableModalWithState from '../vendors-cookie-tables/DeleteCookieTableModalWithState'
import useCreateCookieTableReportMutation from '../reports/useCreateCookieTableReportMutation'
import useEvents from '../../hooks/useEvents'
import Tag from '../../utils/Tag'

const CookieTableItemsPageMenuDotsButton = ({
    cookieTable,
    onDeleteCompleted,
}) => {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [createReport] = useCreateCookieTableReportMutation()
    const [reportInfo, setReportInfo] = React.useState(null)

    useEvents(['report:completed'], (event) => {
        if (!reportInfo) return

        const { id: reportId, type: reportType } =
            reportInfo.data.createCookieTableReport.report
        const { id: eventId, type: eventType, file } = event.report

        if (reportId === eventId && reportType === eventType) {
            setLoading(false)
            window.open(file.url, '_blank', 'noopener')
        }
    })

    const handleCreateReport = async () => {
        const input = { cookieTableId: cookieTable.id }
        const variables = { input }
        setLoading(true)

        try {
            const response = await createReport({ variables })
            setReportInfo(response)
        } catch (error) {
            console.error('Error creating report:', error)
        }
    }

    return (
        <>
            {deleteModalIsOpen && (
                <DeleteCookieTableModalWithState
                    cookieTable={cookieTable}
                    onDismiss={() => setDeleteModalIsOpen(false)}
                    onCompleted={onDeleteCompleted}
                />
            )}
            <DropdownMenu
                position="bottom-end"
                content={() => (
                    <>
                        <DropdownMenuItem
                            iconName="download"
                            disabled={isLoading}
                            isLoading={isLoading}
                            onClick={() => handleCreateReport()}
                        >
                            <Trans>Export cookie table</Trans>
                            <Tag type="green" className="ml-2">
                                .xlsx
                            </Tag>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={() => setDeleteModalIsOpen(true)}
                        >
                            <Trans>Delete cookie table</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                <Button
                    className="h-[40px]"
                    type="white"
                    iconBeforeProps={{
                        name: 'menu-dots',
                        size: 'sm',
                    }}
                />
            </DropdownMenu>
        </>
    )
}

export default CookieTableItemsPageMenuDotsButton
