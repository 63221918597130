import React from 'react'
import { Trans } from '@lingui/macro'
import LoadingIndicator from '../../utils/LoadingIndicator'
import Icon from '../../utils/Icon'
import AlertActivityListItemWithState from './AlertActivityListItemWithState'

const DashboardPageAlertsActivities = ({
    isFetching,
    activities = [],
    onAlertStatusChanged,
    onComment,
    startDate,
    endDate,
}) => (
    <div className="mb-8 flex max-h-[610px] flex-col gap-4 rounded-2xl border border-gray-200 bg-white py-6">
        {(() => {
            if (isFetching) {
                return (
                    <div className="flex h-[300px] items-center justify-center">
                        <LoadingIndicator />
                    </div>
                )
            }
            return (
                <>
                    <div className="mb-2 flex justify-between">
                        <div>
                            <h3 className="px-6 font-semibold">
                                <Trans>Recent activities</Trans>
                            </h3>
                        </div>
                        <div />
                    </div>
                    <div className="flex min-h-[300px] flex-1 flex-col overflow-auto">
                        {activities.length === 0 ? (
                            <div className="flex flex-col items-center justify-center gap-4">
                                <Icon name="inbox" size="2xl" />
                                <h2>No recent activities</h2>
                            </div>
                        ) : (
                            <ul className="flex w-full flex-col">
                                {activities.map((activity) => (
                                    <AlertActivityListItemWithState
                                        key={activity.id}
                                        activity={activity}
                                        onAlertStatusChanged={
                                            onAlertStatusChanged
                                        }
                                        startDate={startDate}
                                        endDate={endDate}
                                        onComment={onComment}
                                    />
                                ))}
                            </ul>
                        )}
                    </div>
                </>
            )
        })()}
    </div>
)

export default DashboardPageAlertsActivities
