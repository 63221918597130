import React from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import VendorTypeTags from '../../utils/VendorTypeTags'
import DrawerDetailsItem, {
    DrawerDetailsItemText,
} from '../../utils/DrawerDetailsItem'
import Flag from '../../utils/Flag'

export default function VendorDetailsDrawerDetailsTab({ vendor }) {
    const {
        domain,
        activities,
        description,
        sourceUrl,
        websiteUrl,
        privacyPolicyUrl,
        cookiePolicyUrl,
        types,
        headQuarterLocations = [],
        dataCenterLocations = [],
        otherLocations = [],
    } = vendor
    return (
        <>
            <DrawerDetailsItem isHorizontal label={<Trans>Types</Trans>}>
                <VendorTypeTags showAll types={types} />
            </DrawerDetailsItem>
            {description && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Description</Trans>}
                >
                    {description}
                </DrawerDetailsItemText>
            )}
            {activities && activities.length > 0 && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Activities</Trans>}
                >
                    {activities !== null &&
                        activities.map((activity) => (
                            <Tag className="mr-2" type="primary">
                                {activity}
                            </Tag>
                        ))}
                </DrawerDetailsItemText>
            )}
            {domain && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Domain</Trans>}
                >
                    {domain}
                </DrawerDetailsItemText>
            )}
            {websiteUrl && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Website</Trans>}
                >
                    <a
                        className="text-primary"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={websiteUrl}
                    >
                        {websiteUrl}
                    </a>
                </DrawerDetailsItemText>
            )}
            {privacyPolicyUrl && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Privacy policy</Trans>}
                >
                    <a
                        className="text-primary"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={privacyPolicyUrl}
                    >
                        {privacyPolicyUrl}
                    </a>
                </DrawerDetailsItemText>
            )}
            {cookiePolicyUrl && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Cookie policy</Trans>}
                >
                    <a
                        className="text-primary"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={cookiePolicyUrl}
                    >
                        {cookiePolicyUrl}
                    </a>
                </DrawerDetailsItemText>
            )}
            {sourceUrl && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Source</Trans>}
                >
                    <a
                        className="text-primary"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={sourceUrl}
                    >
                        {sourceUrl}
                    </a>
                </DrawerDetailsItemText>
            )}
            {headQuarterLocations.length > 0 && (
                <DrawerDetailsItem label={<Trans>Headquarters</Trans>}>
                    <div className="flex">
                        {headQuarterLocations.map((countryCode) => (
                            <Flag
                                key={countryCode}
                                className="mr-2"
                                countryCode={countryCode}
                            />
                        ))}
                    </div>
                </DrawerDetailsItem>
            )}
            {dataCenterLocations.length > 0 && (
                <DrawerDetailsItem label={<Trans>Data centers</Trans>}>
                    <div className="flex">
                        {dataCenterLocations.map((countryCode) => (
                            <Flag
                                key={countryCode}
                                className="mr-2"
                                countryCode={countryCode}
                            />
                        ))}
                    </div>
                </DrawerDetailsItem>
            )}
            {otherLocations.length > 0 && (
                <DrawerDetailsItem label={<Trans>Other locations</Trans>}>
                    <div className="flex">
                        {otherLocations.map((countryCode) => (
                            <Flag
                                key={countryCode}
                                className="mr-2"
                                countryCode={countryCode}
                            />
                        ))}
                    </div>
                </DrawerDetailsItem>
            )}
        </>
    )
}
