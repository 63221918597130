import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import Icon from '../utils/Icon'

export default function HeaderMenuItem({
    to,
    matchPath,
    exact = false,
    strict = false,
    iconName,
    children,
}) {
    const isActive = useRouteMatch({
        path: matchPath || to,
        exact,
        strict,
    })
    return (
        <li>
            <Link
                to={to}
                className={clsx(
                    'flex items-center gap-2 text-sm  font-medium  hover:text-slate-800 hover:bg-gray-100 transition-colors duration-200 group py-2 px-3 xl:px-4 rounded-lg text-nowrap',
                    isActive ? 'font-bold text-slate-800' : 'text-slate-600'
                )}
            >
                <div className="relative">
                    <Icon
                        name={iconName}
                        size="sm"
                        className={clsx(
                            isActive ? 'text-primary-600' : 'text-slate-600'
                        )}
                    />
                    <div
                        className={clsx(
                            'absolute bottom-[-23px] left-1/2 h-[2px] bg-primary-600 transition-width duration-200 transform -translate-x-1/2',
                            isActive ? 'w-full' : 'w-0'
                        )}
                    />
                </div>
                <span className="hidden sm:inline">{children}</span>
            </Link>
        </li>
    )
}
