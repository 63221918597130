import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import LoginProfilesPageTableRow from './LoginProfilesPageTableRow'
import LoginProfilesPageTableHeaderRow from './LoginProfilesPageTableHeaderRow'

const LoginProfilesPageTable = ({
    loginProfiles,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="LoginProfilesPageTable">
            <thead>
                <LoginProfilesPageTableHeaderRow />
            </thead>
            <TableBody>
                {loginProfiles.map((loginProfile) => (
                    <LoginProfilesPageTableRow
                        key={loginProfile.id}
                        loginProfile={loginProfile}
                        onDeleteCompleted={onDeleteCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default LoginProfilesPageTable
