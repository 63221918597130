import React from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import TableCell from '../../utils/TableCell'
import AvatarGroup from '../../utils/AvatarGroup'
import StaffUnclassifiedTrackersPageTableRowActionButtonWithState from './StaffUnclassifiedTrackersPageTableRowActionButtonWithState'
import TableRow from '../../utils/TableRow'

const FRAGMENT = gql`
    fragment StaffUnclassifiedTrackersPageTableRow on StaffUnclassifiedTracker {
        rootDomain
        totalCount
        organizations {
            id
            name
            imageUrl
        }
        audits {
            id
            name
            imageUrl
        }
    }
`

const StaffUnclassifiedTrackersPageTableRow = ({
    unclassifiedTracker,
    onClassifyTrackerCompleted,
}) => {
    const history = useHistory()
    const { rootDomain, totalCount, audits, organizations } =
        unclassifiedTracker
    return (
        <TableRow
            isSelectable
            selectionValue={unclassifiedTracker}
            selectionCriteria={(row) =>
                row.rootDomain === unclassifiedTracker.rootDomain
            }
            data-testid="unclassifiedTrackerPageTableRow"
            onClick={() => {
                history.push(`/staff/unclassifiedTrackers/${rootDomain}`)
            }}
        >
            <TableCell hasText className="max-w-xs truncate" columnId="domain">
                <div className="mx-4">{rootDomain}</div>
            </TableCell>
            <TableCell
                hasText
                className="max-w-xs truncate"
                columnId="occurence"
            >
                <Tag>{totalCount}</Tag>
            </TableCell>
            <TableCell className="" columnId="organizations">
                {organizations.length > 0 && (
                    <AvatarGroup
                        imageUrls={organizations.map(
                            ({ imageUrl }) => imageUrl
                        )}
                    />
                )}
            </TableCell>
            <TableCell className="" columnId="audtis">
                {audits.length > 0 && (
                    <AvatarGroup
                        imageUrls={audits.map(({ imageUrl }) => imageUrl)}
                    />
                )}
            </TableCell>
            <TableCell className="text-right">
                <StaffUnclassifiedTrackersPageTableRowActionButtonWithState
                    scanTracker={unclassifiedTracker}
                    onClassifyTrackerCompleted={onClassifyTrackerCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

StaffUnclassifiedTrackersPageTableRow.FRAGMENT = FRAGMENT

export default StaffUnclassifiedTrackersPageTableRow
