import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateCustomCmpModal from './CreateCustomCmpModal'
import useCreateCustomCmpMutation from './useCreateCustomCmpMutation'
import useCreateCustomCmpFormState from './useCreateCustomCmpFormState'

const CreateCustomCmpModalWithState = ({ onDismiss, onCompleted, onError }) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const formState = useCreateCustomCmpFormState()
    const [createCustomCmp] = useCreateCustomCmpMutation({
        onCompleted,
        onError,
    })
    return (
        <CreateCustomCmpModal
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { name } = formState.values
                        const input = { name }
                        await createCustomCmp({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>New cmp {name} created!</Trans>,
                        })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0]
                        dispatchError({ message })
                    }
                }
            }}
        />
    )
}

export default CreateCustomCmpModalWithState
