import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteTrackerModal = ({ tracker, isLoading, onDismiss, onConfirm }) => (
    <ConfirmModal
        title={<Trans>Remove tracker classification</Trans>}
        description={
            tracker && (
                <Trans>
                    Are you sure you want to delete{' '}
                    <strong>{tracker.domain}</strong> for all organizations?
                </Trans>
            )
        }
        confirmButtonText={<Trans>Delete tracker</Trans>}
        isLoading={isLoading}
        isDisabled={isLoading}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
    />
)

export default DeleteTrackerModal
