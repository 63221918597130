import { useState } from 'react'

const useFilters = (values = {}) => {
    const [activeFilters, setActiveFilters] = useState([])
    const [selectedFilters, setSelectedFilters] = useState(values)

    const handleChangeFilter = (key, value) => {
        setSelectedFilters((state) => ({
            ...state,
            [key]: value,
        }))
    }

    const handleClearFilters = () => setSelectedFilters(values)
    return {
        selectedFilters,
        onChangeFilter: handleChangeFilter,
        onClearFilters: handleClearFilters,
        activeFilters,
        filterValues: activeFilters.reduce((acc, name) => {
            if (values[name]) {
                acc[name] = values[name]
            }
            return acc
        }, {}),
        onAddFilter: (name) => setActiveFilters((state) => [...state, name]),
        onRemoveFilter: (name) =>
            setActiveFilters((state) => state.filter((item) => item !== name)),
        onRemoveAllFilters: () => setActiveFilters([]),
    }
}

export default useFilters
