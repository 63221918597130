import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import useCookieTableItemPageQuery from './useCookieTableItemPageQuery'
import CookieTableVersionCompareModalTable from './CookieTableVersionCompareModalTable'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalFooter from '../../utils/ModalFooter'

const compareCookieTables = (newArray, oldArray) => {
    const isEqual = (item1, item2) => {
        if (item1.cookie && item2.cookie) {
            return item1.cookie.id === item2.cookie.id
        }
        return (
            item1.name === item2.name && item1.rootDomain === item2.rootDomain
        )
    }

    // First find all deprecated items
    const deprecatedStatusCookies = newArray.filter(
        (newItem) => newItem.status === 'DEPRECATED'
    )

    // Find truly new items (not in old array AND not deprecated)
    const newComparedCookies = newArray.filter(
        (newItem) =>
            (!oldArray.some((oldItem) => isEqual(newItem, oldItem)) || // Completely new items
                oldArray.some(
                    (oldItem) =>
                        isEqual(newItem, oldItem) && oldItem.status !== 'ADDED'
                )) && // OR items that were re-added
            newItem.status === 'ADDED' &&
            newItem.status !== 'DEPRECATED'
    )

    // Find deleted items (in old array but not in new array)
    // BUT exclude items that were re-added (status changed to ADDED)
    const deletedComparedCookies = oldArray.filter(
        (oldItem) =>
            oldItem.status === 'ADDED' &&
            !newArray.some((newItem) => isEqual(newItem, oldItem))
    )

    const sameComparedCookies = newArray.filter(
        (newItem) =>
            newItem.status !== 'DEPRECATED' &&
            newItem.status === 'ADDED' &&
            !newComparedCookies.some((newCookie) =>
                isEqual(newCookie, newItem)
            ) &&
            !deletedComparedCookies.some((removedItem) =>
                isEqual(removedItem, newItem)
            ) &&
            oldArray.some(
                (oldItem) =>
                    isEqual(newItem, oldItem) && oldItem.status === 'ADDED'
            )
    )

    return {
        newComparedCookies,
        sameComparedCookies,
        deletedComparedCookies,
        deprecatedStatusCookies,
    }
}

const CookieTableVersionCompareModal = ({
    versionId,
    cookieTableId,
    onDismiss,
    onCreate,
    onCancel,
}) => {
    const {
        refetch: refetchLatestCookieTableItems,
        cookieTableItems: latestCookieTableItems,
        isFetching: isFetchingLatestVersion,
    } = useCookieTableItemPageQuery({
        cookieTableId,
    })
    const {
        refetch: refetchComparingCookieTableItems,
        cookieTableItems: comparingCookieTableItems,
        isFetching: isFetchingOldVersion,
    } = useCookieTableItemPageQuery({
        cookieTableId,
        cookieTableVersionId: versionId,
    })

    const isLoading = isFetchingLatestVersion || isFetchingOldVersion
    const {
        newComparedCookies,
        sameComparedCookies,
        deletedComparedCookies,
        deprecatedStatusCookies,
    } = compareCookieTables(comparingCookieTableItems, latestCookieTableItems)

    return (
        <Modal className="max-w-3xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create new cookie table version</Trans>}
                description={
                    <Trans>
                        In the overview below you can compare and adjust the
                        differences between the previous version
                    </Trans>
                }
                iconName="cookie"
                iconColor="none"
                iconSize="md"
                onClose={onCancel}
                newModalTitle
            />
            <div>
                <CookieTableVersionCompareModalTable
                    deletedCookies={deletedComparedCookies}
                    sameCookies={sameComparedCookies}
                    newCookies={newComparedCookies}
                    deprecatedCookies={deprecatedStatusCookies}
                    isFetching={isLoading}
                    onUpdateCompleted={() => {
                        refetchLatestCookieTableItems()
                        refetchComparingCookieTableItems()
                    }}
                />

                <ModalFooter>
                    <Button type="white" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={onCreate}>
                        <Trans>Create</Trans>
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    )
}

export default CookieTableVersionCompareModal
