import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

export default function IdentifiedScanCookieDetailsDrawerTabInstances({
    identifiedCookieId,
}) {
    if (!identifiedCookieId) {
        return (
            <DrawerEmptyCard
                iconName="scan"
                title={<Trans>No Information</Trans>}
                description={<Trans>There was no information found.</Trans>}
            />
        )
    }

    return (
        <DrawerEmptyCard
            iconName="scan"
            title={<Trans>No Information</Trans>}
            description={<Trans>There was no information found.</Trans>}
        />
    )
}
