/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormButtonGroup from '../../utils/FormButtonGroup'
import PurposesSelect from '../../utils/PurposesSelect'
import VendorSearchSelect from '../../utils/VendorSearchSelect'

const CreateOrganizationCookieModal = ({
    title = <Trans>Create new cookie</Trans>,
    description = <Trans>Fill in all details about the cookie below.</Trans>,
    originalCookieName,
    purposes = [],
    isUpdating,
    initialVendor,
    formState,
    onDismiss,
    onSubmit,
    canCreateVendor,
}) => {
    const { values, handleChange, getErrorMessages, getNativeInputProps } =
        formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-xl">
            <ModalTitle
                title={title}
                description={description}
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Description</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('description')}
                    {...getNativeInputProps('description')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Root Domain</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('domain')}
                    {...getNativeInputProps('domain')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Regex</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('regex')}
                    {...getNativeInputProps('regex')}
                />
                {originalCookieName && (
                    <div className="text-xs mt-1 text-gray-600">
                        <Trans>Original name:</Trans> {originalCookieName}
                    </div>
                )}
            </FormGroup>
            <FormGroup label={<Trans>Vendor</Trans>} className="mb-4">
                <VendorSearchSelect
                    isOrganizationContext
                    value={values.vendorId}
                    initialVendor={initialVendor}
                    canCreateVendor={canCreateVendor}
                    errors={getErrorMessages('vendorId')}
                    onChange={(id) => handleChange('vendorId', id)}
                />
            </FormGroup>
            <FormGroup label={<Trans>Purposes</Trans>}>
                <PurposesSelect
                    purposes={purposes}
                    value={values.purposeIds}
                    errors={getErrorMessages('purposeIds')}
                    onChange={(ids) => handleChange('purposeIds', ids)}
                />
            </FormGroup>
            <FormButtonGroup>
                <Button
                    onClick={onSubmit}
                    disabled={isUpdating}
                    loading={isUpdating}
                >
                    <Trans>Done</Trans>
                </Button>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
            </FormButtonGroup>
        </Modal>
    )
}

export default CreateOrganizationCookieModal
