/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DropdownMenuTitle from '../../utils/DropdownMenuTitle'
import Input from '../../utils/Input'

const AlertsPageDelegateDropdown = ({
    users,
    onDelegateExternal,
    onSelectUser,
    onClearDelegation,
    onOpenDropdownMenu,
    onCloseDropdownMenu,
    canClearDelegation,
    onSearch,
    children,
}) => (
    <DropdownMenu
        dropdownClassName="w-[300px] max-h-[400px] overflow-y-auto"
        position="bottom-start"
        onOpen={onOpenDropdownMenu}
        onClose={onCloseDropdownMenu}
        content={({ close }) => (
            <>
                <DropdownMenuTitle>
                    <Trans>Users</Trans>
                </DropdownMenuTitle>
                <div className="px-4 py-2">
                    <Input
                        iconNameBefore="search"
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        onChange={(e) => onSearch(e.target.value)}
                    />
                </div>
                {users.map((user) => (
                    <DropdownMenuItem
                        key={user.id}
                        iconName="user"
                        onClick={(e) => {
                            e.stopPropagation()
                            onSelectUser(user)
                            close()
                        }}
                    >
                        {user.fullName}
                    </DropdownMenuItem>
                ))}
                {/* <DropdownMenuItem
                    iconName="send-email"
                    onClick={async (e) => {
                        e.stopPropagation()
                        onDelegateExternal()
                        close()
                    }}
                >
                    <Trans>Delegate to external</Trans>
                </DropdownMenuItem> */}
                {canClearDelegation && (
                    <>
                        <DropdownMenuTitle>
                            <Trans>Other actions</Trans>
                        </DropdownMenuTitle>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={async (e) => {
                                e.stopPropagation()
                                onClearDelegation()
                                close()
                            }}
                        >
                            <Trans>Clear delegation</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            </>
        )}
    >
        {children}
    </DropdownMenu>
)

export default AlertsPageDelegateDropdown
