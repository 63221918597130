import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { MY_ALERT_DELEGATION_FRAGMENT } from './MyAlertListItemWithState'
import useFetchMore from '../../hooks/useFetchMore'

const QUERY = gql`
    query myAlertsQuery($after: String) {
        myAlertDelegations(after: $after) {
            edges {
                node {
                    ...MyAlertDelegation
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${MY_ALERT_DELEGATION_FRAGMENT}
`

export default function useDashboardPageMyAlertsQuery() {
    const variables = {}
    const {
        data = {},
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'myAlertDelegations'
    )

    return {
        isFetching,
        isFetchingMore,
        hasNextPage,
        myAlertDelegations:
            data.myAlertDelegations && data.myAlertDelegations.edges,
        refetch,
        onFetchMore: handleFetchMore,
    }
}
