import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import ButtonLink from '../../utils/ButtonLink'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import AlertsBySubjectListItem from './AlertsBySubjectListItem'
import VendorDetailsDrawer from '../staff-vendors/VendorDetailsDrawer'

const DashboardPageAlertsByVendorChart = ({
    startDate,
    endDate,
    vendor,
    alertCount,
    maxAlertCount,
}) => {
    const { open } = useDrawerStack()
    const [isModalOpen, setModalIsOpen] = useState(false)
    return (
        <AlertsBySubjectListItem
            iconName="building-07"
            key={vendor.id}
            name={
                <PopoverVendorInfo vendor={vendor}>
                    <ButtonLink
                        as="button"
                        onClick={() => {
                            open(VendorDetailsDrawer, {
                                vendorId: vendor.id,
                            })
                        }}
                    >
                        {vendor.name}
                    </ButtonLink>
                </PopoverVendorInfo>
            }
            imageUrl={vendor.imageUrl}
            alertCount={alertCount}
            maxAlertCount={maxAlertCount}
            // onHandleClick={() => {
            //     open(IdentifiedVendorDetailsDrawer, {
            //         identifiedVendorId: vendor.id,
            //     })
            // }}
        />
    )
}

export default DashboardPageAlertsByVendorChart
