import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query updateCookieTablePage($id: ID!) {
        cookieTable(id: $id) {
            id
            name
            versions {
                id
                name
                status
                startDate
                endDate
                period
                createdAt
            }
            latestVersion {
                id
                name
                status
                startDate
                endDate
                period
                createdAt
                updatedAt
                audits {
                    id
                    name
                }
            }
        }
    }
`

const useUpdateCookieTablePageQuery = ({ cookieTableId: id }) => {
    const variables = { id }
    const {
        data,
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        cookieTable: data?.cookieTable || {},
        isFetching,
        refetch,
    }
}

export default useUpdateCookieTablePageQuery
