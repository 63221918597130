/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DropdownMenuItemDivider from '../../utils/DropdownMenuItemDivider'
import DropdownMenuTitle from '../../utils/DropdownMenuTitle'

const IdentifiedVendorsPageTableRowActionsStaff = ({
    vendor,
    isLoading,
    onUpdate,
    onUpdateOrganization,
    children,
}) => (
    <DropdownMenu
        as="span"
        position="bottom-end"
        content={({ close }) => (
            <>
                <DropdownMenuTitle>
                    <Trans>Admin actions</Trans>
                </DropdownMenuTitle>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={vendor.belongsToOrganization}
                    onClick={(e) => {
                        onUpdate(vendor)
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Update classification</Trans>
                </DropdownMenuItem>
                <DropdownMenuItemDivider />
                <DropdownMenuTitle>
                    <Trans>Organization actions</Trans>
                </DropdownMenuTitle>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={!vendor.belongsToOrganization}
                    onClick={(e) => {
                        onUpdateOrganization(vendor)
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Update organization classification</Trans>
                </DropdownMenuItem>
            </>
        )}
    >
        {children}
    </DropdownMenu>
)

export default IdentifiedVendorsPageTableRowActionsStaff
