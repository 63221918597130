import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import ReportsPageTableRow from './ReportsPageTableRow'

const QUERY = gql`
    query reportsPage($after: String) {
        reports(after: $after) {
            edges {
                node {
                    ...ReportsPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${ReportsPageTableRow.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useReportsPageQuery = () => {
    const variables = {}
    const {
        data,
        error,
        loading: isFetching,
        fetchMore,
        ...result
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'reports'
    )
    if (error) {
        return {
            error,
            ...result,
        }
    }
    if (isFetching) {
        return {
            reports: [],
            audits: [],
            auditTags: [],
            isFetching,
            ...result,
        }
    }
    return {
        error,
        reports: connectionToCollection(data.reports),
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
        ...result,
    }
}

export default useReportsPageQuery
