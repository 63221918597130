import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import FormGroup from '../../utils/FormGroup'
import FormSelect from '../../utils/FormSelect'
import FormInput from '../../utils/FormInput'
import Button from '../../utils/Button'
import FormButtonGroup from '../../utils/FormButtonGroup'
import Toggle from '../../utils/Toggle'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'

const {
    AVAILABLE_COUNTRIES,
    AVAILABLE_LANGUAGES,
    AVAILABLE_DATE_FORMATS,
    AVAILABLE_TIME_FORMATS,
    AVAILABLE_TIME_ZONES,
} = constants

const SettingsPageFormCard = ({ formState, isLoading, onSubmit }) => (
    <Card className="mb-8">
        <form>
            <CardHeader
                title={<Trans>Organization settings</Trans>}
                description={
                    <Trans>
                        Below you can edit all basic information related to
                        organization.
                    </Trans>
                }
            />
            <div className="divide-y">
                <FormGroup isHorizontal label={<Trans>Name</Trans>}>
                    <FormInput
                        className="max-w-md"
                        errors={formState.getErrorMessages('name')}
                        {...formState.getNativeInputProps('name')}
                    />
                </FormGroup>
                <FormGroup isHorizontal label={<Trans>Website url</Trans>}>
                    <FormInput
                        className="max-w-md"
                        errors={formState.getErrorMessages('websiteUrl')}
                        {...formState.getNativeInputProps('websiteUrl')}
                    />
                </FormGroup>
                <FormGroup isHorizontal label={<Trans>Country code</Trans>}>
                    <FormSelect
                        className="max-w-xs"
                        disabled
                        errors={formState.getErrorMessages('countryCode')}
                        {...formState.getNativeInputProps('countryCode')}
                    >
                        {AVAILABLE_COUNTRIES.map((code) => (
                            <option key={code} value={code}>
                                {code}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormGroup isHorizontal label={<Trans>Timezone</Trans>}>
                    <FormSelect
                        className="max-w-xs"
                        disabled
                        errors={formState.getErrorMessages('timeZone')}
                        {...formState.getNativeInputProps('timeZone')}
                    >
                        {AVAILABLE_TIME_ZONES.map((code) => (
                            <option key={code} value={code}>
                                {code}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormGroup isHorizontal label={<Trans>Default language</Trans>}>
                    <FormSelect
                        className="max-w-xs"
                        disabled
                        errors={formState.getErrorMessages('languageCode')}
                        {...formState.getNativeInputProps('languageCode')}
                    >
                        {AVAILABLE_LANGUAGES.map((code) => (
                            <option key={code} value={code}>
                                {code}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Default date format</Trans>}
                >
                    <FormSelect
                        className="max-w-xs"
                        disabled
                        errors={formState.getErrorMessages('dateFormat')}
                        {...formState.getNativeInputProps('dateFormat')}
                    >
                        {AVAILABLE_DATE_FORMATS.map((code) => (
                            <option key={code} value={code}>
                                {code}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Default time format</Trans>}
                >
                    <FormSelect
                        className="max-w-xs"
                        disabled
                        errors={formState.getErrorMessages('timeFormat')}
                        {...formState.getNativeInputProps('timeFormat')}
                    >
                        {AVAILABLE_TIME_FORMATS.map((code) => (
                            <option key={code} value={code}>
                                {code}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                        className="w-md"
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                </FormButtonGroup>
            </div>
        </form>
    </Card>
)

export default SettingsPageFormCard
