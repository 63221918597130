import { useRef, useState, useLayoutEffect } from 'react'

export default function useElementWidth() {
    const [width, setWidth] = useState(null)
    const ref = useRef(null)
    useLayoutEffect(() => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth)
        }
    }, [])
    return [ref, width]
}
