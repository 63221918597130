import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import PageHeader from '../../utils/PageHeader'
import Button from '../../utils/Button'

const UpdateDomainPageHeader = ({ domain, audit }) => {
    const { name } = domain
    return (
        <PageHeader
            title={name}
            rightChildren={
                <Link to={`/audits/${audit.id}/settings`}>
                    <Button type="white">
                        <Trans>Cancel</Trans>
                    </Button>
                </Link>
            }
        />
    )
}

export default UpdateDomainPageHeader
