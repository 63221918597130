import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const DropdownMenuItem = ({
    as: Component = 'button',
    iconName,
    children,
    className,
    isLoading,
    disabled = false,
    ...props
}) => (
    <Component
        disabled={disabled}
        type="button"
        role="menuitem"
        className={clsx(
            'group flex w-full items-center px-4 py-2.5 text-sm font-medium leading-5 transition-colors',
            !disabled &&
                'text-slate-700 hover:bg-slate-50 hover:text-slate-900',
            disabled &&
                'cursor-not-allowed text-slate-400 hover:bg-transparent hover:text-slate-400',
            className
        )}
        {...props}
    >
        {iconName && (
            <Icon
                name={isLoading ? 'spinner' : iconName}
                size="sm"
                color="none"
                className={clsx(
                    'mr-3',
                    isLoading && 'animate-spin',
                    !disabled && 'text-slate-500 group-hover:text-slate-600',
                    disabled && 'text-slate-300 group-hover:text-slate-400'
                )}
            />
        )}
        {children}
    </Component>
)

export default DropdownMenuItem
