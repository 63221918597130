import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { AUDIT_DETAILS_FRAGMENT } from './useAuditSettingsPageQuery'

export const UPDATE_AUDIT_MUTATION = gql`
    mutation updateAudit($id: ID!, $input: UpdateAuditInput!) {
        updateAudit(id: $id, input: $input) {
            audit {
                ...Details
            }
        }
    }
    ${AUDIT_DETAILS_FRAGMENT}
`

const useUpdateAuditMutation = (options) =>
    useMutation(UPDATE_AUDIT_MUTATION, options)

export default useUpdateAuditMutation
