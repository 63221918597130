import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import Alert from '../../utils/Alert'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormCheckbox from '../../utils/FormCheckbox'
import LoginPageContainer from './LoginPageContainer'
import LoginPageTitle from './LoginPageTitle'
import LoginPageCard from './LoginPageCard'

const LoginPage = ({ errorMessage, isLoading, formState, onSubmit }) => {
    const { getErrorMessages, getNativeInputProps } = formState
    return (
        <LoginPageContainer data-testid="LoginPage">
            <LoginPageCard>
                <LoginPageTitle>
                    <Trans>Login</Trans>
                </LoginPageTitle>
                {errorMessage && (
                    <Alert
                        className="mb-6"
                        title={<Trans>Failed to login</Trans>}
                    >
                        {errorMessage}
                    </Alert>
                )}
                <div>
                    <FormGroup
                        label={<Trans>Email address</Trans>}
                        htmlFor="email"
                    >
                        <FormInput
                            id="email"
                            type="email"
                            className="max-w-lg"
                            errors={getErrorMessages('email')}
                            {...getNativeInputProps('email')}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Password</Trans>}
                        htmlFor="password"
                    >
                        <FormInput
                            id="password"
                            type="password"
                            className="max-w-lg"
                            errors={getErrorMessages('password')}
                            {...getNativeInputProps('password')}
                        />
                    </FormGroup>
                    <div className="mt-6 flex items-center justify-between">
                        <div className="flex items-center">
                            <FormCheckbox
                                label={<Trans>Remember me</Trans>}
                                {...getNativeInputProps('rememberMe')}
                            />
                        </div>
                        <div className="text-sm leading-5">
                            <Link to="/reset-password">
                                <button
                                    type="button"
                                    className="font-medium text-primary-600 hover:text-blue-500 transition ease-in-out duration-150"
                                >
                                    <Trans>Forgot your password?</Trans>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="mt-6">
                        <Button
                            data-testid="SignInButton"
                            loading={isLoading}
                            disabled={isLoading}
                            onClick={onSubmit}
                            className="w-full"
                        >
                            <Trans>Login</Trans>
                        </Button>
                    </div>
                </div>
            </LoginPageCard>
        </LoginPageContainer>
    )
}

export default LoginPage
