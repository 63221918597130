import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormSelect from '../../utils/FormSelect'

const CreateRoleModal = ({ roles, formState, onDismiss, onSubmit }) => (
    <Modal onDismiss={onDismiss} className="max-w-lg">
        <ModalTitle
            title={<Trans>Change Role</Trans>}
            description={<Trans>Change role description</Trans>}
            onClose={onDismiss}
        />
        <FormGroup htmlFor="roleId">
            <FormSelect
                id="role"
                errors={formState.getErrorMessages('role')}
                {...formState.getNativeInputProps('role')}
            >
                {roles.map((role) => (
                    <option key={role} value={role}>
                        {role}
                    </option>
                ))}
            </FormSelect>
        </FormGroup>
        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <Button onClick={onSubmit}>
                    <Trans>Change Role</Trans>
                </Button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
            </span>
        </div>
    </Modal>
)

export default CreateRoleModal
