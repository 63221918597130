import React from 'react'
import { Trans } from '@lingui/macro'
import { AVAILABLE_TRACKER_RESOURCE_TYPES } from '@clew/shared/src/constants'
import TableFiltersMenu from '../../utils/TableFiltersMenu'
import TableFiltersMenuItem from '../../utils/TableFiltersMenuItem'
import TableFilterSelect from '../../utils/TableFilterSelect'
import TableFilterSelectOption from '../../utils/TableFilterSelectOption'
import IdentifiedScanVendorSelectList from './IdentifiedScanVendorSelectList'
import { useScanPageCookiesPurposesQuery } from './useScanPageDetailCookiesDrawer'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'

export function ScanPageRequestsAvailableFilters({
    activeFilters,
    onAddFilter,
    onRemoveAllFilters,
}) {
    return (
        <TableFiltersMenu
            onRemoveAll={onRemoveAllFilters}
            iconBefore="filter-lines"
            label=""
            autoClose
        >
            <TableFiltersMenuItem
                onAdd={onAddFilter}
                activeFilters={activeFilters}
                iconName="review"
                name="type"
            >
                <Trans>Type</Trans>
            </TableFiltersMenuItem>
            <TableFiltersMenuItem
                onAdd={onAddFilter}
                activeFilters={activeFilters}
                iconName="review"
                name="purpose"
            >
                <Trans>Purpose</Trans>
            </TableFiltersMenuItem>
            <TableFiltersMenuItem
                onAdd={onAddFilter}
                activeFilters={activeFilters}
                iconName="building-07"
                name="vendors"
            >
                <Trans>Vendors</Trans>
            </TableFiltersMenuItem>
        </TableFiltersMenu>
    )
}

export function ScanPageRequestsActiveFilters({
    scanPageId,
    activeFilters,
    onRemoveFilter,
    typeFilter,
    onChangeTypeFilter,
    purposeFilter,
    onChangePurposeFilter,
    vendorsFilter,
    onChangeVendorsFilter,
}) {
    const {
        purposes,
        isFetching: loading,
        error,
    } = useScanPageCookiesPurposesQuery()

    if (error) return <DrawerTabError error={error} />
    if (loading) return <DrawerTabLoading />

    let typeLabel = AVAILABLE_TRACKER_RESOURCE_TYPES.find(
        (type) => type === typeFilter
    )
    if (!typeLabel) {
        typeLabel = <Trans>Choose Type</Trans>
    }

    let purposeLabel = purposes?.find((purpose) => {
        return purpose.id === purposeFilter
    })?.name
    if (!purposeLabel) {
        if (purposeFilter === 'UNCLASSIFIED') {
            purposeLabel = <Trans>Unclassified</Trans>
        } else {
            purposeLabel = <Trans>Choose Purpose</Trans>
        }
    }

    let vendorsLabel = <Trans>{vendorsFilter.length} selected</Trans>
    if (!vendorsLabel) {
        vendorsLabel = <Trans>Choose vendors</Trans>
    }
    return (
        <>
            <TableFilterSelect
                name="type"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={typeLabel}
                position="bottom-start"
                asChild
                autoClose
            >
                {AVAILABLE_TRACKER_RESOURCE_TYPES.map((resourceType) => {
                    return (
                        <TableFilterSelectOption
                            key={resourceType}
                            onClick={() => onChangeTypeFilter(resourceType)}
                        >
                            <Trans>{resourceType}</Trans>
                        </TableFilterSelectOption>
                    )
                })}
            </TableFilterSelect>
            <TableFilterSelect
                name="purpose"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={purposeLabel}
                position="bottom-start"
                asChild
                autoClose
            >
                <TableFilterSelectOption
                    onClick={() => onChangePurposeFilter('UNCLASSIFIED')}
                >
                    <Trans>Unclassified</Trans>
                </TableFilterSelectOption>
                {purposes?.map(({ id, name }) => {
                    return (
                        <TableFilterSelectOption
                            key={id}
                            onClick={() => onChangePurposeFilter(id)}
                        >
                            {name}
                        </TableFilterSelectOption>
                    )
                })}
            </TableFilterSelect>
            <TableFilterSelect
                name="vendors"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={vendorsLabel}
                position="bottom-start"
                asChild
            >
                <IdentifiedScanVendorSelectList
                    scanPageId={scanPageId}
                    value={vendorsFilter}
                    onChange={onChangeVendorsFilter}
                />
            </TableFilterSelect>
        </>
    )
}
