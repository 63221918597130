import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import TableStateProvider from '../../providers/TableStateProvider'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import StaffHeader from '../../layout/StaffHeader'
import Button from '../../utils/Button'
import VendorsPageSidebar from './VendorsPageSidebar'
import VendorsPageTable from './VendorsPageTable'

import VendorsPageTableActions from './VendorsPageTableActions'

const VendorsPage = ({
    search,
    vendors,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    onChangeVendorTypeFilter,
    activeFilters,
    vendorTypeFilter,
    initialOrderBy,
    onChangeOrderBy,
    isFetching,
    onDeleteVendorCompleted,
    onUpdateVendorCompleted,
    onBulkUpload,
    onCreateVendor,
}) => {
    return (
        <PageContainer data-testid="VendorsPage">
            <StaffHeader />
            <SidebarContainer>
                <VendorsPageSidebar />
                <PageContent size="full">
                    <div className="flex justify-between items-center">
                        <PageHeader
                            title={<Trans>Vendors</Trans>}
                            description={
                                <Trans>
                                    Below you can find the global vendor
                                    directory of Webclew.
                                </Trans>
                            }
                            rightChildren={
                                <div className="flex gap-2">
                                    <Button
                                        onClick={onBulkUpload}
                                        className="h-[40px]"
                                        type="white"
                                        iconBeforeProps={{
                                            name: 'upload-cloud-02',
                                            size: 'sm',
                                        }}
                                    >
                                        <Trans>Bulk Upload</Trans>
                                    </Button>
                                    <Button
                                        onClick={onCreateVendor}
                                        className="h-fit"
                                        type="primary"
                                        iconBeforeProps={{
                                            name: 'plus',
                                            size: 'sm',
                                        }}
                                    >
                                        <Trans>Create Vendor</Trans>
                                    </Button>
                                </div>
                            }
                        />
                    </div>
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <VendorsPageTableActions
                            search={search}
                            onSearch={onSearch}
                            activeFilters={activeFilters}
                            vendorTypeFilter={vendorTypeFilter}
                            onAddFilter={onAddFilter}
                            onRemoveFilter={onRemoveFilter}
                            onRemoveAllFilters={onRemoveAllFilters}
                            onChangeVendorTypeFilter={onChangeVendorTypeFilter}
                        />
                        <VendorsPageTable
                            vendors={vendors}
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            isFetching={isFetching}
                            onDeleteVendorCompleted={onDeleteVendorCompleted}
                            onUpdateVendorCompleted={onUpdateVendorCompleted}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}

export default VendorsPage
