import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation delegateAlertInternal(
        $groupingIds: [ID!]
        $auditId: ID
        $input: DelegateAlertInternalInput!
    ) {
        delegateAlertInternal(
            groupingIds: $groupingIds
            auditId: $auditId
            input: $input
        )
    }
`

const useDelegateAlertInternalMutation = (options) =>
    useMutation(MUTATION, options)

export default useDelegateAlertInternalMutation
