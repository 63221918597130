import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import useDrawerStack from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import PurposesTags from '../../utils/PurposesTags'
import AlertCountTag from '../../utils/AlertCountTag'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import IdentifiedTrackerDetailsDrawer from '../staff-scans/IdentifiedScanTrackerDetailsDrawer'
import InfiniteScroll from '../../utils/InfiniteScroll'

const QUERY = gql`
    query scanDetailsDrawerTabTackers(
        $search: String
        $after: String
        $offset: Int
        $orderBy: TrackerOrderByInput
        $filters: IdentifiedScanTrackersFilters
    ) {
        identifiedScanTrackers(
            search: $search
            after: $after
            offset: $offset
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                node {
                    id
                    lastSeenAt
                    occurrence
                    rootDomain
                    lastScanRequest {
                        id
                        resourceType
                        url
                        rootDomain
                    }
                    audits {
                        id
                        name
                        imageUrl
                    }
                    tracker {
                        id
                        domain
                        description
                        belongsToOrganization
                        purposes {
                            id
                            name
                            parent {
                                id
                                name
                                parent {
                                    id
                                    name
                                }
                            }
                        }
                        vendor {
                            id
                            name
                            imageUrl
                        }
                    }
                    alerts {
                        id
                        message
                        type
                        severityType
                        subjectType
                        subjectName
                        occurrences
                    }
                    pages {
                        scanPages {
                            id
                            url
                            status
                            duration
                        }
                    }
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function ScanDetailsDrawerTabTrackers({ scanId }) {
    const { add } = useDrawerStack()
    const filters = { scanId }
    const variables = { filters }
    const { data, loading, error, refetch, fetchMore } = useQuery(QUERY, {
        variables,
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'identifiedScanTrackers'
    )
    if (loading) return <DrawerTabLoading />
    if (error) return <DrawerTabError error={error} />
    const identifiedScanTrackers = connectionToCollection(
        data.identifiedScanTrackers
    )
    if (identifiedScanTrackers.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="tracker"
                title={<Trans>No trackers</Trans>}
                description={
                    <Trans>No trackers where found for this scan.</Trans>
                }
            />
        )
    }

    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasNextPage}
            onFetchMore={handleFetchMore}
        >
            <Table hasMinWidth={false} isRounded hasHeader={false}>
                <TableBody>
                    {identifiedScanTrackers.map((identifiedTracker) => {
                        const {
                            id,
                            rootDomain,
                            tracker,
                            alerts = [],
                        } = identifiedTracker
                        return (
                            <TableRow
                                isSelectable
                                key={id}
                                onClick={() => {
                                    add(IdentifiedTrackerDetailsDrawer, {
                                        identifiedScanRequest:
                                            identifiedTracker,
                                    })
                                }}
                            >
                                <TableCellAvatar
                                    columnId="subjectName"
                                    py="xs"
                                    px="sm"
                                    truncate
                                    className="w-3/4"
                                    avatarIconName="tracker"
                                    avatarSrc={tracker?.vendor?.imageUrl}
                                    title={rootDomain}
                                />
                                <TableCell py="sm">
                                    <div className="flex justify-end gap-1">
                                        <AlertCountTag alerts={alerts} />
                                        {tracker && (
                                            <PurposesTags
                                                showAll
                                                purposes={tracker.purposes}
                                            />
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
