import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import VendorsPageTableHeaderRow from './OrganizationVendorsPageTableHeaderRow'
import VendorsPageTableRow from './OrganizationVendorsPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'

const OrganizationVendorsPageTable = ({
    vendors = [],
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onDeleteCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table
            isFetching={isFetching}
            hasMinWidth={false}
            isFullWidth
            data-testid="VendorsPageTable"
        >
            <thead>
                <VendorsPageTableHeaderRow />
            </thead>
            <TableBody>
                {vendors.map((vendor) => (
                    <VendorsPageTableRow
                        key={vendor.id}
                        vendor={vendor}
                        onDeleteCompleted={onDeleteCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default OrganizationVendorsPageTable
