import React, { useState } from 'react'
import useStaffOrganizationsPageQuery from './useStaffOrganizationsPageQuery'
import StaffOrganizationsPage from './StaffOrganizationsPage'
import CreateOrganizationModalWithState from '../create-organization/CreateOrganizationModalWithState'

const StaffOrganizationsPageWithState = () => {
    const [isModalOpen, setModalIsOpen] = useState()
    const {
        organizations,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
        refetch,
    } = useStaffOrganizationsPageQuery()
    return (
        <>
            {isModalOpen && (
                <CreateOrganizationModalWithState
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
            <StaffOrganizationsPage
                organizations={organizations}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onDeleteCompleted={() => refetch()}
                onCreateOrganization={() => setModalIsOpen(true)}
                isFetching={isFetching}
                error={error}
            />
        </>
    )
}

export default StaffOrganizationsPageWithState
