import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation delegateAlertExternal(
        $groupingIds: [ID!]
        $auditId: ID
        $input: DelegateAlertExternalInput!
    ) {
        delegateAlertExternal(
            groupingIds: $groupingIds
            auditId: $auditId
            input: $input
        )
    }
`

const useAlertDelegateExternalMutation = (options) =>
    useMutation(MUTATION, options)

export default useAlertDelegateExternalMutation
