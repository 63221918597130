import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'
import { resolverActionOptions } from '../settings-login-profiles/useCreateLoginProfileFormState'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this login profile.</Trans>,
    },
])

const toFormValues = ({ name, resolverActions = [] }) => ({
    name,
    resolverActions,
})

const useUpdateLoginProfileFormState = (loginProfile) => {
    const defaultAction = {
        type: Object.keys(resolverActionOptions)[0],
        selector: '',
    }
    const formState = useFormState(toFormValues(loginProfile), { validation })
    formState.handleAddResolverAction = () => {
        formState.handleChange('resolverActions', (resolverActions) => [
            ...resolverActions,
            defaultAction,
        ])
    }
    formState.handleRemoveResolverAction = (index) => {
        formState.handleChange('resolverActions', (resolverActions) => {
            if (resolverActions.length === 1) {
                return [defaultAction]
            }
            return resolverActions.filter((_, i) => i !== index)
        })
    }
    return formState
}

export default useUpdateLoginProfileFormState
