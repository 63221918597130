import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import Alert from '../../utils/Alert'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormSelect from '../../utils/FormSelect'

const AddConsentProfileModal = ({
    consentProfiles,
    formState,
    onDismiss,
    onSubmit,
}) => {
    const noConsentProfileToAdd = consentProfiles.length === 0
    return (
        <Modal onDismiss={onDismiss} className="max-w-lg">
            <ModalTitle
                title={<Trans>Add consent profile</Trans>}
                description={
                    <Trans>Add a new consent profile to this audit.</Trans>
                }
                onClose={onDismiss}
            />
            {(() => {
                if (noConsentProfileToAdd) {
                    return (
                        <Alert
                            type="warning"
                            className="mb-6"
                            title={<Trans>No consent profile to add</Trans>}
                        >
                            <Trans>
                                This audit already contains all consent
                                profiles. You cannot add more consent profiles
                                than this.
                            </Trans>
                        </Alert>
                    )
                }
                return (
                    <FormGroup htmlFor="roleId">
                        <FormSelect
                            id="role"
                            errors={formState.getErrorMessages(
                                'consentProfileId'
                            )}
                            {...formState.getNativeInputProps(
                                'consentProfileId'
                            )}
                        >
                            {consentProfiles.map(({ id, name, cmp }) => (
                                <option key={id} value={id}>
                                    {name} [{cmp.name}]
                                </option>
                            ))}
                        </FormSelect>
                    </FormGroup>
                )
            })()}
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit} disabled={noConsentProfileToAdd}>
                        <Trans>Add Profile</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default AddConsentProfileModal
