import React, { useState } from 'react'
import Icon from './Icon'

const Expandable = ({ title, children, className, ...props }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div className={`${className} w-full my-4 hover:cursor-pointer`}>
            <div
                className="flex w-full justify-between text-sm font-medium leading-5 text-gray-500"
                onClick={toggleExpand}
            >
                {title}
                {isExpanded ? (
                    <Icon size={'sm'} name={'chevron-up'} />
                ) : (
                    <Icon size={'sm'} name={'chevron-down'} />
                )}
            </div>
            {isExpanded && <div className="pt-4">{children}</div>}
        </div>
    )
}

export default Expandable
