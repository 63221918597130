import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import UsersPageTableRow from './UsersPageTableRow'

const QUERY = gql`
    query usersPage($after: String) {
        users(after: $after) {
            edges {
                cursor
                node {
                    ...UserPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${UsersPageTableRow.FRAGMENT}
`

const useUsersPageQuery = () => {
    const variables = {}
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'users'
    )
    return {
        error,
        users: connectionToCollection(data.users),
        roles: data.roles,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && data.users.pageInfo.hasNextPage,
    }
}

export default useUsersPageQuery
