import clsx from 'clsx'
import React from 'react'
import Icon from './Icon'

const DrawerEmptyCard = ({
    iconName,
    iconColor = 'primary',
    title,
    description,
    children,
    className,
}) => (
    <div
        className={clsx(
            'shadow-xs rounded-xl bg-white px-6 py-14 text-center flex flex-col items-center border border-gray-200',
            className
        )}
    >
        {iconName && <Icon name={iconName} color={iconColor} size="lg" />}
        {title && (
            <h3 className="leading-6 font-medium text-slate-900 mt-4">
                {title}
            </h3>
        )}
        {title && (
            <p className="mt-2 max-w-lg text-sm leading-5 text-slate-500">
                {description}
            </p>
        )}
        {children}
    </div>
)

export default DrawerEmptyCard
