import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const ACCEPT_INVITATION_MUTATION = gql`
    mutation acceptInvitation($id: ID!) {
        acceptInvite(invitationId: $id)
    }
`

const useAcceptInvitationMutation = (options) =>
    useMutation(ACCEPT_INVITATION_MUTATION, options)

export default useAcceptInvitationMutation
