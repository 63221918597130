import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import useNotifications from '../../hooks/useNotifications'
import ConfirmModal from '../../utils/ConfirmModal'

const MUTATION = gql`
    mutation staffDeleteOrganization($id: ID!) {
        staffDeleteOrganization(id: $id)
    }
`

const useDeleteOrganization = (options) => useMutation(MUTATION, options)

const DeleteOrganizationModal = ({
    organization,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteOrganization, { loading }] = useDeleteOrganization({
        onCompleted,
        onError,
    })
    return (
        <ConfirmModal
            title={<Trans>Delete organization</Trans>}
            description={
                <Trans>
                    Are you sure you want to delete{' '}
                    <strong>{organization.name}</strong>? This actions cannot be
                    undone! This might take a while.
                </Trans>
            }
            confirmButtonText={
                <Trans>
                    Delete {organization.name}
                </Trans>
            }
            isLoading={loading}
            isDisabled={loading}
            onDismiss={onDismiss}
            onConfirm={async () => {
                try {
                    await deleteOrganization({
                        variables: { id: organization.id },
                    })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                Organization {organization.name} successfully
                                delete.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteOrganizationModal
