import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query alertDelegateQuery($search: String) {
        users(first: 100, search: $search) {
            edges {
                cursor
                node {
                    id
                    fullName
                    email
                    role
                }
            }
        }
    }
`

const useAlertDelegateQuery = (variables = {}, options = {}) => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        users: connectionToCollection(data.users),
        isFetching,
        refetch,
    }
}

export default useAlertDelegateQuery
