/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalLoading from '../../utils/ModalLoading'
import FormButtonGroup from '../../utils/FormButtonGroup'
import FormGroup from '../../utils/FormGroup'
import FormSelectList from '../../utils/FormSelectList'
import AuditSearchSelect from '../../utils/AuditSearchSelect'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import * as constants from '../../../constants'

const { APPLICATION_LEVEL_AUDIT: AUDIT } = constants

const CreateAlertReportModal = ({
    level,
    isFetching = false,
    selectedAudits,
    onAddAudit,
    onAddAllVisibleAudits,
    onDismiss,
    onRemoveAudit,
    onSubmit,
    scanRangeState,
}) => (
    <Modal className="max-w-2xl" onDismiss={onDismiss}>
        <ModalTitle
            title={<Trans>Create alert report</Trans>}
            description={
                <Trans>
                    Download all data protection alerts from selected scans
                </Trans>
            }
            onClose={onDismiss}
        />
        {(() => {
            if (isFetching) {
                return <ModalLoading />
            }
            return (
                <>
                    <FormGroup
                        label={<Trans>Select time period</Trans>}
                        isHorizontal
                    >
                        <ScanRangeSelect
                            fullWidth
                            onChange={scanRangeState.onChangeDate}
                            period={scanRangeState.selectedPeriod}
                            startDate={scanRangeState.startDate}
                            endDate={scanRangeState.endDate}
                        />
                    </FormGroup>
                    {level !== AUDIT && (
                        <FormGroup
                            label={<Trans>Select audits</Trans>}
                            isHorizontal
                        >
                            <FormSelectList>
                                <AuditSearchSelect
                                    className="h-[400px]"
                                    value={selectedAudits.map(({ id }) => id)}
                                    onSelect={(id, audit) => onAddAudit(audit)}
                                    onDeselect={(id) => onRemoveAudit(id)}
                                    onSelectAllVisibleAudits={
                                        onAddAllVisibleAudits
                                    }
                                />
                            </FormSelectList>
                        </FormGroup>
                    )}
                    <FormButtonGroup>
                        <Button onClick={onSubmit}>
                            <Trans>Confirm</Trans>
                        </Button>
                        <Button type="white" onClick={onDismiss}>
                            <Trans>Cancel</Trans>
                        </Button>
                    </FormButtonGroup>
                </>
            )
        })()}
    </Modal>
)

export default CreateAlertReportModal
