import React from 'react'
import { Trans } from '@lingui/macro'
import useTableState from '../../hooks/useTableState'
import SearchInput from '../../utils/SearchInput'
import useNotifications from '../../hooks/useNotifications'
import TableActionsContainer from '../../utils/TableActionsContainer'
import useUpdateCookieTableItemMutation from './useUpdateCookieTableItemMutation'
import { COOKIE_TABLE_ITEM_STATUS_DEPRECATED } from '../../../constants'
import TableViewSettingsMenu from '../../utils/TableViewSettingsMenu'

import Button from '../../utils/Button'

const CookieTableItemsPageTableActions = ({
    search,
    onSearch,
    onCompleted,
    onError,
}) => {
    const {
        isSelectingRows,
        startSelectingRows,
        stopSelectingRows,
        selectedRows,
        clearSelectedRows,
    } = useTableState()

    const { dispatchSuccess, dispatchError } = useNotifications()

    const [updateCookieTableItem, { loading }] =
        useUpdateCookieTableItemMutation({ onCompleted, onError })

    return (
        <TableActionsContainer isSticky>
            <div className="flex w-full flex-row justify-between">
                {isSelectingRows ? (
                    <div className="flex flex-row gap-2">
                        <Button
                            type="white"
                            size="sm"
                            onClick={() => stopSelectingRows()}
                        >
                            <Trans>Cancel</Trans>
                        </Button>
                        <Button
                            type="danger"
                            size="sm"
                            disabled={loading || selectedRows.length === 0}
                            onClick={async () => {
                                try {
                                    await updateCookieTableItem({
                                        variables: {
                                            id: selectedRows.map(
                                                (cookieTableItem) =>
                                                    cookieTableItem.id
                                            ),
                                            input: {
                                                status: COOKIE_TABLE_ITEM_STATUS_DEPRECATED,
                                            },
                                        },
                                    })
                                    stopSelectingRows()
                                    dispatchSuccess({
                                        message: (
                                            <Trans>
                                                Cookie successfully deleted.
                                            </Trans>
                                        ),
                                    })
                                } catch (error) {
                                    const { message } =
                                        error.graphQLErrors[0] || {}
                                    dispatchError({ message })
                                }
                            }}
                        >
                            <Trans>Remove</Trans>
                        </Button>
                    </div>
                ) : (
                    <div className="flex items-center gap-2">
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{
                                name: 'check-done-01',
                                size: 'sm',
                            }}
                            onClick={() => startSelectingRows()}
                        />
                        <SearchInput value={search} onChange={onSearch} />
                    </div>
                )}
                <TableViewSettingsMenu />
            </div>
        </TableActionsContainer>
    )
}

export default CookieTableItemsPageTableActions
