/* eslint-disable max-len */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import MultiActionSelectorInput from '../../utils/MultiActionSelectorInput'
import { resolverActionOptions } from './useCreateLoginProfileFormState'

const CreateLoginProfileModal = ({ formState, onDismiss, onSubmit }) => {
    const { values, getErrorMessages, getNativeInputProps, handleChange } =
        formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-3xl">
            <ModalTitle
                title={<Trans>Create new login profile</Trans>}
                description={
                    <Trans>
                        A login profile describes a login flow to simulate an
                        authenticated session.
                    </Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Login actions</Trans>} className="mb-4">
                <MultiActionSelectorInput
                    actions={values.resolverActions}
                    options={resolverActionOptions}
                    onChange={handleChange}
                    onAdd={formState.handleAddResolverAction}
                    onRemove={formState.handleRemoveResolverAction}
                    inputKey="resolverActions"
                />
            </FormGroup>
            <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <Button onClick={onSubmit}>
                        <Trans>Create profile</Trans>
                    </Button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default CreateLoginProfileModal
