import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useUpdateVendorTableItemMutation from './useUpdateVendorTableItemMutation'
import { VENDOR_TABLE_ITEM_STATUS_DEPRECATED } from '../../../constants'
import DeleteVendorTableItemModal from './DeleteVendorTableItemModal'

const DeleteVendorTableItemModalWithState = ({
    vendorTableItem,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [updateVendorTableItem, { loading }] =
        useUpdateVendorTableItemMutation({ onCompleted, onError })
    return (
        <DeleteVendorTableItemModal
            onDismiss={onDismiss}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    await updateVendorTableItem({
                        variables: {
                            id: [vendorTableItem.id],
                            input: {
                                status: VENDOR_TABLE_ITEM_STATUS_DEPRECATED,
                            },
                        },
                    })
                    onDismiss()
                    dispatchSuccess({
                        message: <Trans>Vendor successfully removed.</Trans>,
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteVendorTableItemModalWithState
