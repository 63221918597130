import useFormState from 'use-form-state'
import { validation } from './useClassifyCookieFormState'

const useUpdateCookieFormState = (
    { name, regex, domain, description, purposes, vendor },
    scanCookie
) => {
    const formState = useFormState(
        {
            name,
            regex,
            domain,
            description,
            purposeIds: purposes ? purposes.map(({ id }) => id) : [],
            vendorId: vendor ? vendor.id : null,
        },
        {
            validation,
            validationOptions: {
                originalCookieName: scanCookie ? scanCookie.originalName : null,
            },
        }
    )
    return formState
}

export default useUpdateCookieFormState
