import React, { useState } from 'react'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import UsersPageTableRowActionButton from './UsersPageTableRowActionButton'
import DeleteUserModalWithState from './DeleteUserModalWithState'
import ChangeUserRoleModalWithState from './ChangeUserRoleModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const UsersPageTableRowActionButtonWithState = ({
    user,
    onChangeRoleSuccess,
    onDeleteUserSuccess,
}) => {
    const { currentUser } = useAppState()
    const [isChangeRoleModalIsOpen, setChangeRoleModalIsOpen] = useState(false)
    const [isRemoveUserModalOpen, setRemoveUserModalOpen] = useState(false)
    return (
        <>
            {isChangeRoleModalIsOpen && (
                <ChangeUserRoleModalWithState
                    user={user}
                    onDismiss={() => setChangeRoleModalIsOpen(false)}
                    onCompleted={onChangeRoleSuccess}
                />
            )}
            {isRemoveUserModalOpen && (
                <DeleteUserModalWithState
                    user={user}
                    onDismiss={() => setRemoveUserModalOpen(false)}
                    onCompleted={onDeleteUserSuccess}
                />
            )}
            <UsersPageTableRowActionButton
                user={user}
                canChangeRole={currentUser.role === OWNER}
                canDeleteUser={currentUser.role === OWNER}
                onChangeRole={() => setChangeRoleModalIsOpen(true)}
                onDeleteUser={() => setRemoveUserModalOpen(true)}
            />
        </>
    )
}

export default UsersPageTableRowActionButtonWithState
