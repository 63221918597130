import React, { useState } from 'react'
import { format, addDays } from 'date-fns'
import DashboardPage from './DashboardPage'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import * as constants from '../../../constants'

const { DATE_FORMAT_GRAPHQL_DATE: FORMAT } = constants

export default function DashboardPageWithState() {
    useCloseDrawerOnLeave()
    const [selectedDate, setSelectedDate] = useState('last-7')
    let startDate = format(addDays(new Date(), -7), FORMAT)
    let endDate = format(new Date(), FORMAT)
    if (selectedDate === 'last-14') {
        startDate = format(addDays(new Date(), -14), FORMAT)
        endDate = format(new Date(), FORMAT)
    }
    if (selectedDate === 'last-30') {
        startDate = format(addDays(new Date(), -30), FORMAT)
        endDate = format(new Date(), FORMAT)
    }
    return (
        <DashboardPage
            endDate={endDate}
            startDate={startDate}
            selectedDate={selectedDate}
            onChangeDate={(e) => setSelectedDate(e.target.value)}
        />
    )
}
