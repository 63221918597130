import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateVendorTableItem($id: [ID]!, $input: VendorTableItemInput!) {
        updateVendorTableItem(id: $id, input: $input) {
            vendorTableItem {
                id
                status
            }
        }
    }
`

const useUpdateVendorTableItemMutation = (options) =>
    useMutation(MUTATION, options)

export default useUpdateVendorTableItemMutation
