/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import Avatar from './Avatar'
import Button from './Button'
import useAppState from '../hooks/useAppState'

const AlertCommentInput = ({ formState, onSubmit }) => {
    const errorMessages = formState.getErrorMessages('text')
    const hasErrors = errorMessages.length > 0
    const { currentUser } = useAppState()
    return (
        <form
            className="flex gap-2"
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
            }}
        >
            <Avatar name={currentUser.fullName} />
            <div className="border border-gray-200 w-full rounded-lg">
                <div className="flex items-center justify-between py-2 px-4 border-b border-gray-200">
                    <div className="text-sm font-medium">
                        {currentUser.fullName}
                    </div>
                    <div className="text-gray-500 text-sm">Today</div>
                </div>
                <textarea
                    className={clsx(
                        'w-full h-24 py-2 px-4 text-sm border-b border-gray-200',
                        hasErrors && 'placeholder:text-red-600'
                    )}
                    placeholder="Write a comment..."
                    {...formState.getNativeInputProps('text')}
                />
                <div className="flex items-center justify-between pb-2 pt-1 px-2 ">
                    <div className="ml-2 text-red-600 text-sm">
                        {errorMessages}
                    </div>
                    <div className="flex items-center">
                        <Button>
                            <Trans>Comment</Trans>
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default AlertCommentInput
