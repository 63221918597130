import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateTrackers($ids: [ID!], $input: TrackersInput!) {
        updateTrackers(ids: $ids, input: $input) {
            trackers {
                id
            }
        }
    }
`

const useUpdateTrackersMutation = (options) => useMutation(MUTATION, options)

export default useUpdateTrackersMutation
