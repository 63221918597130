import React from 'react'
import { Trans } from '@lingui/macro'
import HeaderContainer from './HeaderContainer'
import HeaderLogo from './HeaderLogo'
import HeaderProfileWithState from './HeaderProfileWithState'
import StaffHeaderMenu from './StaffHeaderMenu'

export default function StaffHeader() {
    return (
        <>
            <HeaderContainer>
                <div className="flex items-center flex-shrink-0 gap-3">
                    <HeaderLogo />
                    <span className="text-base text-slate-900 font-semibold">
                        <Trans>Staff portal</Trans>
                    </span>
                </div>
                <StaffHeaderMenu className="hidden lg:flex" />
                <HeaderProfileWithState />
            </HeaderContainer>
            <HeaderContainer className="lg:hidden">
                <div />
                <StaffHeaderMenu />
                <div />
            </HeaderContainer>
        </>
    )
}
