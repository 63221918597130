import React, { useState } from 'react'
import gql from 'graphql-tag'
import useDrawerStack from '../../hooks/useDrawerStack'
import AlertDetailsDrawer from '../staff-scans/AlertDetailsDrawer'
import * as constants from '../../../constants'
import MyAlertListItem from './MyAlertListItem'
import useAppState from '../../hooks/useAppState'
import GroupedAlertDetailsDrawer from '../alerts/GroupedAlertDetailsDrawer'

const {
    ALERT_STATUS_LEVEL_ORGANIZATION: ORGANIZATION,
    ALERT_STATUS_LEVEL_AUDIT: AUDIT,
} = constants

export const MY_ALERT_DELEGATION_FRAGMENT = gql`
    fragment MyAlertDelegation on AlertDelegation {
        id
        groupingId
        createdAt
        audit {
            id
            name
            imageUrl
        }
        author {
            id
            fullName
        }
        lastKnownAlert {
            id
            subjectName
        }
    }
`

export default function MyAlertListItemWithState({
    alertDelegation,
    onAlertStatusChanged,
    startDate,
    endDate,
}) {
    const { open } = useDrawerStack()
    const { currentUser } = useAppState()
    return (
        <MyAlertListItem
            alertDelegation={alertDelegation}
            user={currentUser}
            onClick={() => {
                open(AlertDetailsDrawer, {
                    alertId: alertDelegation.lastKnownAlert.id,
                    page: 'dashboard',
                })
            }}
        />
    )
}
