import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Avatar from '../../utils/Avatar'
import VendorTypeTags from '../../utils/VendorTypeTags'
import OrganizationVendorsPageTableRowActionButtonWithState from './OrganizationVendorsPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment OrganizationVendorsPageTableRow on Vendor {
        id
        name
        types
        imageUrl
    }
`

const OrganizationVendorsPageTableRow = ({ vendor, onDeleteCompleted }) => {
    const { name, types, imageUrl } = vendor
    return (
        <TableRow data-testid="VendorsPageTableRow">
            <TableCell className="max-w-md">
                <div className="flex items-center">
                    <Avatar iconName="building-07" src={imageUrl} />
                    <div className="mx-4">{name}</div>
                </div>
            </TableCell>
            <TableCell>
                <VendorTypeTags types={types} />
            </TableCell>
            <TableCell className="text-right">
                <OrganizationVendorsPageTableRowActionButtonWithState
                    vendor={vendor}
                    onDeleteCompleted={onDeleteCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

OrganizationVendorsPageTableRow.FRAGMENT = FRAGMENT

export default OrganizationVendorsPageTableRow
