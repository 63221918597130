import React from 'react'
import { Trans } from '@lingui/macro'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'

export default function AuditCookiesPageTableHeader() {
    return (
        <TableHeaderRow isSticky stickyPosition="below-actions">
            <TableHeaderCell columnId="name" initialWidth={300}>
                <Trans>Name</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="alerts" initialWidth={100}>
                <Trans>Alerts</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="purposes" initialWidth={150}>
                <Trans>Purposes</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="vendor" initialWidth={100}>
                <Trans>Vendor</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="occurrence" canSort initialWidth={150}>
                <Trans>Occurrence</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="description" initialWidth={300}>
                <Trans>Description</Trans>
            </TableHeaderCell>
            <TableHeaderCell columnId="lastSeenAt" initialWidth={150}>
                <Trans>Last Seen At</Trans>
            </TableHeaderCell>
            <TableHeaderCell initialWidth={50} />
        </TableHeaderRow>
    )
}
