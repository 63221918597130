/* eslint-disable max-len */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import AddConsentProfileModalWithState from './AddConsentProfileModalWithState'
import useNotifications from '../../hooks/useNotifications'
import AuditSettingsPage from './AuditSettingsPage'
import useUpdateAuditMutation from './useUpdateAuditMutation'
import useUpdateAuditFormState from './useUpdateAuditFormState'
import useUpdateAuditAlertsFormState from './useUpdateAuditAlertsFormState'
import useAuditSettingsPageQuery from './useAuditSettingsPageQuery'
import AddLoginProfileModalWithState from './AddLoginProfileModalWithState'

const AuditSettingsPageWithState = () => {
    const history = useHistory()
    const [isAddConsentProfileModal, setIsAddConsentProfileModal] =
        useState(false)
    const [isAddLoginProfileModal, setIsAddLoginProfileModal] = useState(false)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const {
        audit,
        organization,
        cookieTables,
        vendorTables,
        domains,
        loginProfiles,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        refetch,
    } = useAuditSettingsPageQuery()
    const [updateAudit, { loading: isUpdating }] = useUpdateAuditMutation()
    const settingsFormState = useUpdateAuditFormState(audit)
    const alertSettingsFormState = useUpdateAuditAlertsFormState(audit)
    return (
        <>
            <AuditSettingsPage
                audit={audit}
                organization={organization}
                cookieTables={cookieTables}
                vendorTables={vendorTables}
                isUpdating={isUpdating}
                settingsFormState={settingsFormState}
                alertSettingsFormState={alertSettingsFormState}
                domains={domains}
                loginProfiles={loginProfiles}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onAddDomain={() =>
                    history.push(`/audits/${audit.id}/domains/create`)
                }
                onAddConsentProfile={() => setIsAddConsentProfileModal(true)}
                onAddLoginProfile={() => setIsAddLoginProfileModal(true)}
                onRemoveConsentProfileCompleted={() => refetch()}
                onRemoveLoginProfileCompleted={() => refetch()}
                onDeleteDomainCompleted={() => refetch()}
                onUpdateSettings={async () => {
                    if (settingsFormState.validate()) {
                        try {
                            const input = settingsFormState.valuesToInput()
                            const { data } = await updateAudit({
                                variables: { id: audit.id, input },
                            })
                            dispatchSuccess({
                                message: (
                                    <Trans>
                                        Audit{' '}
                                        <strong>
                                            {data.updateAudit.audit.name}
                                        </strong>{' '}
                                        settings updated!
                                    </Trans>
                                ),
                            })
                        } catch (error) {
                            dispatchGraphqlError(error)
                        }
                    }
                }}
                onUpdateAlertSettings={async () => {
                    if (alertSettingsFormState.validate()) {
                        try {
                            const originalInput =
                                alertSettingsFormState.valuesToInput()
                            const input = {
                                ...originalInput,
                                maxCookieRetention: parseInt(
                                    originalInput.maxCookieRetention,
                                    10
                                ),
                            }
                            if (input.vendorTableId === 'NONE') {
                                input.vendorTableId = null
                            }
                            if (input.cookieTableId === 'NONE') {
                                input.cookieTableId = null
                            }
                            const { data } = await updateAudit({
                                variables: { id: audit.id, input },
                            })
                            dispatchSuccess({
                                message: (
                                    <Trans>
                                        Audit{' '}
                                        <strong>
                                            {data.updateAudit.audit.name}
                                        </strong>{' '}
                                        alert rules updated!
                                    </Trans>
                                ),
                            })
                        } catch (error) {
                            dispatchGraphqlError(error)
                        }
                    }
                }}
            />
            {isAddConsentProfileModal && (
                <AddConsentProfileModalWithState
                    audit={audit}
                    onDismiss={() => setIsAddConsentProfileModal(false)}
                    onCompleted={() => refetch()}
                />
            )}
            {isAddLoginProfileModal && (
                <AddLoginProfileModalWithState
                    audit={audit}
                    onDismiss={() => setIsAddLoginProfileModal(false)}
                    onCompleted={() => refetch()}
                />
            )}
        </>
    )
}

export default AuditSettingsPageWithState
