import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { ORGANIZATION_FRAGMENT } from './useSettingsPageQuery'

const MUTATION = gql`
    mutation updateOrganization($id: ID!, $input: UpdateOrganizationInput!) {
        updateOrganization(id: $id, input: $input) {
            organization {
                ...OrganizationPage
            }
        }
    }
    ${ORGANIZATION_FRAGMENT}
`

const useUpdateSettingsMutation = (options) => useMutation(MUTATION, options)

export default useUpdateSettingsMutation
