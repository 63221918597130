import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation removeLoginProfile(
        $auditId: ID!
        $input: RemoveLoginProfileInput!
    ) {
        removeLoginProfile(auditId: $auditId, input: $input) {
            audit {
                id
            }
        }
    }
`

const useRemoveLoginProfileMutation = (options) =>
    useMutation(MUTATION, options)

export default useRemoveLoginProfileMutation
