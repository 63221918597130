import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import ScanPageTableRow from './ScanPageTableRow'

const QUERY = gql`
    query scansPage(
        $offset: Int
        $filters: ScanFilters
        $orderBy: ScanOrderByInput
        $search: String
    ) {
        scans(
            offset: $offset
            filters: $filters
            orderBy: $orderBy
            search: $search
        ) {
            edges {
                cursor
                node {
                    ...ScanPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
        audits(first: 100) {
            edges {
                cursor
                node {
                    id
                    name
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
        consentProfiles {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${ScanPageTableRow.FRAGMENT}
`

const useScansPageQuery = ({ search, filters, orderBy }) => {
    const variables = {
        filters,
        search,
        orderBy,
    }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
        ...result
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'scans'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            scans: [],
            audits: [],
            isFetching,
        }
    }
    return {
        scans: connectionToCollection(data.scans),
        audits: connectionToCollection(data.audits),
        consentProfiles: connectionToCollection(data.consentProfiles),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
        ...result,
    }
}

export default useScansPageQuery
