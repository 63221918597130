import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const RESET_PASSWORD_MUTATION = gql`
    mutation requestPasswordReset($input: RequestPasswordResetInput!) {
        requestPasswordReset(input: $input)
    }
`

export default function useResetPasswordMutation() {
    return useMutation(RESET_PASSWORD_MUTATION)
}
