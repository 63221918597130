import React from 'react'
import { Trans } from '@lingui/macro'
import Sidebar from '../../layout/Sidebar'
import SidebarMenu from '../../layout/SidebarMenu'
import SidebarTitle from '../../layout/SidebarTitle'
import SidebarMenuItem from '../../layout/SidebarMenuItem'

export default function StaffOrganizationsSidebar() {
    return (
        <Sidebar>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>Admin</Trans>
                </SidebarTitle>
                <SidebarMenuItem to="/staff" iconName="building-07">
                    <Trans>Organizations</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem
                    to="/staff/organization-users"
                    iconName="users-01"
                >
                    <Trans>Organization users</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem to="/staff/staff-users" iconName="tool-02">
                    <Trans>Staff users</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
        </Sidebar>
    )
}
