import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation classifyCookie($input: CookieInput!) {
        classifyCookie(input: $input) {
            cookie {
                id
            }
        }
    }
`

const useClassifyCookieMutation = (options) => useMutation(MUTATION, options)

export default useClassifyCookieMutation
