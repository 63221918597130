import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import StaffHeader from '../../layout/StaffHeader'
import VendorsPageSidebar from '../staff-vendors/VendorsPageSidebar'
import PageHeader from '../../layout/PageHeader'
import StaffUnclassifiedCookiesTable from './StaffUnclassifiedCookiesTable'
import StaffUnclassifiedCookiesPageTableActions from './StaffUnclassifiedCookiesPageTableActions'
import TableStateProvider from '../../providers/TableStateProvider'

export default function StaffUnclassifiedCookiesPage({
    search,
    dateRangeState,
    unclassifiedCookies,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    isFetching,
    onClassifyCookieCompleted,
    orderBy,
    onReset,
    onChangeOrderBy,
}) {
    return (
        <PageContainer>
            <StaffHeader />
            <SidebarContainer>
                <VendorsPageSidebar />
                <PageContent size="full">
                    <div className="flex justify-between items-center">
                        <PageHeader
                            title={<Trans>Unclassified cookies</Trans>}
                            description={
                                <Trans>
                                    Below you can find the global cookie
                                    directory of Webclew.
                                </Trans>
                            }
                            breadcrumbsItems={[
                                {
                                    text: 'Unclassified Cookies',
                                    to: '/unclassified-cookies',
                                },
                            ]}
                        />
                    </div>
                    <TableStateProvider>
                        <StaffUnclassifiedCookiesPageTableActions
                            search={search}
                            onSearch={onSearch}
                            dateRangeState={dateRangeState}
                            orderBy={orderBy}
                            onChangeOrderBy={onChangeOrderBy}
                            onReset={onReset}
                        />
                        <StaffUnclassifiedCookiesTable
                            unclassifiedCookies={unclassifiedCookies}
                            onClassifyCookieCompleted={
                                onClassifyCookieCompleted
                            }
                            isFetchingMore={isFetchingMore}
                            isFetching={isFetching}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
