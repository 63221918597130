import React from 'react'
import PageContent from './PageContent'
import PageContainer from './PageContainer'
import OrganizationHeader from '../layout/OrganizationHeader'
import Alert from './Alert'
import SidebarContainer from '../layout/SidebarContainer'

const ErrorPage = ({ error, showNavigation = true }) => (
    <PageContainer data-testid="ErrorPage">
        {showNavigation && <OrganizationHeader />}
        <SidebarContainer>
            <PageContent centerContent>
                <Alert title="Error">{error.message}</Alert>
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default ErrorPage
