import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import Tabs, { TabLink } from '../../utils/Tabs'

const VendorDetailsPageHeader = ({ vendor }) => (
    <>
        <PageHeader title={vendor.name} avatarIconName="building-07" />
        <Tabs>
            <TabLink exact to={`/staff/vendors/${vendor.id}`}>
                <Trans>Details</Trans>
            </TabLink>
            <TabLink exact to={`/staff/vendors/${vendor.id}/trackers`}>
                <Trans>Trackers</Trans>
            </TabLink>
            <TabLink exact to={`/staff/vendors/${vendor.id}/cookies`}>
                <Trans>Cookies</Trans>
            </TabLink>
        </Tabs>
    </>
)

export default VendorDetailsPageHeader
