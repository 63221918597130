import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import useTableState from '../hooks/useTableState'
import TableCell from './TableCell'
import Checkbox from './Checkbox'

const TableRow = ({
    isActive,
    selectionValue,
    selectionCriteria = (row) => row.id === selectionValue,
    selectionCellSize,
    className,
    isSelectable = false,
    onClick = () => {},
    children,
    ...props
}) => {
    const dragTimeout = useRef(null)
    const [isDragging, setIsDragging] = useState(false)
    const finalClassName = clsx(
        isSelectable && 'hover:bg-slate-100 cursor-pointer',
        isActive && 'bg-slate-100 ',
        className
    )
    const {
        columnIds = [],
        canReorderColumns = false,
        isSelectingRows = false,
        selectedRows = [],
        selectRow,
        deselectRow,
    } = useTableState() || {}
    const isSelected = selectedRows.find(selectionCriteria)
    if (!canReorderColumns) {
        return (
            <tr
                className={finalClassName}
                onMouseDown={() => {
                    dragTimeout.current = setTimeout(() => {
                        setIsDragging(true)
                    }, 300)
                }}
                onMouseUp={(e) => {
                    if (!isDragging) {
                        clearTimeout(dragTimeout.current)
                        if (!isSelectingRows) {
                            onClick(e)
                        } else if (isSelected) {
                            deselectRow(selectionValue)
                        } else {
                            selectRow(selectionValue)
                        }
                    } else {
                        setIsDragging(false)
                    }
                }}
                {...props}
            >
                {isSelectingRows && (
                    <TableCell size={selectionCellSize}>
                        <Checkbox
                            value={isSelected}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    selectRow(selectionValue)
                                } else {
                                    deselectRow(selectionValue)
                                }
                            }}
                        />
                    </TableCell>
                )}
                {children}
            </tr>
        )
    }
    const orderCells = []
    const otherCells = []
    React.Children.toArray(children).forEach((child) => {
        if (columnIds.includes(child.props.columnId)) {
            orderCells.push(child)
        } else {
            otherCells.push(child)
        }
    })
    return (
        <tr className={finalClassName} {...props}>
            {columnIds.map((columnId) => {
                let el
                for (let i = 0; i < orderCells.length; i += 1) {
                    if (orderCells[i].props.columnId === columnId) {
                        el = orderCells[i]
                        break
                    }
                }
                return el
            })}
            {otherCells}
        </tr>
    )
}

export default TableRow
