import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import UserInvitationsPageTableRowActionButton from './UserInvitationsPageTableRowActionButton'
import DeleteInvitationModalWithState from './DeleteInvitationModalWithState'
import useResendUserInviteMutation from './useResendUserInviteMutation'

const { USER_ROLE_OWNER: OWNER } = constants

const UsersPageTableRowActionButtonWithState = ({
    invitation,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => {
    const { currentUser } = useAppState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [resendInvite, { loading }] = useResendUserInviteMutation({
        onCompleted: onResendInvitationSuccess,
    })
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    return (
        <>
            {isDeleteModalOpen && (
                <DeleteInvitationModalWithState
                    invitation={invitation}
                    onDismiss={() => setDeleteModalOpen(false)}
                    onCompleted={onDeleteUserSuccess}
                />
            )}
            <UserInvitationsPageTableRowActionButton
                isLoading={loading}
                invitation={invitation}
                canDeleteInvitation={currentUser.role === OWNER}
                canResendInvitation={currentUser.role === OWNER}
                onDeleteUser={() => setDeleteModalOpen(true)}
                onResendInvitation={async () => {
                    try {
                        const variables = { invitationId: invitation.id }
                        await resendInvite({ variables })
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Invite send to{' '}
                                    <strong>{invitation.email}</strong>.
                                </Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }}
            />
        </>
    )
}

export default UsersPageTableRowActionButtonWithState
