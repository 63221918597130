import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../hooks/useNotifications'
import CreateTrackerModal from './CreateTrackerModal'
import useCreateTrackerMutation from './useCreateTrackerMutation'
import useCreateTrackerFormState from './useCreateTrackerFormState'

const CreateTrackerModalWithState = ({ onDismiss, onCompleted, onError }) => {
    const [createTracker] = useCreateTrackerMutation({ onCompleted, onError })
    const formState = useCreateTrackerFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateTrackerModal
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        await createTracker({ variables: { input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Tracker created successfully!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateTrackerModalWithState
