import React from 'react'
import useAppState from '../../hooks/useAppState'
import ProfilePage from './ProfilePage'

const ProfilePageWithState = () => {
    const { logout } = useAppState()
    return <ProfilePage onLogout={() => logout()} />
}

export default ProfilePageWithState
