import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import AcceptInvitationCard from './AcceptInvitationCard'

const AcceptInvitationUnauthenticated = ({
    organization,
    formState,
    onRegisterUser,
}) => {
    const { values, getErrorMessages, getNativeInputProps } = formState
    return (
        <AcceptInvitationCard
            iconName="invitation"
            title={<Trans>Register</Trans>}
            description={
                <Trans>
                    You have been invited to the{' '}
                    <strong>{organization.name}</strong> organization.
                </Trans>
            }
        >
            <div className="mt-6 justify-center">
                <FormGroup
                    label={<Trans>First name</Trans>}
                    htmlFor="firstName"
                    className="mb-4"
                >
                    <FormInput
                        id="firstName"
                        errors={getErrorMessages('firstName')}
                        {...getNativeInputProps('firstName')}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>Last name</Trans>}
                    htmlFor="lastName"
                    className="mb-4"
                >
                    <FormInput
                        id="lastName"
                        errors={getErrorMessages('lastName')}
                        {...getNativeInputProps('lastName')}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>Email</Trans>}
                    htmlFor="email"
                    className="mb-4"
                >
                    <FormInput
                        id="email"
                        name="email"
                        type="email"
                        disabled
                        value={values.email}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>Password</Trans>}
                    htmlFor="password"
                    className="mb-4"
                >
                    <FormInput
                        id="password"
                        type="password"
                        errors={getErrorMessages('password')}
                        {...getNativeInputProps('password')}
                    />
                </FormGroup>
                <FormGroup
                    label={<Trans>Repeat password</Trans>}
                    htmlFor="password"
                    className="mb-4"
                >
                    <FormInput
                        id="repeatPassword"
                        type="password"
                        errors={getErrorMessages('repeatPassword')}
                        {...getNativeInputProps('repeatPassword')}
                    />
                </FormGroup>
                <div className="mt-6">
                    <Button onClick={onRegisterUser} className="w-full">
                        <Trans>Register</Trans>
                    </Button>
                </div>
            </div>
        </AcceptInvitationCard>
    )
}

export default AcceptInvitationUnauthenticated
