import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this vendor.</Trans>,
    },
])

const useUpdateOrganizationVendorFormState = ({
    name = '',
    description = '',
    types = [],
    domain = '',
    websiteUrl = '',
} = {}) => {
    const formState = useFormState(
        {
            name,
            description,
            types: types || [],
            domain,
            websiteUrl,
        },
        {
            validation,
        }
    )
    return formState
}

export default useUpdateOrganizationVendorFormState
