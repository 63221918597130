import React from 'react'
import clsx from 'clsx'

const DetailsList = ({ className, children, ...props }) => (
    <div className={clsx('px-4 py-5 sm:p-0', className)} {...props}>
        <dl>{children}</dl>
    </div>
)

export default DetailsList
