import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useSearch from '../../hooks/useSearch'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import ScansPage from './ScansPage'
import useNotifications from '../../hooks/useNotifications'
import useDeleteScansMutation from '../audits-scans/useDeleteScansMutation'
import useScansPageQuery from './useScansPageQuery'
import useFilters from '../../hooks/useFilters'

export default function ScansPageWithState() {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()

    const [statusFilter, onChangeStatusFilter] = useState(null)
    const [auditsFilter, onChangeAuditsFilter] = useState(null)
    const [consentProfilesFilter, onChangeConsentProfilesFilter] =
        useState(null)

    const { dispatchError, dispatchSuccess } = useNotifications()
    const dateRangeState = useScanRangeSelect('none')
    const { startDate, endDate } = dateRangeState

    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        status: statusFilter,
        consentProfileId: consentProfilesFilter,
        auditId: auditsFilter,
    })

    const [orderBy, setOrderBy] = useState({ id: 'DESC' })
    const {
        scans,
        audits,
        consentProfiles,
        refetch,
        error,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useScansPageQuery({
        search,
        filters: {
            ...filterValues,
            startDate,
            endDate,
        },
        orderBy,
    })

    const [deleteScans] = useDeleteScansMutation()

    const handleDeleteScans = async (selectedScans, onClearSelectedRows) => {
        try {
            await deleteScans({
                variables: {
                    input: {
                        ids: selectedScans.map(({ id }) => id),
                    },
                },
            })
            dispatchSuccess({
                message: <Trans>Deleted scans!</Trans>,
            })
            onClearSelectedRows()
            refetch()
        } catch (e) {
            const { message } = e.graphQLErrors[0]
            dispatchError({ message })
        }
    }

    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)
    return (
        <ScansPage
            dateRangeState={dateRangeState}
            search={search}
            scans={scans}
            audits={audits}
            consentProfiles={consentProfiles}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            onSearch={handleSearch}
            error={error}
            statusFilter={statusFilter}
            consentProfilesFilter={consentProfilesFilter}
            auditsFilter={auditsFilter}
            onChangeStatusFilter={onChangeStatusFilter}
            onChangeConsentProfilesFilter={onChangeConsentProfilesFilter}
            onChangeAuditsFilter={onChangeAuditsFilter}
            activeFilters={activeFilters}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            onRemoveAllFilters={onRemoveAllFilters}
            initialOrderBy={orderBy}
            onChangeOrderBy={(value, direction) =>
                setOrderBy({ [value]: direction })
            }
            onDeleteScans={handleDeleteScans}
            onDeleteScanCompleted={() => refetch()}
        />
    )
}
