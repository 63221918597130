import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import AlertExternalDelegationModal from './AlertExternalDelegationModal'
import useAlertExternalDelegationFormState from './useAlertExternalDelegationFormState'
import useAlertDelegateExternalMutation from './useAlertDelegateExternalMutation'
import * as constants from '../../../constants'

const { ALERT_STATUS_LEVEL_AUDIT: AUDIT } = constants

const AlertExternalDelegationModalWithState = ({
    groupingIds,
    audit,
    onDismiss,
    onCompleted,
    level,
}) => {
    const formState = useAlertExternalDelegationFormState()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [delegate, { loading: isSendingMail }] =
        useAlertDelegateExternalMutation()

    const variables = {
        groupingIds,
        level,
    }
    if (level === AUDIT) {
        variables.auditId = audit.id
    }
    return (
        <AlertExternalDelegationModal
            groupingIds={groupingIds}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.valuesToInput()
                        await delegate({ variables: { ...variables, input } })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Successfully delegated to external!
                                </Trans>
                            ),
                        })
                        onCompleted()
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
            isSendingMail={isSendingMail}
        />
    )
}

export default AlertExternalDelegationModalWithState
