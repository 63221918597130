import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const CollapseButton = ({
    className,
    children,
    disabled,
    isCollapsed = false,
    isPositionedRight = false,
    size = 'md',
    ...props
}) => {
    let iconName = 'chevron-down'
    if (isCollapsed) {
        if (isPositionedRight) {
            iconName = 'chevron-left'
        } else {
            iconName = 'chevron-right'
        }
    }
    return (
        <button
            type="button"
            className={clsx(
                'inline-flex rounded-md transition ease-in-out duration-150 hover:bg-gray-200',
                size === 'md' && 'p-1.5',
                size === 'sm' && 'p-0.5',
                className,
                disabled && 'opacity-75 cursor-not-allowed'
            )}
            {...props}
        >
            <Icon className="group-hover:text-gray-500" name={iconName} />
        </button>
    )
}

export default CollapseButton
