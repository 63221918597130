import React, { useState } from 'react'
import useStaffJobsPageQuery from './useStaffJobsPageQuery'
import StaffJobsPage from './StaffJobsPage'

const StaffJobsPageWithState = ({ activeQueue, title }) => {
    const [activeStatus, setActiveStatus] = useState('completed')
    const { jobs, isFetching, isFetchingMore, handleFetchMore, hasMore } =
        useStaffJobsPageQuery(activeStatus, activeQueue)
    return (
        <StaffJobsPage
            jobs={jobs}
            title={title}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            activeQueue={activeQueue}
            activeStatus={activeStatus}
            onChangeStatus={(status) => setActiveStatus(status)}
            isFetching={isFetching}
        />
    )
}

export default StaffJobsPageWithState
