import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createCustomCmp($input: CreateCustomCmpInput!) {
        createCustomCmp(input: $input) {
            cmp {
                id
                name
            }
        }
    }
`

const useCreateCustomCmpMutation = (options) => useMutation(MUTATION, options)

export default useCreateCustomCmpMutation
