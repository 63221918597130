import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query updateCustomCmpPage($id: ID!) {
        cmp(id: $id) {
            id
            name
            resolvers {
                id
                name
                type
                script
                profile {
                    id
                    name
                }
            }
        }
        consentProfiles {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

const useUpdateCustomCmpPageQuery = () => {
    const { id } = useParams()
    const variables = { id }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        cmp: data.cmp || {},
        consentProfiles: connectionToCollection(data.consentProfiles),
        isFetching,
        refetch,
    }
}

export default useUpdateCustomCmpPageQuery
