import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const REMOVE_AUDIT_MUTATION = gql`
    mutation deleteAudit($id: ID!) {
        deleteAudit(id: $id)
    }
`

const useDeleteAuditMutation = (options) =>
    useMutation(REMOVE_AUDIT_MUTATION, options)

export default useDeleteAuditMutation
