import React from 'react'
import clsx from 'clsx'

const DetailsListItem = ({
    label,
    children,
    className,
    compressed = false,
    isHorizontal = true,
    ...props
}) => (
    <div
        className={clsx(
            isHorizontal ? 'grid grid-cols-4 gap-4' : '',
            compressed && 'py-2 print:py-1',
            !compressed && 'px-6 py-5',
            className
        )}
        {...props}
    >
        <dt className="text-sm leading-5 font-medium text-gray-500">{label}</dt>
        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3 break-all">
            {children}
        </dd>
    </div>
)

export default DetailsListItem
