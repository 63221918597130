import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'organizations',
        validate: isRequired,
        message: <Trans>Please select atleast 1 organization</Trans>,
    },
    {
        path: 'userId',
        validate: isRequired,
        message: <Trans>User is required</Trans>,
    },
])

const valuesToInput = ({ organizations, userId }) => ({
    organizations,
    userId,
})

const useStaffAssignOrganizationsToUserFormState = (initialValues) =>
    useFormState(
        {
            userId: '',
            organizations: [],
            ...initialValues,
        },
        {
            validation,
            valuesToInput,
        }
    )

export default useStaffAssignOrganizationsToUserFormState
