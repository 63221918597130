import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createLoginProfile($input: LoginProfileInput!) {
        createLoginProfile(input: $input) {
            loginProfile {
                id
            }
        }
    }
`

const useCreateLoginProfileMutation = (options) =>
    useMutation(MUTATION, options)

export default useCreateLoginProfileMutation
