import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation createIdentifiedCookiesReport(
        $input: CreateIdentifiedCookiesReportInput!
    ) {
        createIdentifiedCookiesReport(input: $input) {
            report {
                id
            }
        }
    }
`

const useCreateIdentifiedCookiesReportMutation = (options) =>
    useMutation(MUTATION, options)

export default useCreateIdentifiedCookiesReportMutation
