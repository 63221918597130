import React from 'react'
import Avatar from '../../utils/Avatar'
import ButtonLink from '../../utils/ButtonLink'
import { timeAgo } from '../../../utils/dates'

export default function AlertListItem({ item, children, onClick }) {
    const { createdAt, audit, lastKnownAlert } = item
    return (
        <li className="border-b last:border-0">
            <button
                type="button"
                onClick={onClick}
                className="flex w-full cursor-pointer items-center gap-3 px-6 py-4 hover:bg-gray-100"
            >
                <Avatar iconName="building-07" src={audit && audit.imageUrl} />
                <div className="flex w-full flex-1 flex-wrap justify-between lg:flex-nowrap">
                    <div className="w-full text-left lg:w-auto">
                        <ButtonLink as="span" className="whitespace-pre-wrap">
                            {lastKnownAlert && lastKnownAlert.subjectName}
                        </ButtonLink>
                        <p className="text-gray-700">{children}</p>
                    </div>
                    <p className="text-right text-sm text-gray-500">
                        {timeAgo(createdAt)}
                    </p>
                </div>
            </button>
        </li>
    )
}
