/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormButtonGroup from '../../utils/FormButtonGroup'
import VendorTypeSelect from '../../utils/VendorTypeSelect'

const UpdateOrganizationVendorModal = ({
    title = <Trans>Update vendor</Trans>,
    description = <Trans>Fill in all details about the vendor below.</Trans>,
    formState,
    onDismiss,
    onSubmit,
}) => {
    const { values, getErrorMessages, getNativeInputProps, handleChange } =
        formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-lg">
            <ModalTitle
                title={title}
                description={description}
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Description</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('description')}
                    {...getNativeInputProps('description')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Type</Trans>} htmlFor="type">
                <VendorTypeSelect
                    className="max-w-md"
                    value={values.types}
                    errors={getErrorMessages('types')}
                    onChange={(types) => handleChange('types', types)}
                />
            </FormGroup>
            <FormGroup label={<Trans>Root domain</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('domain')}
                    {...getNativeInputProps('domain')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Website</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('websiteUrl')}
                    {...getNativeInputProps('websiteUrl')}
                />
            </FormGroup>
            <FormButtonGroup>
                <Button onClick={onSubmit}>
                    <Trans>Done</Trans>
                </Button>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
            </FormButtonGroup>
        </Modal>
    )
}

export default UpdateOrganizationVendorModal
