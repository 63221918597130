import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import DeleteConsentProfileModal from './DeleteConsentProfileModal'
import useDeleteConsentProfile from './useDeleteConsentProfile'

const DeleteConsentProfileModalWithState = ({
    consentProfile,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const [deleteConsentProfile, { loading }] = useDeleteConsentProfile({
        onCompleted,
        onError,
    })
    return (
        <DeleteConsentProfileModal
            onDismiss={onDismiss}
            consentProfile={consentProfile}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { id: consentProfile.id }
                    await deleteConsentProfile({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {consentProfile.name} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    const { message } = error.graphQLErrors[0] || {}
                    dispatchError({ message })
                }
            }}
        />
    )
}

export default DeleteConsentProfileModalWithState
