import React from 'react'
import HydrateSafely from '../components/utils/HydrateSafely'
import AppWithState from '../components/AppWithState'

const Client = () => (
    <HydrateSafely>
        <AppWithState />
    </HydrateSafely>
)

export default Client
