import { gql } from '@apollo/client'
import { createUnauthorizedApolloClient } from '../createApolloClient'

const REFRESH_ACCESS_TOKEN_MUTATION = gql`
    mutation refreshAccessToken($refreshToken: String!) {
        refreshAccessToken(refreshToken: $refreshToken)
    }
`

export default async function refreshAccessToken(refreshToken) {
    const apolloClient = createUnauthorizedApolloClient()
    const mutation = REFRESH_ACCESS_TOKEN_MUTATION
    const variables = { refreshToken }
    try {
        const response = await apolloClient.mutate({ mutation, variables })
        return response.data.refreshAccessToken
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to refresh access token.')
        return null
    }
}
