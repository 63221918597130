import React from 'react'
import { cva } from 'cva'
import clsx from 'clsx'
import Icon from './Icon'

const tagVariant = cva(
    'inline-flex items-center font-medium rounded-full whitespace-nowrap',
    {
        variants: {
            type: {
                primary:
                    'bg-primary-50 text-primary-600 border border-primary-200',
                gray: 'bg-slate-100 border border-slate-300 text-slate-700',
                red: 'bg-red-100 text-red-700 border border-red-300',
                blue: 'bg-blue-100 text-blue-700 border border-blue-300',
                yellow: 'bg-amber-100 text-amber-700 border border-amber-300',
                orange: 'bg-orange-100 text-orange-700 border border-orange-300',
                green: 'bg-green-100 text-green-700 border border-green-300',
                white: 'bg-gray-50 border border-gray-200 text-gray-700',
            },
            size: {
                sm: 'text-xs py-0.5 gap-0.5',
                md: 'py-0.5 text-sm gap-1',
                lg: 'py-1 text-base gap-1.5',
            },
        },
        compoundVariants: [
            {
                size: 'sm',
                hasIconBefore: false,
                hasIconAfter: false,
                className: 'px-2',
            },
            {
                size: 'sm',
                hasIconBefore: true,
                hasIconAfter: false,
                className: 'pl-1.5 pr-2',
            },
            {
                size: 'sm',
                hasIconBefore: false,
                hasIconAfter: true,
                className: 'pl-2 pr-1.5',
            },
            {
                size: 'sm',
                hasIconBefore: true,
                hasIconAfter: true,
                className: 'px-1.5',
            },
            {
                size: 'md',
                hasIconBefore: false,
                hasIconAfter: false,
                className: 'px-2.5',
            },
            {
                size: 'md',
                hasIconBefore: true,
                hasIconAfter: false,
                className: 'pl-2 pr-2.5',
            },
            {
                size: 'md',
                hasIconBefore: false,
                hasIconAfter: true,
                className: 'pl-2.5 pr-2',
            },
            {
                size: 'md',
                hasIconBefore: true,
                hasIconAfter: true,
                className: 'px-2',
            },
            {
                size: 'lg',
                hasIconBefore: false,
                hasIconAfter: false,
                className: 'px-3.5',
            },
            {
                size: 'lg',
                hasIconBefore: true,
                hasIconAfter: false,
                className: 'pl-3 pr-3.5',
            },
            {
                size: 'lg',
                hasIconBefore: false,
                hasIconAfter: true,
                className: 'pl-3.5 pr-3',
            },
            {
                size: 'lg',
                hasIconBefore: true,
                hasIconAfter: true,
                className: 'px-3',
            },
        ],
        defaultVariants: {
            type: 'gray',
            size: 'sm',
            hasIconBefore: false,
            hasIconAfter: false,
        },
    }
)

const Tag = ({
    type = 'gray',
    size = 'sm',
    isSelectable = false,
    iconNameBefore,
    iconNameAfter,
    className,
    children,
    ...props
}) => {
    const iconSize = size === 'sm' ? '2xs' : 'xs'
    return (
        <span
            className={tagVariant({
                type,
                size,
                hasIconBefore: !!iconNameBefore,
                hasIconAfter: !!iconNameAfter,
                className: clsx(
                    isSelectable && 'cursor-pointer hover:border-opacity-100 ',
                    type === 'white' && isSelectable && 'hover:border-gray-300',
                    className
                ),
            })}
            {...props}
        >
            {iconNameBefore && <Icon size={iconSize} name={iconNameBefore} />}
            {children}
            {iconNameAfter && <Icon size={iconSize} name={iconNameAfter} />}
        </span>
    )
}

export default Tag
