import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateCustomCmp($id: ID!, $input: LoginProfileInput!) {
        updateLoginProfile(id: $id, input: $input) {
            loginProfile {
                id
            }
        }
    }
`

const useUpdateLoginProfileMutation = (options) =>
    useMutation(MUTATION, options)

export default useUpdateLoginProfileMutation
