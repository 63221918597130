/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import clsx from 'clsx'
import Popover from './Popover'

const DropdownMenu = ({
    as = 'div',
    content,
    enabled = true,
    hideContent = false,
    style,
    dropdownClassName,
    autoClose = false,
    ...props
}) => {
    if (!enabled) {
        return props.children
    }
    return (
        <Popover
            as={as}
            content={(options) => {
                if (hideContent) {
                    return null
                }
                return (
                    <div
                        style={style}
                        className={clsx(
                            'my-2 rounded-md shadow-lg',
                            dropdownClassName
                        )}
                    >
                        <div
                            className="relaive rounded-lg border border-slate-200 bg-white py-1 shadow-lg"
                            role="menu"
                            tabIndex={0}
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                            onClick={(e) => {
                                e.stopPropagation()
                                autoClose && options.close()
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                            onMouseUp={(e) => e.stopPropagation()}
                        >
                            {(() => {
                                if (typeof content === 'function') {
                                    return content(options)
                                }
                                return content
                            })()}
                        </div>
                    </div>
                )
            }}
            {...props}
        />
    )
}

export default DropdownMenu
