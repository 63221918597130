import React from 'react'
import clsx from 'clsx'

export default function HeaderContainer({ className, children }) {
    return (
        <header
            className={clsx('relative border-gray-200 border-b', className)}
        >
            <div className="flex items-center justify-between px-6 mx-auto relative h-16">
                {children}
            </div>
        </header>
    )
}
