/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { forwardRef } from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const ColorPicker = forwardRef(
    (
        {
            color,
            setColor,
            id,
            className,
            inputClassName,
            canClear,
            onClear,
            disabled,
            hasError,
            ...props
        },
        ref
    ) => {
        return (
            <div
                ref={ref}
                className={clsx(
                    'relative rounded-lg shadow-xs flex items-center w-full',
                    className
                )}
            >
                <input
                    id={id}
                    type="text"
                    value={color}
                    disabled={disabled}
                    className={clsx(
                        'pl-12 pr-4 py-2 border block w-full transition duration-150 ease-in-out rounded-lg sm:text-sm sm:leading-5',
                        !hasError &&
                            'border-gray-300 enabled:hover:border-gray-400',
                        hasError &&
                            'border-red-300 text-red-600 placeholder-red-300 enabled:hover:border-red-400',
                        disabled && 'opacity-50 cursor-not-allowed',
                        inputClassName
                    )}
                    {...props}
                />
                <input
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-6 h-6 rounded-md cursor-pointer"
                    type="color"
                    id="favcolor"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                />
                {canClear && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                        <button
                            type="button"
                            onClick={(e) => {
                                if (typeof onClear === 'function') {
                                    onClear()
                                    e.stopPropagation()
                                }
                            }}
                            className="rounded hover:bg-gray-200 p-0.5"
                        >
                            <Icon name="x" size="sm" />
                        </button>
                    </div>
                )}
            </div>
        )
    }
)

export default ColorPicker
