import React, { useState, useRef, forwardRef } from 'react'
import { usePopper } from 'react-popper'
import { motion } from 'framer-motion'
import { Slot } from '@radix-ui/react-slot'
import clsx from 'clsx'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { combineRefs } from '../../utils/index'
import Portal from './Portal'

const EVENT_CLICK = 'click'
const EVENT_HOVER = 'hover'
const POSITION_AUTO = 'auto'
const POSITION_AUTO_START = 'auto-start'
const POSITION_AUTO_END = 'auto-end'
const POSITION_TOP = 'top'
const POSITION_TOP_START = 'top-start'
const POSITION_TOP_END = 'top-end'
const POSITION_BOTTOM = 'bottom'
const POSITION_BOTTOM_START = 'bottom-start'
const POSITION_BOTTOM_END = 'bottom-end'
const POSITION_RIGHT = 'right'
const POSITION_RIGHT_START = 'right-start'
const POSITION_RIGHT_END = 'right-end'
const POSITION_LEFT = 'left'
const POSITION_LEFT_START = 'left-start'
const POSITION_LEFT_END = 'left-end'

const PopoverContainer = forwardRef(
    ({ onOutsideClick, nestedPopover, ...props }, ref) => {
        const containerRef = useRef()
        const containerRefs = nestedPopover
            ? [(containerRef, nestedPopover)]
            : containerRef
        useOnClickOutside(containerRefs, () => {
            if (typeof onOutsideClick === 'function') {
                onOutsideClick()
            }
        })
        const refs = ref !== null ? [ref, containerRef] : [containerRef]
        return <div ref={combineRefs(refs)} {...props} />
    }
)

const AnimatedPopoverContainer = motion(PopoverContainer)

const Popover = ({
    as = 'span',
    asChild,
    position = POSITION_BOTTOM_START,
    event = EVENT_CLICK,
    content,
    hasArrow,
    arrowClassName,
    querySelector = '#popovers',
    className,
    children,
    onOpen,
    onClose,
    nestedPopover,
    onSetRef,
}) => {
    const Component = asChild ? Slot : as
    const [isOpen, setIsOpen] = useState(false)
    const [popperElement, setPopperElement] = useState(null)
    const [referenceElement, setReferenceElement] = useState(null)
    const [arrowElement, setArrowElement] = useState(null)

    let arrowModifiers = []
    if (hasArrow) {
        arrowModifiers = [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                },
            },
            {
                name: 'offset',
                options: { offset: [0, 8] },
            },
        ]
    }

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        strategy: 'fixed',
        placement: position,
        modifiers: [...arrowModifiers],
    })

    const open = () => {
        setIsOpen(true)
        if (typeof onOpen === 'function') {
            onOpen()
        }
    }

    const close = () => {
        setIsOpen(false)
        if (typeof onClose === 'function') {
            onClose()
        }
    }

    const handleMouseEnter = () => {
        if (event === EVENT_HOVER) {
            open()
        }
    }

    const handleMouseLeave = () => {
        if (event === EVENT_HOVER) {
            close()
        }
    }

    return (
        <>
            {/* The trigger element */}
            <Component
                className={clsx('focus:outline-none', className)}
                ref={setReferenceElement}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onClick={(e) => {
                    open()
                    e.stopPropagation()
                }}
                onPointerEnter={handleMouseEnter}
                onPointerLeave={handleMouseLeave}
            >
                {children}
            </Component>

            {/* The popover content */}
            {isOpen && (
                <Portal querySelector={querySelector}>
                    <AnimatedPopoverContainer
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onOutsideClick={close}
                        nestedPopover={nestedPopover}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div
                            ref={(ref) => {
                                setPopperElement(ref)
                                if (typeof onSetRef === 'function') {
                                    onSetRef(ref)
                                }
                            }}
                            style={styles.popper}
                            {...attributes.popper}
                        >
                            {hasArrow && (
                                <div
                                    ref={setArrowElement}
                                    style={styles.arrow}
                                    className={arrowClassName}
                                />
                            )}
                            {(() => {
                                if (typeof content === 'function') {
                                    return content({
                                        open,
                                        close,
                                    })
                                }
                                return content
                            })()}
                        </div>
                    </AnimatedPopoverContainer>
                </Portal>
            )}
        </>
    )
}

Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_AUTO = POSITION_AUTO
Popover.POSITION_AUTO_START = POSITION_AUTO_START
Popover.POSITION_AUTO_END = POSITION_AUTO_END
Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_TOP_START = POSITION_TOP_START
Popover.POSITION_TOP_END = POSITION_TOP_END
Popover.POSITION_BOTTOM = POSITION_BOTTOM
Popover.POSITION_BOTTOM_START = POSITION_BOTTOM_START
Popover.POSITION_BOTTOM_END = POSITION_BOTTOM_END
Popover.POSITION_RIGHT = POSITION_RIGHT
Popover.POSITION_RIGHT_START = POSITION_RIGHT_START
Popover.POSITION_RIGHT_END = POSITION_RIGHT_END
Popover.POSITION_LEFT = POSITION_LEFT
Popover.POSITION_LEFT_START = POSITION_LEFT_START
Popover.POSITION_LEFT_END = POSITION_LEFT_END

export default Popover
