import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

export const START_AUDIT_MUTATION = gql`
    mutation startAudit($id: ID!) {
        startAudit(id: $id)
    }
`

const useStartAuditMutation = (options) => {
    const { id } = useParams()
    return useMutation(START_AUDIT_MUTATION, {
        variables: { id },
        ...options,
    })
}

export default useStartAuditMutation
