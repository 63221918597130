import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'audits',
        validate: isRequired,
        message: (
            <Trans>Choose a audit for this vendor observation report.</Trans>
        ),
    },
])

const useCreateIdentifiedVendorsReportFormState = (selectedAudits) => {
    const formState = useFormState(
        {
            audits: selectedAudits.map((id) => id),
        },
        {
            validation,
        }
    )
    return formState
}

export default useCreateIdentifiedVendorsReportFormState
