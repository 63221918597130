import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import TrackersPageTableRow from './TrackersPageTableRow'

const QUERY = gql`
    query trackersPage(
        $offset: Int
        $search: String
        $filters: TrackersFilters
        $orderBy: TrackerOrderByInput
    ) {
        trackers(
            offset: $offset
            search: $search
            filters: $filters
            orderBy: $orderBy
        ) {
            edges {
                cursor
                node {
                    ...TrackersPageTableRow
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${TrackersPageTableRow.FRAGMENT}
`

const useTrackersPageQuery = ({ search, filters, orderBy }) => {
    const variables = { search, filters, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'trackers'
    )
    if (error) {
        return { error }
    }
    if (isFetching) {
        return {
            trackers: [],
            isFetching,
        }
    }
    return {
        trackers: connectionToCollection(data.trackers),
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && data.trackers.pageInfo.hasNextPage,
    }
}

export default useTrackersPageQuery
