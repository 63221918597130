import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const SWITCH_ORGANIZATION_MUTATION = gql`
    mutation switchOrganization($organizationId: ID!) {
        switchOrganization(organizationId: $organizationId)
    }
`

export default function useSwitchOrganizationMutation() {
    return useMutation(SWITCH_ORGANIZATION_MUTATION)
}
