import React from 'react'
import LogoWithName from '../../utils/LogoWithName'

const LoginPageContainer = ({ children, ...props }) => (
    <div
        className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
        {...props}
    >
        <LogoWithName className="h-20" />
        {children}
    </div>
)

export default LoginPageContainer
