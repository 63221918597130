import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation updateCookies($ids: [ID!], $input: CookiesInput!) {
        updateCookies(ids: $ids, input: $input) {
            cookies {
                id
            }
        }
    }
`

const useUpdateCookiesMutation = (options) => useMutation(MUTATION, options)

export default useUpdateCookiesMutation
