import React from 'react'
import StaffOrganizationUsersPage from './StaffOrganizationUsersPage'
import useStaffOrganizationUsersPageQuery from './useStaffOrganizationUsersPageQuery'

const StaffOrganizationUsersPageWithState = () => {
    const { users, isFetching, isFetchingMore, handleFetchMore, hasMore } =
        useStaffOrganizationUsersPageQuery()
    return (
        <StaffOrganizationUsersPage
            users={users}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            isFetching={isFetching}
        />
    )
}

export default StaffOrganizationUsersPageWithState
