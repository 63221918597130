import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation deleteOrganizationTracker($id: ID!) {
        deleteOrganizationTracker(id: $id)
    }
`

const useDeleteOrganizationTrackerMutation = (options) =>
    useMutation(MUTATION, options)

export default useDeleteOrganizationTrackerMutation
