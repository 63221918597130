import React from 'react'
import { Trans } from '@lingui/macro'
import InfoCard from './InfoCard'
import { getGraphqlErrorMessage } from '../../utils/graphql'

export default function DrawerTabError({ error }) {
    const message = getGraphqlErrorMessage(error)
    return (
        <InfoCard
            iconName="alert-octagon"
            title={<Trans>Something went wrong</Trans>}
            text={message}
            type="error"
        />
    )
}
