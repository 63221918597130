/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Tooltip from './Tooltip'
import AlertTag from './AlertTag'

const AlertTooltip = ({ alert, ...props }) => (
    <Tooltip
        contentClassName="max-w-[400px]"
        content={
            alert && (
                <div>
                    <div className="mb-2">
                        <AlertTag alert={alert}>{alert.severityType}</AlertTag>
                    </div>
                    <div>{alert.message}</div>
                </div>
            )
        }
        {...props}
    />
)

export default AlertTooltip
