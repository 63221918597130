import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import VendorTypeTags from '../../utils/VendorTypeTags'
import useDrawerStack from '../../hooks/useDrawerStack'
import ActionButton from '../../utils/ActionButton'
import VendorDetailsDrawer from './VendorDetailsDrawer'
import VendorsPageActionsDropdownMenu from './VendorsPageActionsDropdownMenu'

const FRAGMENT = gql`
    fragment VendorsPageTableRow on Vendor {
        id
        name
        occurrence
        domain
        types
        imageUrl
        description
        externalId
        privacyPolicyUrl
        cookiesCount
        trackersCount
        createdAt
        updatedAt
    }
`

const VendorsPageTableRow = ({
    vendor,
    onDeleteVendorCompleted,
    onUpdateVendorCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        name,
        types,
        domain,
        externalId,
        privacyPolicyUrl,
        cookiesCount,
        trackersCount,
        occurrence,
        imageUrl,
        description,
        createdAt,
        updatedAt,
    } = vendor
    return (
        <TableRow
            isSelectable
            isActive={currentDrawerProps?.vendorId === id}
            selectionValue={vendor}
            selectionCriteria={(row) => row.id === id}
            onClick={() => {
                open(VendorDetailsDrawer, {
                    vendorId: id,
                })
            }}
        >
            <TableCellAvatar
                columnId="name"
                size="sm"
                avatarSrc={imageUrl}
                avatarIconName="building-07"
                title={name}
                text={
                    domain || (
                        <span className="text-slate-300">
                            <Trans>No Domain</Trans>
                        </span>
                    )
                }
            />
            <TableCell columnId="types" size="sm">
                <VendorTypeTags showAll types={types} />
            </TableCell>
            <TableCell columnId="cookiesCount" size="sm">
                <Tag iconNameBefore="cookie">{cookiesCount}</Tag>
            </TableCell>
            <TableCell columnId="trackersCount" size="sm">
                <Tag iconNameBefore="tracker">{trackersCount}</Tag>
            </TableCell>
            <TableCell columnId="occurrence" size="sm">
                <Tag>{occurrence}</Tag>
            </TableCell>
            <TableCell hasText columnId="description" size="sm">
                {description && (
                    <Tooltip
                        position="top-start"
                        content={description}
                        contentClassName="max-w-[400px]"
                    >
                        {description}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell hasText columnId="privacyPolicyUrl" size="sm">
                {privacyPolicyUrl && (
                    <Tooltip position="top-start" content={privacyPolicyUrl}>
                        {privacyPolicyUrl}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell hasText columnId="externalId" size="sm">
                {externalId}
            </TableCell>
            <TableCell hasText columnId="domain" size="sm">
                {domain}
            </TableCell>
            <TableCell hasText columnId="createdAt" size="sm">
                <DateTime>{createdAt}</DateTime>
            </TableCell>
            <TableCell hasText columnId="updatedAt" size="sm">
                <DateTime>{updatedAt}</DateTime>
            </TableCell>
            <TableCell className="text-right" size="sm">
                <VendorsPageActionsDropdownMenu
                    vendor={vendor}
                    onDeleteVendorCompleted={onDeleteVendorCompleted}
                    onUpdateVendorCompleted={onUpdateVendorCompleted}
                >
                    <ActionButton />
                </VendorsPageActionsDropdownMenu>
            </TableCell>
        </TableRow>
    )
}

VendorsPageTableRow.FRAGMENT = FRAGMENT

export default VendorsPageTableRow
