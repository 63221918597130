import React from 'react'
import { Trans } from '@lingui/macro'
import EmptyPageCard from '../../utils/EmptyPageCard'
import AlertsPageActions from './AlertsPageActions'
import AlertsPageTable from './AlertsPageTable'
import useSelectAlertsState from './useSelectAlertsState'

const AlertsPageWithActions = ({
    scanRangeState,
    selectedAlertStatus,
    alerts,
    totalAlertsCount,
    ignoredAlertsCount,
    reviewedAlertsCount,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onFilterAlerts,
    onAlertStatusChanged,
    filters,
    selectedFilters,
    onChangeFilter,
    onClearFilters,
}) => {
    const level = 'ORGANIZATION'

    const {
        selectedAlerts,
        onIgnoreAlerts,
        onDelegateAlertsInternal,
        onClearAlertsDelegate,
        onDelegateAlertsExternal,
        onRestoreAlerts,
    } = useSelectAlertsState({
        level,
        onAlertStatusChanged,
    })

    return (
        <>
            <AlertsPageActions
                onIgnoreAlerts={onIgnoreAlerts}
                onDelegateAlertsInternal={onDelegateAlertsInternal}
                onClearAlertsDelegate={onClearAlertsDelegate}
                onDelegateAlertsExternal={onDelegateAlertsExternal}
                onRestoreAlerts={onRestoreAlerts}
                selectedAlertStatus={selectedAlertStatus}
                totalAlertsCount={totalAlertsCount}
                ignoredAlertsCount={ignoredAlertsCount}
                reviewedAlertsCount={reviewedAlertsCount}
                scanRangeState={scanRangeState}
                onFilterAlerts={onFilterAlerts}
                filters={filters}
                selectedFilters={selectedFilters}
                onChangeFilter={onChangeFilter}
                onClearFilters={onClearFilters}
            />
            <AlertsPageTable
                startDate={scanRangeState.startDate}
                endDate={scanRangeState.endDate}
                alerts={alerts}
                isFetchingMore={isFetchingMore}
                onFetchMore={onFetchMore}
                hasMoreRecords={hasMoreRecords}
                isFetching={isFetching}
                selectedAlerts={selectedAlerts}
                onAlertStatusChanged={onAlertStatusChanged}
            />
        </>
    )
}

export default AlertsPageWithActions
