import React from 'react'
import { Trans } from '@lingui/macro'
import Input from './Input'
import Button from './Button'

export default function functionSelectListCreateInput({
    buttonText = <Trans>Add</Trans>,
    onAdd,
    ...props
}) {
    return (
        <div className="flex w-full">
            <Input {...props} className="flex-1" />
            <Button
                className="ml-2"
                size="sm"
                iconBeforeProps={{
                    name: 'add',
                    size: 'sm',
                }}
                onClick={onAdd}
            >
                {buttonText}
            </Button>
        </div>
    )
}
