/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useCreateVendorTableReportMutation from '../reports/useCreateVendorTableReportMutation'
import DropdownMenu from '../../utils/DropdownMenu'
import DeleteVendorTableModalWithState from '../vendors-vendor-tables/DeleteVendorTableModalWithState'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import Button from '../../utils/Button'
import Tag from '../../utils/Tag'
import useEvents from '../../hooks/useEvents'
import useNotifications from '../../hooks/useNotifications'

const VendorTableItemsPageMenuDotsButton = ({
    vendorTable,
    onDeleteCompleted,
}) => {
    const { dispatchError } = useNotifications()
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [createReport] = useCreateVendorTableReportMutation()
    const [reportInfo, setReportInfo] = useState(null)

    useEvents(['report:completed', 'report:failed'], (event) => {
        if (event.status === 'FAILED') {
            setLoading(false)
            dispatchError({
                message: <Trans>Export failed</Trans>,
            })
            return
        }
        if (!reportInfo) return

        const { id: reportId, type: reportType } =
            reportInfo.data.createVendorTableReport.report
        const { id: eventId, type: eventType, file } = event.report

        if (reportId === eventId && reportType === eventType) {
            setLoading(false)
            window.open(file.url, '_blank', 'noopener')
        }
    })

    const handleCreateReport = async () => {
        const input = { vendorTableId: vendorTable.id }
        const variables = { input }
        setLoading(true)

        try {
            const response = await createReport({ variables })
            setReportInfo(response)
        } catch (error) {
            console.error('Error creating report:', error)
        }
    }

    return (
        <>
            {deleteModalIsOpen && (
                <DeleteVendorTableModalWithState
                    vendorTable={vendorTable}
                    onDismiss={() => setDeleteModalIsOpen(false)}
                    onCompleted={onDeleteCompleted}
                />
            )}
            <DropdownMenu
                position="bottom-end"
                content={() => (
                    <>
                        <DropdownMenuItem
                            iconName="download"
                            disabled={isLoading}
                            isLoading={isLoading}
                            onClick={() => handleCreateReport()}
                        >
                            <Trans>Export vendor table</Trans>
                            <Tag type="green" className="ml-2">
                                .xlsx
                            </Tag>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={() => setDeleteModalIsOpen(true)}
                        >
                            <Trans>Delete vendor table</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                <Button
                    className="h-[40px]"
                    type="white"
                    iconBeforeProps={{
                        name: 'menu-dots',
                        size: 'sm',
                    }}
                />
            </DropdownMenu>
        </>
    )
}

export default VendorTableItemsPageMenuDotsButton
