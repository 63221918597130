import React from 'react'
import clsx from 'clsx'

const CardHeader = ({
    title,
    description,
    className,
    titleClassName,
    descriptionClassName,
    rightChildren,
    topChildren,
    ...props
}) => (
    <div className={clsx('w-full', className)} {...props}>
        {topChildren && <div className="mb-4">{topChildren}</div>}
        <div className="mb-5 flex items-center justify-between">
            <div>
                {title && (
                    <h3
                        className={clsx(
                            'text-lg leading-6 font-medium text-gray-900',
                            titleClassName
                        )}
                    >
                        {title}
                    </h3>
                )}
                {description && (
                    <p
                        className={clsx(
                            'text-sm leading-5 text-gray-500 mt-1',
                            descriptionClassName
                        )}
                    >
                        {description}
                    </p>
                )}
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
                {rightChildren}
            </div>
        </div>
    </div>
)

export default CardHeader
