import React from 'react'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const UserInvitationsPageTableRowActionButton = ({
    isLoading,
    canResendInvitation,
    canDeleteInvitation,
    onResendInvitation,
    onDeleteUser,
}) => (
    <DropdownMenu
        position="bottom-start"
        content={({ close }) => (
            <>
                <DropdownMenuItem
                    iconName="email"
                    disabled={isLoading || !canResendInvitation}
                    onClick={async () => {
                        await onResendInvitation()
                        close()
                    }}
                >
                    Resend Invitation
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="bin"
                    disabled={!canDeleteInvitation}
                    onClick={async () => {
                        await onDeleteUser()
                        close()
                    }}
                >
                    Delete invitation
                </DropdownMenuItem>
            </>
        )}
    >
        <ActionButton disabled={isLoading} aria-label="Dismiss" />
    </DropdownMenu>
)

export default UserInvitationsPageTableRowActionButton
