import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import CustomCmpsPageTableRowActionButtonWithState from './CustomCmpsPageTableRowActionButtonWithState'

export const FRAGMENT = gql`
    fragment CustomCmpsPageTableRow on Cmp {
        id
        name
    }
`

const CustomCmpsPageTableRow = ({ cmp, onDeleteCmpCompleted }) => {
    const { name } = cmp
    const history = useHistory()
    return (
        <TableRow
            isSelectable
            data-testid="CustomCmpsPageTableRow"
            onClick={() => {
                history.push(`/settings/cmps/${cmp.id}`)
            }}
        >
            <TableCell>{name}</TableCell>
            <TableCell className="text-right">
                <CustomCmpsPageTableRowActionButtonWithState
                    cmp={cmp}
                    onDeleteCmpCompleted={onDeleteCmpCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

CustomCmpsPageTableRow.FRAGMENT = FRAGMENT

export default CustomCmpsPageTableRow
