import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import UserInvitationsPageTableRow from '../settings-users-invitations/UserInvitationsPageTableRow'

export const INVITE_USER_MUTATION = gql`
    mutation inviteUser($input: InviteUserInput!) {
        inviteUser(input: $input) {
            invitation {
                ...UserPageInvitationTableRow
            }
        }
    }
    ${UserInvitationsPageTableRow.FRAGMENT}
`

const useInviteUserMutation = () => useMutation(INVITE_USER_MUTATION)

export default useInviteUserMutation
