import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query myOrganizations($search: String) {
        myOrganizations(search: $search) {
            id
            name
            imageUrl
        }
    }
`

const useMyOrganizationsQuery = ({ search }) => {
    const variables = { search }
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        organizations: data.myOrganizations || [],
        isFetching,
    }
}

export default useMyOrganizationsQuery
