import { useReducer } from 'react'
import dotProp from 'dot-prop-immutable'

const CLEAR_COLLECTION = 'CLEAR_COLLECTION'
const ADD_TO_COLLECTION = 'ADD_TO_COLLECTION'
const ADD_ALL_TO_COLLECTION = 'ADD_ALL_TO_COLLECTION'
const REMOVE_FROM_COLLECTION = 'REMOVE_FROM_COLLECTION'

const INITIAL_STATE = {
    compareKey: 'id',
    collection: [],
}

const createReducer = () => (state, action) => {
    const { type, value } = action
    switch (type) {
        case ADD_TO_COLLECTION: {
            return dotProp.set(state, 'collection', (values) => [
                ...values,
                value,
            ])
        }
        case ADD_ALL_TO_COLLECTION: {
            return dotProp.set(state, 'collection', (values) => [
                ...values,
                ...value,
            ])
        }
        case REMOVE_FROM_COLLECTION: {
            const { compareKey } = state
            return dotProp.set(state, 'collection', (values) =>
                values.filter(
                    (currentValue) =>
                        currentValue[compareKey] !== value[compareKey]
                )
            )
        }
        case CLEAR_COLLECTION: {
            return INITIAL_STATE
        }
        default:
            throw new Error(`Unknown action ${action.type}`)
    }
}

const useCollectionState = (initialState = {}) => {
    const [state, dispatch] = useReducer(createReducer(), {
        ...INITIAL_STATE,
        ...initialState,
    })

    const addToCollection = (value) =>
        dispatch({
            type: ADD_TO_COLLECTION,
            value,
        })

    const addAllToCollection = (value) =>
        dispatch({
            type: ADD_ALL_TO_COLLECTION,
            value,
        })

    const removeFromCollection = (value) =>
        dispatch({
            type: REMOVE_FROM_COLLECTION,
            value,
        })

    const clearCollection = (value) =>
        dispatch({
            type: CLEAR_COLLECTION,
            value,
        })

    return {
        ...state,
        addToCollection,
        addAllToCollection,
        removeFromCollection,
        clearCollection,
    }
}

export default useCollectionState
