import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import StaffUnclassifiedCookiesPageTableRow from './StaffUnclassifiedCookiesPageTableRow'
import StaffUnclassifiedCookiesPageTableHeaderRow from './StaffUnclassifiedCookiesPageTableHeaderRow'

const StaffUnclassifiedCookiesTable = ({
    unclassifiedCookies = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onClassifyCookieCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
            <thead>
                <StaffUnclassifiedCookiesPageTableHeaderRow />
            </thead>
            <TableBody>
                {unclassifiedCookies.map((unclassifiedCookie) => (
                    <StaffUnclassifiedCookiesPageTableRow
                        key={unclassifiedCookie._id}
                        unclassifiedCookie={unclassifiedCookie}
                        onClassifyCookieCompleted={onClassifyCookieCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default StaffUnclassifiedCookiesTable
