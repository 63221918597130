import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const MUTATION = gql`
    mutation uploadSession($input: UploadSessionInput!) {
        uploadSession(input: $input) {
            session {
                id
            }
        }
    }
`

const useUploadSessionMutation = (options) => useMutation(MUTATION, options)

export default useUploadSessionMutation
