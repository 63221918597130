import React from 'react'
import clsx from 'clsx'

export default function EmptyTable({
    title,
    description,
    children,
    colSpan,
    className,
    rowClassName,
}) {
    return (
        <tr className={rowClassName}>
            <td
                colSpan={colSpan}
                className={clsx('text-center py-8', className)}
            >
                <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
                    {title}
                </h3>
                <p className="mt-2 max-w-lg text-sm leading-5 text-gray-500 mx-auto">
                    {description}
                </p>
                {children}
            </td>
        </tr>
    )
}
