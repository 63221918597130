/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const IdentifiedVendorsPageTableRowActions = ({
    vendor,
    isLoading,
    onUpdate,
    children,
}) => (
    <DropdownMenu
        as="span"
        position="bottom-start"
        content={({ close }) => (
            <DropdownMenuItem
                iconName="edit"
                disabled={!vendor.belongsToOrganization}
                onClick={(e) => {
                    onUpdate(vendor)
                    close()
                    e.stopPropagation()
                }}
            >
                <Trans>Update your classification</Trans>
            </DropdownMenuItem>
        )}
    >
        {children}
    </DropdownMenu>
)

export default IdentifiedVendorsPageTableRowActions
