import React from 'react'
import { Trans } from '@lingui/macro'
import InfoCard from '../../utils/InfoCard'
import Tag from '../../utils/Tag'

const ALERT_SUCCESS = 'success'
const ALERT_ERROR = 'error'
const ALERT_WARNING = 'warning'

const getAlertType = (alertRecords) => {
    if (alertRecords.errors.length > 0) {
        return ALERT_ERROR
    }
    if (alertRecords.warnings.length > 0 && alertRecords.errors.length === 0) {
        return ALERT_WARNING
    }
    return ALERT_SUCCESS
}
const getAlertInfo = (alertType) => {
    let iconName = ''
    let title = ''
    let description = ''

    switch (alertType) {
        case ALERT_ERROR:
            iconName = 'alert-octagon'
            title = <Trans>File Validation Error</Trans>
            description = (
                <Trans>
                    The file is not valid and cannot be uploaded. Please fix the
                    errors below and retry to upload.
                </Trans>
            )

            break
        case ALERT_WARNING:
            iconName = 'alert-triangle'
            title = <Trans>File Validation Warning</Trans>
            description = (
                <Trans>
                    The file is valid and can be uploaded but certain records
                    could not be matched . Only valid records will be updated.
                </Trans>
            )

            break
        default:
            iconName = 'check-circle'
            title = <Trans>File Validation Successful</Trans>
            description = <Trans>The file is valid and can be uploaded.</Trans>
    }

    return { iconName, title, description }
}

const BulkUploadAlertCard = ({ className, alertRecords, children }) => {
    const alertType = getAlertType(alertRecords)
    const alertInfo = getAlertInfo(alertType)
    return (
        <InfoCard
            className={className}
            type={alertType}
            title={alertInfo.title}
            text={alertInfo.description}
            iconName={alertInfo.iconName}
        >
            {alertType === 'error' || alertType === 'warning' ? (
                <div className="overflow-y-auto max-h-52 mt-4 w-full">
                    <div className="border border-gray-200 rounded-lg overflow-hidden">
                        <table className="divide-y divide-gray-200">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-4 py-3 text-start text-xs font-medium text-gray-600">
                                        Row
                                    </th>
                                    <th className="px-4 py-3 text-start text-xs font-medium text-gray-600">
                                        Type
                                    </th>
                                    <th className="px-4 py-3 text-start text-xs font-medium text-gray-600">
                                        Message
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {alertRecords.errors.map((record) => (
                                    <tr>
                                        <td className="px-4 py-2 text-sm font-semibold text-gray-600">
                                            {record.row}
                                        </td>
                                        <td className="px-4 text-sm text-gray-600">
                                            <Tag type="red">{record.type}</Tag>
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-600 w-full">
                                            {record.message}
                                        </td>
                                    </tr>
                                ))}
                                {alertRecords.warnings.map((record) => (
                                    <tr>
                                        <td className="px-4 py-2 text-sm text-gray-600 font-semibold">
                                            {record.row}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-600">
                                            <Tag type="orange">
                                                {record.type}
                                            </Tag>
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-600">
                                            {record.message}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                children
            )}
        </InfoCard>
    )
}

export default BulkUploadAlertCard
