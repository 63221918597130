import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const CHANGE_USER_ROLE_MUTATION = gql`
    mutation changeUserRole($input: ChangeUserRoleInput!) {
        changeUserRole(input: $input)
    }
`

const useChangeUserRoleMutation = () => useMutation(CHANGE_USER_ROLE_MUTATION)

export default useChangeUserRoleMutation
