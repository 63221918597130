import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query alertsDashboard($filters: AlertFilters) {
        alertsByPeriod(filters: $filters) {
            warningAlerts
            issueAlerts
            criticalAlerts
        }
    }
`

const useAlertsDashboardQuery = (auditId, type, tag, startDate, endDate) => {
    const filters = {
        auditId,
        type,
        tags: tag ? [tag] : [],
        startDate,
        endDate,
    }
    const variables = { filters }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    return {
        alertsByPeriod: data.alertsByPeriod,
        error,
        isFetching,
        refetch,
    }
}

export default useAlertsDashboardQuery
