import React, { useState } from 'react'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import AuditDomainsPageConsentProfilesTableRowActionButton from './AuditDomainsPageConsentProfilesTableRowActionButton'
import RemoveConsentProfileModalWithState from './RemoveConsentProfileModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const AuditDomainsPageConsentProfilesTableRowActionButtonWithState = ({
    audit,
    consentProfile,
    onRemoveConsentProfileCompleted,
}) => {
    const { currentUser } = useAppState()
    const [isRemoveProfileModalOpen, setRemoveProfileModalOpen] =
        useState(false)
    return (
        <>
            {isRemoveProfileModalOpen && (
                <RemoveConsentProfileModalWithState
                    audit={audit}
                    consentProfile={consentProfile}
                    onDismiss={() => setRemoveProfileModalOpen(false)}
                    onCompleted={onRemoveConsentProfileCompleted}
                />
            )}
            <AuditDomainsPageConsentProfilesTableRowActionButton
                consentProfile={consentProfile}
                canRemoveConsentProfile={currentUser.role === OWNER}
                onRemoveConsentProfile={() => setRemoveProfileModalOpen(true)}
            />
        </>
    )
}

export default AuditDomainsPageConsentProfilesTableRowActionButtonWithState
