import React from 'react'
import LoadingIndicator from './LoadingIndicator'

export default function DrawerTabLoading() {
    return (
        <div className="flex justify-center items-center h-[200px]">
            <LoadingIndicator />
        </div>
    )
}
