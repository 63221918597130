/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const StaffUnclassifiedCookiesPageTableRowActionButton = ({
    isLoading,
    onClassify,
}) => (
    <DropdownMenu
        as="span"
        position="bottom-start"
        content={({ close }) => (
            <DropdownMenuItem
                iconName="cookie"
                onClick={(e) => {
                    onClassify()
                    close()
                    e.stopPropagation()
                }}
            >
                <Trans>Classify cookie</Trans>
            </DropdownMenuItem>
        )}
    >
        <ActionButton disabled={isLoading} />
    </DropdownMenu>
)

export default StaffUnclassifiedCookiesPageTableRowActionButton
