import React from 'react'
import gql from 'graphql-tag'
import * as constants from '../../../constants'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import UserInvitationsPageTableRowActionButtonWithState from './UserInvitationsPageTableRowActionButtonWithState'

const {
    INVITATION_STATUS_PENDING: PENDING,
    INVITATION_STATUS_EXPIRED: EXPIRED,
    INVITATION_STATUS_ACCEPTED: ACCEPTED,
} = constants

const FRAGMENT = gql`
    fragment UserPageInvitationTableRow on Invitation {
        id
        email
        role
        status
    }
`

const statusTypeMap = {
    [PENDING]: 'orange',
    [EXPIRED]: 'red',
    [ACCEPTED]: 'primary',
}

const UserInvitationsPageTableRow = ({
    invitation,
    onDeleteUserSuccess,
    onResendInvitationSuccess,
}) => {
    const { email, role, status } = invitation
    return (
        <TableRow data-testid="UsersPageTableRow">
            <TableCell columnId="email" hasText>
                {email}
            </TableCell>
            <TableCell columnId="role" hasText>
                <Tag className="capitalize">{role.toLowerCase()}</Tag>
            </TableCell>
            <TableCell columnId="status">
                <Tag className="capitalize" type={statusTypeMap[status]}>
                    {status.toLowerCase()}
                </Tag>
            </TableCell>
            <TableCell hasText className="text-right">
                <UserInvitationsPageTableRowActionButtonWithState
                    invitation={invitation}
                    onDeleteUserSuccess={onDeleteUserSuccess}
                    onResendInvitationSuccess={onResendInvitationSuccess}
                />
            </TableCell>
        </TableRow>
    )
}

UserInvitationsPageTableRow.FRAGMENT = FRAGMENT

export default UserInvitationsPageTableRow
