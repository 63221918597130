import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import EmptyPageCard from '../../utils/EmptyPageCard'
import SidebarContainer from '../../layout/SidebarContainer'
import SettingsPageHeader from '../settings/SettingsPageHeader'
import CustomCmpsPageTable from './CustomCmpsPageTable'
import OrganizationHeader from '../../layout/OrganizationHeader'

const CustomCmpsPage = ({
    cmps = [],
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onDeleteCmpCompleted,
    onCreateCmp,
}) => (
    <PageContainer data-testid="SettingsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <PageContent>
                <SettingsPageHeader />
                {(() => {
                    if (cmps.length === 0) {
                        return (
                            <EmptyPageCard
                                iconName="settings"
                                title={<Trans>No custom CMPs</Trans>}
                                description={
                                    <Trans>
                                        No custom cmps were found for this
                                        organization.
                                        <br />
                                        Start by adding new custom cmps here.
                                    </Trans>
                                }
                            >
                                <Button onClick={onCreateCmp} className="mt-6">
                                    <Trans>Create new custom CMP </Trans>
                                </Button>
                            </EmptyPageCard>
                        )
                    }
                    return (
                        <>
                            <div className="flex flex-row justify-end items-center mb-8">
                                <Button onClick={onCreateCmp}>
                                    <Trans>Create CMP</Trans>
                                </Button>
                            </div>
                            <CustomCmpsPageTable
                                cmps={cmps}
                                isFetchingMore={isFetchingMore}
                                hasMoreRecords={hasMoreRecords}
                                onFetchMore={onFetchMore}
                                onDeleteCmpCompleted={onDeleteCmpCompleted}
                            />
                        </>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default CustomCmpsPage
