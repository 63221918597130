import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import ConsentProfilesPageTableRowActionButton from './ConsentProfilesPageTableRowActionButton'
import DeleteConsentProfileModalWithState from './DeleteConsentProfileModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const ConsentProfilesPageTableRowActionButtonWithState = ({
    consentProfile,
    onDeleteCompleted,
}) => {
    const history = useHistory()
    const { currentUser } = useAppState()
    const deleteModalIsOpen = useRouteMatch({
        path: `/settings/consent-profiles/${consentProfile.id}/delete`,
    })
    return (
        <>
            {deleteModalIsOpen && (
                <DeleteConsentProfileModalWithState
                    consentProfile={consentProfile}
                    onDismiss={() => history.push('/settings/consent-profiles')}
                    onCompleted={onDeleteCompleted}
                />
            )}
            <ConsentProfilesPageTableRowActionButton
                consentProfile={consentProfile}
                canDelete={currentUser.role === OWNER}
                onDelete={() =>
                    history.push(
                        `/settings/consent-profiles/${consentProfile.id}/delete`
                    )
                }
            />
        </>
    )
}

export default ConsentProfilesPageTableRowActionButtonWithState
