/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DropdownMenuTitle from '../../utils/DropdownMenuTitle'
import ActionButton from '../../utils/ActionButton'

const ScanTrackersPageTableRowActionButtonStaff = ({
    tracker,
    isLoading,
    canClassify,
    canDelete,
    canUpdate,
    onClassify,
    onDelete,
    onUpdate,
    onClassifyOrganization,
    onDeleteOrganization,
    onUpdateOrganization,
    children,
}) => (
    <DropdownMenu
        as="span"
        position="bottom-end"
        content={({ close }) => (
            <>
                <DropdownMenuTitle>
                    <Trans>Staff actions</Trans>
                </DropdownMenuTitle>
                <DropdownMenuItem
                    iconName="tracker"
                    disabled={!canClassify}
                    onClick={(e) => {
                        onClassify()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Classify tracker</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={!canUpdate}
                    onClick={(e) => {
                        onUpdate(tracker)
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Update classification</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="x"
                    disabled={!canDelete}
                    onClick={(e) => {
                        onDelete()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Remove classification</Trans>
                </DropdownMenuItem>
                <DropdownMenuTitle>
                    <Trans>Organization actions</Trans>
                </DropdownMenuTitle>
                <DropdownMenuItem
                    iconName="tracker"
                    disabled={tracker !== null && tracker.belongsToOrganization}
                    onClick={(e) => {
                        onClassifyOrganization()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Classify tracker for organization</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="edit"
                    disabled={
                        tracker === null ||
                        (tracker && !tracker.belongsToOrganization)
                    }
                    onClick={(e) => {
                        onUpdateOrganization(tracker)
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Update organization classification</Trans>
                </DropdownMenuItem>
                <DropdownMenuItem
                    iconName="x"
                    disabled={
                        tracker === null ||
                        (tracker && !tracker.belongsToOrganization)
                    }
                    onClick={(e) => {
                        onDeleteOrganization()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Remove organization classification</Trans>
                </DropdownMenuItem>
            </>
        )}
    >
        {children}
    </DropdownMenu>
)

export default ScanTrackersPageTableRowActionButtonStaff
