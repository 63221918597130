/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import MultiInput from './MultiInput'
import ActionSelectorInput from './ActionSelectorInput'
import DraggableList from './DraggableList'
import Icon from './Icon'
import Button from './Button'
import ButtonLink from './ButtonLink'

export default function MultiActionSelectorInput({
    actions,
    options,
    onAdd,
    onChange,
    onRemove,
    inputKey,
}) {
    return (
        <MultiInput onAdd={onAdd}>
            <DraggableList
                items={actions}
                onChange={(values) => {
                    onChange(inputKey, values)
                }}
            >
                {actions.map((action, index) => {
                    return (
                        <div
                            className="flex items-center w-full gap-2 mb-2"
                            key={index}
                        >
                            <DraggableList.Item
                                id={index + 1}
                                className="w-full"
                            >
                                <MultiInput.Item key={index}>
                                    <ActionSelectorInput
                                        type={action.type}
                                        selector={action.selector}
                                        options={options}
                                        onChangeType={(value) =>
                                            onChange(
                                                `${inputKey}.${index}.type`,
                                                value
                                            )
                                        }
                                        onChangeSelector={(value) =>
                                            onChange(
                                                `${inputKey}.${index}.selector`,
                                                value
                                            )
                                        }
                                        name={`${inputKey}.${index}.selector`}
                                    />
                                </MultiInput.Item>
                            </DraggableList.Item>
                            <ButtonLink
                                onClick={() => onRemove(index)}
                                type="danger"
                                className="hover:text-red-800 flex-shrink-0 transition-colors duration-150 ease-in-out"
                            >
                                Remove
                            </ButtonLink>
                        </div>
                    )
                })}
            </DraggableList>
        </MultiInput>
    )
}
