import React, { useState } from 'react'
import HeaderProfile from './HeaderProfile'
import useAppState from '../hooks/useAppState'

export default function HeaderProfileWithState() {
    const { logout, currentUser } = useAppState()
    const [isOpen, setIsOpen] = useState(false)
    return (
        <HeaderProfile
            currentUser={currentUser}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            isOpen={isOpen}
            onLogout={() => logout()}
        />
    )
}
