import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import InfiniteScroll from '../../utils/InfiniteScroll'
import CustomCmpsPageTableRow from './CustomCmpsPageTableRow'

const CustomCmpsPageTable = ({
    cmps,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    onDeleteCmpCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table data-testid="CustomCmpsPageTable">
            <thead>
                <tr>
                    <TableHeaderCell>
                        <Trans>Consent management platform</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell />
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {cmps.map((cmp) => (
                    <CustomCmpsPageTableRow
                        key={cmp.id}
                        cmp={cmp}
                        onDeleteCmpCompleted={onDeleteCmpCompleted}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default CustomCmpsPageTable
