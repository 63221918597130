import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
    mutation deleteCookie($id: ID!) {
        deleteCookie(id: $id)
    }
`

const useDeleteCookieMutation = (options) => useMutation(MUTATION, options)

export default useDeleteCookieMutation
