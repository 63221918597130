import React, { useState } from 'react'
import TableCell from '../../utils/TableCell'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import AuditAvatar from '../../utils/AuditAvatar'
import AuditAlertSummary from '../../utils/AuditAlertSummary'
import CollapseButton from '../../utils/CollapseButton'
import useDrawerStack from '../../hooks/useDrawerStack'
import ScanPageDetailsDrawer from '../scans/ScanPageDetailsDrawer'

const GroupedAlertObservationTableRow = ({ audit, pages = [] }) => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const { add } = useDrawerStack()
    return (
        <>
            <TableRow>
                <td className="pl-6 w-[45px]">
                    <CollapseButton
                        isCollapsed={isCollapsed}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsCollapsed(!isCollapsed)
                        }}
                    />
                </td>
                <TableCell px="none">
                    <AuditAvatar audit={audit} />
                </TableCell>
                <TableCell>
                    <AuditAlertSummary audit={audit} />
                </TableCell>
            </TableRow>
            {!isCollapsed && (
                <tr>
                    <td colSpan="10">
                        <div className="max-h-[400px] overflow-y-scroll">
                            {pages.length > 0 && (
                                <table className="w-full">
                                    <TableBody>
                                        {pages.map(
                                            (page) =>
                                                page.audit.id === audit.id && (
                                                    <TableRow
                                                        isSelectable
                                                        onClick={() =>
                                                            add(
                                                                ScanPageDetailsDrawer,
                                                                {
                                                                    scanPageId:
                                                                        page.id,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <TableCell className="pl-12">
                                                            {page.url}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                        )}
                                    </TableBody>
                                </table>
                            )}
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}

export default GroupedAlertObservationTableRow
