import { useEffect } from 'react'
import { Events, parseEvent } from '../../utils/events'

export default function useEvents(events = [], handler) {
    useEffect(() => {
        const instance = Events.getInstance()
        const listener = (event) => {
            const { id, data } = parseEvent(event)
            if (events.includes(id)) {
                handler(data)
            }
        }
        instance.eventSource.addEventListener('message', listener)
        return () => {
            instance.eventSource.removeEventListener('message', listener)
        }
    }, [events, handler])
}
