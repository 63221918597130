/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef } from 'react'
import { Trans } from '@lingui/macro'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import Icon from './Icon'
import DropdownMenu from './DropdownMenu'
import DropdownMenuItem from './DropdownMenuItem'
import DropdownMenuItemDivider from './DropdownMenuItemDivider'
import useDragResize from '../hooks/useDragResize'
import useTableState from '../hooks/useTableState'

const TableHeaderCell = ({
    className,
    children,
    columnId,
    initialWidth = 100,
    isInitiallyHidden,
    canResize = false,
    canHide = false,
    canSort = false,
    canReorder = false,
    isSticky = false,
    sortDirection,
    noPaddingLeft = false,
    noPaddingRight = false,
    onSort,
    onHide,
    ...props
}) => {
    const tableState = useTableState()
    useEffect(() => {
        if (tableState && columnId) {
            tableState.registerColumn(columnId, {
                label: children,
                canResize,
                canHide,
                canSort,
                isSticky,
                canReorder,
                isInitiallyHidden,
            })
            return () => {
                tableState.unregisterColumn(columnId)
            }
        }
    }, [])
    const ref = useRef()
    const { width, isDragging, handleDragStart } = useDragResize(ref, {
        initialWidth,
        isEnabled: canResize,
    })
    let Component = 'th'
    let resizeProps = {}
    if (canResize) {
        Component = motion.th
        resizeProps = {
            animate: { width: `${width}px`, maxWidth: `${width}px` },
            transition: { duration: 0 },
        }
    }
    let TextComponent = 'div'
    if (canSort || canHide) {
        TextComponent = 'button'
    }
    let sortIconName = 'chevron-selector-vertical'
    let sortIconClassName = 'text-gray-300'
    if (tableState) {
        if (tableState.sortColumnId === columnId) {
            if (tableState.sortDirection === 'ASC') {
                sortIconName = 'sort-asc'
                sortIconClassName = 'text-gray-500'
            }
            if (tableState.sortDirection === 'DESC') {
                sortIconName = 'sort-desc'
                sortIconClassName = 'text-gray-500'
            }
        }
        if (tableState.hiddenColumnIds.includes(columnId)) {
            return null
        }
    }
    return (
        <Component
            ref={ref}
            className={clsx(
                'header-cell relative py-1.5 bg-gray-50 text-left text-gray-500 tracking-wider break-all z-10',
                !isSticky && !canResize && 'sticky-border-bt',
                !isSticky && canResize && 'sticky-border-brt',
                isSticky && 'sticky top-0 z-10',
                isSticky && canResize && 'sticky-border-brt',
                isSticky && !canResize && 'sticky-border-bt',
                canResize && 'overflow-hidden whitespace-nowrap',
                !noPaddingLeft && 'pl-3',
                !noPaddingRight && 'pr-3',
                className
            )}
            {...resizeProps}
            {...props}
        >
            <DropdownMenu
                position="bottom-start"
                enabled={canSort || canHide}
                content={({ close }) => (
                    <>
                        {canSort && (
                            <>
                                <DropdownMenuItem
                                    iconName="sort-asc"
                                    onClick={() => {
                                        if (typeof onSort === 'function') {
                                            onSort(columnId, 'ASC')
                                        }
                                        if (tableState) {
                                            tableState.changeOrderBy(
                                                columnId,
                                                'ASC'
                                            )
                                        }
                                        close()
                                    }}
                                >
                                    <Trans>Sort Ascending</Trans>
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    iconName="sort-desc"
                                    onClick={() => {
                                        if (typeof onSort === 'function') {
                                            onSort(columnId, 'DESC')
                                        }
                                        if (tableState) {
                                            tableState.changeOrderBy(
                                                columnId,
                                                'DESC'
                                            )
                                        }
                                        close()
                                    }}
                                >
                                    <Trans>Sort Descending</Trans>
                                </DropdownMenuItem>
                            </>
                        )}
                        {canSort && canHide && <DropdownMenuItemDivider />}
                        {canHide && (
                            <DropdownMenuItem
                                iconName="eye-close"
                                onClick={() => {
                                    if (typeof onHide === 'function') {
                                        onHide()
                                    }
                                    if (tableState) {
                                        tableState.hideColumn(columnId)
                                    }
                                    close()
                                }}
                            >
                                <Trans>Hide column</Trans>
                            </DropdownMenuItem>
                        )}
                    </>
                )}
            >
                <TextComponent
                    type={canSort || canHide ? 'button' : undefined}
                    className={clsx(
                        'text-xs leading-4 font-medium uppercase flex items-center truncate  rounded-md ',
                        canSort && 'pl-3 pr-1 py-1',
                        !canSort && 'px-3 py-1.5',
                        (canSort || canHide) && 'hover:bg-gray-200'
                    )}
                >
                    {children}
                    {canSort && (
                        <Icon
                            size="sm"
                            color="none"
                            name={sortIconName}
                            className={clsx('ml-1', sortIconClassName)}
                        />
                    )}
                </TextComponent>
            </DropdownMenu>
            {canResize && (
                <button
                    tabIndex="-1"
                    type="button"
                    className={clsx(
                        'absolute top-0 right-0 bottom-0 w-1 cursor-col-resize hover:bg-primary',
                        isDragging && 'bg-primary'
                    )}
                    onMouseDown={handleDragStart}
                />
            )}
        </Component>
    )
}

export default TableHeaderCell
