import { format, isValid } from 'date-fns'
import useAppState from '../hooks/useAppState'
import * as constants from '../../constants'

const { DEFAULT_DATE_FORMAT: DATE_FORMAT, DEFAULT_TIME_FORMAT: TIME_FORMAT } =
    constants

const DateTime = ({
    children,
    dateOnly = false,
    timeOnly = false,
    dateFormat,
    timeFormat,
}) => {
    const { currentUser = {} } = useAppState()
    let finalDateFormat = dateFormat
    let finalTimeFormat = timeFormat
    if (!dateFormat) {
        finalDateFormat = currentUser.dateFormat || DATE_FORMAT
    }
    if (!timeFormat) {
        finalTimeFormat = currentUser.timeFormat || TIME_FORMAT
    }
    const date = new Date(children)
    if (!isValid(date)) {
        return 'Invalid date'
    }
    finalDateFormat = finalDateFormat.replace('mm', 'MM')
    let dateTimeFormat = `${finalDateFormat} ${finalTimeFormat}`
    if (dateOnly) {
        dateTimeFormat = finalDateFormat
    }
    if (timeOnly) {
        dateTimeFormat = finalTimeFormat
    }
    return format(date, dateTimeFormat)
}

export default DateTime
