import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import AuditSettingsPageLoginProfileTableRow from './AuditSettingsPageLoginProfileTableRow'
import EmptyTable from '../../utils/EmptyTable'
import Button from '../../utils/Button'

const AuditSettingsPageLoginProfilesTable = ({
    audit,
    loginProfiles = [],
    onRemoveLoginProfileCompleted,
    onAddLoginProfile,
}) => (
    <Table data-testid="AuditSettingsPageTable">
        <thead>
            <tr>
                <TableHeaderCell>
                    <Trans>Login Profile</Trans>
                </TableHeaderCell>
                <TableHeaderCell />
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {loginProfiles.length > 0 ? (
                loginProfiles.map((loginProfile) => (
                    <AuditSettingsPageLoginProfileTableRow
                        key={loginProfile.id}
                        audit={audit}
                        loginProfile={loginProfile}
                        onRemoveLoginProfileCompleted={
                            onRemoveLoginProfileCompleted
                        }
                    />
                ))
            ) : (
                <EmptyTable
                    colSpan={2}
                    title={<Trans>No login profiles</Trans>}
                    description={
                        <Trans>
                            No login profiles were found for this audit.
                        </Trans>
                    }
                >
                    <Button
                        onClick={onAddLoginProfile}
                        className="mt-6 mx-auto"
                        iconBeforeProps={{
                            name: 'plus',
                            color: 'white',
                        }}
                    >
                        Add login profile
                    </Button>
                </EmptyTable>
            )}
        </tbody>
    </Table>
)

export default AuditSettingsPageLoginProfilesTable
