import React from 'react'
import AlertListItem from './AlertListItem'
import { getDelegatedByText } from '../../../utils/delegations'

export default function MyAlertListItem({ alertDelegation, onClick, user }) {
    return (
        <AlertListItem item={alertDelegation} onClick={onClick}>
            {getDelegatedByText(alertDelegation.author, user)}
        </AlertListItem>
    )
}
