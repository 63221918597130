import React from 'react'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'
import VendorTableVersionCompareModalTableActions from './VendorTableVersionCompareModalTableActions'
import VendorTableVersionCompareModalTableHeaderRow from './VendorTableVersionCompareModalTableHeaderRow'
import VendorTableVersionCompareModalTableRow from './VendorTableVersionCompareModalTableRow'

const VendorTableVersionCompareModalTable = ({
    deletedVendors = [],
    sameVendors = [],
    newVendors = [],
    deprecatedVendors = [],
    onUpdateCompleted,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => {
    const [activeTab, setActiveTab] = React.useState('all')

    const allVendors = React.useMemo(() => {
        return [
            ...newVendors.map((vendor) => ({ ...vendor, type: 'new' })),
            ...deletedVendors.map((vendor) => ({ ...vendor, type: 'danger' })),
            ...sameVendors.map((vendor) => ({ ...vendor, type: 'same' })),
            ...deprecatedVendors.map((vendor) => ({
                ...vendor,
                type: 'warning',
            })),
        ]
    }, [newVendors, deletedVendors, sameVendors, deprecatedVendors])

    const getVendorsToRender = () => {
        switch (activeTab) {
            case 'new':
                return newVendors.map((vendor) => ({ ...vendor, type: 'new' }))
            case 'deleted':
                return deletedVendors.map((vendor) => ({
                    ...vendor,
                    type: 'danger',
                }))
            case 'deprecated':
                return deprecatedVendors.map((vendor) => ({
                    ...vendor,
                    type: 'warning',
                }))
            case 'same':
                return sameVendors.map((vendor) => ({
                    ...vendor,
                    type: 'same',
                }))
            case 'all':
            default:
                return allVendors
        }
    }
    const vendorsToRender = getVendorsToRender()

    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <VendorTableVersionCompareModalTableActions
                allCount={allVendors.length}
                addedCount={newVendors.length}
                deletedCount={deletedVendors.length}
                deprecatedCount={deprecatedVendors.length}
                sameCount={sameVendors.length}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
            />
            <Table
                className="h-[55vh] overflow-x-hidden"
                isFetching={isFetching}
            >
                <thead>
                    <VendorTableVersionCompareModalTableHeaderRow />
                </thead>
                <TableBody>
                    {vendorsToRender.map((vendor) => (
                        <VendorTableVersionCompareModalTableRow
                            key={vendor.id}
                            vendorItem={vendor}
                            rowType={vendor.type}
                            onUpdateCompleted={onUpdateCompleted}
                        />
                    ))}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}

export default VendorTableVersionCompareModalTable
